export const  events =  [
  {
    id:'igor-bulgakov-poetic-paintings',
    active: true,
    name:
        `Игорь Булгаков. Поэтические полотна`,
    titel:`Тематический цикл «Экология Сознания». Синтез философии, живописи и поэзии.
    `,
    place: `
      Галерея на «Мосфильме»
      г. Москва, ул. Мосфильмовская, д. 1, стр. 18
    `,
    time: 'С 12:00 до 21:00. Выходной: понедельник. ',
    img: 'event_title_polotna.png',
    imgMainDesktop:'photo_5292249446932930802_y.jpg',
    imgMainMobile:'event_title_polotna.jpg',
    additionally: {
      text: `
<h2>Поэтические полотна</h2>
<p>&nbsp;</p>
<p>Становление Игоря Яковлевича Булгакова как художника, притом художника неординарного, можно даже сказать, уникального, аналогов которому трудно подыскать в мировом изобразительном искусстве, и к тому же создающего живописные полотна за один подход, свершилось в уже достаточно зрелом возрасте.</p>
<p>Произведения И.Я. Булгакова &mdash; визуализация неуловимого духа, обитающего в безграничности вселенной, зачастую человеком не осознаваемого , но безусловно наличествующего в глубине его подсознания.</p>
<p>Иначе говоря, картины художника обладают свойством вызывать в подсознании зрителя онтологические аспекты собственной жизни, особенности субъективного опыта, имманентные свойства духовных переживаний. На этих полотнах изображены миры, которых мы как будто никогда не видели, но чувствовали возможность их существования.</p>
<p>Вы летали когда-нибудь ночью во сне? Если хоть раз это с вами случалось, вы навсегда запомнили захватывающее ощущение парения.</p>
<p>Подобное чувство овладевает мной, когда я стою перед картинами Игоря Булгакова. В них открывается множество планетных систем с таинственными обитателями, которые непостижимым образом свидетельствуют не только о давнопрошедших временах, но и приоткрывают завесу грядущего.</p>
<p>Вдохновенные изображения с первого взгляда завораживают вдумчивого зрителя и заставляют подолгу всматриваться во всеобъемлющие просторы загадочных макромиров, запечатлённых на полотнах художника. Хотя в случае живописных произведений Игоря Яковлевича слово &laquo;зритель&raquo; не очень адекватно, здесь уместнее выражение &laquo;созерцатель&raquo;.</p>
<p>Сопутствующие многим картинам поэтические тексты, которые Игорь Булгаков пишет без черновиков, так же как и картины, за один подход, генерируют формирование синергического эффекта, многократно усиливающего взаимодействие живописи и лексики, тем самым способствуя более глубокому пониманию содержания , а следовательно, и индивидуальному расширению границ познания с возможностью осознавания познанного.</p>
<p>Искусствовед, культуролог Т. Строганова, член Ассоциации искусствоведов, член Творческого союза художников России</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2>Рецензия на Поэтические полотна И.Я.&nbsp;Булгакова</h2>
<p>&nbsp;</p>
<p>Отражение жизни в любом из видов искусства происходит в единстве отображения и выражения. Но одни искусства, в том числе и живопись, преимущественно идут от изображения к выражению, а другие, включая поэзию, наоборот, от выражения к изображению. На первый взгляд эти искусства (живопись и поэзия) достаточно далеки друг от друга. Одно статично, неподвижно, другое динамично, развивается во времени. И тем не менее, между ними могут быть не только черты общности, но при определенных условиях они проникают друг в друга и даже могут вступать в некоторый художественный синтез.</p>
<p>Как известно, к синтезу искусств особенно стремились художники эпохи Возрождения, творцы Серебряного века. Живописец Василий Кандинский говорил о всеобъемлющей гармонии искусств, а композитор Рихард Вагнер создал концепцию Gesamtkunstwerk (совокупного произведения искусства). Однако все эти люди добивались гармоничного соединения различных пространственных и временных искусств путем кропотливой продуманной работы. В творчестве же нашего современника доктора философии Игоря Яковлевича Булгакова синтез живописи и поэзии не надуманный, а интуитивный. Он не создает свои картины, на которых живут лишенные знаков препинания тексты, по специальному заказу, его синкретичные произведения рождаются исключительно стихийно, по воле вдохновения:</p>
<p><em>Я бы мог на холст добавить красок</em></p>
<p><em>Мог бы холст скомпоновать иначе</em></p>
<p><em>Но не рисовальщик я</em></p>
<p><em>И значит</em></p>
<p><em>Нет передо мной такой задачи</em></p>
<p><em>Я на холст выплескиваю чувства</em></p>
<p><em>Жидкой краской</em></p>
<p><em>Как</em></p>
<p><em>И сам не знаю</em></p>
<p><em>Я про холст как будто забываю</em></p>
<p><em>Как выходит так</em></p>
<p><em>Не понимаю</em></p>
<p><em>Что проявит холст не я решаю</em></p>
<p><em>Тот решает</em></p>
<p><em>Кто рукою водит</em></p>
<p><em>Я лишь удивляясь наблюдаю</em></p>
<p><em>Кто и как ко мне на холст приходит</em></p>
<p><em>Из различных уровней Вселенной</em></p>
<p><em>Многоликостью проявленных мгновений</em></p>
<p><em>Открывая Душу восприятью</em></p>
<p><em>Вечной жизни</em></p>
<p><em>Высших откровений</em></p>
<p>Будучи уже зрелым человеком И.&nbsp;Я.&nbsp;Булгаков стал писать стихи, в которых нет ни единого знака препинания. В этих поэтических опусах мысль течет единым потоком, нескованная правилами пунктуации:</p>
<p><em>В каждой строчке заложен ритм</em></p>
<p><em>Жизнетворных частот вибрации</em></p>
<p><em>Формирующих диапазон</em></p>
<p><em>Волновым лучом генерации</em></p>
<p><em>Что имеет свою длину</em></p>
<p><em>Что имеет свое звучание</em></p>
<p><em>Что включает палитру всю</em></p>
<p><em>Цветоносного содержания&hellip;.</em></p>
<p>Позднее, когда у И.&nbsp;Я.&nbsp;Булгакова возникла острая потребность посмотреть, как сосуществуют краски на холсте, он взял в руки мастихин и масляные краски. Стоит отметить, что И.&nbsp;Я.&nbsp;Булгаков не получил специального художественного образования и не изучал законов построения перспективы, анатомии и т.д. Подходя к чистому холсту (tabula rasa ‒ чистая доска, лат.) он не знает, какое изображение на нем в результате возникнет. По сути, И.Я.&nbsp;Булгаков, который определяет свою манеру работы как энергоинформационная живопись, непроизвольно воспроизводит, облекает в конкретные осязаемые формы те образы, которые уже существуют на просторах вселенной.</p>
<p>В этой связи необходимо упомянуть об истории появления авторской монограммы И.&nbsp;Я.&nbsp;Булгакова, которой он подписывает свои картины. Однажды, находясь на грани сна и бодрствования (по словам художника), И.&nbsp;Я.&nbsp;Булгаков отчетливо увидел сияющий в небесной безграничности знак ‒ энергетическую символику переплетающихся линий, которую по прошествии некоторого времени он смог расшифровать как собственные инициалы.</p>
<p>То, о чем И.&nbsp;Я.&nbsp;Булгаков пишет в стихах: любовь, подлость, вечная борьба двух начал, - он визуализирует на своих полотнах, которые всегда создаются только за один сеанс. Главные герои всех булгаковских картин, объединенных автором в цикл &laquo;Экология Сознания&raquo;, &minus; Человек и Вселенная.</p>
<p>На ранних живописных холстах И.&nbsp;Я.&nbsp;Булгакова, написанных в удивительно прозрачной акварельной манере, очень часто можно увидеть пришедших неведомо откуда одиноких персонажей, которые изображены в окружении многочисленных ликов &ndash; бессмертных душ:</p>
<p><em>Я на краю стою</em></p>
<p><em>Вокруг меня бездонье</em></p>
<p><em>Не различить объема глубины</em></p>
<p><em>Масштабы те что были мне известны</em></p>
<p><em>Не применить</em></p>
<p><em>Границы не видны&hellip;.</em></p>
<p>В некоторых случаях человеческие фигуры намечены лишь силуэтно, едва проявляются, а в других ‒ вполне телесно трактованы, проработаны в полноте объема, несмотря на то, что И.&nbsp;Я.&nbsp;Булгаков не изучал пластическую анатомию! Каким образом такое возможно пока остается загадкой&hellip;</p>
<p>Конечно, с течением времени манера письма И.&nbsp;Я.&nbsp;Булгакова и характер его работ претерпевают изменения. Живопись становится более пастозной, мазок &ndash; размашистым, небесно-солнечные тона постепенно уступают место более насыщенным темным драматичным краскам, а количество проявленных на булгаковских холстах персонажей неминуемо увеличивается. Существуя на разных уровнях в иерархии вселенной, эти человеческие образы так или иначе взаимодействуют между собой. Так, одну из картин И.&nbsp;Я.&nbsp;Булгакова, датированных 2015 годом, можно трактовать как вечный суд, где практически всю плоскость холста занимает сложенная из страдающих существ высокая гора, на вершине которой представлены стаффажные человеческие фигурки, объединенные в хоровод-предстояние. Верхний же фрагмент картины напоминает небесный свод, который поддерживают титаны.</p>
<p>Как известно, в живописи изображение создается на плоскости, в двух измерениях, но в творчестве большинства художников стремится перейти в пространство &ndash; три измерения. На картинах же И.&nbsp;Я.&nbsp;Булгакова искусство живописи словно еще более расширяет свои границы и переходит в четвертое измерение &minus; передачи времени в развитии. На каждом полотне художника запечатлено застывшее мгновение вечности: прошлое, настоящее и будущее. По словам И.&nbsp;Я.&nbsp;Булгакова, в основе всех его живописных холстов лежит формула &ndash; одно во всем и все в одном:</p>
<p><em>Отсутствие понятий измерений</em></p>
<p><em>Дает почувствовать безмерность величин</em></p>
<p><em>Эфирной зыби в красоте явлений</em></p>
<p><em>Вселенской плоти</em></p>
<p><em>С коей я един&hellip;..</em></p>
<p>Каждая картина И.&nbsp;Я.&nbsp;Булгакова, созданная по наитию, без опоры на какие-либо законы построения академической живописной композиции, и при этом нередко сопровождаемая поэтическими текстами, представляет собой масштабную энергоинформационную поэму о бесконечности человеческого бытия, в которой гармонично соединяются черты пространственных и временных искусств:</p>
<p><em>Многообразье измерений творя невидимость границ</em></p>
<p><em>Собой являет книгу Сутей с незримым множеством страниц&hellip;..</em></p>
<p><em>&nbsp;</em></p>
<p><strong>Александра Хомякова &minus; искусствовед</strong></p>
      `
    }
  },
  {
    id:'premiere-at-the-museum-of-modern-art',
    active: false,
    name: 'В Музее современного искусства — премьера',
    titel:
        `Впервые свои работы ярославцам представляет Игорь Булгаков. Почти половину работ автор привёз в столицу Золотого кольца.`,
    place: `
      Мемориальный Дом-музей Л.В. Собинова
      г. Ярославль, ул. Собинова, 25
    `,
    time: '17 — 19 декабря 2021 г., с 10:00 до 22:00',
    img:'event_title_yaroslavl.jpg',
    imgMainDesktop: null,
    imgMainMobile: null,
    additionally: {
      video: 'https://www.youtube.com/embed/WfTwF_Z5b4A?si=O9J7dRFej0pO5MEL',
      text:`
        В Доме муз — Музее современного искусства — премьера. Впервые свои работы ярославцам представляет Игорь Булгаков.
        Почти половину работ автор привёз в столицу Золотого кольца.
        Каждая сопровождается поэтическими строчками. Главная тема мастера — экология сознания.
        Игорь Булгаков, доктор философии:
        «Лучом тончайшим ухожу в простор. В просторе я свободен от проблем. В просторе я живу. Простор мой дом.
        Простор безмерен, как безмерна вечность. Простор в понятии времени беспечность. И я беспечен, я являюсь им».
        Первые опыты уникального постижения мира обращены в книгу. Эволюция творчества — картины и сопровождающие их стихи, 
        а возможно наоборот, сначала слово, потом — живые краски бытия. Безусловный синтез образов.
        Игорь Булгаков, доктор философии:
        «Сам по себе этот храм, будем называть так, с душой. Она здесь присутствует. И рад тому, что мои холсты этот храм воспринял».
        Небесный и солнечный, от зимнего рассвета до южной ночи — такова палитра художника. 
        Искусствоведы называют творчество Игоря Булгакова серьёзным культурологическим явлением.
        Елена Шахова, генеральный директор ИКК «Вятское» им. Е.А. Анкудиновой:
        «Эти работы, они наполнены любовью, и заставляют нас задуматься о бытие, о подсознательном,
        о космической составляющей нашего бытия, о бесконечности Вселенной. Мне кажется, это такое новогоднее чудо, 
        сказка, которая подарена Центром Современного искусства, будет очень привлекательна. И мы приглашаем бесплатно до конца февраля».
        Картины Игоря Булгакова приглашают не к созерцанию. В них нужно погрузиться, улавливая в глубинах неясные тени,
        обрывки мыслей, смутные образы. Энергоинформационные поля. Просторы Вселенной.
        Марина Позднякова:
        «Безусловно, понимаешь и принимаешь сразу. Принимаешь стих, принимаешь полотно. Кажется, что-то про тебя. 
        Очень хорошо, что такие работы, такие художники имеют возможность быть представлены ярославской публике».
        Художник экспонировал работы и в Звёздном городке, и в Центре искусств на Покровке, во многих городах России.
        Сложился круг людей, которым близки духовные искания философа. Умеющего видеть и слышать, и передавать свои чувства другим.
      `,
      images:[
        'photo_02.jpg',
        'photo_03.jpg',
        'photo_04.jpg',
        'photo_05.jpg',
        'photo_06.jpg',
        'photo_07.jpg',
        'photo_08.jpg',
        'photo_09.jpg',
        'photo_10.jpg',
      ]

    }
  },
  // {
  //   id:'valaam-through-the-eyes-of-the-brethren',
  //   active: false,
  //   name: 'Валаам глазами братии',
  //   titel:
  //     `Фотографическая выставка «ВАЛААМ ГЛАЗАМИ БРАТИИ»  была представлена посетителям Галерей под открытым небом, как на самом Валааме, так и в Москве, на Тверском бульваре в Галерее Российского Фонда Мира, а  так же  на  «новых территориях» в Галерее Музея Победы.
  //     `,
  //   place: `
  //     Российский Фонд Мира
  //     г. Москва, ул. Пречистенка, д. 10, строение 2
  //     `,
  //   time: null,
  //   img:'event_title_valaam.jpg',
  //   additionally: {
  //     video: null,
  //     text: `
  //       В ходе общения с посетителями обнаружилось единство признания чрезвычайной своевременности данной выставки.
  //       Благодарность организаторам и устроителям выставки, формирующей в обществе условия для Духовно-нравственного возрождения.
  //       Единая и непререкаемая поддержка традиционных гуманитарных ценностей нашей огромной, в настоящий период сплочённой как никогда, страны.
  //       Единство мнения в вопросе популяризации традиционных морально-этических норм и Духовно- нравственного воспитания общества, в том числе посредством
  //       охвата большего количества регионов России для обустройства подобных Галерей под открытым небом, в том числе и в целях обеспечения всеобщей
  //       доступности.
  //     `,
  //     images:null
  //   }
  // },
  {
    id:'starfall-for-every-day',
    active: false,
    name: 'Звездопад на каждый день',
    titel:`Творческий вечер доктора философии, художника и поэта Игоря Булгакова, посвящённый выходу в свет сборников поэтического цикла «Экология Сознания».`,

    place: `
      Центр современного искусства «Винзавод» 
      г. Москва, 4-й Сыромятнический переулок, 1/8с6
    `,
    time: null,
    img:'event_title_zvezdopad.jpg',
    imgMainDesktop: null,
    imgMainMobile:null,
    additionally: {
      video: null,
      text: `
      Новые стихотворные сборники Игоря Булгакова относится к лирической поэзии ровно настолько, насколько требует принадлежность жанру, в который облечено самобытное творчество автора - преимущественно философское, мировоззренческое. Прикосновение к нему не сулит читателю легкого развлечения, а ждет от него сосредоточенности и искренности. Доктор философии Игорь Булгаков в другой своей ипостаси - художник-живописец. Для воплощения изобразительных образов он берет краски и кисть. А слово использует по его коренному и прямому назначению, неотрывному от смыслов. Его произведения - способ философского познания глубин человеческой души, причинно-следственных свойств: "...нет во Вселенной такого процесса, который был бы замкнут внутри себя и тем самым не имел бы резонансных свойств, не являлся бы в определенной степени формирующим иные, более сложные процессы".

      Ведущая
      Заслуженный артист России, Заслуженный деятель искусств России
      Галина Преображенская
      
      В программе вечера
      - вернисаж живописных работ Игоря Булгакова
      - презентация поэтических сборников Игоря Булгакова серии "Экология Сознания":
      "В спираль закрученная мысль", "Знакомьтесь, это МЫ", "Директория Любви", "Звездопад на каждый день"
      - выступление искусствоведа Татьяны Строгановой
      `,
      images:[
        'event_01.jpg',
        'event_02.jpg',
        'event_03.jpg',
        'event_04.jpg',
        'event_05.jpg',
        'event_06.jpg',
      ]
    }
  },
  {
    id:'vinzavod',
    active: false,
    name:
        `Творческий вечер в центре современного искусства "Винзавод"`,
    titel:`Творческий вечер доктора философии, поэта и художника Игоря Булгакова, посвященный выходу в свет поэтических сборников серии "Экология Сознания".
    `,
    place: `
      г. Москва, 4-й Сыромятнический переулок, 1/8с21
    `,
    time: '11 сентября 2012',
    img: 'vinzavod_title.jpg',
    imgMainDesktop: null,
    imgMainMobile:null,
    additionally: {
      text: `
      <p>Звездопад на каждый день</p>
<p>В Центре современного искусства "Винзавод" 11 сентября 2012 года состоится творческий вечер доктора философии, поэта и художника Игоря Булгакова, посвященный выходу в свет поэтических сборников серии "Экология Сознания".</p>
<p>Новые стихотворные сборники Игоря Булгакова относится к лирической поэзии ровно настолько, насколько требует принадлежность жанру, в который облечено самобытное творчество автора - преимущественно философское, мировоззренческое. Прикосновение к нему не сулит читателю легкого развлечения, а ждет от него сосредоточенности и искренности. Доктор философии Игорь Булгаков в другой своей ипостаси - художник-живописец. Для воплощения изобразительных образов он берет краски и кисть. А слово использует по его коренному и прямому назначению, неотрывному от смыслов. Его произведения - способ философского познания глубин человеческой души, причинно-следственных свойств: "...нет во Вселенной такого процесса, который был бы замкнут внутри себя и тем самым не имел бы резонансных свойств, не являлся бы в определенной степени формирующим иные, более сложные процессы".</p>
<p>Ведущая<br />Заслуженный артист России, Заслуженный деятель искусств России Галина Преображенская.</p>
<p>В программе вечера<br />- вернисаж живописных работ Игоря Булгакова<br />- презентация поэтических сборников Игоря Булгакова серии "Экология Сознания": "В спираль закрученная мысль", "Знакомьтесь, это МЫ", "Директория Любви", "Звездопад на каждый день"<br />- выступление искусствоведа Татьяны Строгановой</p>
      `
    }
  },
  {
    id: 'bogoroditsk',
    active: false,
    name:
        `Выставка живописных работ Игоря Булгакова «Экология Сознания»`,
    titel: `Такое событие как выставка живописных работ Игоря Булгакова для Богородицкого дворца-музея и парка — не новость: несколько лет назад богородчане очень тепло восприняли творческий посыл Игоря Яковлевича. В этот раз он представляет свои новые работы..
      `,
    place: `
        Тульская область, г. Богородицк, территория парка, д. 1
      `,
    time: '3 апреля - 2 мая 2015',
    img: 'bogoroditsk_title.jpg',
    imgMainDesktop: null,
    imgMainMobile:null,
    additionally: {
      text: `
        <p>Такое событие как выставка живописных работ Игоря Булгакова для Богородицкого дворца-музея и парка &mdash; не новость: несколько лет назад богородчане очень тепло восприняли творческий посыл Игоря Яковлевича. В этот раз он представляет свои новые работы в жанре, который он сам характеризует как энергоинформационная живопись: &laquo;&hellip;что проявит холст не я решаю /Тот решает кто рукою водит /Я лишь удивляясь наблюдаю кто и как ко мне на холст приходит&hellip;&raquo;</p>
<p>В картинах Булгакова в центре сюжета &mdash; микрокосмос внутреннего мира человека, который проходит путь духовного, нравственного очищения &mdash; катарсиса. Это и дало название выставки &mdash; &laquo;Экология Сознания&raquo;. Автор задается вопросом: &laquo;&hellip;мы считаем, что понятие экология включает в себя исключительно физическое засорение природы. И услышав об экологии Мысли, экологии Души, экологии Сознания, мы всерьез начинаем думать над тем, что это &mdash; шутка или глупость?&raquo; Ответы найдет зритель, внимательно вглядывающийся в живописный мир Булгакова. Ему откроется безграничность сознания и бескрайность творчества, бесконечность оттенков мироощущения и &laquo;многоликость моментов&raquo;. На некоторых работах автора присутствуют поэтические строчки, которые, несомненно, дополнят восприятие метафизической живописи Булгакова.</p>
        `
    }
  },
  {
    id:'xudozhnik',
    active: false,
    name: 'Художник и философ Игорь Булгаков приоткрывает тайны экологии сознания',
    titel:`В Москве проходит выставка поэтической живописи Игоря Булгакова. Представленные в экспозиции картины объединены в философский художественно-поэтический цикл «Экология Сознания».`,

    place: null,
    time: `3 апреля 2017`,
    img:'xudozhnik_title.jpg',
    imgMainDesktop: null,
    imgMainMobile:null,
    additionally: {
      video: null,
      text: `
      <p>Приходя на выставки, мы порой ищем красивые пейзажи, сильные характеры на портретах. Все это есть и у Булгакова, но, при этом, как-то не выпирает. Тут главенствует другое.</p>
<p>&laquo;Жизнь и бытие в искусстве предполагает полное погружение в его пространство. Пространство это сложное и оно не про &laquo;красиво&raquo;. Это пространство провоцирует художника на вызовы, а он, в свою очередь, дает ответы и, прежде всего, себе самому, а уж потом зрителю или критику&raquo;, &ndash; рассказала обозревателю &laquo;Мира новостей&raquo; арт-критик Галина Мажейкина.</p>
<p>Без специального консультанта или гида на выставке не обойтись. Как будто поняв это, организаторы снабдили все полотна стихотворными комментариями автора. Игорь Булгаков вообще необычный человек &ndash; он не только доктор философии и член творческого Союза художников России, но и член Союза писателей. Известно, что к синтезу искусств стремились художники эпохи Возрождения, а также творцы Серебряного века. В стихах Булгакова нет знаков препинания:</p>
<p>Отсутствие понятий измерений<br />Дает почувствовать безмерность величин<br />Эфирной зыби в красоте явлений<br />Вселенской плоти<br />С коей я един&hellip;</p>
<p>То, о чем Игорь Булгаков пишет в стихах: любовь, подлость, вечная борьба двух начал, - он визуализирует на своих полотнах, которые всегда создаются только за один сеанс. Главные герои всех булгаковских картин, объединенных автором в цикл &laquo;Экология Сознания&raquo;, &minus; Человек и Вселенная.</p>
<p>&laquo;Как известно, в живописи изображение создается на плоскости, в двух измерениях, но в творчестве большинства художников стремится перейти в пространство &ndash; три измерения. На картинах же Игоря Булгакова искусство живописи словно еще более расширяет свои границы и переходит в четвертое измерение &minus; передачи времени в развитии. На каждом полотне художника запечатлено застывшее мгновение вечности: прошлое, настоящее и будущее&raquo;, &ndash; комментирует искусствовед Александра Хомякова.</p>
<p>После просмотра экспозиции автор порекомендовал гостям еще раз пройтись по выставке и внимательно всмотреться в картины, поскольку так они открываются глубже. Мы последовали совету и можем подтвердить, что увидели то, что в первый раз пропустили. Сходите и убедитесь сами&hellip;</p>
<p>Азат Примов.</p>
<p>Фото Маргариты Мельниковой.</p>
      `,
      images:[
        'xudozhnik-01.jpg',
        'xudozhnik-02.jpg',
      ]
    }
  },
  {
    id:'2019gallery',
    active: false,
    name: 'Выставка работ Игоря Булгакова',
    titel:`Фотогалерея`,

    place: null,
    time: `2019 г.`,
    img:'2019gallery_title.jpg',
    imgMainDesktop: null,
    imgMainMobile:null,
    additionally: {
      video: null,
      text: null,
      images:[
        '2019gallery-01.jpg',
        '2019gallery-03.jpg',
        '2019gallery-04.jpg',
        '2019gallery-05.jpg',
        '2019gallery-06.jpg',
        '2019gallery-08.jpg',
        '2019gallery-10.jpg',
        '2019gallery-11.jpg',
        '2019gallery-12.jpg',
        '2019gallery-13.jpg',
        '2019gallery-14.jpg',
        '2019gallery-15.jpg',
        // '2019gallery-16.jpg',
        // '2019gallery-17.jpg',
        // '2019gallery-18.jpg',
        '2019gallery-02.jpg',
        '2019gallery-07.jpg',
        '2019gallery-09.jpg',
      ]
    }
  },
  {
    id:'bolgaria',
    active: false,
    name: 'Чтить и преумножать традиции',
    titel:`В канун Дня славянской письменности и культуры в галерее НЕрисованной живописи Игоря Булгакова прошла презентация недавно созданной Ассоциации «Ассоциация и развитие болгарской культуры»`,

    place: null,
    time: `24.05.2021`,
    img:'bolgaria_title.jpg',
    imgMainDesktop: null,
    imgMainMobule: null,
    additionally: {
      video: null,
      text: `
      <p>В канун Дня славянской письменности и культуры в галерее НЕрисованной живописи Игоря Булгакова &ndash; доктора философии, члена Творческого союза художников России, члена союза писателей России, автора живописно&ndash;поэтического цикла &laquo;Экология сознания&raquo; &ndash; прошла презентация недавно созданной Ассоциации &laquo;Ассоциация и развитие болгарской культуры&raquo;. Организаторы Ассоциации приурочили данное событие к одному из значимых праздников Болгарии и России и назвали его &laquo;Вечер, посвященный объединяющей силе духовности и Дню славянской письменности и культуры&raquo;.</p>
<p>В мероприятии принимали участие Антонио Николайчев &ndash; президент Ассоциации &laquo;Ассоциация и развитие болгарской культуры&raquo;, Катя Жекова &ndash; директор офиса по туризму посольства Болгарии, Здравко Димитров, член Ассоциации, управляющий компании &laquo;Мир Болгарии&raquo;, Елена Шахова &ndash; генеральный директор историко-культурного комплекса &laquo;Вятское&raquo; им. Елены Анкудиновой. Почетным гостем вечера стал Чрезвычайный и Полномочный Посол Болгарии в РФ Атанас Крыстин.</p>
<p>По словам Атанаса Крыстина, святые равноапостольные Кирилл и Мефодий &ndash; наиболее почитаемые болгарские святые. День памяти этих святых, известный как День славянской письменности и культуры, начали праздновать в Болгарии еще в XIX веке, а затем эта традиция перешла в другие славянские страны. В настоящее время День славянской письменности и культуры &ndash; это день единства славянских народов, объединенных богатейшими традициями, добрососедскими отношениями и культурно-историческим наследием, пронесенным через века.</p>
<p>&laquo;Мы должны гордиться, что мы славяне. Пускай славянская культура из года в год процветает и передается последующим поколениям&raquo;.</p>
<p>Организатор вечера &ndash; Нина Лесина.</p>
<p>Фото Владимира Орехова</p>
      `,
      images:[
        'bolgaria-01.jpg',
        'bolgaria-02.jpg',
        'bolgaria-03.jpg',
        'bolgaria-04.jpg',
        'bolgaria-05.jpg',
        'bolgaria-06.jpg',
        'bolgaria-07.jpg',
        'bolgaria-08.jpg',
        'bolgaria-09.jpg',
        'bolgaria-10.jpg',
        'bolgaria-11.jpg',
      ]
    }
  },
]