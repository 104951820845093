<template>
<div class="main">
        <div class="module about">
            <div class="module-center">
                <div class="module-content">
                    Здесь будет политика конфиденциаальности
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AboutView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
