export const  poems =  [
  // «Звездопад на каждый день»
  {
    id: '1',
    name: 'В стихах основы жизни есть',
    text: `
      <strong>В</strong> стихах основы жизни есть
      <em>Рекомендую их прочесть</em>
      Коль примешь их Душой
      Тогда
      Жизнь наградит тебя сполна
    `
  },
  {
    id: '2',
    name: 'Зачем слова запоминать',
    text: `
      <strong>З</strong>ачем слова запоминать
      Куда важней Суть Смысла понимать
      <em>Запомнить их а Сути не понять</em>
      <em>Как не нашедши</em>
    `,
   // sound:'dvn-mozhno-ya-s-toboj-mp3.mp3'
  },
  {
    id:'3',
    name: 'Не торопись читать глазами',
    text: `
      <strong>Н</strong>е торопись читать глазами
      Прочтя Душой
      Отыщешь для себя ч<em>то прежде знал</em>
      Но новизна звучаний
      Откроет <strong>то</strong>
      Что не предполагал
    `,
   // sound:'basta-na-zare-alyans-cover-mp3.mp3'
  },
  {
    id:'4',
    name: 'Целительный бальзам',
    text: `
      <strong>Ц</strong>елительный бальзам
      Рифмованной строкою
      Творит собою Рождество тебя
      <em>В том случае когда</em>
      Приняв слова Душою
      В словах моей Души
      Ты растворишь себя
    `
  },
  {
    id:'5',
    name: 'Не прячу я от вас что мне открыто Богом',
    text: `
      <strong>Н</strong>е прячу я от вас что мне открыто Богом
      <strong>О том</strong> что ждёт нас за порогом
      Порогом перехода в Мир иной
      <em>Дор<strong>о</strong>гой прежде хоженою мной</em>
    `
  },
  {
    id:'6',
    name: 'Как только сердце биться перестанет',
    text: `
      <strong>Как</strong> только сердце биться перестанет
      Себя в Мирах других увидеть срок настанет
      Но есть иные
      Им и сейчас открыт канал
      И их не мало
      <em>Я их там встречал</em>
    `
  },
  {
    id:'7',
    name: 'Прозрачность чистоты Духовных Знаний',
    text: `
      <strong>П</strong>розрачность чистоты Духовных Знаний
      Величьем простоты их пониманий
      Откроет Суть времён
      Чредой былых событий
      В грядущее построивших мосты
    `
  },
  {
    id:'8',
    name: 'Понять сокрытый смысл дня',
    text: `
      <strong>П</strong>онять сокрытый смысл дня
      Довольно просто
      <em>Для этого всего лишь нужно снять</em>
      <em>С Души кор<strong>о</strong>сту</em>
    `
  },
  {
    id:'9',
    name: 'Случайным не бывает завтра',
    text: `
      <strong>С</strong>лучайным не бывает <em>завтра</em>
      Оно
      Рождённое <em>вчера</em>
      Живёт <em>сегодня</em>
      Прошивая собой пространства-времена
    `
  },
  {
    id:'10',
    name: 'Событие',
    text: `
      <strong>С</strong><strong>обытие</strong>
      <em>Причина</em> есть и <em>следствие</em>
      Которое даст знать себя впоследствии
      <em>Причиной</em> став события <strong>того</strong>
      Что <em>следствием </em>является давно
    `
  },
  {
    id:'11',
    name: 'Лишь размотав события клубок',
    text: `
      <strong>Л</strong>ишь размотав события <em>клубок</em>
      Поймёшь
      Насколько Смысл <em>клубка</em> глубок
      &nbsp;
      <em>Первопричины своих проблем в себе ищи</em>
      <em>Не хочешь в этом разобраться</em>
      <em>Так не взыщи</em>
    `
  },
  {
    id:'12',
    name: 'Одно во Всём и Всё в Одном',
    text: `
      <strong><em>О</em></strong><strong><em>дно во Всём и Всё в Одном</em></strong>
      Мы этой формулой живём
      Живём
      Того не понимая
      <em>Что Всё в себе</em>
      <em>Себя во Всём</em>
      <em>Живём</em>
      <em>Буквально растворяя</em>
    `
  },
  {
    id:'13',
    name: 'Картинки',
    text: `
      <strong>К</strong>артинки
      <strong>Те </strong>что открывает зренье
      Являют Суть
      <strong><em>Коль есть прозренье</em></strong>
    `
  },
  {
    id:'14',
    name: 'Когда бы нам увидеть дали то',
    text: `
      <strong>К</strong>огда бы нам увидеть дали <strong>то</strong>
      Что кроется за гранью пониманья
      <em>Возможно</em>
      Некоторым это помогло б
      Расширить зону самоосознанья
    `
  },
  {
    id:'15',
    name: 'Ты постарайся осознать',
    text: `
      <strong>Т</strong>ы постарайся осознать
      <strong><em>Что видишь</em></strong>
      <em>Нужно понимать</em>
    `
  },
  {
    id:'16',
    name: 'Прозрачность',
    text: `
      <strong>П</strong>розрачность
      Многие считают <em>пустотой</em>
      И лишь немногие
      Считают <em>чистотой</em>
    `
  },
  {
    id:'17',
    name: 'Увидит каждый в своей жизни то',
    text: `
      <strong>У</strong>видит каждый в своей жизни <strong>то</strong>
      Что сможет
      Ему его Душа поможет увидеть <strong>то</strong>
      Что он обязан знать
      <strong><em>Знать </em></strong>
      <em>Означает</em>
      <strong><em>Осознать</em></strong>
    `
  },
  {
    id:'18',
    name: 'Жизнь',
    text: `
      <strong>Ж</strong>изнь это ключик к <em>познаванью</em>
      А что есть ключ к <em>осознаванью</em>
    `
  },
  {
    id:'19',
    name: 'Жизнь коротка',
    text: `
      <strong>Ж</strong>изнь коротка
      Но Вечен её Миг
      <em>&nbsp;</em>
      <em>Поймет лишь <strong>тот</strong> кто Истину постиг</em>
    `
  },
  {
    id:'20',
    name: 'Живём не там и не тогда',
    text: `
      <strong>Ж</strong>ивём не там и не тогда
      Живём не так
      Живём не с теми
      <em>У каждого своя пора</em>
      <em>Поразбираться в этой теме</em>
    `
  },
  {
    id:'21',
    name: 'Живём',
    text: `
      <strong>Ж</strong>ивём
      Законы жизни зная
      <em>Но многими из них пренебрегая</em>
    `
  },
  {
    id:'22',
    name: 'Была не драка',
    text: `
      <strong>Б</strong>ыла не драка
      <strong>Настоящий бой</strong>
      <em>В длиннющей очереди за Святой Водой</em>
    `
  },
  {
    id:'23',
    name: 'Всё взаимосвязано настолько',
    text: `
      <strong>В</strong>сё взаимосвязано <strong><em>настолько</em></strong>
      Что понять не в силах мы насколько
    `
  },
  {
    id:'24',
    name: 'Когда вчера проснётся в завтра',
    text: `
      <strong>К</strong>огда <strong><em>вчера</em> </strong>проснётся в <strong><em>завтра</em></strong>
      Построив <strong><em>завтра </em></strong><em>под себя</em>
      Не пожалеть бы о <strong><em>сегодня</em></strong>
    `
  },
  {
    id:'25',
    name: 'Осознанная Суть факторов влиянья',
    text: `
      <strong>О</strong>сознанная Суть факторов влиянья
      Определяет развитость Сознанья
      &nbsp;
      При ограниченном развитии Сознанья
      В тени находится суть факторов влиянья
      &nbsp;
      В тени осознанности Миропониманья
      Мы продолжаем жить
      <em>Но вне Сознанья</em>
    `
  },
  {
    id:'26',
    name: 'И нахлебавшись ложкой суеты',
    text: `
      <strong>И </strong>нахлебавшись ложкой <em>суеты</em>
      Из жбана жизни <em>недо</em> или <em>пере</em>
      Не знаем мы
      <em>Что по Вселенской мере</em>
      <em>Мы сыты так же как и голодны</em>
    `
  },
  {
    id:'27',
    name: 'Вертикальное',
    text: `
      <strong>В</strong>ертикальное
      Не параллельное
      <em>Постарайтесь его ощущать</em>
      Время Вечности
      Суть мгновения
      <em>Что ещё предстоит осознать</em>
    `
  },
  {
    id:'28',
    name: 'Ты выше не продвинешься',
    text: `
      <strong>Т</strong>ы выше не продвинешься
      Пока
      Сознанье накрывают облака
      <em>Когда разгонишь облака с Сознанья</em>
      <em>Тогда откроют Истинные Знанья</em>
    `
  },
  {
    id:'29',
    name: 'Как ручейки впадают в речки',
    text: `
      <strong>К</strong>ак ручейки впадают в речки
      Которые несут себя к морям
      Так жизнь любого человечка
      Впадает в жизни Океан
    `
  },
  {
    id:'30',
    name: 'Мысль не видна',
    text: `
      <strong>М</strong>ысль не видна
      Но виден результат
      <em>Которому ты рад</em>
      <em>Или не рад</em>
    `
  },
  {
    id:'31',
    name: 'Меняя своей жизни путь',
    text: `
      <strong>М</strong>еняя своей жизни путь
      Судьбу не сможешь обмануть
    `
  },
  {
    id:'32',
    name: 'Что суждено',
    text: `
      <strong>Ч</strong>то <strong>суждено</strong>
      Того не миновать
      <strong>&nbsp;</strong>
      <strong>Что</strong> суждено
      Ещё не время знать
    `
  },
  {
    id:'33',
    name: 'Свою Судьбу познаешь лишь тогда',
    text: `
      <strong>С</strong>вою Судьбу познаешь лишь тогда
      Когда тобою прожита она
      Меж тем
      Возможно видеть завтрашние дни
      <em>Но <strong>тем </strong>кому известно где они</em>
    `
  },
  {
    id:'34',
    name: 'Поверить в это очень просто',
    text: `
      <strong>П</strong>оверить в это очень просто
      <em>Но в жизни нет сложнее ничего</em>
      Чтобы поверить <strong>в то</strong>
      <strong>Что всё возможно</strong>
      <em>Стремленьем <strong>Вашим</strong></em>
      <em>Волею <strong>ЕГО</strong></em>
    `
  },
  {
    id:'35',
    name: 'Живи Душой',
    text: `
      <strong>Ж</strong>иви Душой
      <em>Поймёшь тогда</em>
      Что <strong>Всё</strong>
      Господние дела
    `
  },
  {
    id:'36',
    name: 'Читая',
    text: `
      <strong>Ч</strong>итая
      Критикуешь всё подряд
      <em>&nbsp;</em>
      <em>Попробуй сам писать</em>
      <em>Прочесть я буду рад</em>
    `
  },
  {
    id:'37',
    name: 'Коль чувства только чистые в Душе',
    text: `
      <strong>К</strong>оль чувства <strong>только чистые</strong> в Душе
      <em>То ты не здесь ещё</em>
      <em>Иль там уже</em>
    `
  },
  {
    id:'38',
    name: 'И смех и грех',
    text: `
      <strong><em>И</em></strong><em> смех и грех</em>
      Ты жизнь убил на <strong>то</strong>
      Что вывел формулу
      <strong>Что</strong> есть <em>добро</em>&nbsp;
      <strong>Что </strong><em>зло</em>
      Причём понятья эти разделил
      <em>Чем очень сильно удивил</em>
    `
  },
  {
    id:'39',
    name: 'Для каждого найдётся искушенье',
    text: `
      <strong>Д</strong>ля каждого найдётся искушенье
      Вопрос лишь в том
      <em>Готов ли к искупленью </em>
    `
  },
  {
    id:'40',
    name: 'Коль Вы до этой строчки дочитали',
    text: `
      <strong>К</strong>оль Вы до этой строчки дочитали
      Я рад тому
      <em>Вы значит <strong>не устали</strong></em>
      А коль страницу дочитав перелистнули
      Я счастлив тем
      <em>Вы значит <strong>не заснули</strong></em>
    `
  },
  {
    id:'41',
    name: 'Ах если б мы могли',
    text: `
      <strong>А</strong>х если б мы могли
      Ах если б мы ум<strong>е</strong>ли
      Понять себя самих
      <em>Живущих в нашем теле</em>
    `
  },
  {
    id:'42',
    name: 'До той поры',
    text: `
      <strong>Д</strong>о той поры
      Покуда ни осмыслишь
      <em>Системного значенья</em>
      <em>Тобой прож<strong>и</strong>тых дней</em>
      До той &nbsp;поры
      Ты жить не начинаешь
      <em>Живя в плену незрелости своей</em>
    `
  },
  {
    id:'43',
    name: 'Фиолетовое облако',
    text: `
      <strong>Ф</strong>иолетовое облако
      Фиолетовым лучом
      Очень бережно коснулось моей думы &nbsp;<em>ни о чём</em>
      <em>&nbsp;</em>
      <em>В фиолетной благодати растворив себя</em>
      <em>Я увидел зарожденье завтрашнего дня</em>
    `
  },
  {
    id:'44',
    name: 'Полоса Земная посадочная',
    text: `
      <strong>П</strong>олоса Земная <em>посадочная</em>
      Приняла рожденьем меня
      А сама оказалась <em>взлётною</em>
      В Вечность жизни Вселенского дня
    `
  },
  {
    id:'45',
    name: 'Весенний луч туманного рассвета',
    text: `
      <strong>В</strong>есенний луч туманного рассвета
      Собою указал
      <em>Дарованную <strong>мне</strong></em>
      <em>Моей Судьбой</em>
      Дорогу <em>на край света</em>
      Дорогу
      <em>К самому себе </em>
    `
  },
  {
    id:'46',
    name: 'Глаза закрою',
    text: `
      <strong>Г</strong>лаза закрою
      И купаюсь в облаках
      Качаясь на перинах фиолета
      <em>Нет слов таких</em>
      <em>Чтоб чувства описать</em>
      <strong><em>Неописуемо блаженство это</em></strong>
    `
  },
  {
    id:'47',
    name: 'Сегодня мне спокойно',
    text: `
      <strong>С</strong>егодня мне спокойно
      <strong>Я свободен</strong>
      <em>Свободен я от самого себя</em>
      Свободен <strong>тем</strong>
      Что быть могу собою
      И чувствовать себя <strong>тем </strong>что являю я
    `
  },
  {
    id:'48',
    name: 'Я знал всё то что знаешь ты',
    text: `
      <strong>Я </strong>знал всё то что знаешь <strong>ты</strong>
      Когда я был тобой
      <strong>Ты</strong> будешь знать что знаю я
      Когда ты станешь мной
    `
  },
  {
    id:'49',
    name: 'Чем больше будешь понимать',
    text: `
      <strong>Ч</strong>ем больше будешь понимать
      Тем меньше будешь говорить
      <strong>О том</strong> <em>как людям <strong>нужно </strong>жить</em>
      Но если есть <strong>что предложить</strong>
      То ты обязан говорить
    `
  },
  {
    id:'50',
    name: 'Чтоб тоньше чувствовать',
    text: `
      <strong>Ч</strong>тоб тоньше чувствовать
      И глубже понимать
      Придётся многое вам в жизни испытать
      <em>Точней</em>
      <em>Пожалуй даже будет так</em>
      <strong><em>Вам не пройти на Свет</em></strong>
      <strong><em>Пока не пройден мрак </em></strong>
    `
  },
  {
    id:'51',
    name: 'Чтоб тоньше чувствовать',
    text: `
      <strong>Ч</strong>тоб тоньше чувствовать
      И глубже понимать
      Придётся многое вам в жизни испытать
      <em>Точней</em>
      <em>Пожалуй даже будет так</em>
      <strong><em>Вам не пройти на Свет</em></strong>
      <strong><em>Пока не пройден мрак </em></strong>
    `
  },
  {
    id:'52',
    name: 'Что либо объяснить тому кто Вас не слышит',
    text: `
      <strong>Ч</strong>то либо объяснить тому кто Вас не слышит
      <em>Сложно</em>
      Кто слушать не желает
      <em>Не возможно</em>
    `
  },
  {
    id:'53',
    name: 'Стараемся не видеть очевидное',
    text: `
      <strong>С</strong>тараемся не видеть очевидное
      <em>Особенно когда оно обидное</em>
      Обидное увидеть нам обидно
      <strong><em>Глупцы мы</em></strong>
      <em>Это очевидно</em>
    `
  },
  {
    id:'54',
    name: 'Себя не помня позабыли Бога',
    text: `
      <strong>С</strong>ебя не помня позабыли Бога
      <em>По той причине и живём убого</em>
    `
  },
  {
    id:'55',
    name: 'Мы держим пост',
    text: `
      <strong>М</strong>ы держим пост
      И не едим мясного
      <em>Но сколько в мыслях вертится дурного</em>
    `
  },
  {
    id:'56',
    name: 'Себя заставить думать',
    text: `
      <strong>С</strong>ебя заставить думать
      Не легко
      Не думать
      Проще
      <em>Это всем дано</em>
    `
  },
  {
    id:'57',
    name: 'Никто из нас понятья не имеет',
    text: `
      <strong>Н</strong>икто из нас понятья не&nbsp; имеет
      <strong>Что </strong>отдаёт за <strong>то</strong>
      Чем в данный миг владеет
    `
  },
  {
    id:'58',
    name: 'Событий',
    text: `
      <strong>С</strong>обытий
      Не увязанных&nbsp; друг с другом не бывает
      <em>Об этом знает <strong>тот</strong></em>
      <em>Кто это понимает</em>
    `
  },
  {
    id:'59',
    name: 'Событья',
    text: `
      <strong>С</strong>обытья
      <strong>Те </strong>что впереди
      Уже находятся в пути
    `
  },
  {
    id:'60',
    name: 'События прошедших лет',
    text: `
      <strong>С</strong>обытия прошедших лет
      Нас отвели от б<strong>о</strong>льших бед
    `
  },
  {
    id:'61',
    name: 'Нам следует в себе изжить',
    text: `
      <strong>Н</strong>ам следует в себе изжить
      Всё <strong>то</strong>
      Что нам мешает жить
    `
  },
  {
    id:'62',
    name: 'Чтоб стать таким',
    text: `
      <strong>Ч</strong>тоб стать таким
      Каким хотели б быть
      Вам следует себя <em>переродить</em>
    `
  },
  {
    id:'63',
    name: 'Чтобы гармонию творить своей Душой',
    text: `
      <strong>Ч</strong>тобы гармонию творить своей Душой
      Ты должен вспомнить <em>кто ты есть такой</em>
      <strong><em>Покуда не распознан сам собой</em></strong>
      <strong><em>Не сможешь жить в гармонии с Душой</em></strong>
    `
  },
  {
    id:'64',
    name: 'Прощённым ты настолько можешь быть',
    text: `
      <strong>П</strong>рощённым ты настолько можешь быть
      Насколько в состоянии простить
    `
  },
  {
    id:'65',
    name: 'Коль не хватает сил вперёд идти',
    text: `
      <strong>К</strong>оль не хватает сил вперёд идти
      Ты помощи у Бога попроси
      <em>И вот уж снова ты идёшь вперёд</em>
      Точней
      <strong><em>ОН на руках тебя несёт</em></strong>
    `
  },
  {
    id:'66',
    name: 'Тому кто счастлив тем',
    text: `
      <strong>Т</strong><strong>ому </strong>кто счастлив <strong>тем</strong>
      <em>Что день сменяет ночь</em>
      Вселенский Дух всегда готов помочь
    `
  },
  {
    id:'67',
    name: 'Понятье ЧелоВек',
    text: `
      <strong>П</strong>онятье <em>ЧелоВек</em>
      Понятье <em>люди</em>
      Понятья разные по сути
      <em>Природа изначально их различна</em>
      <em>Меж ними разница видна отлично</em>
    `
  },
  {
    id:'68',
    name: 'Тебе столь многое дано',
    text: `
      <strong>Т</strong>ебе столь многое дано
      Но ты забыл о том давно
      И вспоминать не захотел
      <em>Живя в плену бездумных дел</em>
    `
  },
  {
    id:'69',
    name: 'Ты по привычке прячешь взгляд',
    text: `
      <strong>Т</strong>ы по привычке прячешь взгляд
      <em>Жизнь проживая невпопад </em>
    `
  },
  {
    id:'70',
    name: 'Ты сам себе буквально ужаснёшься',
    text: `
      <strong>Т</strong>ы сам себе буквально ужаснёшься
      Когда<em> после вчерашнего </em>проснёшься
    `
  },
  {
    id:'71',
    name: 'Друг мой',
    text: `
      <strong>Д</strong><strong>руг мой</strong>
      <strong>Порадуйся за нас</strong>
      <em>А друг мой</em>
      <em>В зависти увяз</em>
    `
  },
  {
    id:'72',
    name: 'Ты дружбу променял на сто рублей',
    text: `
      <strong>Т</strong>ы дружбу променял на сто рублей
      Оправдываясь тем что был голодный
      <em>А говорят</em>
      <em>Грядущая зима</em>
      <em>Пообещала быть весьма холодной</em>
    `
  },
  {
    id:'73',
    name: 'Хитрил',
    text: `
      <strong><em>Х</em></strong><em>итрил</em>
      <em>Крутился</em>
      <em>Извивался</em>
      В конце концов
      Один остался
    `
  },
  {
    id:'74',
    name: 'Нам очень часто дарят то',
    text: `
      <strong>Н</strong>ам очень часто дарят <strong>то</strong>
      К чему мы быстро привыкаем
      И вдруг лишённые того
      Не разобравшись
      Обвиняем
      <strong>Того</strong>
      Кто нас лишил того
    `
  },
  {
    id:'75',
    name: 'Хотите прожить и богато и честно',
    text: `
      <strong>Х</strong>отите прожить и богато и честно
      Ну что ж
      Попытайтесь
      <em>Но бесполезно</em>
    `
  },
  {
    id:'76',
    name: 'Поверил наслово',
    text: `
      <strong>П</strong>оверил н<strong>а</strong>слово
      На деле всё не так
      <strong>Ты</strong> значит
      <em>Ясный день</em>
      <strong>Он</strong>
      <em>Полуночный мрак</em>
    `
  },
  {
    id:'77',
    name: 'Чтоб поросль выросла',
    text: `
      <strong>Ч</strong><strong>тоб поросль выросла</strong>
      <strong>Мы сеем что придётся</strong>
      И в этом кроется Великая из Тайн
      А в ней <em>Урок рождения бездушных</em>
      <em>Принявших Преисподнюю за Рай</em>
    `
  },
  {
    id:'78',
    name: 'Парадоксально чувственное зрение',
    text: `
      <strong>П</strong>арадоксально <em>чувственное зрение</em>
      <em>Свет чистой мысли </em>
      Ощутит на расстоянии
      <em>А грязь людскую </em>
      При прикосновении
    `
  },
  {
    id:'79',
    name: 'Что одному кромешный Ад',
    text: `
      <strong>Ч</strong>то одному <em>кромешный Ад</em>
      Другому - <em>выше всех наград </em>
    `
  },
  {
    id:'80',
    name: 'Тем кто творит добро за злато',
    text: `
      <strong>Т</strong><strong>ем </strong>кто творит добро за злато
      <em>В стремлении пожить богато</em>
      Понять нелишне постулат
      <strong><em>Лишь кто Душой богат</em></strong>
      <strong><em>Богат</em></strong>
    `
  },
  {
    id:'81',
    name: 'Добро творящие',
    text: `
      <strong>Д</strong>обро творящие
      <em>Живут</em>
      Другие
      <em>Просто существуют</em>
      Добро творящим&nbsp; <em>Гимн поют</em>
      Других
      <em>Уж завтра позабудут</em>
    `
  },
  {
    id:'82',
    name: 'Понятье счастье субъективно',
    text: `
      <strong>П</strong>онятье <strong>счастье </strong>субъективно
      <em>Взгляни на это объективно</em>
    `
  },
  {
    id:'83',
    name: 'Спокойно примешь всё сполна',
    text: `
      <strong>С</strong>покойно примешь всё сполна
      Когда жизнь <em>Свету</em> отдана
    `
  },
  {
    id:'84',
    name: 'Ты веришь в Бога',
    text: `
      <strong>Т</strong>ы веришь в Бога
      Сам себе не веря
      <em>И в этом есть твоя потеря</em>
    `
  },
  {
    id:'85',
    name: 'Мысль глубока',
    text: `
      <strong>М</strong>ысль глубок<strong>а</strong>
      А пониманье мельче
      Причина этого сокрыта в том
      <em>Что мы живём в бреду&nbsp; похмельном</em>
      <em>Себя терзая явью между сном</em>
    `
  },
  {
    id:'86',
    name: 'С три короба себе готовы лгать',
    text: `
      <strong>С</strong> <em>три короба</em> себе готовы лгать
      Чтобы себя перед собою оправдать
    `
  },
  {
    id:'87',
    name: 'Пока соломку подстилал',
    text: `
      <strong>П</strong>ока <em>соломку подстилал</em>
      Туда
      Куда предполагал упасть
      Себя чуть было ни сгубил
      <em>Такая видишь ли напасть</em>
    `
  },
  {
    id:'88',
    name: 'С одними',
    text: `
      <strong>С </strong>одними
      Ни разу
      С другими
      Однажды
      С иными
      Бывает случается дважды
      А с кем-то и трижды
      Но это не часто
      <em>Как правило раньше становится ясно</em>
    `
  },
  {
    id:'89',
    name: 'Ты не согласен с тем что сделали другие',
    text: `
      <strong>Т</strong>ы не согласен с тем что сделали другие
      <em>Но что поделать</em>
      <em>Ведь они иные</em>
      И им не нравится всё <strong>то</strong> что сделал ты
      <em>И в этом нет твоей</em>
      <em>Как нет и их вины </em>
    `
  },
  {
    id:'90',
    name: 'Твои проблемы - мне скучны',
    text: `
      <strong>Т</strong>вои проблемы - мне скучны
      Тебе мои проблемы - не понятны
      А для второго - все мои мелк<strong>и </strong>
      Для&nbsp; третьего -&nbsp; покажутся невнятны
    `
  },
  {
    id:'91',
    name: 'При демонстрации того каков ты есть',
    text: `
      <strong>П</strong>ри <strong>демонстрации </strong>того к<em>аков ты есть</em>
      В ответ получишь <strong>искреннюю лесть</strong>
    `
  },
  {
    id:'92',
    name: 'Решив что ты умнее всех',
    text: `
      <strong>Р</strong>ешив что ты умнее всех
      В том ты не прав
      <em>И вызываешь смех </em>
    `
  },
  {
    id:'93',
    name: 'Ты обо всех буквально знаешь всё',
    text: `
      <strong>Т</strong>ы обо всех буквально знаешь всё
      <em>Что было у кого</em>
      <em>Что есть</em>
      <em>Что будет</em>
      Пускай твоя Судьба тебя рассудит
      <strong><em>Вот о Судьбе своей не знаешь ничего</em></strong>
    `
  },
  {
    id:'94',
    name: 'Орехи колем',
    text: `
      <strong>О</strong>рехи к<strong>о</strong>лем
      Семечки щелк<strong>а</strong>ем
      <em>Суть действия проста</em>
      <em>Но мы не понимаем</em>
      <strong>Что так во всём</strong>
      <strong><em>Чтоб до ядра добраться</em></strong>
      <strong><em>Сначала в шелухе придётся покопаться</em></strong>
    `
  },
  {
    id:'95',
    name: 'Один молчит',
    text: `
      <strong>О</strong>дин молчит
      <em>Не от того что глупый</em>
      Другой кричит
      <em>Поскольку не умён</em>
    `
  },
  {
    id:'96',
    name: 'Нельзя',
    text: `
      <strong><em>Н</em></strong><em>ельзя</em>
      <em>Нельзя</em>
      <em>Нельзя</em>
      <em>Нельзя</em>
      Таких понятий не бывает
      К такому слову привыкают
      Не понимая что нельзя
      &nbsp;
      Другое есть понятье
      <strong><em>Это мне не нужно</em></strong>
      <em>В союзе с ним Вы заживёте дружно</em>
    `
  },
  {
    id:'97',
    name: 'Неприятность',
    text: `
      <strong>Н</strong><strong>еприятность</strong>
      <em>Такое случается</em>
      Даже больше того
      <em>Повторяется</em>
      Но при этом она многим нравится
      <em>Тем конечно</em>
      <em>Кого не касается</em>
    `
  },
  {
    id:'98',
    name: 'Раздался вопль',
    text: `
      <strong>Р</strong>аздался вопль
      Гортанный крик
      <strong>Спасайся кто как может</strong>
      <em>И именно <strong>такой</strong> подход</em>
      <em>Весьма меня тревожит</em>
    `
  },
  {
    id:'99',
    name: 'Лишь потеряв',
    text: `
      <strong>Л</strong>ишь потеряв
      Мы можем оценить
      Чем и насколько нужно дорожить
    `
  },
  {
    id:'100',
    name: 'Легко иль тяжело живётся тем',
    text: `
      <strong>Л</strong>егко иль тяжело живётся <strong>тем</strong>
      Кто истинных причин не понимает
      И оттого
      Виновником проблем
      Считает <strong>тех</strong> кто им не помогает
    `
  },
  {
    id:'101',
    name: 'Никто и никогда',
    text: `
      <strong>Н</strong>икто и никогда
      Нам ничего не должен
      <em>И даже тем из нас кому паёк положен</em>
    `
  },
  {
    id:'102',
    name: 'Кто грязью пачкает других',
    text: `
      <strong>К</strong>то <em>грязью </em>пачкает других
      Тот в сотни раз <em>грязнее</em> их
    `
  },
  {
    id:'103',
    name: 'Кто гадости творит',
    text: `
      <strong>К</strong>то гадости творит
      Те сами их получат
      <em>Настанет в жизни миг</em>
      <em>И небеса проучат</em>
    `
  },
  {
    id:'104',
    name: 'Когда стремишься сам к чему не знаешь',
    text: `
      <strong>К</strong>огда стремишься сам к чему не знаешь
      То как того достичь не понимаешь
    `
  },
  {
    id:'105',
    name: 'Любое дело без Души',
    text: `
      <strong>Л</strong>юбое дело <em>без Души</em>
      Как мрак ночной в лесной глуши
    `
  },
  {
    id:'106',
    name: 'Когда желаешь достиженья цели страстно',
    text: `
      <strong>К</strong>огда желаешь достиженья цели страстно
      <em>Как правило</em>
      Тебе&nbsp; неясно
      Насколько это <strong>для тебя опасно </strong>
    `
  },
  {
    id:'107',
    name: 'Мало одного желанья',
    text: `
      <strong>М</strong>ало одного желанья
      <em>Нужно чуточку старанья</em>
      Мало одного стремленья
      <em>Нужно чуточку уменья </em>
    `
  },
  {
    id:'108',
    name: 'Поставил цель себе',
    text: `
      <strong>П</strong>оставил цель себе
      И лез к ней напролом
      <em>В пути случалось драться и ругаться</em>
      <strong>И вот он цель достиг</strong>
      <em>Но не постиг</em>
      <strong><em>Как обращаться с тем чего достиг </em></strong>
    `
  },
  {
    id:'109',
    name: 'Верить другим не всегда безопасно',
    text: `
      <strong>В</strong>ерить другим не всегда безопасно
      Не верить себе так и вовсе опасно
    `
  },
  {
    id:'110',
    name: 'Всяко бывает',
    text: `
      <strong><em>В</em></strong><em>сяко бывает</em>
      <em>Всяко случается</em>
      Но не всегда это <em>всяко</em> нам нравится
    `
  },
  {
    id:'111',
    name: 'Как часто боремся за то',
    text: `
      <strong>К</strong>ак часто боремся за <strong>то</strong>
      Что нам уже давно не нужно
      <em>Но бьёмся насмерть</em>
      <em>Бьёмся дружно</em>
      За <strong>то</strong>
      Что нам давно не нужно
    `
  },
  {
    id:'112',
    name: 'Бывает ложь похожая на правду',
    text: `
      <strong>Б</strong>ывает ложь похожая на правду
      Бывает правда вылитая ложь
      <em>До той поры покуда ни познаешь</em>
      <em>Где правда а где ложь не разберёшь </em>
    `
  },
  {
    id:'113',
    name: 'Вы жизнь живёте как умеете',
    text: `
      <strong>В</strong>ы жизнь живёте как умеете
      <em>Поэтому почти всегда болеете</em>
    `
  },
  {
    id:'114',
    name: 'Боль - признак жизни',
    text: `
      <strong>Б</strong>оль - признак жизни
      <em>Это нужно знать</em>
      Причину боли - понимать
    `
  },
  {
    id:'115',
    name: 'Задумался над тем как нужно жить',
    text: `
      <strong>З</strong>адумался над тем&nbsp; <strong>как нужно жить</strong>
      <em>Ну что ж </em>
      <em>Есть шанс с самим собой дружить</em>
      А то враждуешь сам с собой
      <em>Какой уж здесь Души покой</em>
    `
  },
  {
    id:'116',
    name: 'Вы сможете понять что счастье есть',
    text: `
      <strong>В</strong>ы сможете понять что <strong><em>счастье есть</em></strong>
      Когда забудете что есть понятье &nbsp;<em>месть </em>
    `
  },
  {
    id:'117',
    name: 'Коль в жалости к себе проводишь дни',
    text: `
      <strong>К</strong>оль в жалости к себе проводишь дни
      То сам тому виной
      <em>А не они </em>
    `
  },
  {
    id:'118',
    name: 'Грустно',
    text: `
      <strong>Г</strong>рустно
      Печально и скучно <strong>тому</strong>
      Кто смог наскучить себе самому
    `
  },
  {
    id:'119',
    name: 'На вопрос кто Вы такой',
    text: `
      <strong>Н</strong>а вопрос <strong>кто Вы такой</strong>
      Вы можете рассказывать часами
      <em>Когда б его себе задали сами</em>
      <em>То пару фраз навряд ли бы связали</em>
    `
  },
  {
    id:'120',
    name: 'Наелся слов',
    text: `
      <strong>Н</strong>аелся слов
      <strong><em>Тех </em></strong><em>что в сиропе лицемерья</em>
      Сладко
      <em>А на Душе </em>противно гадко
    `
  },
  {
    id:'121',
    name: 'Кричишь',
    text: `
      <strong>К</strong>ричишь
      А на Душе покой
      <em>Ну что тут скажешь</em>
      <strong>Ты такой</strong>
      &nbsp;
      Другой молчит
      Крича Душой
      <em>Ну что тут скажешь</em>
      <strong>Он иной </strong>
    `
  },
  {
    id:'122',
    name: 'Недоказуемо не прав',
    text: `
      <strong><em>Н</em></strong><em>едоказуемо не прав</em>
      По той причине что<em> без прав </em>
    `
  },
  {
    id:'123',
    name: 'Аукнулось',
    text: `
      <strong>А</strong>укнулось
      Откликнулось
      <em>И от обиды всхлипнулось</em>
    `
  },
  {
    id:'124',
    name: 'Что для тебя является понятным',
    text: `
      <strong>Ч</strong>то для тебя является понятным
      Другому может видеться невнятным
    `
  },
  {
    id:'125',
    name: 'Жить наружу а не внутрь',
    text: `
      <strong>Ж</strong>ить <em>наружу</em> а <em>не внутрь</em>
      Дозволяется лишь <strong>тем</strong>
      Кто умеет жить <em>наружу</em>
      Пряча <em>внутрь</em> боль проблем
    `
  },
  {
    id:'126',
    name: 'Ты весь измаялся',
    text: `
      <strong>Т</strong>ы весь измаялся
      Тебя тоска съедает
      Ну а другому время не хватает
      Поэтому и спит по три часа
      <em>Где уж ему понять</em>
      <em>Что есть собой тоска </em>
    `
  },
  {
    id:'127',
    name: 'Земная жизнь прошла',
    text: `
      <strong>З</strong>емная жизнь прошла
      И превратилась в точку
      А описание её вместилось в строчку
      <em>Строка хранит в себе и радости и горести</em>
      <em>Тире меж дат есть изложенье повести</em>
    `
  },
  {
    id:'128',
    name: 'Пока ещё не здесь',
    text: `
      <strong>П</strong>ока ещё не здесь
      Но и уже не там
      Или
      Уже не здесь
      Но и не там покуда
      <em>На самом деле</em>
      <strong><em>Ты живёшь везде</em></strong>
      <strong><em>Одновременно</em></strong>
      <strong><em>Ты живёшь повсюду</em></strong>
    `
  },
  {
    id:'129',
    name: 'Коль ты считаешь что ты всё знаешь',
    text: `
      <strong>К</strong>оль ты считаешь <em>что ты всё знаешь</em>
      Коль ты считаешь <em>что осмыслил всё</em>
      То это только <strong>то</strong> обозначает
      <em>Что ты не понимаешь ничего</em>
    `
  },
  {
    id:'130',
    name: 'Иной прочёл число большое книг',
    text: `
      <strong>И</strong>ной прочёл число большое книг
      <em>А Истину всё так и не постиг</em>
      Другой
      Читать с рожденья не умел
      <em>Быть истинным</em>
      Таков его удел
    `
  },
  {
    id:'131',
    name: 'Понравилась обложка',
    text: `
      <strong>П</strong>онравилась обложка
      Ты купил
      <em>Уж жизнь прошла</em>
      <em>Ты так и не раскрыл</em>
    `
  },
  {
    id:'132',
    name: 'Великой силой нужно обладать',
    text: `
      <strong>В</strong>еликой силой нужно обладать
      Чтобы с собою совладать
    `
  },
  {
    id:'133',
    name: 'Кто-то пишет',
    text: `
      <strong>К</strong>то-то пишет
      Кто-то рисует
      Кто-то ничто
      <em>Но критикует </em>
    `
  },
  {
    id:'134',
    name: 'Вам дали будут близкими казаться',
    text: `
      <strong>В</strong>ам д<strong>а</strong>ли будут близкими казаться
      Как только прекратите обижаться
    `
  },
  {
    id:'135',
    name: 'Сиреневый туман зелёных глаз',
    text: `
      <strong>С</strong>иреневый туман зелёных глаз
      Меня окутав подарил покой
      Собою загасив бушующий вулкан
      В котором жил моих обид огонь
    `
  },
  {
    id:'136',
    name: 'Бездонный взгляд зажёг свечу в Душе',
    text: `
      <strong>Б</strong>ездонный взгляд зажёг свечу в Душе
      Огню свечи своей Души внимая
      Открылось мне
      <em>Что много лет</em>
      <em>Я жил совсем себя не понимая</em>
    `
  },
  {
    id:'137',
    name: 'Я расправил паруса своей Судьбы',
    text: `
      <strong>Я </strong>расправил паруса своей Судьбы
      Их наполнив ветром солнц и ветром звёзд
      Что несут меня <em>в Вселенной закружив</em>
      Открывая многомерности Миров
    `
  },
  {
    id:'138',
    name: 'Я Душу распахнул',
    text: `
      <strong>Я</strong> Душу распахнул
      Чтоб ей тебя обнять
      <em>Но ты не смог понять</em>
      <em>И бросился бежать</em>
      Чем дальше ты бежал
      Тем ближе был ко мне
      <em>Так как</em>
      <em>Моя Душа</em>
      <em>Находится везде </em>
    `
  },
  {
    id:'139',
    name: 'Возвысься сам',
    text: `
      <strong>В</strong>озвысься сам
      Других не унижая
      Свободу каждого всецело уважая
    `
  },
  {
    id:'140',
    name: 'Различие понятий',
    text: `
      <strong>Р</strong>азличие понятий
      <strong>Хотеть или желать</strong>
      Различие понятий
      <strong>Мечтать иль претворять</strong>
      Различие понятий
      <strong>Влюблённость и Любовь</strong>
      <em>Познаешь Суть различий</em>
      <em>Собой родишься вновь</em>
    `
  },
  {
    id:'141',
    name: 'Я утопил свой взгляд в твоих глазах',
    text: `
      <strong>Я</strong> утопил свой взгляд в твоих глазах
      Теперь я вижу по-иному
      Душою вижу
      <em>Приближается гроза</em>
      <em>Или гроза проходит стороною</em>
    `
  },
  {
    id:'142',
    name: 'Любовь',
    text: `
      <strong>Любовь</strong>
      Это щедрый Божественный дар
      <strong>Влюблённость</strong>
      Пылающий страстью пожар
      <strong>Любовь</strong>
      Это <strong>то</strong>
      Что даётся не всем
      <strong>Влюблённость</strong>
      Пожалуйста
      <em>С кучей проблем</em>
    `
  },
  {
    id:'143',
    name: 'Признание в любви - одно',
    text: `
      <strong>П</strong>ризнание <strong>в </strong>любви - <em>одно</em>
      Признание Любви - <em>другое дело</em>
      <strong><em>От первого бывает тяжело</em></strong>
      <strong><em>А от второго всё поёт</em></strong>
      <strong><em>Душа и тело</em></strong>
    `
  },
  {
    id:'144',
    name: 'Любят ни за что-то',
    text: `
      <strong>Л</strong>юбят ни за что-то
      А несмотря на что-то
    `
  },
  {
    id:'145',
    name: 'Спокойно жил',
    text: `
      <strong>С</strong>покойно жил
      Пока тебя ни встретил
      <em>Лишь повстречав тебя</em>
      <em>Я понял</em>
      <em>Что не жил</em>
    `
  },
  {
    id:'146',
    name: 'Со временем',
    text: `
      <strong>С</strong>о временем
      <em>Очарованье</em>
      Преобразилось в <em>разочарованье</em>
    `
  },
  {
    id:'147',
    name: 'Вода смывает разные следы',
    text: `
      <strong>В</strong>ода смывает разные следы
      А так же краску
      <em>Средство красоты</em>
      Природную красивость не смывает
      <em>Она её собою умывает</em>
    `
  },
  {
    id:'148',
    name: 'Во всех грехах мы тех стремимся обвинить',
    text: `
      <strong><em>В</em></strong><em>о всех грехах</em> мы <strong>тех </strong>стремимся обвинить
      Которых мы перестаём любить
    `
  },
  {
    id:'149',
    name: 'Кого бы Вы назвали эгоистом',
    text: `
      <strong>К</strong>ого бы Вы назвали эгоистом
      <em>Того</em>
      <em>Кто жизнь живёт как хочет сам того</em>
      Или того
      <em>Кто хочет <strong>чтобы Вы</strong></em>
      <em>Вплетали в жизнь свою его мечты</em>
    `
  },
  {
    id:'150',
    name: 'То что в союзе для тебя блаженство',
    text: `
      <strong>Т</strong><strong>о</strong> что в союзе для тебя блаженство
      <strong>То</strong> для другого м<strong>у</strong>кой может быть
      И будете у Высших Сил просить
      Один о том чтобы <em>продлить</em>
      Другой молить <em>освободить</em>
    `
  },
  {
    id:'151',
    name: 'Коль отраженье в зеркале полюбим',
    text: `
      <strong>К</strong>оль отраженье в зеркале полюбим
      Мы больше <strong>тех</strong> кто дарит нам Любовь
      То начинаем жить зеркальным отраженьем
      Чуть приподняв при том рисованную бровь
    `
  },
  {
    id:'152',
    name: 'Как губки ни старайтесь раздувать',
    text: `
      <strong>К</strong>ак губки ни старайтесь раздувать
      Вам это не поможет добрым стать
      Суть не удастся этим поменять
      <em>Вам губки тонкие подстать</em>
    `
  },
  {
    id:'153',
    name: 'Жизнь',
    text: `
      <strong>П</strong>ришла Любовь
      И весело смеялась
      Потом ушла
      <em>И сильно испугалась</em>
      <em>Увидев <strong>то</strong></em>
      <em>Что от неё осталось</em>
    `
  },
  {
    id:'154',
    name: 'От тебя',
    text: `
      <strong>О</strong>т тебя
      <em>Я убежал в себя</em>
      От себя
      <em>Я убежал бы дальше</em>
    `
  },
  {
    id:'155',
    name: 'Когда Любовь приходит',
    text: `
      <strong>К</strong>огда Любовь приходит
      Мы смеёмся
      <em>Когда уходит</em>
      <em>С чем мы остаёмся</em>
    `
  },
  {
    id:'156',
    name: 'Я отвечал тебе',
    text: `
      <strong>Я </strong>отвечал тебе
      <em>Как ты считала</em>
      <strong><em>Невпопад</em></strong>
      С тех пор не виделись
      <strong><em>Чему я очень рад</em></strong>
    `
  },
  {
    id:'157',
    name: 'Обиды вовсе не имею на тебя',
    text: `
      <strong>О</strong>биды в<strong>о</strong>все не имею на тебя
      Живи как хочешь
      <em>Только без меня</em>
    `
  },
  {
    id:'158',
    name: 'Любить',
    text: `
      <strong>Л</strong>юбить
      Не есть <strong>владеть</strong>
      <em>Об этом нужно знать</em>
      Иначе нам Любви не распознать
    `
  },
  {
    id:'159',
    name: 'Огонь Души',
    text: `
      <strong>О</strong>гонь Души
      Не подожжёт твой дом
      Отсутствие Огня Души
      Его остудит
      <em>Но дом холодный не пригоден для жилья</em>
      <em>И вряд ли кто с тобой жить в этом доме будет </em>
    `
  },
  {
    id:'160',
    name: 'Как свежевыпеченный хлеб жую слова',
    text: `
      <strong>К</strong>ак свежевыпеченный хлеб жую слова
      Я наслаждаюсь вкусом смыслов слов
      Слов
      Открывающих понятье для меня
      <strong>Величье Сути</strong> в степени <strong><em>Любовь</em></strong>
    `
  },
  {
    id:'161',
    name: 'Увидев Суть',
    text: `
      <strong>У</strong>видев Суть
      <em>Что скрыта между строк</em>
      Приблизишь тем прозренья срок
    `
  },
  {
    id:'162',
    name: 'Я вижу больше чем вчера я видел',
    text: `
      <strong>Я</strong> вижу больше чем вчера я видел
      И чувствую сегодня по-иному
      Воспринимаю жизнь не так как прежде
      <em>Не дай Господь скатиться мне к былому</em>
    `
  },


  // «В спираль закрученная мысль» ========================================================================
  {
    id:'163',
    name: 'ВСТУПЛЕНИЕ',
    text: `
      <strong>ВСТУПЛЕНИЕ</strong>
      &nbsp;
      <strong>Я </strong>думаю что очень скоро вокруг стихов возникнет спор
      Но я прошу Вас
      <em>Ради Бога</em>
      Не выносите приговор
      Моей заслуги к сожаленью не много
      <em>Роль скромна моя</em>
      <strong>Я лишь писал в момент прозренья диктуемые мне слова</strong>
      Лишь кое-где менял местами <em>так чтобы Суть не исказить</em>
      Так можно ли <em>судите сами</em> меня за это обвинить
      &nbsp;
      В стихах нет точек
      Нет и запятых
      И прочих знаков нет
      <em>Я их не ставил</em>
      Причина в том
      Что я для Вас
      Процесс прочтенья творческим оставил
      &nbsp;
      Осознанность рифмующихся строк
      В развёрнутом объёме пониманья
      Предложит каждому <strong>той</strong> тяжести <em>мешок</em>
      Который сможет он вместить в своё Сознанье
      &nbsp;
      &nbsp;
      <strong><em>А я молю </em></strong>
      <strong><em>Молю Всевысье</em></strong>
      <strong><em>Что б продолжали быть Слова</em></strong>
      <strong><em>Слова </em></strong>
      <strong><em>Диктуемые Высью</em></strong>
      <strong><em>А я б записывал Слова </em></strong>
    `
  },
  {
    id:'164',
    name: 'Как можно описать словами',
    text: `
      <strong>Как</strong> <strong>можно описать словами</strong>
      <strong><em>Прошу у Бога </em></strong>
      <strong>Дай понять</strong>
      Где взять слова что в состояньи собою свойства описать
      <em>Величья Вечного процесса Мироустройственных начал</em>
      <em>Венчающих сияньем Света цветок Души и Духа Храм</em>
      Как описать Души движенье внимающей Святым хорам
      <em>Что торжествуют всеактавно даря всезвучие Мирам</em>
      Как описать среду энергий творящих в всенебесье жизнь
      <em>Чья многоликость проявлений пронзает внеземную синь</em>
      Как описать Великий Смысл причинно-следственной системы
      И что такое <em>Дух смятенный</em>
      И что есть <em>верх</em> и что есть <em>низ</em>
      Как описать взаимосвязь
      Того
      <em>Что жизнь оборвалась и в тот же миг продлилась вновь</em>
      Как описать что <strong>Свет - Любовь</strong>
      Как описать непониманье <em>что обрекает на страданье</em>
      Как написать чтоб дать понять
      <em>Что жизнь даётся чтоб познать</em>
      Познать
      Всемерности слияний и Истину сокрытых Знаний
      <em>Что открывают Сути слов дающих Знанья МирОснов</em>
    `
  },
  {
    id:'165',
    name: 'Простыми словами нельзя описать',
    text: `
      <strong>П</strong>ростыми словами нельзя описать
      Красу неземную Вселенной
      Простыми словами нельзя передать
      Суть Правды Великой Нетленной
      Простыми словами нельзя проявить
      Блаженство того ощущенья
      С которым в пространствах возможно парить
      Себе открывая прозренье
      <em>А впрочем возможно</em>
      <strong>Но только тому</strong>
      Кто дверь распахнул из <em>трехмерья</em>
      И сделал лишь шаг
      Шаг навстречу тому
      Кто в руки дал луч озаренья
      &nbsp;
      В просторах Вселенной по струям скользя
      По струям энергий тончайших
      Ты там обретёшь
      <em>Убедишься в том сам</em>
      Всё <strong>то </strong>о чём в жизни мечтаешь
      &nbsp;
      <em>Простыми словами нельзя описать</em>
      <em>Волнистую зыбкость Всевысья</em>
      <em>Простыми словами нельзя рассказать</em>
      <em>Как встретилась мысль твоя с Мыслью</em>
      <em>С той Мыслью что светит в ночи как маяк</em>
      <em>Собой оживляя Сознанье</em>
      <em>Простыми словами нельзя объяснить</em>
      <em>Что все вы Творцы состоянья</em>
      <em>Того состоянья</em>
      <em>В котором сейчас</em>
      <em>В подборе глубин наказанья</em>
      <em>Глазами Вселенскими смотрит на вас</em>
      <em>Старейший Совет Мирозданья</em>
    `
  },
  {
    id:'166',
    name: 'Отдельные звуки от букв сочетания',
    text: `
      <strong>Отдельные звуки от букв сочетания </strong>
      <strong>Не смогут расширить объёма Сознания</strong>
      <strong>Ты выстроишь смыслы&nbsp; рифмованной строчкой</strong>
      <strong><em>Что б Суть проявить написаньем без точки</em></strong>
      &nbsp;
      Тогда лишь возникнет волнующий образ
      Тогда лишь услышишь иных Миров голос
      И только тогда
      Восприятием чувства
      Поймёшь <strong><em>что</em></strong><em> собою являет искусство</em>
      &nbsp;
      Тогда лишь почувствуешь запахи строчек
      Увидишь их цвет
      Осязать сможешь вкус
      Тогда лишь поймёшь
      Что<strong> величие слова</strong> <em>в развёрнутых смыслах слияния букв</em>
      &nbsp;
      Сознанье своё расширяя познаньем
      Познанье - прож<strong>и</strong>того осознаваньем
      Ты сможешь прочувствовать
      Сможешь понять
      Увидеть
      Услышать
      <em>Чтоб <strong>Истинно Знать</strong></em>
      &nbsp;
      Тебе предоставлена будет возможность
      Потрогать лучистый ликующий Свет
      Который развёрнутым смыслом понятий
      Тебе на вопрос твой откроет &nbsp;ответ
      <em>&nbsp;</em>
      Коль Душу откроешь энергиям строчек
      Сплетая в едино понятия строф
      То станет возможным
      <em>В любое мгновенье</em>
      Увидеть в стихе полноту многомерья
      &nbsp;
      Лишь после того как проснётся Душа
      Ты сможешь понять - <em>сколь она хороша</em>
      &nbsp;
      Недуги уйдут не оставив следа
      Когда пробудится от спячки Душа
      &nbsp;
      Себя изменяя изменишь весь Мир
      И вместо войны попадёшь ты на пир
      Где песни заздравные будут звучать
      И взгляд человечий добро излучать
      &nbsp;
      Себя совершенствуя учишь других
      Таких же как сам
      <em>Пусть немного иных</em><em>&nbsp;</em>
      &nbsp;
      Лишь только Душой исцелиться возможно
      Причина тому абсолютна проста
      А Суть её в том
      Что <strong>живешь отраженьем</strong>
      <strong>Того</strong>
      <strong>Что являет Творенье Творца</strong>
    `
  },
  {
    id:'167',
    name: 'Есть',
    text: `
      <strong>Д</strong>ля тех записан стих <em>кем будет он услышан</em>
      На лист пролился он моей рукой
      А вот составлен <strong>тем</strong> кто много выше
      <strong>Тем</strong>
      Кто сейчас плывёт в свободе неземной
      &nbsp;
      <em>Что ждёт нас впереди никто из нас не знает</em>
      <em>Предполагать не есть располагать</em>
      Отсюда вывод прост
      <strong><em>Лишь тот располагает</em></strong>
      <strong><em>Кто умудрился этот Мир Создать</em></strong>
    `
  },
  {
    id:'168',
    name: 'Есть',
    text: `
      <strong><em>В</em></strong><em>сё что выходит за границы знанья</em>
      <em>Научной степени сознанья</em>
      Собою вызывает отрицанье
      У тех
      Кто <strong>раб</strong>
      В понятьи Мирозданья
      &nbsp;
      Рабы взобрались на Олимп науки
      И молятся
      Себе
      Заламывая руки
      &nbsp;
      <em>Но иногда бывает так</em>
      Что спотыкаясь о вершину
      Скатившись кубарем в долину
      Молиться начинают Богу
      <em>Чтоб Бог </em>
      <em>Благословил дорогу</em>
      Дорогу
      <em>Что откроет пониманье</em>
      <em>Науки</em>
      <em>Под названьем</em>
      <strong><em>Мирозданье</em></strong>
    `
  },
  {
    id:'169',
    name: 'На голом камне вам не вырастить пшеницы',
    text: `
      <strong>Н</strong>а голом камне вам не вырастить пшеницы
      В болоте вам не вырастить тюльпан
      Вам не уснуть в ту ночь когда не спится
      И не запомнить что под утро снится
      И не иметь
      <strong><em>Коль я того не дам</em></strong>
      &nbsp;
      <em>Бывает впрочем</em>
      <em>Что наука достигнет нужный результат</em>
      &nbsp;
      Тогда на камне вырастет пшеница
      Тогда в болоте расцветёт тюльпан
      Уснёте в ночь когда совсем не спится
      Запомните что вам под утро снится
      &nbsp;
      Бывает так
      &nbsp;
      <strong><em>Когда я это дам</em></strong>
    `
  },
  {
    id:'170',
    name: 'В меня не веря',
    text: `
      <strong>В меня не веря</strong>
      <strong>Знаете меня</strong>
      <strong>В меня не веря</strong>
      <strong>Вы меня боитесь</strong>
      <strong>В меня не веря</strong>
      <strong>Просите меня</strong>
      <strong>Не веря просите</strong>
      <strong>При этом не стыдитесь</strong>
      <strong>&nbsp;</strong>
      <strong>Для вас для каждого наступит свой черёд</strong>
      <strong>Переосмыслить многое придётся</strong>
      <em>Теперь пиши</em>
      <strong>Пусть надо мной смеётся тот</strong>
      <strong>Кто без последствий для себя смеётся</strong>
      <strong>&nbsp;</strong>
      <strong>Но я таких покуда не встречал</strong>
      <strong>&nbsp;</strong>
      <em>Продиктовав</em>
      <em>ОН</em>
      <em>замолчал</em>
    `
  },
  {
    id:'171',
    name: 'Вас всех собрали на ковчеге Ноя',
    text: `
      <strong>Вас всех собрали на <em>ковчеге Ноя</em></strong>
      <strong><em>Вы и гребёте</em></strong>
      <strong><em>От утра и до утра</em></strong>
      <strong>Как он плывёт</strong>
      <strong>Того не понимаете</strong>
      <strong>Зачем плывёт</strong>
      <strong>Откуда и куда</strong>
      <strong>&nbsp;</strong>
      <strong>Плывёт ковчег просторами Вселенной</strong>
      <strong>Сияет путеводная Звезда</strong>
      <strong>А он плывёт маршрутом неизменным</strong>
      <strong>Ковчег ваш</strong>
      <strong>Под названием <em>Земля</em></strong>
      <strong><em>&nbsp;</em></strong>
      <strong><em>&nbsp;</em></strong>
      <em>*&nbsp; *</em>
      <em>&nbsp;</em>
      <em>Ты &nbsp;маленький такой</em>
      <em>Едва тебя&nbsp; я вижу</em>
      <em>Среди иных</em>
      <em>Чуть б<strong>о</strong>льших и чуть м<strong>е</strong>ньших</em>
      <em>Ты был всегда хорош</em>
      <em>Ты нёс собой покой</em>
      <strong><em>Мой милый шар </em></strong>
      <strong><em>Планеты голубой</em></strong>
      <em>&nbsp;</em>
      <em>&nbsp;И вот</em>
      <em>&nbsp;</em>
      <em>Неровно дышишь</em>
      <em>Не стабильный пульс</em>
      <em>То в жар тебя бросает то в озноб</em>
      <em>Ты захворала</em>
      <em>Ты теряешь силы</em>
      <strong><em>Земля родная</em></strong>
      <strong><em>Шарик ты мой милый</em></strong>
    `
  },
  {
    id:'172',
    name: 'Я здесь',
    text: `
      <strong>Я здесь</strong>
      Но вы не видите меня
      Причина в том что вы слепые
      Я говорю
      Но вы глухие
      Поэтому не слышите меня
      Я знать даю
      Но проку в этом нет
      Причина в том что вам неведом <em>Свет</em>
      По той причине слушаю в ответ сужденья ваши
      <em>Будто меня нет</em>
    `
  },
  {
    id:'173',
    name: 'Поговори со мной',
    text: `
      <strong><em>П</em></strong><em>оговори со мной </em>
      <strong>&nbsp;</strong>
      <strong>Поговорю конечно</strong>
      <strong>Коль есть вопрос</strong>
      <strong>Задай</strong>
      <strong>Я помогу</strong>
      <em>&nbsp;</em>
      <em>Кто виноват</em>
      <em>Кто лжёт самозабвенно</em>
      <em>Иль тот кому самозабвенно лгут</em>
      <em>&nbsp;</em>
      <strong>Ответ настолько очевиден</strong>
      <strong>Он разве что глупцу не виден</strong>
      <strong>&nbsp;</strong>
      <strong>Конечно виноват во лжи</strong>
      <strong>Лишь тот кто <em>правды</em> не приемлет</strong>
      <strong>И принуждает лгать ему</strong>
      <strong>А не приемлет от того </strong>
      <strong>Что всё своим аршином мерит</strong>
      <strong>По той причине <em>кривде</em> верит</strong>
      <strong>Она понятнее ему</strong>
      &nbsp;
      <em>Да да</em>
      <em>Всё так оно и есть</em>
      <em>Как раньше сам того не понял</em>
      <strong>&nbsp;</strong>
      <strong>Заметь </strong>
      <strong>Тебя я не неволил</strong>
      <strong>Ты сам решил что так и есть</strong>
      &nbsp;
      <strong>Теперь взгляни на ложь иначе</strong>
      <strong>Кто виноват</strong>
      <strong>Кто верит в ложь</strong>
      <strong>Иль лжец в погоне за удачей</strong>
      <strong>Взгляни</strong>
      <strong>Другой ответ найдёшь</strong>
      <strong>А он настолько очевиден</strong>
      <strong>Ну разве что глупцу не виден</strong>
      <strong><em>&nbsp;</em></strong>
      <strong>Конечно виноват во лжи</strong>
      <strong>Лишь тот кто лжёт тому кто верит</strong>
      <strong>Он ложь его аршином мерит</strong>
      <strong>Подумай</strong>
      <strong>Может быть поймёшь</strong>
      &nbsp;
      <em>Постой</em>
      <em>Совсем меня запутал</em>
      <em>Вопрос предельно был простой</em>
      <em>А так выходит - виноваты оба</em>
      <em>И тот кто лжёт </em>
      <em>Чтоб сохранить покой</em>
      <em>И тот кто правды не приемлет </em>
      <em>Так как своим аршином мерит</em>
      <strong><em>Так вывод из сего какой</em></strong>
      <strong>&nbsp;</strong>
      <strong>&nbsp;</strong>
      <strong>О выводе заговорил</strong>
      <strong>Приятно</strong>
      <strong>И вывод впрочем сделал тоже сам</strong>
      <strong>Я лишь дополню что бы было внятно</strong>
      <strong><em>&nbsp;</em></strong>
      <strong>Вину за ложь я людям всем раздам</strong>
      <em>&nbsp;</em>
      <em>А мне за что раздашь </em>
      <strong>&nbsp;</strong>
      <strong>Ты что стал хуже слышать</strong>
      <em>&nbsp;</em>
      <em>Нет слышу хорошо</em>
      <em>Поверить не могу</em>
      <em>В то что меня виновным сделал</em>
      <strong><em>Да я вообще с рождения не лгу</em></strong>
      <strong><em>&nbsp;</em></strong>
      <strong>Вот вот и я о том</strong>
      &nbsp;
      <em>Так чём же я виновен</em>
      <em>&nbsp;</em>
      <strong>Твоя вина любезный в том</strong>
      <strong>Что <em>правду</em> говоря ты Истины не знаешь</strong>
      <strong>А Истина мой друг </strong>
      <strong>Она совсем в ином</strong>
    `
  },
  {
    id:'174',
    name: 'Перестаньте просчитывать выгоду',
    text: `
      <strong>П</strong>ерестаньте просчитывать выгоду
      Попробуйте
      Хотя бы раз
      Быть добрым не по принуждению
      Попробуйте
      Хотя бы раз
      Не думая о том что будет завтра
      Отдать сегодня <strong>то</strong> что есть у Вас
      Попробуйте
      И многое изменится
      Так как наступит новой жизни час
      &nbsp;
      <em>А вашу выгоду </em>
      <em>Без Вас я просчитаю</em>
      <em>В сравненьи с ней&nbsp; всё прочее &ndash; пустяк</em>
      <strong><em>Я Вам не сделку друг мой предлагаю</em></strong>
      <strong><em>Я просто предлагаю сделать так</em></strong>
    `
  },
  {
    id:'175',
    name: 'Не продавай себя',
    text: `
      <strong>Н</strong>е продавай себя
      Дари
      И это с торицей вернётся
      Продажа болью отзовётся
      Не продавай себя
      Дари
      &nbsp;
      Я знаю
      Ты готов к тому
      И Я тебя Благословляю
      А как воздастся и кому
      Известно только одному
      И <strong>Я</strong> конечно это знаю
      <em>&nbsp;</em>
      <em>Он диктовал</em>
      <em>А я писал</em>
      <em>&nbsp;</em>
      <em>Чтоб прочитавший&nbsp;&nbsp; осознал</em>
    `
  },
  {
    id:'176',
    name: 'Мне некуда спешить',
    text: `
      <strong><em>М</em></strong><em>не некуда спешить</em>
      <em>Я времени не знаю</em>
      <em>По той причине просто наблюдаю как вы калечите себя</em>
      &nbsp;
      Я даровал вам Ночь и День
      Я даровал вам Свет и Тень
      Я даровал вам Жизнь Земную
      А вы
      Проводите впустую тот срок что вам определен
      Вам непонятна Суть Времён
      Вам неясны Значенья Дней
      Земных
      Открытых для страстей
      Страстей Духовного подъёма
      Вам страсть полёта незнакома в глубины внеземных Миров
      Где Вечен Миг
      Где мой Покров
      &nbsp;
      Скажу ещё что знать должны
      <strong>На вас нет никакой вины</strong>
      <em>Что жизнь земную проживая</em>
      <em>Не знаете</em>
      <em>Она какая</em>
      <em>Вам не дано на этот раз &nbsp;осмыслить Мудрецов Наказ</em>
      <em>&nbsp;</em>
      Но если кто-то посчитает
      Что в состояньи сам решить <strong><em>когда</em></strong> и <strong><em>как</em></strong> жизнь прекратить
      Тем говорю
      <strong><em>И БУДЕТ ТАК</em></strong>
      <em>Тех ждёт потусторонний мрак</em>
      <em>Тем нет возврата в синь небес</em>
      <em>Для тех понятья нет <strong>Воскрес</strong></em>
      <strong><em>Я </em></strong><em>каждому мерило дней</em>
      <strong><em>Я </em></strong><em>каждому Судья и Всепрощенье</em>
      <strong><em>Я </em></strong><em>каждому определяю Сам ухода срок и срок их возвращенья</em>
      &nbsp;
      <strong>Я вас Люблю мои родные дети</strong>
      <strong>Люблю вас всех</strong>
      <strong>И если вы</strong>
      <strong>За время пребыванья на Планете</strong>
      <strong>Поймёте</strong>
      <strong>Что земные ваши дни всего лишь Суть большого перехода</strong>
      <strong>В мою обитель</strong>
      <strong>В неземной ваш дом</strong>
      <strong>Я вас приму</strong>
      <strong>Что бы отправить снова</strong>
      <strong>Туда</strong>
      <strong>Где каждый будет вновь рождён </strong>
    `
  },
  {
    id:'177',
    name: 'Что',
    text: `
      <strong>Ч</strong>то
      Пульс пропал
      &nbsp;
      <em>Так это ерунда</em>
      &nbsp;
      Что
      Ваше сердце биться перестало
      <em>&nbsp;</em>
      <em>Таких как Вы я повидал не мало</em>
      <em>Так в чём же здесь скажите мне беда</em>
      &nbsp;
      Вот Вы передо мной
      <em>Испуг Ваш долго длится</em>
      Вы видите и слышите меня
      <em>Что ж Вам ещё</em>
      &nbsp;
      Да
      Это заграница
      Да
      Это жизнь за гранью бытия
      <em>&nbsp;</em>
      <em>А тот кто там</em>
      &nbsp;
      Так это Вы в прошедшем
      &nbsp;
      Сейчас Вы в Вечном
      <em>&nbsp;</em>
      <em>Как сие понять</em>
      &nbsp;
      Ну скажем так
      <strong>Что было - это снится</strong>
      <strong>Что есть - ещё придётся осознать</strong>
      <em>&nbsp;</em>
      <em>Куда теперь</em>
      <em>Налево иль направо</em>
      <em>Вверх или вниз</em>
      &nbsp;
      Нет
      Это брат каприз
      <em>А Вы шутник и шутка прелесть</em>
      <em>Браво</em>
      Да к стати<em> - здесь на <strong>ты</strong></em>
      Ну ты напрасно сник
      &nbsp;
      Обратно хочешь
      <em>&nbsp;</em>
      <em>Это есть ошибка</em>
      Ты к этой мысли просто не привык
      <em>Ну вот и славно</em>
      <em>Первая улыбка</em>
      Ты лучше расскажи что на Земле постиг
      Кого любил и кем был сам любим
      Как предавал и кем был предан
      Чем занимался и к чему привык
      Что думал ты
      Что говорил
      Что делал
      &nbsp;
      Что
      В двух словах не рассказать
      <em>&nbsp;</em>
      <em>Вот весельчак</em>
      &nbsp;
      Тебя ведь не торопят
      Нам срок с тобой не малый коротать
      Пока опять тебя на землю ни прогонят
      &nbsp;
      Так начинай
      Я слушаю тебя
      Нет подожди
      Я сяду поудобней
      <em>Теперь давай</em>
      Начни рассказ <em>с нуля</em>
      Начни с момента как был похоронен
      <strong><em>Да нет</em></strong>
      <strong><em>Не в этот раз</em></strong>
      <strong><em>А в тот что до того</em></strong>
      Ты <strong>там</strong> не помнил
      <strong>Здесь </strong>ты это знаешь
      <em>Ну ты чудак</em>
      <em>Опять не понимаешь</em>
      Здесь не Земля
      Взгляни
      Здесь Небеса
      Здесь помнят все и всё о том что было прежде
      <em>Лишь на земле глупцы живут в надежде </em>
      <em>Что им не зададут вопрос</em>
      <strong><em>Кем был</em></strong>
      <strong><em>Как жил</em></strong>
      <strong><em>И что привнёс</em></strong>
      &nbsp;
      Ну ладно
      Вижу ты в смятеньи
      Опять пытаешься хитрить
      <em>Смешной ты право</em>
      <strong>Нет здесь денег</strong>
      <strong>На них прощенья не купить</strong>
      <em>&nbsp;</em>
      Теперь поговорим серьезно
      Настал момент смотреть кино
      <em>Оно покажет</em>
      <em>Что на сколько</em>
      <em>В твоих понятьях Зло - Добро</em>
      Тогда и вынесем решенье куда тебе продолжить путь
      Иль вид земного воплощенья придётся нам тебе вернуть
      <strong>&nbsp;</strong>
      <strong>Решенье будет беспристрастным</strong>
      <em>Ты это должен понимать</em>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Вот что нелишне </em></strong>
      <strong><em>Всем Землянам</em></strong>
      <strong><em>Как можно раньше осознать</em></strong>
    `
  },
  {
    id:'178',
    name: 'Всё вроде есть',
    text: `
      <strong><em>В</em></strong><em>сё вроде есть</em>
      <em>Живу нужды не зная</em>
      <em>Есть что попить</em>
      <em>Есть что поесть</em>
      <em>Но всё же чувствую</em>
      <em>Чего-то не хватает</em>
      <em>В груди как будто бы пустоты есть</em>
      &nbsp;
      Пустоты говоришь
      Вот это интересно
      Об этом поподробней расскажи
      Глядишь и разберём твою проблему
      А там глядишь придут другие дни
      <em>&nbsp;</em>
      <em>Да что тут говорить</em>
      <em>Я право и не знаю</em>
      <em>Так </em>
      <em>Иногда</em>
      <em>Навалится тоска</em>
      <em>Куда себя девать не знаю</em>
      <em>Потом отпустит</em>
      <em>И уйдёт сама</em>
      &nbsp;
      И всё же
      Отчего ж с тобой такое
      Что происходит у тебя внутри
      Да и вообще
      Внутри ли происходит
      Ты за собой внимательней смотри
      Понаблюдай
      В какое время суток
      На сытый или на пустой желудок
      Иль ноги застудил когда
      Случается такое иногда
      <em>&nbsp;</em>
      <em>Нет</em>
      <em>Всё не то</em>
      &nbsp;
      Не то
      Так в чём же дело
      <em>&nbsp;</em>
      <em>Да я и сам хотел бы это знать</em>
      &nbsp;
      Хотел бы знать
      Да друг мой
      Это см<strong>е</strong>ло
      Ну коль <strong>хотел бы</strong>
      Буду помогать
      &nbsp;
      Давай посмотрим из чего ты сложен
      &nbsp;
      <em>Как из чего</em>
      <em>Вот я перед тобой</em>
      &nbsp;
      Передо мной не ты
      А твой носитель
      <em>&nbsp;</em>
      <em>Ты видно дед смеёшься надо мной</em>
      &nbsp;
      Ну отчего ж смеюсь
      Смеялся чуть пораньше
      Сейчас серьёзно говорю с тобой
      И коль поймёшь
      Тогда решишь задачу
      И сразу жизнь проявится иной
      <em>&nbsp;</em>
      Всё что внутри
      Всего лишь механизмы
      Рука
      Нога
      И прочее что есть
      <strong>Биологический прибор</strong>
      Который впрочем
      Как сам ты говоришь
      Любитель пить и есть
      И это <strong>то</strong>
      Чем ты себя считаешь
      Иначе б не сказал
      <em>Что вот мол я</em>
      Ты
      Кое - что <strong>ещё</strong>
      А что <strong>ещё</strong> не знаешь
      Вот в этом в аккурат тебе и нужен я
      <em>&nbsp;</em>
      Ты много больше
      Чем ты прежде думал
      И в основном находишься не здесь
      <em>&nbsp;</em>
      <em>Да что ты говоришь</em>
      <em>Об этом я не думал</em>
      &nbsp;
      Так вот подумай
      Время ещё есть
      И хватит реплик
      Ты меня сбиваешь
      Молчи и слушай что тебе скажу
      <strong>Кто ты такой</strong>
      <strong>Конечно ты не знаешь</strong>
      <strong>Но я тебе об этом расскажу</strong>
      &nbsp;
      Давным &ndash; давно
      По меркам человечьим
      Жил-был купец
      Богат
      Но одинок
      Жил всяко-разно
      И финал конечно
      Себя ждать не заставил
      Выпал срок
      &nbsp;
      А ты заметь
      Я начал из &nbsp;дал<strong>ё</strong>ка
      Но это не начало дней твоих
      Начала дней твоих запрятаны глуб<strong>о</strong>ко
      Тебе отсюда не увидеть их
      Поэтому
      Рассказ с купца я начал
      Так вот
      Продолжу
      Выпал ему срок
      Был погребён
      А с ним его удача
      Душою же купец был вознесён
      &nbsp;
      И вот <strong>когда</strong> его Душе досталось
      Прошла чистилище
      Прошла суровый Суд
      &nbsp;
      Так
      Кое в чём конечно оправдалась
      По чести-совести
      Ведь там у нас не лгут
      &nbsp;
      О том как быт на небесах устроен
      Рассказывать не стану
      Сам поймёшь
      Когда пора придет всё сам узнаешь
      <strong>Да как ещё</strong>
      Когда пора придёт
      &nbsp;
      Короче говоря
      Судом Всевышней Власти
      Определив некачества Души
      Решили так
      <strong>Довольно жить ей в сласти</strong>
      <strong>Что для неё дадут другие дни</strong>
      &nbsp;
      Так дальше и пошло
      &nbsp;
      Её опять вселили
      При всём при этом наделили
      Судьбой
      Которую она собой в младенца привнесла
      &nbsp;
      И эта жизнь прошла своей дор<strong>о</strong>гой
      Дорогой бедною
      Но уж не одинокой
      &nbsp;
      И долго ещё длился хоровод
      Тем приближая твой черёд
      &nbsp;
      Я рассказал тебе не много
      Но и достаточно вполне
      Чтоб ты подумал о дороге
      Что вручена твоей Судьбе
      Чтоб обнаружил недостаток
      В том что Душе не додаёшь
      Отсюда выведешь остаток
      И по- другому заживёшь
      &nbsp;
      Твоя Душа тебе сигналит
      В груди рождая пустоту
      Поразбирайся сам с собою
      Не сможешь
      Дальше помогу
    `
  },
  {
    id:'179',
    name: 'Обширных знаний крест несёшь по жизни ты',
    text: `
      <strong>О</strong>бширных знаний крест несёшь по жизни ты
      <em>Тебе те знанья дали в наказанье</em>
      И потому мой брат в ответе ты
      <em>За Свет за Тьму за Мирозданье</em>
      &nbsp;
      В тебе пылает жизненная страсть
      Возможность всеобъемлющих понятий
      Тебе <strong>дано</strong> что трудно <strong>им</strong> понять
      <em>В бреду живущим</em> <em>в чреве всех напастей</em>
      <em>&nbsp;</em>
      Задача только видится простой
      <em>Понять систему отношений</em>
      Но как мелк<strong>и</strong> вы все порой
      Когда <em>вот так</em> не разбирая
      <em>Надувши щёки</em>
      <em>Выстрел взгляд</em>
      Собой довольствуясь не мало
      Забыв что ваших знаний мало
      Гадаете
      <em>Зачем сей сад</em>
      Сад
      Жизни
      Смерти
      Переходов
      Взаимодействий и свобод
      Сад наказаний и поощрений
      И сад где всё наоборот
      <em>В обратном всё порядке вновь</em>
      Вот просыпается Любовь
      Вот счастье
      Вот разлука
      Боль
      Вот Свет
      Вот Тьма
      <em>Как жизнь сурова</em>
      <em>С порога жизни и до гроба</em>
      &nbsp;
      Тебе дано сей цикл познать
      Тебе дано так много знать
      Тебе дано
      Дано
      Дано
      <strong>НО</strong>
      Есть понятье <strong><em>НО</em></strong>
      &nbsp;
      Но коль дано
      Держи ответ
      Пред всеми кто живёт и нет
      Кто жил
      Кто будет жить ещё
      <strong>И здесь опять встречаем НО</strong>
    `
  },
  {
    id:'180',
    name: 'Чтоб себя оградить от досадных проблем',
    text: `
      <strong>Ч</strong>тоб себя оградить &nbsp;от досадных проблем
      Ты уйти должен <strong>от</strong> составления схем
      &nbsp;
      Схемы
      Это уроки прошедшего дня
      И смывает их ранней росою заря
      Свойства нового дня
      Торжеством перемен
      Не оставят следа
      От составленных схем
      &nbsp;
      <em>Это многим покажется странным подходом</em>
      <em>Ты же <strong>знай</strong></em>
      <em>Перед дальним серьёзным походом</em>
      <em>Вверить нужно себя </em>
      <em>Тем </em>
      <em>Кому доверяешь</em>
      <em>И быть готовым познать </em>
      <em>Что покуда не знаешь</em>
      &nbsp;
      <strong>Будет резким рывок в Мир наполненный новью</strong>
      <strong><em>Не робей</em></strong>
      <strong><em>Ты готов</em></strong>
      <strong><em>Ты пройдешь путь с Любовью</em></strong>
    `
  },
  {
    id:'181',
    name: 'Мне дали знать',
    text: `
      <strong>М</strong>не дали знать
      <em>Настало время</em>
      <em>Открыть предметно Суть того</em>
      <em>Что до текущего момента понять вам было не дано</em>
      <strong>&nbsp;</strong>
      <strong>Вселенная в себя включает несчётное число Миров</strong>
      <strong>Которые объединяет взаимодействием Домов</strong>
      <strong>Дома Миров собой являют взаимосвязанность Родов</strong>
      <strong>Тех что процессом управляют</strong>
      <strong>Процессом жизненных основ</strong>
      <strong>Многообразье измерений творя невидимость границ</strong>
      <strong>Собой являет книгу Сутей с незримым множеством страниц</strong>
      <strong>Но чтоб вы прочитать сумели хотя б строку из книги той</strong>
      <strong>Необходимо постараться</strong>
      <strong>И для начала разобраться</strong>
      <strong>Придётся вам&nbsp; самим с собой</strong>
    `
  },
  {
    id:'182',
    name: 'Я на краю стою',
    text: `
      <strong>Я </strong>на краю стою
      Вокруг меня <em>бездонье</em>
      Не различить объёма глубины
      Масштабы те что были мне известны
      Не применить
      Границы не видны
      &nbsp;
      <em>Отсутствие понятий измерений</em>
      <em>Даёт почувствовать безмерность величин</em>
      <em>Эфирной зыби в красоте явлений</em>
      <em>Вселенской плоти</em>
      <em>С коей я един</em>
      &nbsp;
      Я на краю стою своих непониманий
      <em>Вокруг меня</em>
      <em>Безмерной глубиной</em>
      <em>Единое Вселенское Сознанье</em>
      Я им дышу
      Как дышит оно мной
    `
  },
  {
    id:'183',
    name: 'Разорвав пространство-время',
    text: `
      <strong>Р</strong>азорвав пространство-время
      Отворив калитку Света
      Как стреноженный свободой
      Восторгаясь с раздраженьем
      Обнаружив тень от солнца
      Убедившись в тверди неба
      Ощутив озноб горячий в пламени холодных звёзд
      Отражением небесным на земле я проявился
      И ничуть не удивился проявлению небес
      В эти плоские картинки
      На экране жизни плотской
      Кое-как я разместился отражением себя
      Обнажив себя я выжил в этом мире отражений
      Обнажив себя услышал и почувствовал на вкус
      Как сплетаются в едино все процессы во Вселенной
      Отражённым светом тени
      Звуком мысли
      Чувством чувств
    `
  },
  {
    id:'184',
    name: 'Мы на Земле живём',
    text: `
      <strong>М</strong>ы на Земле живём&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      Над головами небо
      Там жизнь бурлит неведомая вам
      Хотите расскажу как происходит это
      Довольно часто я бываю там
      &nbsp;
      То сам туда иду
      А то по приглашенью
      А то и вызовут б<em>ывает даже так</em>
      Дела
      Везде дела
      Нет времени безделью
      <em>Поверьте&nbsp; н<strong>а</strong>слово</em>
      <em>Ведь я озвучил факт</em>
      &nbsp;
      То что постичь мне дали
      Это м<strong>а</strong>лость
      И за неё спасибо небесам
      Я ж в свой черёд от вас скрывать не стану
      И приоткрою всё что знаю сам
      &nbsp;
      Я постараюсь аккуратным словом
      Рассказ наполнить
      Тем открыть для вас
      Понятье Вечного Вселенского Закона
      И показать что значит он для нас
      &nbsp;
      На небесах иное время жизни
      Есть небеса где время вовсе нет
      И на зам<strong>о</strong>к закрытой нет там двери
      Таблички нет <em>Закрыто на обед</em>
      Прозрачны мысли там и намер<strong>е</strong>нья
      Там нет нужды раскладывать пасьянс
      Там также нет и лунного затменья
      И нет того что принуждало б нас
      &nbsp;
      И вот подсказка
      &nbsp;
      <em>Строй рассказ ин<strong>а</strong>че</em>
      <em>Заполни Сутью место между строк</em>
      <em>Те кто прочтут межстрочья</em>
      <em>Тем удача</em>
      <em>Они постигнут первый наш Урок</em>
      <em>&nbsp;</em>
      Пиши о том
      <em>Что безграничны дали</em>
      Пиши о том
      <em>Что нескончаем Миг</em>
      Пиши о том
      <em>Как вам Планету дали и воплощенье в Человечий вид</em>
      Пиши о том
      <em>Что это проходяще</em>
      Пиши о том
      <em>Что <strong>та </strong>придет пор<strong>а</strong></em>
      <em>Когда Судом Вселенской Высшей Власти</em>
      <em>Воздастся вам за мысли и дела</em>
      Пиши о том
      <em>Что жизнь &ndash; весы</em>
      <em>Слова</em>
      <em>Поступки</em>
      <em>Мысли - это гири</em>
      Пиши о том
      <em>Что каждый сам свободен выбирать в каком жить Мире</em>
      <em>В каких Мирах из тех что за чертой</em>
      <em>Продолжить долгое существованье</em>
      <em>Иль в том где боль</em>
      <em>Иль где Святой покой развёрнутым понятием Сознанья</em>
      Пиши о том
      <em>Что каждого из вас</em>
      <em>На всём пути Земного проявленья</em>
      <em>Ведут</em>
      <em>Не оставляя ни на час</em>
      <em>Те силы что вам дали воплощенье</em>
      Пиши о том
      <em>Что связь причин и следствий</em>
      <em>Везде во всём</em>
      <em>И будет так всегда</em>
      Пиши о том
      <em>Что жизнь границ не знает</em>
      <em>И только те кто это понимает</em>
      <em>Прочтя межстрочья</em>
      <em>Обретут себя</em>
      <em>&nbsp;</em>
      <em>Пиши о том</em>
    `
  },
  {
    id:'185',
    name: 'Нет границ',
    text: `
      <strong>Н</strong>ет границ
      Безграничны владенья Твои
      Сколько солнц
      Сколько лун
      И не счесть
      Океаны
      Моря
      Города и <strong>я </strong>
      В безграничной Вселенной есть
      &nbsp;
      Я в безвременьи
      Я в безграничьи живу
      Там где правит Закон Любви
      Но всегда возвращаюсь
      Туда где поют песню дивную соловьи
      Возвращаюсь туда
      Где меж веток берёз паучок выплетает сеть
      Я туда возвращаюсь
      Где пахнет трава и к обеду хочется есть
    `
  },
  {
    id:'186',
    name: 'Иду размерено не торопясь',
    text: `
      <strong>И</strong>ду размерено не торопясь
      А мне толпа бежит навстречу
      Стараются друг друга обогнать
      Подножки ставят
      Тем&nbsp; себя&nbsp; калеча
      &nbsp;
      Кричат мне набегу что я сошел с ума
      Что я иду в обратном&nbsp; направленьи
      А я иду
      И мне легко шагать
      Я знаю <strong>то</strong>
      Что не дано им знать
      Поскольку
      Возвращаюсь я оттуда
      Куда им не удастся добежать
      &nbsp;
      Я возвратился чтобы предложить
      <em>Всё <strong>то</strong></em>
      <em>Чем я теперь владею</em>
      Но видя то что вижу
      Я не смею
      <em>С живущим на бегу не</em> <em>ст<strong>о</strong>ит говорить</em>
      &nbsp;
      Я буду говорить для вас
      Кто не бежит
      Кто сердцем слышать может
      <em>Пусть каждому из вас Душа поможет</em>
      <em>Услышать <strong>то</strong></em>
      <em>Что буду говорить</em>
    `
  },
  {
    id:'187',
    name: 'Я слышу как поют хоры',
    text: `
      <strong>Я </strong>слышу как поют хоры
      Слов не понять
      Я слышу только чувства
      Я слышу
      В чистоте Вселенских нот Звучание небесного искусства
      Многоголосие небес
      Влечёт меня
      Лучом тончайшим
      Спирально восходящим в Свет
      В котором &nbsp;неземной &nbsp;рассвет хранит восход Великой Тайны
      Звучит заполненный объём Всезвучьем Высшего хорала
      Внутри себя мне места мало
      Вся эта музыка во мне
      Я звук
      Я Свет
      Я бесконечность
      В Вселенском половодье звуков
      Я в высшей степени беспечность
      Так как собой являя Вечность
      Ликую стройной высотой многооктавных излияний
      Духовность
      В звуках стройных нот
      Даруя вечностный аккорд
      Звучит&nbsp; Венцом в <em>седьмой </em>октаве
      Я знаю эти голоса
      Я слышал их неоднократно
      Они
      Лучистый вздох Творца
      Они
      Творят Миры
      Наглядно
      Подобий нет тем голосам
      Что наполняя Свет собою
      Являя щедрый&nbsp; Божий Дар в меня проникнув стали мною
      &nbsp;
      <em>Ох</em>
      <em>Боже мой</em>
      <em>Какая м<strong>у</strong>ка</em>
      <em>Какая сладостная страсть</em>
      <em>В том </em>
      <em>Что ищу возможность</em>
      <em>Словом</em>
      <em>Звучанье чувства передать</em>
      <em>Прошу Тебя</em>
      <em>Способность дай мне</em>
      <em>Души движенье описать</em>
      <em>Души </em>
      <em>Что вторит песням Неба</em>
      <strong>Дай Сил</strong>
      <strong><em>Величье Слов познать</em></strong>
    `
  },
  {
    id:'188',
    name: 'Стиха творенье Суть стихотворенья',
    text: `
      <strong>С</strong>тиха творенье Суть стихотворенья
      Даётся мне в моменты просветленья
      Когда отринув страсти бытия
      В Вселенной растворяюсь я
      Когда Душа свободу обретя
      Парит в пространствах
      Вечностью живя
      &nbsp;
      И лишь тогда сплетаются слова
      Рождая поэтичность текстов
      Что содержанием подтекстов
      Вскрывают смыслы бытия
      &nbsp;
      Чтоб Вы могли парить со мной
      В объятьях жизни внеземной
      Вобрать в себя смогли б просторы
      Каких не достигают взоры
      Откройте сердце
      Лишь тогда
      Стихи Вас унесут туда
      Где жду Вас я
      Где даль близка
      Где нет понятия тоска
      Где полной чашей Истинные Знанья
      Для Вас&nbsp; откроют Суть самопознанья
    `
  },
  {
    id:'189',
    name: 'В каждой строчке заложен ритм',
    text: `
      <strong>В</strong> каждой строчке заложен ритм
      Жизнетворных частот вибрации
      Формирующих диапазон
      Волновым лучом генерации
      Что имеет свою длину
      Что имеет свое звучание
      Что включает палитру всю
      Цветоносного содержания
      &nbsp;
      И отсюда взаимосвязь
      Восходящих потоков Сознания
      И портрета ментального явь
      В отражении материального
      &nbsp;
      И ещё попрошу учесть
      Межпараметровые переходы
      Эта связь проявляет собой
      Аспектуальный фрагмент Природы
      И ещё попрошу учесть
      Смысла Суть стихотворных строк
      Она поможет Душой прозреть
      И поможет усвоить <em>Урок</em>
      И ещё попрошу учесть
      Что <em>Урок </em>это жизненный путь
      Тот
      Который когда нибудь
      Отворит дверь в иную Суть
    `
  },
  {
    id:'190',
    name: 'Я словом',
    text: `
      <strong>Я </strong>словом
      Кровь твою восстановлю
      Ту
      Что энергией из твоего сочится тела
      Глаза закрою
      Богу помолюсь
      Что бы помог успешно сделать дело
      А дело
      Не простое ремесло
      В том деле Суть Судьбы моей сокрыта
      Оно в звучаньи Слова моего
      К твоей Душе
      Что для него открыта
      &nbsp;
      <em>Прими Душой</em>
      <em>И раствори в Душе </em>
      <em>Сердечный ритм Духовных толкований </em>
      <em>Величья нашей жизни на Земле </em>
      <em>В процессе Вечном </em>
      <em>Самопознаваний</em>
    `
  },
  {
    id:'191',
    name: 'Дай Бог',
    text: `
      <strong>Дай Бог</strong>
      Мне не свернуть с пути
      Которым должен я пройти
      <strong>Дай Бог </strong>
      Способность <strong>осознать </strong>
      <em>Всё <strong>то</strong> что предстоит познать</em>
      <strong>Дай Бог</strong>
      <strong>Способность</strong> проявить
      <em>Идя по жизни мудрым быть</em>
      Чтоб благодарно принимать
      <strong><em>Всё</em></strong><em> что придётся испытать</em>
      <strong><em>Душой Ликуя и Любя</em></strong>
      <strong><em>Тебя в себе</em></strong>
      <strong><em>В Тебе себя</em></strong>
    `
  },
  {
    id:'192',
    name: 'Вроде бы не мало',
    text: `
      <strong>В</strong>роде бы не мало
      Да не так уж много
      Пройдена с рожденья по сей день дорога
      &nbsp;
      Пройдена не много
      И не то чтоб мало
      Только жизнь быстрее от чего-то стала
      &nbsp;
      <strong>То</strong> что раньше длилось нынче пролетает
      Календарь настенный незаметно тает
      &nbsp;
      Вдаль уходят годы
      Вырастают дети
      Как бы дать понять им
      Что живя на свете н<em>ужно жить Полётом</em>
      <em>Нужно жить Любовью</em>
      <em>Лишь тогда возможно в жизни быть собою</em>
      Быть собою
      Значит
      Жить своей <em>Судьбою</em>
      Чтоб Судьбу осмыслить нужно жить <em>Душою</em>
      &nbsp;
      Лишь тогда не страшно ускоренье жизни
      <strong><em>Ведь Душа бессмертна</em></strong>
      <strong><em>Как бессмертны мысли</em></strong>
    `
  },
  {
    id:'193',
    name: 'На волне своих завтрашних снов',
    text: `
      <strong>Н</strong>а волне своих завтрашних снов
      Уплыву в безграничные дали
      <em>Вы мой друг там ещё не бывали</em>
      Там общаться возможно без слов
      Там открыто понятье ч<em>то есть собой жизнь</em>
      Жизнь
      В всемерностях Мирозданья
      Там нет времени
      <em>Только безкрайностный Миг</em>
      Миг развернутых видов Сознанья
      Там есть <strong><em>Всё</em></strong>
      <em>Что для многих являет <strong>Ничто</strong></em>
      Там ядро концентрации <em>Воли</em>
      Там беспечность живёт
      <em>В пониманьи людском</em>
      Там нет слёз
      Нет страданий
      Нет боли
      Там
      Понятье различий являет собой
      Единение мысленных взоров
      Там богатства Духовные
      Вечной рекой наполняют собою просторы
      Там красиво
      Надёжно
      Там мой родной дом
      <em>По нему я конечно скучаю</em>
      А вот быть мне приходится <em>здесь</em> и <em>сейчас</em>
      <em>В чём причина того</em>
      <em>Понимаю</em>
    `
  },
  {
    id:'194',
    name: 'На вопрос',
    text: `
      <strong>Н</strong>а вопрос
      <em>Я верю &nbsp;иль не верю</em>
      Не ответить попросту не смею
      От того как <em>на дух<strong>у</strong></em> вам отвечаю
      <em>Вы верите </em>
      <em>А я</em>
      <em>Поверьте</em>
      <strong><em>Знаю</em></strong>
    `
  },
  {
    id:'195',
    name: 'Рождённый мыслью жизнь творящий Свет',
    text: `
      <strong>Р</strong>ождённый мыслью жизнь творящий Свет
        Ослепительной вспышкой тьмы
        Освещая изнанку цвета
        Проявляет Миров сюжеты
        <em>Тех Миров где живут мечты</em>
        &nbsp;
        Приоткрыв неземной архив
        Неосознанных состояний
        Наделяет крупицей знаний
        <em>Чувством знания в нас растворив</em>
        <em>&nbsp;</em>
        Отражением знания чувств
        Насыщая пространство-время
        Он вручает нам тяжкое бремя
        <em>Допуская в Священный Союз</em>
    `
  },
  {
    id:'196',
    name: 'Опаздывал туда',
    text: `
      <strong>О</strong>паздывал туда
      <em>Куда спеш<strong>и</strong>л</em>
      И приходил туда
      <em>Куда не звали</em>
      Смеялся там
      <em>Где были все в печали</em>
      И плакал так
      <em>Что многих веселил</em>
      Путём широким шёл как по тропе
      Наощупь выходил из лабиринтов
      При свете &nbsp;слеп
      <em>Но видел в темноте</em>
      И <strong>то </strong>что не терял искал везде
      Бывал виновным <em>будучи невинным</em>
      &nbsp;
      Напрасно вы сердились на меня
      <em>Я вам открою что вы знать должны</em>
      <em>&nbsp;</em>
      <strong><em>Я это время жил с обратной стороны</em></strong>
      <strong><em>И отводил от вас</em></strong>
      <strong><em>Всё то</em></strong>
      <strong><em>Чем вы больны</em></strong>
    `
  },
  {
    id:'197',
    name: 'Я на обряде был',
    text: `
      <strong>Я </strong>на обряде был
      Где Свет и Тьма венчались
      С тех пор живут семьёй
      Ни разу не ругались
      Их брак не тяготит
      Всё делят на двоих
      <em>И дети есть у них</em>
      <em>Я знаю их</em>
    `
  },
  {
    id:'198',
    name: 'Я был у Врат',
    text: `
      <strong>Я </strong>был у <em>Врат</em>
      И за <em>Вратами</em> был
      Я через них входил и выходил
      Я видел тех кого не пропускали
      <em>Они ж просили</em>
      <em>Умоляли</em>
      Я видел тех кто плакал от досады
      И видел тех которым были рады
      &nbsp;
      Да
      Я частенько там бываю
      Бывая там
      Я наблюдаю
      Как люди начинают понимать
      Всё <strong>то</strong>
      Что раньше нужно было б знать
    `
  },
  {
    id:'199',
    name: 'Ты хочешь знать что будет завтра',
    text: `
      <strong>Т</strong>ы хочешь знать что будет завтра
      Скажу
      Но прежде ты ответь
      <em>Что мой ответ в тебе изменит</em>
      <em>Что в дне сегодняшнем отменит</em>
      <em>Иль может привнесёт в него</em>
      Я жду ответа твоего
      &nbsp;
      Молчишь
      <em>Ты вижу испугался</em>
      Так я тебя не тороплю
      Когда с собою разберёшься
      Зови
      Я вновь к тебе приду
      Тогда быть может потолкуем
      Поразмышляем <em>что к чему</em>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Подумай</em></strong>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Хорошо подумай</em></strong>
      <strong>&nbsp;</strong>
      <strong>Как знать &nbsp;что я тебе &nbsp;&nbsp;скажу</strong>
    `
  },
  {
    id:'200',
    name: 'Собой являет каждый то',
    text: `
      <strong>С</strong>обой являет каждый <strong>то</strong>
      Что сам собой являет
      Но что являет сам собой
      Не каждый понимает
      &nbsp;
      Двухмерным штампом &nbsp;<em>зло-добро</em>
      Клеймим себе подобных
      В непониманьи <em>зла-добра</em>
      И их корней природных
      &nbsp;
      Ведь &nbsp;<em>добрость зла</em> утаена
      <em>Как смыслы в каплях ртути</em>
      А ртуть
      Нам не спешит открыть
      Проявленности Сути
      И лишь мгновение спустя
      Сливаясь воедино
      Проявит ртуть Суть бытия
      <em>Дав знать что <strong>всё едино</strong></em>
      <em>&nbsp;</em>
      <em>И те кто смогут распознать </em>
      <em>Причинность их слияний</em>
      <em>Те смогут глубже понимать</em>
      <em>Природный смысл деяний</em>
    `
  },
  {
    id:'201',
    name: 'Ты есть творенье Бога',
    text: `
      <strong>Т</strong>ы есть творенье Бога
      Ты есть Бог
      Крупица Бога
      Толика Его
      <em>Осознаешь ты это или нет</em>
      <em>В том разницы не много для него</em>
      &nbsp;
      <strong>Вот для тебя</strong>
      <em>Да что тут говорить</em>
      Пока не осознаешь ты сего
      Не сможешь слышать видеть и творить
      Себя с собой не сможешь примирить
      Не сможешь осознать <em>что</em> <strong><em>ЧелоВек</em></strong>
      Есть Вечный Разум в Вечности Миров
      Что брызги знаний принятых тобой
      Тебе возможность дали быть собой
      &nbsp;
      Но осознанье кто собой есть ты
      Возможно&nbsp; только в случае того
      <em>Что ты познаешь Код своей Души</em>
      И будешь <strong>знать</strong>
      <strong>Что</strong> есть <em>добро </em><strong>что</strong> <em>зло</em>
      Что <strong><em>зло добра</em></strong> творимое тобой
      Есть то же самое что есть <strong><em>добро от зла</em></strong>
      И что всем этим пропитала жизнь&nbsp; оттенки многие
      От <em>б<strong>е</strong>ла</em> до <em>темн<strong>а</strong></em>
      &nbsp;
      Да
      Верить в это мало
      Нужно знать
      <em>Знать</em>
      <em>Что рулетом свернутая жизнь</em>
      <em>Тобой пронзая Вечности Миров</em>
      <em>Имеет неразгаданный мотив</em>
      &nbsp;
      А что бы ты всё это Осознал
      Тебе дана возможность Познавать
      А это значит
      <em>Знать</em>
      <em>Любить</em>
      <em>Творить</em>
      Что позволяет <strong>ЧелоВеком быть</strong>
    `
  },
  {
    id:'202',
    name: 'Религий много',
    text: `
      <strong>Р</strong>елигий много
      А <em>Исток</em> один
      Един Исток <em>с Момента и Вовеки</em>
      А мы живём не видя <em>Высоты</em>
      Живём <strong>в бреду</strong> не поднимая веки
      &nbsp;
      <strong>Возможно ли кого-то обвинить</strong>
      В том что понятие &nbsp;Единого Начала
      <em>Как Суть глубин познания себя</em>
      Отдали в руки <strong>тех</strong>
      Кто &nbsp;изначально
      В стремлении открыть природность дней
      Даруя людям Знания Единства
      Не в состояньи были углядеть незримость нашу
      Сути<em> Триединства</em>
      Что растащив понятья по углам
      Соорудив из них себе оружье
      Построил каждый для себя свой Храм
      <strong>Крупицей</strong> знанья Смысла<em> Вездесущья</em>
      <em>&nbsp;</em>
      Но я в свой час определил конец
      Непониманья мною многих сутей
      И он явил начальность для меня
      Пути начальность
      Между перепутий
      Мой путь открыл мне многие слова
      Что до того скрывали Коды Знаний
      И Коды Знаний развернули для меня
      Суть слитных Смыслов в Мире пониманий
      <strong>И вот</strong> слова творимые Душой
      Всех вас в Души просторы приглашая
      Являя <em>Суть Целительства </em>собой
      <em>Лекарства Суть</em>
      Болезни отгоняя
      <em>Бальзамом </em>служат для глубоких ран
      <em>Нектаром </em>ото всех недугов жизни
      Надеждой
      Верой
      Знаньем исцелят
      <strong>Ваш</strong> открывая путь к потоку мыслей
      &nbsp;
      <strong><em>И знайте</em></strong>
      <strong><em>БОЛЬ</em></strong> ДЛЯ ВСЕХ ЛЮДЕЙ ЗЕМЛИ
      ЕСТЬ ПОКАЗАТЕЛЬ <strong><em>СКОЛЬ БЕЗДУМНЫ МЫ</em></strong>
    `
  },
  {
    id:'203',
    name: 'Не сразу',
    text: `
      <strong>Н</strong>е сразу
      Но наступит время
      Придёт желанная пора
      И примут то что отрицали
      Признав что прежде отвергали
      <em>Всем Миром примут</em>
      <em>На Ура</em>
      &nbsp;
      Признают правильность религий
      В &nbsp;<strong>ЕдиноБожии начал</strong>
      Признают <strong>то</strong> что Бог Единый
      Сынов религий <strong>назначал</strong>
      Что лишь людское извращенье
      Основ Единого Отца
      Зат<strong>е</strong>рнило пути общенья
      ВзаимоСвятоУваженья
      Поправ Высокое Ученье
      Бой развязав&nbsp; за пьедестал
      &nbsp;
      Я знаю что наступит время
      Придёт желанная пора
      И примут то что отрицали
      Признав что прежде отвергали
      <strong><em>Всем Миром примут</em></strong>
      <strong><em>На Ура</em></strong>
    `
  },
  {
    id:'204',
    name: 'В спираль закрученная мысль',
    text: `
      <strong>В </strong>спираль закрученная мысль
      Пройдя чистилище Судьбы
      Мгновеньем озаренья сна
      Пронзая времени круги
      Прошив материю времён
      Образовала <em>цвета тон</em>
      &nbsp;
      Тон цвета
      Обращённый в Свет
      В себя вобравший Сути Суть
      Формированием себя
      Не завершит свой вечный путь
      Ведь состоявшаяся мысль
      Цветной являя перелив
      Даёт возможность осознать
      Твой личный&nbsp; жизненный мотив
      &nbsp;
      Понятьем <strong><em>Тени Света</em></strong>
      Понятьем <strong><em>Свет Теней</em></strong>
      Позволит распознать тебе
      Иные свойства дней
    `
  },
  {
    id:'205',
    name: 'Когда спит Бог',
    text: `
      <strong><em>К</em></strong><em>огда спит Бог</em>
      <em>То кто же правит Миром</em>
      <strong>Святые</strong>
      <em>А их как распознают</em>
      <strong>Распознавать Святых довольно просто</strong>
      <strong>Они дают чуть больше чем берут</strong>
      <em>Так отчего ж тогда живём во мраке</em>
      <em>И почему не сладок наш удел</em>
      <em>Ни потому ль </em>
      <em>Что у Святых сегодня без суеты земной хватает дел</em>
      <strong>Конечно нет</strong>
      <strong>Всё выглядит иначе</strong>
      <strong>Причина в том </strong>
      <strong>Что все мы чуда ждём</strong>
      <strong>Так и живём</strong>
      <strong>В погоне за удачей</strong>
      <strong>Умом</strong>
      <strong>А не Душой своей живём</strong>
      <strong>&nbsp;</strong>
      <strong>А что Святых касается</strong>
      <strong>Конечно</strong>
      <strong>Бывает ошибаются они</strong>
      <strong>А как иначе</strong>
      <strong>Ведь Святые люди</strong>
      <strong>Они ведь люди</strong>
      <strong>Люди</strong>
      <strong>Как и мы</strong>
    `
  },
  {
    id:'206',
    name: 'Чтобы понять природу Высшего Начала',
    text: `
      <strong>Ч</strong>тобы понять природу Высшего Начала
      Себя познать должны мы для начала
      <em>Увидеть что творится вокруг нас</em>
      А мы живём не открывая глаз
      &nbsp;
      Стремленье &nbsp;Жить
      Мы путаем с желаньем
      Господний дар&nbsp; Творить
      Смешали с подражаньем
      Событья происшедшие с другими
      Мы для себя считаем роковыми
      <strong>&nbsp;</strong>
      <strong><em>Мы путаемся </em></strong>
      <strong><em>Путая других</em></strong>
      <strong><em>Причин не ведая </em></strong>
      <strong><em>Не понимая их</em></strong>
    `
  },
  {
    id:'207',
    name: 'Лето',
    text: `
      <strong>Л</strong>ето
      Зима
      Осень
      Весна
      Жара
      Холода
      Огонь и вода
      Земля и небо
      Глубина
      Высота
      Рожденный
      Убитый
      Всегда
      Никогда
      Теряем
      Находим
      Приходим
      Уходим
      <em>И вновь</em>
      <em>Всё по кругу</em>
      Уносим
      Приносим
      Хотим
      Не желаем
      Даём и берём
      Зовём
      Отвергаем
      Торопим и ждём
      <em>И снова</em>
      <em>И снова</em>
      <em>Вращаем мы стрелки</em>
      <em>Заводим пружины</em>
      <em>Толкаем и тащим</em>
      <em>Затем забываем</em>
      <em>Потом вспоминаем</em>
      <strong><em>Что было</em></strong>
      <strong><em>Что есть и что будет</em></strong>
      <strong><em>Не знаем</em></strong>
    `
  },
  {
    id:'208',
    name: 'Смешны мы восприятием Природы',
    text: `
      <strong>С</strong>мешны мы восприятием Природы
      Мы к ней относим дождь траву и лес
      Капризы переменчивой погоды
      А нужно было бы к ней отнести и годы
      <em>Живём в которых формой <strong>Человек</strong></em>
      Где <strong>Человек</strong>
      Всего лишь вид Природы
      Короткий вида срок
      А дальше
      Переход
      Причина в том<em> что жизнь границ не знает</em>
      Причина в том
      <em>Что Замысел Высок</em>
      &nbsp;
      Мы продолжаем видоизменяться
      Не прерывая жизненных дорог
      Мы продолжаем перевоплощаться
      <em>Причина в том</em>
      <em>Что Замысел Высок</em>
    `
  },
  {
    id:'209',
    name: 'Вселикостью эфирных проявлений',
    text: `
      <strong>В</strong>селикостью эфирных проявлений
      <em>Чьи образы сливаются в один</em>
      Звучащей зыбью
      Рождеством явлений
      <em>Простор Вселенский Вечен и Един</em>
      Наполненный событиями&nbsp; жизни
      Дарящий нежность Светлой Чистоты
      Откроется тебе <em>как</em> <em>Дом Единый</em>
      Во всей красе
      <em>После того как ты</em>
      Пройдя этап Земного воплощенья
      <em>Тем сроком что тебе определён</em>
      Естественностью перевоплощенья продолжишь путь
      Которым наделён
    `
  },
  {
    id:'210',
    name: 'Изнанкой тьмы себя рождает Свет',
    text: `
      <strong>И</strong>знанкой тьмы себя рождает Свет
      Рождает силой мысли в слове
      Формированьем чистой н<strong>о</strong>ви
      Творя среди ноч<strong>и</strong> рассвет
      Напряжено пространство
      Рвётся цвет
      Клокочут Мирозданные Просторы
      Энергий мощью выводя узоры
      В переплетеньях множества небес
      Творящий Свет собой раздвинул тьму
      Своею сутью изменив сред<strong>у</strong>
      Образовав
      Затем стерев граничья
      Полутонам вверяя степени различья
      И в цветопереходах состояний
      Скрыв тайну смысла сотворённых Знаний
      Жизнь пропитав звучанием и цветом
      Объединив слова и мысль при этом
      Сплетает в косу Коды Откровений
      Вселенской Правды
      Вечностью Творений
    `
  },
  {
    id:'211',
    name: 'Изнанка света - это тьма',
    text: `
      <strong>И</strong>знанка <strong>света - э</strong>то <strong>тьма</strong>
      А что изнанка <strong>бытия</strong>
      &nbsp;
      По логике - <strong>н<em>ебытие</em></strong>
      <em>Да только как понять сие</em>
      &nbsp;
      Определяет бытие к<em>ак говорят</em> <strong>Сознанье</strong>
      <strong>Сознанье </strong>в<em> очередь свою</em> даёт <strong>осознаванье</strong>
      <strong>Осознавание себя</strong> есть <strong><em>самоосознанье</em></strong>
      <em>Но почему тогда живём отсутствием <strong>вниманья</strong></em>
      <strong>Вниманья</strong> к самому себе
      Что через <strong>познаванье </strong>понятье открывает нам
      <em>В чём Суть <strong>самопознанья</strong></em>
      &nbsp;
      <em>&nbsp;</em>
      <em>&nbsp;</em>
      <em>И смысл жизни углядев в развёрнутой картине дней</em>
      <em>Я вывод делаю такой о роли их в судьбе людей</em>
      &nbsp;
      <strong>Не познаванием себя в хитросплетениях Миров</strong>
      <strong>Мы жизнь живём в небытие</strong>
      <strong>И сетуем что Мир наш плох</strong>
      &nbsp;
      &nbsp;
      <em>Продолжив построенье строк </em>
      <em>Поймешь в чём состоит Урок</em>
      <em>Поняв </em>
      <em>Откроешь для себя понятие небытия</em>
    `
  },
  {
    id:'212',
    name: 'Неосознанность событий',
    text: `
      <strong>Н</strong>еосознанность событий
      Во вчера ушедших дней
      Не раскроет нам причины не свершения идей
      &nbsp;
      Стереотипностью мышленья нам не увязать того
      Что давно уже готово
      Сформировано давно
      &nbsp;
      Сформировано <strong>не нами</strong>
      Сформировано <strong>для нас</strong>
      Нам осталось только вникнуть ч<em>тобы мысль вселилась в нас</em>
      &nbsp;
      И тогда как на ладони
      В складках жизненных путей
      Прорисуются маршруты исполнения идей
      &nbsp;
      Всё о чём Душа томилась
      Беспокоилась о чём
      Моментально прояснится озарившим ум лучом
    `
  },
  {
    id:'213',
    name: 'Когда мы спим',
    text: `
      <strong>К</strong>огда мы спим
      <em>Не дремлют небеса</em>
      Когда мы молимся
      <em>Они тому внимают</em>
      Когда мы думаем
      <em>Они нам помогают</em>
      О чём мечтаем мы
      <strong><em>То </em></strong>
      <em>Небеса творят</em>
      &nbsp;
      Кто может слышать
      <em>Тот услышит их</em>
      Кто может видеть
      <em>Тем они покажут</em>
      Желающему знать
      <em>Они расскажут</em>
      Кто горделив без меры
      <em>Тех накажут</em>
      Себя понять стремящимся
      <em>Подскажут </em>
      И знать дадут
      <strong>Что<em> помыслы рожденные Душой</em></strong>
      <strong><em>Суть проявляют</em></strong>
      <strong><em>Быть дают собой</em></strong>
    `
  },
  {
    id:'214',
    name: 'Грани форм растворяя',
    text: `
      <strong>Г</strong>рани форм растворяя
      Убирая границы привычного
      Раздвигая Сознанье за пределы себя
      Ты поймешь <em>что есть <strong>Ты</strong></em>
      Это <strong>то</strong> непривычное
      Что являет собой <em>пустота</em>
      <em>Пустота</em>
      В восприятии формы отсутствия
      <em>Пустота</em>
      В невозможности видеть себя
      <em>Пустота</em>
      Это <strong>я</strong>
      В виде явного сущего
      <em>Пустота</em>
      Это <strong>ты</strong>
      В воплощенье меня
      &nbsp;
      Пустоту заполняя собой
      Растворяясь
      Заполняя себя пустотой
      Ты являешь собой
      Только то что осталось
      От меня
      Вновь воскресшим тобой
    `
  },
  {
    id:'215 ',
    name: 'Подумайте о том',
    text: `
      <strong>П</strong>одумайте о&nbsp; том
      <em>Что есть собою Время</em>
      <strong>Не то</strong> что отмеряет день и ночь
      <strong>Не то</strong> которое нас предает забвенью
      <strong>А то </strong>
      <strong>Которое для нас рождает Новь</strong>
      Рождает новь начальности этапов
      В цепи бессмертий многомерий Света
      Где Высших Знаний кружева сплетает
      Всемерность Времени прозрачностью момента
      &nbsp;
      И тем кто распознал <strong>Суть Смысла Времени</strong>
      Тем открывают <strong>Истинные Знанья</strong>
      А вместе с тем и <strong>коды кармы Вечности</strong>
      Даря способность их осознаванья 
    `
  },
  {
    id:'216',
    name: 'Волной небрежной набегают чувства',
    text: `
      <strong>В</strong>олной небрежной набегают чувства
      На берег Вечности моей Души
      И нежный ветерок уносит вдаль безумства
      И шепчут небеса мелодией Любви
      &nbsp;
      Красиво в Мире чувств
      В нём дышишь полным вздохом
      Свобода в Мире том от суеты земной
      Но аккуратен будь в стремлении к свободе
      Она есть <strong><em>чистота</em></strong>
      <strong><em>Она наш общий дом</em></strong>
    `
  },
  {
    id:'217',
    name: 'Я жить хочу не глядя на часы',
    text: `
      <strong>Я </strong>жить хочу не глядя на часы
      Я жить хочу вне времени понятий
      Ведь наша жизнь
      Есть <em>Вечности весы</em>
      Качнулась чаша
      <em>Мы в огне проклятий</em>
      В другую сторону случился перевес
      <em>Живём Любви Высокой ощущеньем</em>
      Коль равновесие
      <em>Оно всему венец</em>
      То мы живём его благословеньем
      &nbsp;
      Я жить хочу не глядя на часы
      Я чище жить хочу чем жить умею
      В <em>Надежде</em> в <em>Вере</em> жить хочу в <em>Любви</em>
      &nbsp;
      Но время&nbsp; это видно не дошло
      <em>Я временем </em>
      <em>Тем что в пути</em>
      <em>&nbsp;Болею</em>
    `
  },
  {
    id:'218',
    name: 'Как только зло ни изгалялось',
    text: `
      <strong>К</strong>ак только <em>зло</em> ни изгалялось
      Куражилось и издевалось
      Над нежною Душой <em>добра</em>
      И дождалось
      Пришла пора
      <em>Добро</em> загнало в угол <em>зло</em>
      <em>Зло</em> бросилось просить прощенья
      <em>Добро</em> без всякого смущенья
      Убило <strong>то</strong> что было <em>зло</em>
      &nbsp;
      И вот теперь живет <em>добро</em>
      С сознаньем что убито <em>зло</em>
      &nbsp;
      <strong><em>Подумай </em></strong>
      <em>Прежде чем решить</em>
      <em>Над кем и как суды вершить</em>
    `
  },
  {
    id:'219',
    name: 'В событиях сегодняшних мгновений',
    text: `
      <strong>В</strong> событиях сегодняшних мгновений
      <em>Что мы&nbsp; привыкли жизнью называть</em>
      Фрагментами прозрачных сновидений
      Ты жизни Суть пытаешься понять
      &nbsp;
      Сопоставляя проявленья Сути
      С теорией <em>придуманной тобой</em>
      Ты мнишь себя <em>величием могучим</em>
      Причину видя в том <em>что ты живой</em>
      И пробуждая снов воспоминанья
      Чтоб осмысленьем тлеющих мгновений
      Восстановить прошедших жизней рой
      Чтобы понять сколь страшно <strong><em>то </em></strong><em>что было</em>
      Насколько страшно <em>что произойдёт</em>
      <strong><em>В основе страха наша память скрыта&nbsp; </em></strong>
      <em>Она тебя волнует и ведёт</em>
      Ведёт тебя по хоженым дорожкам
      С надеждой <strong>той</strong>
      <em>Что прежний путь узнав</em> ты победишь себя
      <em>Хоть это сложно</em>
      Ты усмиришь
      Ты укротишь свой нрав
      И<strong> то </strong>поймёшь
      <em>Что в бесконечности Вселенской </em>
      <strong><em>Буквально ВСЁ</em></strong><em> <strong>имеет право</strong> <strong>быть</strong></em>
      Иль не поймёшь
      <em>Но это не помеха </em>Ему тебя Любить
      Любить
      Любить
    `
  },
  {
    id:'220',
    name: 'Проблемы шумной чередой',
    text: `
      <strong>П</strong>роблемы шумной чередой
      Не престанут быть
      Их Суть природы неземной
      Осмыслив
      Оценить
      Ты сможешь
      Если ты готов
      <strong>К тому</strong>
      Чтобы себя
      Пред Правдой Мира <em>оголить </em>
      <em>Не загасив Огня</em>
      Огня
      Что чувства согревал
      В периоды потерь
      <em>Но коль тогда он не угас </em>
      <em>Так только не теперь</em>
      <em>&nbsp;</em>
      Ты стал мудрее чем тогда
      <em>В периоды потерь</em>
      А сколько было тех потерь
      Не вспомнить уж теперь
      &nbsp;
      <em>И в бесконечности начал</em>
      <em>Не знающих границ</em>
      <em>Ты <strong>партию</strong> с Судьбой играл</em>
      <em>Что получилась <strong>блиц</strong></em>
    `
  },
  {
    id:'221',
    name: 'Энергия Любви',
    text: `
      <strong>Э</strong>нергия Любви
      Пронзая Всё
      Собой напитывая лето
      Зажжёт костёр
      Как память о былом
      <em>Блуждающем в бессознанности где-то</em>
      И грузом неосознанности дней
      Тебе диктуя грустные мотивы
      В туманах спящей памяти твоей
      Откроет жизни прожит<strong>о</strong>й картины
      &nbsp;
      Картинам вскрытым не всегда ты рад
      <em>Печалят многие и Душу бередят</em>
      Но жизнь идёт
      И часто ниткой белой шьёт через край
      Пытаясь увязать
      Всё что любил с тем что не очень мило
      Направленно пуская время вспять
      &nbsp;
      И возвращеньем в пр<strong>о</strong>житые дали
      Ты исправляешь в прошлой жизни <strong>то</strong>
      Что завтра просыпаясь от печали расчистит небо
      <em>Что заволокло</em>
      &nbsp;
      И вспыхнет солнце над Душой природной
      В Душе твоей осмысленностью дней
      Ты расцветёшь понятием раздольным
      Переосмыслив суть былых проблем
    `
  },
  {
    id:'222',
    name: 'В этой жизни',
    text: `
      <strong>В</strong> этой жизни
      Всегда и во всём есть черта
      Та
      Которую переступаем
      <em>И живём за чертой</em>
      Как вернуться назад
      <em>К сожалению</em>
      Часто не знаем
      &nbsp;
      А затем
      Вновь черта
      Оттолкнись от неё
      Только <strong>так</strong> оттолкнись
      Чтоб вернуться на Свет
      Ты обманешь себя
      Если скажешь себе
      <em>Что ты слишком устал</em>
      <em>Оттолкнуться сил нет</em>
      &nbsp;
      Распрямись
      Для того хватит Духа в груди
      Обратись к небесам
      <strong>Будет помощь</strong>
      <em>Я знаю</em>
      Что ты медлишь
      Смелей
      Оттолкнись посильней
      <em>Я давно жду тебя</em>
      <em>Я тебя здесь встречаю</em>
    `
  },
  {
    id:'223',
    name: 'Мы думаем что думаем мозгами',
    text: `
      <strong>М</strong>ы думаем что думаем мозгами
      Закрытыми в коробке черепной
      <em>Но не совсем всё так</em>
      Вот ходим мы ногами
      И топчем <strong>ту </strong>что названа Землей
      <em>А мысли </em>
      <em>Это <strong>то</strong> что за пределом</em>
      <em>Пределом нашей плоти</em>
      <em>И увы</em>
      <em>Пока что очень слабо</em>
      <em>Неумело</em>
      <em>Мы постигаем Знания азы</em>
      Нам аккуратно открывают Знанья
      <strong><em>Те </em></strong><em>Знанья что живут в Веках</em>
      Которые чрез наше осознанье нам знать дают
      <em>Что всё земное <strong>Прах</strong></em>
      Что Высший Разум формируя форму
      Ту форму жизни что назвали <strong><em>Человек</em></strong>
      Определил и срок её развитья
      <em>А срок был назван <strong>Человечий Век</strong></em>
      И что за срок земного пребыванья
      Нам предстоит <strong>столь</strong> осознать себя
      Чтоб нам открыли Высшей Сферы Знанья
      Чем раздвигая степени Сознанья
      Смогли б понять мы смыслы бытия
      Суть бытия в различных формах жизни
      Где форма <em>Человек </em>всего лишь миг
      Великий Миг познания самих же
      Себя самих же в мерностях других
      &nbsp;
      Средь нас иные есть
      <em>И к счастью их не мало</em>
      Кто к банку информации допущен
      Кто знает код <em>Вселенского портала</em>
      Пред кем <em>заслонный занавес</em> приспущен
      &nbsp;
      Живут в различных уголках Планеты
      На островах живут и на материках
      Разноязычные
      Философы
      Поэты
      <em>Общаясь с Миром в прозе и стихах</em>
      &nbsp;
      <strong>Душой общаясь</strong>
      <strong>На языке Души</strong>
      <strong>В просторах неосознанных свобод</strong>
      <strong>Всемерность <em>директории</em> <em>Любви</em></strong>
      <strong>Творит земной и не земной Народ </strong>
    `
  },
  {
    id:'224',
    name: 'Технократичностью подходов',
    text: `
      <em>Не понимая Сути Духа</em>
      <em>Духовной чистоте не вняв</em>
      <em>Неистинным себя навьючил </em>
      <em>Нить этой жизни потеряв</em>
      <em>Забыв или не зная Сути </em>
      <em>Мироустройственных начал</em>
      <em>Ошибкой знания обученный </em>
      <em>Других ошибкой обучал</em>
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      <strong>Т</strong>ехнократичностью подходов
      К переустройству Миросфер
      Мы обрекаем всё живое
      На бездуховный <em>беспредел</em>
      &nbsp;
      Задумайтесь
      В каких Свободах
      В каких безмерностях Миров
      Кто тот
      Кто собственной природой
      Суть остальных убить готов
      &nbsp;
      Ответь себе
      В чём Смыслы жизней
      Ответь
      В какие времена
      Вселенский хор во имя Света
      Молитвы пел мотивом зла
      &nbsp;
      Прошу тебя
      Проснись и вспомни
      Духовности Природной высь
      И сбрось бездушные оковы
      Глаза открой и помолись
      &nbsp;
      <strong>Молись разбуженной Душою</strong>
      <strong>Взор обращая к Небесам</strong>
      <strong>Молитво-раннею зарею</strong>
      <strong>За Светлость Истинных Начал</strong>
    `
  },
  {
    id:'225',
    name: 'Смешная штука жизнь',
    text: `
      <strong>С</strong>мешная штука жизнь
      <em>То хмурится она то улыбается</em>
      <em>То укусить исподтишка пытается</em>
      &nbsp;
      Смешная штука жизнь
      <em>Такая разная</em>
      <em>То чистая она то грязная</em>
      <em>&nbsp;</em>
      Смешная штука жизнь
      <em>То водопад кипучий</em>
      <em>То плавная словно кисель тягучий</em>
      &nbsp;
      Смешная штука жизнь
      <em>То яркая как солнце</em>
      <em>То тусклая как мутное оконце</em>
      &nbsp;
      Смешная штука жизнь
      <em>Какой её воспринимаешь</em>
      <em>Такую для себя и получаешь</em>
      &nbsp;
      Смешная штука жизнь
      <em>Как в ней живешь</em>
      <em>Такою для себя и создаёшь</em>
    `
  },
  {
    id:'226',
    name: 'Капелью звонкой брызгают секунды',
    text: `
      <strong>К</strong>апелью звонкой брызгают <em>секунды</em>
      И дождиком грибным на землю сыплет <em>час</em>
      Объединив <strong>всё это</strong>
      Льются <em>сутки</em>
      Потоком ливневым обрушившись на нас
      Ручьём звенящим отмеряя <em>время </em>
      Несут себя к реке
      Где что волна - то <em>год</em>
      К реке
      Что в соответствии с теченьем
      Бежит <strong>туда </strong>где <em>море жизни </em>ждёт
      А море
      Наделив десятилетья глубинной тайной
      Скрытою от глаз
      Впадает в <em>океан</em> <em>прозрений</em>
      Откуда Вечность вечностных мгновений
      <em>Секундой звонкой</em> брызгается в нас
    `
  },
  {
    id:'227',
    name: 'В любых приключеньях',
    text: `
      <strong>В </strong>любых приключеньях
      Всегда и везде
      Ты будешь лишь <strong>тем</strong> что собою являешь
      И <strong>то</strong> что являешь несёшь словно крест
      <em>И это не бросишь и не потеряешь</em>
      <em>&nbsp;</em>
      Ты твёрд
      Непреклонен
      И это есть <strong>ты</strong>
      <em>Рожденный под Солнцем Планеты Земли</em>
      <em>&nbsp;</em>
      Ты <em>мягок</em> в решении многих задач
      И в то ж время <em>тверд</em> на краю неудач
      И вновь это <strong>ты</strong>
      Ты такой какой есть
      <em>Тебе не нужна в утешение лесть</em>
      <em>&nbsp;</em>
      Ты сам для себя свой придумал закон
      Духовность и Честь ты поставил на кон
      И всё проиграл
      Главный проигрыш твой <strong>в том</strong>
      <em>Что ты не осмыслил зачем был рождён</em>
      &nbsp;
      А ты совместил в себе разные свойства
      Ты в жизни своей не лишён беспокойства
      Ты можешь быть <em>твёрдым</em>
      Ты можешь быть <em>мягким</em>
      <em>Душевным</em> и <em>злобным</em>
      <em>Бесцветным</em> и <em>ярким</em>
      Ты можешь быть <em>разным</em>
      Ведь ты есть <strong>такой</strong>
      <em>Каким был рождён под <strong>своею</strong> звездой</em>
      <em>&nbsp;</em>
      <em>Ты есть не другой</em>
      <em>Ты собою являешь <strong>себя самого</strong></em>
      <strong><em>Хоть себя и не знаешь</em></strong>
    `
  },
  {
    id:'228',
    name: 'Беспардонностью раздвинув время',
    text: `
      <strong>Б</strong>еспардонностью раздвинув время
      Ворвалось вчерашнее<em> в сейчас</em>
      Нерешённое в прошедшем
      <em>Бременем</em>
      Совершает сокрушительный вояж
      &nbsp;
      Словно клин вбивает в промежуток
      Между <em>прож<strong>и</strong>тым</em> и тем что <em>не дошло</em>
      И ломаются истерзанные сутки
      Рвутся между <em>будет</em> и <em>прошло</em>
      Кровоточа тем что было прежде
      Стонет плоть сегодняшнего дня
      <em>Проявлением допущенных ошибок</em>
      <em>Жёстко учит время</em>
      <em>Жить меня</em>
      &nbsp;
      Под напором объективной Сути
      Рушит всё&nbsp; что прежде я воздвиг
      Смыслами сменяющихся б<strong>у</strong>ден
      Иллюстрирует всё <strong>то</strong> что не постиг
      &nbsp;
      И на месте сломанного дома
      Строит время&nbsp; Храм моей Души
      На фундаменте Вселенского Закона
      Заполняя его Вечностью Любви
      &nbsp;
      Храм высок и крепок в основании
      Он гостеприимен
      Он хорош
      Он дает мне чувствовать заранее
      <em>С чем в него войдешь ты</em>
      <em>Что возьмешь</em>
    `
  },
  {
    id:'229',
    name: 'А знаешь как тебя зовут',
    text: `
      <strong>А</strong> знаешь как тебя зовут
      <em>Как здесь зовут ты это знаешь</em>
      А что твоя скажи есть Суть
      <em>Что </em>
      <em>Даже не подозреваешь</em>
      А в скольких ты Мирах живёшь
      Да да живёшь одновременно
      <em>И это ты не знаешь</em>
      <em>Что ж</em>
      <em>Тогда не срок наверно</em>
      <em>&nbsp;</em>
      <em>Когда настанет срок узнать</em>
      <em>Как звать тебя в Всевысьях</em>
      <em>К тому моменту будешь&nbsp; знать</em>
      <strong><em>Что в помыслах и мыслях </em></strong>
      <strong><em>Сокрыта Суть твоей Души</em></strong>
      <strong><em>А в чувствах и мечтаньях</em></strong>
      <strong><em>Сокрыты завтрашние дни </em></strong>
      <strong><em>Дни в счастье иль в страданьях</em></strong>
    `
  },
  {
    id:'230',
    name: 'В азартную игру мы день и ночь играем',
    text: `
      <strong>В </strong>азартную игру мы день и ночь играем
      Стремимся <em>банк сорвать</em>
      Но мы не понимаем
      <em>Что победить нельзя</em>
      <strong>ЕГО</strong><em> не зная</em> <em>правил</em>
      К тому ж <strong>ОН</strong> раздавал
      И <em>прикуп</em> не оставил
      &nbsp;
      Мы <em>профи</em> в <em>преферанс</em>
      <em>Любимая игра</em>
      А тот кто раздавал
      Раздал <em>под дурака</em>
      &nbsp;
      И чем закончится игра
      Совсем легко предугадать
      А что игра зовётся <strong><em>Жизнь </em></strong>
      Нам раньше нужно было б знать
    `
  },
  {
    id:'231',
    name: 'Схватив желанье как таран',
    text: `
      <strong>С</strong>хватив желанье как таран
      Мы рвёмся к мнимому спасенью
      Пренебрегая опасеньем
      Мы лезем к цели напролом
      Таран ломая лом берём
      Крушим всё то что нам мешает
      Корёжим всё что на пути
      И в этой битве забываем
      Куда пытаемся пройти
      Объяты огненною страстью
      В стремленьи результат достичь
      Мы даже не предполагаем
      О том
      Что мы могли б постичь
      Постичь
      Осознанность безумства
      Осознаванием того
      <em>Что сами же уничтожаем</em>
      <em>Всё <strong>то</strong></em>
      <em>Что нам Судьбой дано</em>
    `
  },
  {
    id:'232',
    name: 'Волнуется Душа',
    text: `
      <strong><em>В</em></strong><em>олнуется Душа</em>
      Знакомо это чувство
      Причин для этого не сосчитать
      <em>А в прочем не спеши</em>
      Причин не так уж много
      Другое дело их распознавать
      &nbsp;
      Вот в трепете она
      Крыл<strong>а</strong>ми птицы бьётся
      <em>В предверьи исполнения мечты</em>
      А вот дрожит Душа
      <em>Что если вдруг сорвётся</em>
      <em>Всё то о чём мечтал</em>
      <em>Развеется в ночи</em>
      &nbsp;
      Не торопясь подумай
      В чём причина
      Волнений
      Заставляющих тебя
      Забыть <strong>о том</strong>
      <em>Что следствием причины</em>
      <em>Трепещет иль дрожит твоя Душа</em>
      &nbsp;
      Бывает
      Неосознанность волненья
      В тебе проявится удушливой тоской
      Или бывает
      Светом вдохновенья
      Она нарушит твой земной покой
      &nbsp;
      Причинности непонятые нами
      Нам не дают возможность осознать
      Духовный ритм своей Душ<strong>и</strong> дыханья
      <em>А зачастую </em>
      <em>Просто нет желанья</em>
      <strong>Своей Души Природу опознать</strong>
    `
  },
  {
    id:'233',
    name: 'Свободой выбора ты связан по рукам',
    text: `
      <strong>С</strong>вободой выбора ты связан по рукам
      Свободой выбора стреножен словно конь
      Свободой выбора ты обморочно пьян
      Свободой выбора ты ослеплён
      &nbsp;
      Свобода выбора длиною с поводок
      Свобода выбора в пределах поводка
      Ты снова чертишь своей жизни круг
      Внутри которого свободен ты пока
      &nbsp;
      <em>Остановись</em>
      <em>Прозрей палитрой чувств</em>
      <em>Услышь всезвучье Мировых хоров </em>
      <em>И ты пройдёшь Уроком данный Путь</em>
      <em>Познаньем Истин неземных Домов</em>
    `
  },
  {
    id:'234',
    name: 'Слова рождённые умом',
    text: `
      <strong><em>С</em></strong><em>лова рождённые <strong>умом</strong> </em>
      <em>Проявят знанья <strong>о былом</strong></em>
      &nbsp;
      Слова рождённые Душой
      Напитанные Смыслом Чувств
      Проявят истинность свою
      <em>Слова Души Душой пою</em>
      Пою под музыку Небес
      В себя вобравшую Любовь
      Пою Вселенский я мотив
      Под оркестровый перелив
      Литавры восторгают слух
      <em>Хрустальный звездный перезвон</em>
      Валторн небесных слышу я
      <em>Глубинный нежный баритон</em>
      Наполненная пустота
      Звучаньем истинных хоров
      Рождает новые слова
      <em>Слова творящие Любовь</em>
      <em>&nbsp;</em>
      Свою бескрайность осознав
      И Всё вместив в себе одном
      Себя во Всём я растворял
      Волнующим Сознанье сном
      Сном наяву <em>где разум спит</em>
      <em>Дремотным связанный умом</em>
      Что позволяет расцвести
      Душе раскрывшейся цветком
      &nbsp;
      Быть может это сон во сне
      Души потребность проявил
      Проснувшись
      Я хотел того
      <em>Хотел что было только сил</em>
      Что б этот сон был наяву
      Душой чтоб я творил слова
      Слова
      Которые тебе откроют Сути Существа
      Душою чтоб плелись слова
      И чтоб в словах была Душа
      Чтобы Сакральный Смысл в словах
      Духовность чувств творил в Мирах
    `
  },
  {
    id:'235',
    name: 'Вполне случиться может так',
    text: `
      <strong><em>В</em></strong><em>полне случиться может так</em>
      <em>Что нет желанья осознать</em>
      <em>Развёрнутые &nbsp;Смыслы &nbsp;Смыслов слов</em>
      <em>Прочитанных тобой стихов</em>
      &nbsp;
      Что ж
      Есть состоянье полуснов
      Для осмысления основ
      &nbsp;
      Медитативность нам даёт
      Понятья Сути многих слов
      <em>&nbsp;</em>
      <em>Разлейся</em>
      <em>Раствори себя </em>
      <em>В пространствах солнечных Миров</em>
      Тогда осознано&nbsp; поймёшь
      <strong>Всё то</strong> что в книге ты прочтёшь
      Тогда увидишь чистый Свет
      Небесных вечных городов
      В которых ты живёшь Душой
      В которых бархатный покой
      Где ярче миллионов звёзд
      Горит Души твоей Свеча
      <em>Свеча зажженная Творцом</em>
      <em>Являет истинность тебя</em>
      <em>&nbsp;</em>
      <strong><em>Чтоб не сбивались мы с пути</em></strong>
      <strong><em>Зажгли Вселенскую Свечу</em></strong>
      <em>Душой я вижу ту Свечу</em>
      <em>На Свет которой я лечу</em>
    `
  },
  {
    id:'236',
    name: 'Ах если б было больше букв',
    text: `
      <strong>А</strong>х если б было больше букв
      Я сплёл бы новые слова
      Таких
      Чтоб<em> захватило дух</em>
      Чтоб чувства хлынули в слова
      Слова вместили бы в себя
      Вкус цвета запаха мечты
      Слова открыли б для тебя
      Бескрайность Вечной красоты
      Ты б ощутил слова на вкус
      Ты осязал бы те слова
      И те слова ласкали б слух
      И Душу нежили б слова
      Слова открыли б горизонт
      Убрали б степени границ
      Ты б вместе с ними мог летать
      <em>Не стаей перелётных птиц</em>
      Летать
      Взлетая над собой
      Молитвой слова возносясь
      Ах если б было больше букв
      Я смог тогда бы рассказать
      <em>Как ветром солнечных костров</em>
      <em>Пронзая времени простор</em>
      <em>Симфонии иных Миров </em>
      <em>Вплетались в звёздный разговор</em>
      <em>Как переполнена Душа </em>
      <em>Любовью ко всему что здесь</em>
      <em>Как Вечны Сути Существа</em>
      <em>Как счастлив я </em>
      <strong><em>Тем</em></strong>
      <em>Что&nbsp;&nbsp; </em>
      <strong><em>Я &nbsp;ЕСТЬ</em></strong>
    `
  },
  {
    id:'237',
    name: 'Волнением наполненная грудь',
    text: `
      <strong>В</strong>олнением наполненная грудь
      И в жгут колючий скрученные нервы
      Предчувствием пылающим в крови
      Ведут меня в последующие дни
      &nbsp;
      Адреналиновой энергии вкусив
      Себя своей Судьбе я доверяю
      И страха нет
      <em>Хотя что впереди не вижу я</em>
      <em>Что ждёт меня&nbsp; </em>
      <em>Не знаю</em>
      &nbsp;
      Всецело я себя реке отдал
      <em>И понесла бурлящею волною</em>
      Я&nbsp; плыл <strong>туда </strong>&nbsp;
      Куда она вела
      При этом подгребал одной рукою
      Когда познать нрав речки удалось
      Когда прочувствовал что значит перекаты
      Я подгребал уже второй рукой
      И плыл совсем не так
      Как плыл когда-то
      &nbsp;
      Я научился глубоко нырять
      Я научился ловко плавать <em>б<strong>о</strong>ком</em>
      И лишь в последствии я осознал
      <strong><em>Река Судьбы </em></strong>
      <strong><em>Была дана мне Богом</em></strong>
      <em>Что за пределы речки не уплыть</em>
      И чтоб сберечь себя от водопадов
      <em>Стоять на месте</em>
      <em>Неподвижным нужно быть</em>
      <em>И ждать </em>
      <em>Когда уляжется волна</em>
      <em>Когда наступят дни что будут ярки</em>
      Но мне такая участь не нужна
      И я плыву
      Дни путешествий жарки
      Ведь я плыву <em>Рекой Своей Судьбы</em>
      Плыву легко и <em>баттерфляй </em>и <em>боком</em>
      И разница лишь в том
      Что знаю я
      <strong>То </strong>
      <strong><em>Что плыву вперед </em></strong>
      <strong><em>Ведомый Богом</em></strong>
    `
  },
  {
    id:'238',
    name: 'Всю жизнь живём мы ожиданьем чуда',
    text: `
      <strong>В</strong>сю жизнь живём мы ожиданьем чуда
      <em>Когда оно придёт к нам и откуда</em>
      Мы ищем всюду признаки его
      <em>А у самих в Душе темным-темно</em>
      &nbsp;
      Нам светят звёзды
      <em>Этого не видим</em>
      Инопланетных
      <em>Ненавидим</em>
      Весна меняет зиму
      <em>Ну и что</em>
      <em>Таких чудес вокруг полным-полно</em>
      <em>&nbsp;</em>
      <strong>Откройте Души</strong>
      <strong>И прочтите в осеннем шелесте листвы</strong>
      <strong>Понятье <em>Смысла срока жизни</em></strong>
      <strong>И Суть понятья - <em>Живы мы</em></strong>
      &nbsp;
      <strong>Мы рождены <em>небес твореньем</em></strong>
      <strong>С задачей осознать себя </strong>
      <strong><em>Как Искру Вечности и Света</em></strong>
      <strong><em>Как Сотворцов &nbsp;рожденья дня</em></strong>
      <strong><em>&nbsp;</em></strong>
      <em>Нелёгок путь за горизонты</em>
      <em>Глубинных нравственных высот</em>
      К раскрытью неземных секретов
      Которыми творят Любовь
      К взаимосвязей осознанью
      К безбрежности своей Души
      К прозренью
      К радости
      К страданьям
      К понятию
      <em>Что <strong>живы мы</strong></em>
    `
  },
  {
    id:'239',
    name: 'Мозаикой пестреющих событий',
    text: `
      <strong>М</strong>озаикой пестреющих событий
      Заполнено пространство бытия
      И множеством привязанных к ним нитей
      Пытаюсь изменить картину дня
      Душой закрытой от понятий Сути
      Не в силах осознать причинность дней
      Я ворошу колодой календарной
      Последствия несбывшихся идей
      <em>Но дай мне Бог</em>
      Чтобы пришло мгновенье
      Мгновенье
      Осмысления того
      Что мне мешает обрести спасенье
      Спасенье
      От себя же самого
    `
  },
  {
    id:'240',
    name: 'Разорван человек на мелкие кусочки',
    text: `
      <strong>Р</strong>азорван человек на мелкие кусочки
      <strong><em>Разорван восприятием себя</em></strong>
      Вот сердце
      Вот печёнка
      Селезёнка
      Вот голова
      Рука
      Нога
      <em>&nbsp;</em>
      <em>А вот болезни стройными рядами</em>
      <em>Штурмуют плоть страданья привнося</em>
      <em>&nbsp;</em>
      И мучаемся мы непониманьем
      Того
      <em>Что нам болеть </em>
      <em>Покуда спит Душа</em>
    `
  },
  {
    id:'241',
    name: 'Пустоты в Душах заполняя бренным',
    text: `
      <strong>П</strong>устоты в Душах заполняя бренным
      Мы думать начинаем лишь о тленном
      Тем в драгоценный гроб мы Души запираем
      <em>Цены поступка мы не понимаем</em>
      Живём как будто дальше
      И не знаем
      <em>Что в тот же миг</em>
      <em>Навеки умираем</em>
    `
  },
  {
    id:'242',
    name: 'Клубятся мысли сигаретным дымом',
    text: `
      <strong>К</strong>лубятся мысли сигаретным дымом
      Бегут по кругу зыбкого кольца
      Иль зависают облаком пушистым
      И быстро тают&nbsp;
      <em>Жизнь их коротка</em>
      <em>&nbsp;</em>
      <em>Так многие считают</em>
      Я же знаю
      Что мысль не гибнет
      Продолжает жить
      Дым исчезая мысль освобождает
      Свободой от себя возможность дав творить
      &nbsp;
      <strong>Вот так и мы</strong>
      <strong>Носителем&nbsp; являясь Бессмертных Душ</strong>
      <strong>Тех </strong>
      <strong>Что вселили в нас</strong>
      <strong>В Мир уходя иной тем их освобождаем</strong>
      <strong><em>Ах сколько их сейчас глядит с высот на нас</em></strong>
    `
  },
  {
    id:'243',
    name: 'Лучом тончайшим ухожу в простор',
    text: `
      <strong>Л</strong>учом тончайшим ухожу в простор
      В просторе я свободен от проблем
      В просторе я живу
      Простор мой дом
      В просторе я Люблю
      Люблю вас всех
      В просторе жизнь не ведает границ
      Простор безмерен
      <em>Как безмерна Вечность</em>
      Простор
      В понятьи времени
      <em>Беспечность</em>
      И я беспечен
      Я являюсь им
    `
  },
  {
    id:'244',
    name: 'Мазками дней пишу портрет Судьбы',
    text: `
      <strong>М</strong>азками дней пишу портрет Судьбы
      <em>Не счесть тонов в палитре моей жизни</em>
      Я ими дни пишу которые прошли
      И <strong>те </strong>которые покуда не дошли
      Пишу сегодняшними днями
      <em>Цветом жизни</em>
      <em>&nbsp;</em>
      Мне хватит красок написать его
      <em>Их будет ровно столько</em>
      <em>И не меньше</em>
      Чтоб расписал я ими Белый Свет
      Да
      Не имеет рамок сей портрет
      <strong><em>Он безграничен</em></strong>
      <strong><em>Как бескрайни мысли</em></strong>
    `
  },
  {
    id:'245',
    name: 'Всё в этой жизни цвет имеет свой',
    text: `
      <strong><em>В</em></strong><em>сё в этой жизни цвет имеет свой</em>
      Вчера со мной Вы были
      Сегодня не со мной
      Сегодня Вы смеётесь
      А завтра загрустите
      То что вчера прощали
      То завтра не простите
      Что волновало прежде
      Сегодня не волнует
      То что казалось ясным
      Сегодня интригует
      &nbsp;
      Изменчива погода
      Изменчивы Вы сами
      Вы сами жизнь покрасили
      Как зебру
      Полосами
      &nbsp;
      А буйство красок дивное
      <em>Всецветьем над Землёй</em>
      <em>Вы это всё увидели б</em>
      <em>Коль были б Вы </em>
      <em>Со мной</em>
    `
  },
  {
    id:'246',
    name: 'Многоликость проявлений',
    text: `
      <strong>М</strong>ноголикость проявлений
      Открывает для тебя
      Состояньем настроений
      Свойства нынешнего дня
      &nbsp;
      Что вчера тебя пугало
      <strong>То </strong>сегодня веселит
      Чем сегодня наслаждался
      <strong>Завтра </strong>Душу бередит
      &nbsp;
      <em>&nbsp;</em>
      <em>Состоянья настроений</em>
      <em>Нам даются чтоб понять</em>
      <strong><em>Что причинами событий</em></strong>
      <strong><em>Очень сложно управлять</em></strong>
      &nbsp;
      <em>Очень сложно</em>
      <em>Но возможно</em>
      <em>Управлять причиной <strong>той</strong></em>
      <em>Что как <strong>следствие</strong> <strong>причины </strong></em>
      <em>Была принята тобой</em>
    `
  },
  {
    id:'247',
    name: 'Будь осторожен в просьбах к небу',
    text: `
      <strong>Б</strong>удь осторожен в просьбах к небу
      Оно исполнить может <strong>то</strong> о чём просил
      Но ты при этом
      <em>Быть такое может</em>
      Утратишь <strong>то</strong> что накопил
      &nbsp;
      Коль просишь ты<em> Свободы Духа</em>
      То потерять рискуешь ты
      Тех кто шептал тебе на ухо
      <em>Что должен и не должен ты</em>
      Потеря эта не трагична
      Она дает возможность знать
      Знать
      <em>Что тебе дана возможность </em>
      <em>Свои дороги выбирать</em>
      <em>&nbsp;</em>
      Коль просишь ты О<em>сознаванья</em>
      Осознаванья своих дней
      <em>Тем приближаешь расставанье </em>
      <em>С наивной глупостью своей</em>
      <em>&nbsp;</em>
      Коль просишь ты <em>благополучья</em>
      В виду имея драгметалл
      <em>Прими заранее сочувствие</em>
      Таких как ты
      Я повидал
      &nbsp;
      Бывает
      <em>Правда крайне редко</em>
      Когда сливаются в одно
      <em>Материальное</em> с <em>Духовным</em>
      <em>&nbsp;</em>
      <em>Не всем их совместить дано</em>
    `
  },
  {
    id:'248',
    name: 'Когда ты просишь помощи у сильных',
    text: `
      <strong>К</strong>огда ты просишь помощи у сильных
      Как минимум ты должен знать одно
      <em>Что собственное обуздать бессилье</em>
      <em>Тебе и дальше будет не дано</em>
      &nbsp;
      Когда ты просишь помощи у Мудрых
      На этот счёт спокоен можешь быть
      <em>Они дадут тебе возможностью&nbsp; прозренья</em>
      <em>Себя понять и слабость победить</em>
      &nbsp;
      Когда настанет миг и помощь ты отвергнешь
      Себя считая мудрым силачом
      Ты также должен знать
      <em>Что ты утратил силу</em>
      <em>Пред Высшей Силой</em>
      <em>Сыном и Отцом</em>
      &nbsp;
      <strong><em>Ты был сильнее в их глазах</em></strong>
      <strong><em>Когда о помощи просил</em></strong>
      <strong><em>Чем обессиленный сейчас</em></strong>
      <strong><em>Когда гордыню возродил</em></strong>
    `
  },
  {
    id:'249',
    name: 'Боязнь',
    text: `
      <strong><em>Б</em></strong><em>оязнь&nbsp; </em>
      Есть свойство нежеланья
      Расстаться с тем к чему привык
      С чем научился уживаться
      В чём можно сильно не стараться
      <em>И без того промчится миг</em>
      <em>&nbsp;</em>
      Себя заставить сделать шаг
      Навстречу новым пониманьям
      Возможно
      В случае того
      <em>Что есть потребность в осознаньи </em>
      <em>Непониманья своего</em>
      Когда Душа твоя готова открыть иные рубежи
      Осознаваньем прежней &nbsp;лжи
      Себя наполнив смыслом новым
      Ты должен быть к тому готовым
      <em>Что много из того что знал</em>
      <em>Что многое о чём мечтал</em>
      <em>Тебе покажется ненужным</em>
      <em>Нелепым мелким и бездушным</em>
      &nbsp;
      Не испугался
      <em>Делай шаг </em>
      Оставив за спиною мрак
      Раскрой объятия Души
      Ты столько лет провёл в глуши
      <em>В глуши боязней и тревог</em>
      Но вот настал решенья срок
      <em>Решенья срок </em>
      <em>Приблизил Бог</em>
      <em>За <strong>то</strong> что ты познал Урок</em>
    `
  },
  {
    id:'250',
    name: 'Беглый ветер студит небо',
    text: `
      <strong>Б</strong>еглый ветер студит небо
      В перья рвутся облака
      Отраженьем сей картины переполнена река
      Небу в речке места мало
      И затейница волна
      Что собою отражала <strong>то</strong> бросала в берега
      Расшвыряла
      <em>Как попало</em>
      Пенность рваных облаков
      А потом она устала
      Иль быть может осознала
      Так как в этот день познала
      <em>Свойства разности Миров</em>
      &nbsp;
      <em>Осознала невозможность </em>
      <em>Изменять событья <strong>те</strong></em>
      <em>Что всего лишь отраженьем проявляются в воде</em>
    `
  },
  {
    id:'251',
    name: 'Широко раскрытыми глазами',
    text: `
      <strong>Ш</strong>ироко раскрытыми глазами
      Смотришь ты на мир вокруг себя
      Видишь горы
      Д<strong>о</strong>лы
      Перелески
      Видишь реки
      Степи и моря
      <em>Счастье наполняет твою Душу</em>
      <em>Так</em>
      <em>Что может литься через край</em>
      <em>Ты живёшь</em>
      <em>Ты переполнен жизнью</em>
      <em>Для себя открыв понятье Рай</em>
      &nbsp;
      Ты другой
      Ты видишь много больше
      Наряду со всем что видит он
      Ты узрел на веточке сирени
      Жизнью распустившийся бутон
      Ты увидел как рисует небо
      Ты услышал как поёт ручей
      Как живёт трудов не оставляя
      Крохотный но сильный муравей
      <em>Счастье наполняет твою Душу</em>
      <em>Так</em>
      <em>Что может литься через край</em>
      <em>Ты живёшь</em>
      <em>Ты переполнен жизнью</em>
      <em>Для себя открыв понятье Рай</em>
      &nbsp;
      И ты другой
      И ты сумел увидеть
      То
      Что видят многие
      Но ты
      Ты узрел понятия иные
      Ты не испугался высоты
      Высоты глубинных пониманий
      Что открылись зрением Души
      Хрупкою хранительницей знаний
      Вечных многомерных обитаний
      Давшей тем понять тебе <strong>кто ты </strong>
      <em>Ты живёшь спокойною Душою</em>
      <em>Зная что живёшь <strong>здесь</strong> и <strong>сейчас</strong></em>
      <em>И ещё</em>
      <em>Что ты живёшь собою</em>
      <em>Там живёшь </em>
      <em>Где им не видно нас&nbsp; </em>
    `
  },
  {
    id:'252',
    name: 'Духовным зрением приняв значенье данности',
    text: `
      <strong>Д</strong>уховным зрением приняв значенье данности
      Осознавая что ты есть <em>здесь</em> и <em>сейчас</em>
      Припомнив Суть тобой забытой давности
      Ты оживаешь
      Словно в первый раз
      И в осознаньи Мудрости раскаянья
      Всемерною бескрайностью Души
      В себя вбираешь ты сей жизни радости
      Бросая в речку камни-голыши
      &nbsp;
      Круги от камня тобой в речку брошенного
      Волнуют гладь и бередят покой
      Вот так и ты
      Уйдёшь тропою хоженой
      Оставив круг на глади неземной
      &nbsp;
      Не прекратив своих существований
      Сокрытых кругом от миллионов глаз
      Ты обретаешь свойства новой данности
      Ты снова оживаешь
      В новый раз
      И забывая боль Души истерзанной
      В переоценке прожитых измен
      Ликуешь&nbsp; результатом &nbsp;очарованный
      Свершившихся с тобою перемен
    `
  },
  {
    id:'253',
    name: 'От рассвета до заката',
    text: `
      <strong>О</strong>т рассвета до заката
      Жизнь событьями богата
      От заката до рассвета
      Бродим в поисках ответа
      Бродим в мыслях
      Бродим в чувствах
      Бродим память вороша
      Бродим там
      Куда уносит в нас вселённая Душа
      &nbsp;
      А затем опять рассвет
      А за ним опять закат
      Это будет продолжаться
      Сколько Бог даст лет подряд
      &nbsp;
      <em>А когда настанет срок </em>
      <em>Наших дней прервать поток</em>
      <em>Мы уйдём</em>
      <em>Оставив детям</em>
      <em>На Земле</em>
      <em>Любви росток</em>
    `
  },
  {
    id:'254',
    name: 'Как часто за бесценок продаём',
    text: `
      <strong>К</strong>ак часто за бесценок продаём
      Всё <strong>то</strong> чем прежде мы гордились
      Мы не иметь того стыдились
      <em>А вот теперь</em>
      <em>Распродаём</em>
      &nbsp;
      Продали Души
      Продавая Честь
      И доброту сменив на месть
      Продали честность
      Сохранив лукавство
      Похоронили такт и наплодили хамства
      &nbsp;
      <em>Продали лучшее что было</em>
      <em>Оставив <strong>то</strong> с чем жить не мило</em>
      &nbsp;
      Живём
      Погрязшие в грязи
      Не помня как мы жили прежде
      Не зная <strong>то </strong>что впереди
      Живём
      И я живу
      В надежде
      На <strong>то</strong>
      Что всё ж настанет час
      И возвратятся Души в нас
    `
  },
  {
    id:'255',
    name: 'Какой ты есть',
    text: `
      <strong>К</strong>акой ты есть
      В том есть твоя проблема
      Ты есть такой каким ты можешь быть
      Ты есть такой как есть в любое время года
      <em>Хотя&nbsp; вполне таким бы мог не быть</em>
      Но ты такой
      Я <em>в общем</em> без претензий
      Каким ты можешь так таким и будь
      Но о моих правах в принятии решений
      Прошу тебя
      Ты всё же не забудь
      Ведь я такой как есть
      И в том <strong>моя</strong> проблема
      Я есть такой каким могу я быть
      Я есть такой как есть в любое время года
      <em>Хотя вполне таким бы мог не быть</em>
      &nbsp;
      И если мы
      С взаимным уваженьем
      Попробуем друг друга не менять
      Вполне возможно
      Что придёт мгновенье
      Которое нам принесёт прозренье
      Прозренье <strong>в том</strong>
      Что нам позволит ближе стать
    `
  },
  {
    id:'256',
    name: 'Капают годы',
    text: `
      <strong>К</strong>апают годы
      Минуты текут
      Мчатся секунды потоком
      <em>Кто</em> ты и <em>что</em>
      Предстоит осознать
      <em>В Миг обозначенный Богом</em>
      &nbsp;
      Развитье твоё приближает <strong>тот</strong> <strong>Миг</strong>
      <em>Во вневременном пониманьи</em>
      Свойства Души приближают его
      Души
      Растворенной в Сознаньи
      &nbsp;
      <em>Духовность Сознанья</em>
      <em>Вплетая в себя сознательную Духовность</em>
      <em>По сути своей</em>
      <em>Отражают твою принятия Мига готовность</em>
    `
  },
  {
    id:'257',
    name: 'Китайская стена мне кажется игрушкой',
    text: `
      <strong><em>К</em></strong><em>итайская стена</em> мне кажется игрушкой
      В сравненьи со стеной построенною мной
      Я спрятался за ней от кровь студящей стужи
      Я спрятался за ней как в детстве за спиной
      Как за спиною тех
      <em>Кто был смелей и чище</em>
      Как за спиною тех
      <em>Кто создавал покой</em>
      Как за спиною тех
      <em>Кто не давал в обиду</em>
      Как за спиною тех
      <em>Кто позже предан мной</em>
      &nbsp;
      <em>И если ты в себе такого не отыщешь</em>
      <em>И если ты в себе такого не найдёшь</em>
      <em>То это значит <strong>что</strong></em>
      <em>Ты и сейчас бессилен</em>
      <em>То это значит <strong>что</strong></em>
      <em>Себе ты тоже лжёшь</em>
      &nbsp;
      <strong>Признанием того что это в жизни было</strong>
      <strong>Ты протаранишь брешь в воздвигнутой стене</strong>
      <strong>И выйдешь на простор</strong>
      <strong><em>Которого не видел</em></strong>
      <strong>Так как всю жизнь свою</strong>
      <strong>Сидел &nbsp;душой в тюрьме</strong>
    `
  },
  {
    id:'258',
    name: 'Мне было так легко обманывать себя',
    text: `
      <strong>М</strong>не было так легко обманывать себя
      И ожиданьем жить свершения <strong>того</strong>
      Что не могло случиться до тех пор
      Покуда сам себе ни вынес приговор
      &nbsp;
      Был приговор суров но справедлив
      <em>А в исполнение был всё ж не приведен</em>
      По той причине <em>что я попросту был псих</em>
      Хотя диагноз был <em>практически здоров</em>
      &nbsp;
      А как ещё назвали б вы того
      Который сам себе не доверял
      Который от испуга быть собой
      Себе во всём и беспробудно лгал
      Которого несло потоком дней
      Несло <strong>туда </strong>куда уходит жизнь
      А он
      На лодке глупости своей
      Грёб вёслами несбыточных идей
      <em>&nbsp;</em>
      <em>Но повезло</em>
      Был выброшен на берег
      На берег времени несущейся реки
      На берег <strong>тот</strong> который чист и светел
      Тот
      На котором меня ждали вы
      На берег
      Что помог мне осознать
      Сокрытый смысл понятия <em>реальность</em>
      Реальность
      Заключившую в себе реальное понятье
      <em>Виртуальность</em>
    `
  },
  {
    id:'259',
    name: 'Жизнь на земле',
    text: `
      <strong>Ж</strong>изнь на земле
      <em>Всего букварь</em>
      <em>Круговорота Вечной жизни</em>
      Жизнь на земле
      <em>Всего январь</em>
      <em>Рожденья а чуть позже Тризны</em>
      Жизнь на земле
      <em>Всего фрагмент</em>
      <em>Потоков темноты и света</em>
      &nbsp;
      <strong>Так что такое наша жизнь</strong>
      <em>&nbsp;</em>
      <em>Не торопись</em>
      &nbsp;
      <strong>Я подожду ответа</strong>
    `
  },
  {
    id:'260',
    name: 'Друг мой',
    text: `
      <strong>Д</strong>руг мой
      Ты проржавел своей Душой
      Живёшь не понимая смысла жизни
      Хотя ответ достаточно простой
      <strong>Гармонию творить</strong>
      <em>Вот для чего живой</em>
      <strong>Душой творить </strong>
      <strong>Ей </strong>сотворяя мысли
      &nbsp;
      Тебе врата открыли
      <em>Ты стоишь</em>
      Тебе ковровую дорожку постелили
      <em>Ты недв<strong>и</strong>жим</em>
      Ведёшь себя как будто бы обижен
      На тех
      Кто Свет Господний растворить пытается в твоём сознаньи
      Кто многомерностью тобой не взятых знаний
      Тебе твою Судьбу определил
      &nbsp;
      Пойми
      Что злоключения твои я<em>вляются уроками прозренья</em>
      В том
      Что&nbsp; собой являешь ты <strong><em>Суть Величайшего Творенья</em></strong>
      И что рождён <em>чтобы искать пути</em>
      Пути <em>формирования воззренья</em>
      Того
      <em>Что представляет собой жизнь</em>
      И <strong><em>сколько</em></strong> нужно <strong>и <em>кому</em></strong> терпенья
      <em>Чтобы узреть тот миг когда проснёшься ты</em>
      <strong>Душой проснёшься </strong>
      <strong>Окрылив Любовь</strong>
      <em>Которой осветив свой путь во мраке </em><strong>Воскреснешь вновь </strong>
      <strong>Вселенскою Душой</strong>
      И будешь ей спасён от драки
      <em>В которой ты дерёшься сам с собой</em>
      <em>&nbsp;</em>
      Друг мой
      <strong>Воскресни</strong>
      <strong>И живи&nbsp; Душой</strong>
    `
  },
  {
    id:'261',
    name: 'Есть Вселенский Закон',
    text: `
      <strong>Е</strong>сть Вселенский Закон
      Есть Порядок
      Есть Пр<strong>а</strong>вила
      Мы о них забываем <em>как правило</em>
      От того и живём <em>как придётся</em>
      До тех пор пока жизнь ни прервётся
      &nbsp;
      И в иной не проявленной данности
      Оказавшись в незримой реальности
      Нам откроется Смысл и Суть
      Но былого уже не вернуть
      &nbsp;
      Нашу глупость
      Остатком пути
      Нашим детям придётся пройти
      &nbsp;
      Тем наследством
      Что мы для детей оставляем
      Мы им шансов практически не оставляем
      &nbsp;
      <em>Им придется грехи отработать за нас</em>
      <em>Для того что б для них</em>
      <em>Свет Небес не угас</em>
    `
  },
  {
    id:'262',
    name: 'Вы в розовых очках живёте',
    text: `
      <strong>В</strong>ы <em>в розовых очках</em> живёте
      <em>Считая что так легче жить</em>
      Но это не дает возможность
      О многом с вами говорить
      Вы исказили восприятье
      Простых проявленных вещей
      А с этим не узреть причины
      Пришедшей к вам из прошлых дней
      Вы мотыльком на свет летите
      В стремленьи обрести тепло
      Но то что видится вам светлым
      В реальной жизни несветл<strong>о</strong>
      И счастье ваше иллюзорно
      <em>Как не поймете вы сего</em>
      И <strong>то</strong> что вы так долго ждёте
      Давно сквозь пальцы утекло
      Но вы привыкли к ожиданью
      Вы им живёте каждый день
      Живёте в торжестве страданья
      Лаская розовую тень
      &nbsp;
      Вы ошибаетесь
      Поверьте
      Наш Мир хорош тем что в нём есть
      Очки снимите
      Присмотритесь
      Не бойтесь
      Вам пора прозреть
      &nbsp;
      Настал момент
      Решиться нужно
      Увидеть <em>истинность <strong>того</strong></em>
      Что вас сегодня заставляет
      Смотреть на жизнь через стекло
    `
  },
  {
    id:'263',
    name: 'Желанье',
    text: `
      <strong><em>Ж</em></strong><em>еланье</em>
      <em>Потребность</em>
      <em>Необходимость</em>
      Это <strong>то</strong> что обязаны мы различать
      Различать для того
      Чтоб в процессе стремленья
      Мы смогли <em>Суть природы вещей</em> распознать
      &nbsp;
      Мы желаем <em>чтоб было</em>
      <em>Чем больше тем лучше</em>
      Почему бы ни быть <em>у других это ж есть</em>
      И рождается зависть <em>которая мучит</em>
      А затем поселяется<em> в зависти месть</em>
      &nbsp;
      Есть потребность
      <em>Она формирует задачу</em>
      Это <strong>то</strong>
      Что позволит в сегодняшнем дне
      Распознать не случайность конкретной удачи
      Это <strong>то</strong> что позволит не сбыться беде
      &nbsp;
      Лишь когда твоё <em>эго </em>уснув беззаботно
      Позволяет тебе осознать глубину
      Глубину пониманья Высокого свойства
      Для того чтобы ты различал <em>свет</em> и <em>тьму</em>
      Лишь тогда ты откроешь <em>природу понятий</em>
      Лишь тогда сможешь более полно понять
      Что
      <em>Желанье</em>
      <em>Потребность</em>
      <em>Необходимость</em>
      Можно только Душой различать
    `
  },
  {
    id:'264',
    name: 'Завидуешь что у других всё гладко',
    text: `
      <strong>З</strong>авидуешь что у других всё гладко
      Что всё совсем не так как у тебя
      И невдомёк тебе
      <em>Что зависть</em> -&nbsp; <em>это гадко</em>
      Что это <strong>то</strong> что мучает тебя
      Что ты себя терзая этим чувством
      Жить не даёшь себе же самому
      <em>Так знай</em>
      <strong>Что жизнь твоя имеет русло</strong>
      <strong>А зависть это груз</strong>
      <strong>Что увлечёт ко дну</strong>
    `
  },
  {
    id:'265',
    name: 'Живёшь',
    text: `
      <strong>Живёшь</strong>
      <em>Живу</em>
      <strong>И как тебе живётся</strong>
      <em>Всё в общем ничего</em>
      <em>Да только вот неймётся</em>
      <strong><em>Неймётся</em> это что</strong>
      А он в ответ смеётся
      <em>Неймётся</em>
      <em>Непокой в народе так зовётся</em>
      <em>&nbsp;</em>
      Понятье непокой знакомо нам с тобой
      Иных понятий в сущности не знаем
      И что собою есть воистину покой
      Пока что даже не подозреваем
      &nbsp;
      А истинный покой от нас всего лишь в шаге
      Он рядом
      Но не взять его рукой
      <strong>В Духовной чистоте</strong>
      <strong>И в Светлости понятий</strong>
      <strong>Он пребывает в них</strong>
      <strong>Живя твоей Душой</strong>
    `
  },
  {
    id:'266',
    name: 'Как можно так не аккуратно',
    text: `
      <strong>К</strong>ак можно так не аккуратно
      <em>Вы всю испачкали её</em>
      Как умудрились
      Не понятно
      <em>Такое посадить пятно</em>
      Вы не опрятны
      <em>Это худо</em>
      А ведь могла б ещё служить
      Вы отнеслись к ней как к ненужной
      <em>А нужно было б дорожить</em>
      Она же Вам досталось новой
      Была чиста и хороша
      Она сияла и сверкала
      <strong>В Вас помещенная Душа</strong>
    `
  },
  {
    id:'267',
    name: 'Вчера я так того хотел',
    text: `
      <strong>В</strong>чера я так того хотел
      <em>Достиг</em>
      Что делать с тем теперь не знаю
      Я сам себя не понимаю
      <em>Ещё вчера я так хотел</em>
      Теперь имею
      <em>Но зачем</em>
      Зачем мне это было нужно
      И сердце бьётся равнодушно
      <em>А ведь вчера я так хотел</em>
    `
  },
  {
    id:'268',
    name: 'Не грусти',
    text: `
      <strong>Н</strong>е грусти
      Пройдёт и это
      Отбушует
      Отгремит
      Капля <strong><em>нежного</em></strong> рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Растворит собой тревогу
      Душу Светом исцелит
      &nbsp;
      Не грусти
      Пройдёт и это
      Отликует
      Отблестит
      Капля <strong><em>нового </em></strong>рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Дверь в <em>иное</em> отворит
      &nbsp;
      Не грусти
      Пройдёт и это
      Новым свойством
      Новых дней
      Каплей <strong><em>Вечного </em></strong>рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Сможешь видеть Мир людей
      &nbsp;
      Только знай
      Пройдёт и это
      И тогда увидишь вновь
      Каплю <strong><em>нежного</em></strong> рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Что в тебе разбудит кровь 
    `
  },
  {
    id:'269',
    name: 'У каждого свой путь',
    text: `
      <strong>У</strong> каждого свой путь
      Своя дорога
      Маршрут один
      <strong><em>Оттуда и туда</em></strong>
      Дорога жизни
      От порога до порога
      <em>Есть <strong>время</strong> дверь открыть</em>
      <em>И дверь прикрыть <strong>пора</strong></em>
      &nbsp;
      Уйдём туда где были с вами прежде
      Туда уйдём откуда и пришли
      Оставив здесь друзей
      Врагов
      Надежды
      <em>Там всё иначе</em>
      <em>Там другие дни</em>
      &nbsp;
      Мы плачем
      Когда время наступает
      Расстаться с тем
      С кем коротали жизнь
      Мы плачем о себе
      <em>Ведь остаемся без тех к кому привыкли</em>
      Мы одни
      &nbsp;
      Тот кто ушёл в другое измеренье
      <strong>Тот</strong> видит нас и помогает нам
      <em>А мы живём окутанные ленью</em>
      <em>И слёзы льём по пр<strong>о</strong>житым годам</em>
      &nbsp;
      Затем приходит миг в который <strong>мы</strong> уходим
      Туда где повстречаем всех других
      И будем наблюдать как <strong>вы</strong> живёте
      <em>Себя не помня в мерностях иных</em>
      <em>&nbsp;</em>
      Я обращаюсь к вам
      Ещё живущим
      Живущим на Планете голубой
      <em>Живите срок земного пребыванья</em>
      <em>Настанет время</em>
      <em>Встретитесь со мной</em>
    `
  },
  {
    id:'270',
    name: 'Не может быть',
    text: `
      <strong>Н</strong>е может быть
      <em>Неужто это правда</em>
      То что за жизнь земную
      <em>Пусть хоть раз</em>
      Никто из вас
      <em>Хотя бы мимолетно</em>
      Не вспоминал <strong>о том</strong>
      <em>Что связывает нас</em>
      <em>&nbsp;</em>
      Нет
      Я не верю
      Это невозможно
      Вы шутите
      <em>Ну полноте друзья</em>
      Зачем же вы со мной столь осторожны
      Ведь я открыт пред вами
      <strong>Вот он я</strong>
      И я всё помню
      Мы встречались с вами
      В иных Мирах
      <em>Я помню даже срок</em>
      Я помню
      Как мы с вами разбирали
      Перерожденьем данный нам <em>Урок</em>
      &nbsp;
      Ну пошутили
      <em>Будет</em>
      <em>Право слово</em>
      Любая шутка в меру хороша
      <em>А то смотрите</em>
      Миг настанет встречи новой
      <em>Тогда шутить придёт моя пора</em>
    `
  },
  {
    id:'271',
    name: 'Ты говоришь',
    text: `
      <strong>Т</strong>ы говоришь
      Здоровье беспокоит
      Ты говоришь
      Недуг
      Сердечко ноет
      И что известный валидол не помогает с давних пор
      Что не помог валокордин
      <em>Тогда прими совет один</em>
      &nbsp;
      <em>Чтоб своё сердце исцелить</em>
      <strong><em>Ты научись себя любить</em></strong>
      <strong><em>Любить себя и уважать </em></strong>
      <strong><em>Совсем не значит ублажать</em></strong>
      &nbsp;
      Чтоб сердце вылечить своё
      <em>Ты должен понимать</em>
      Нельзя любовь людей к себе чрез силу добывать
      Нельзя насиловать других чтоб получить любовь
      <strong><em>Не много проку в той любви</em></strong>
      <strong><em>Она не греет кровь</em></strong>
      &nbsp;
      <strong>Но если ты Любовь творишь не требуя взамен</strong>
      <strong>То очень скоро ощутишь весомость перемен</strong>
      &nbsp;
      Но есть проблема
      Она в том
      Что мы живём вчерашним днём
      И очень часто слабый ум нам затмевает светлость дум
      &nbsp;
      <em>Не думай плохо о других</em>
      <em>Не думай плохо о себе</em>
      <em>Нет одинаковых людей</em>
      Пойми <em>что разные мы все</em>
      <em>Что нет причин иных судить</em>
      И в том есть маленький секрет
      И если ты его поймёшь
      Спасёшь себя от многих бед
    `
  },
  {
    id:'272',
    name: 'Нас часто выручают зеркала',
    text: `
      <strong>Н</strong>ас часто выручают зеркала
      Даря возможность разглядеть себя
      <em>Увидеть взгляд </em>
      <em>Что отражает Суть</em>
      И позволяя в Душу заглянуть
      Дают понять <em>в чём мы себя виним</em>
      <em>Простим себя за <strong>то </strong>иль не простим</em>
      Расскажут многое
      <em>Смотри не торопясь</em>
      <strong>Тогда отчистит отраженье с мыслей грязь</strong>
      <strong>&nbsp;</strong>
      <em>&nbsp;</em>
      <em>&nbsp;</em>
      <em>Нас часто выручают зеркала</em>
      <em>Напоминанием как пр<strong>о</strong>жито вчера</em>
    `
  },
  {
    id:'273',
    name: 'Натянут нерв',
    text: `
      <strong>Н</strong>атянут нерв
      Как тетива у лука
      Ещё мгновенье
      И стрела
      <em>Смертельной фразой п<strong>у</strong>щенная в друга</em>
      Пронзила Душу друга <em>как врага</em> 
      &nbsp;
      Друг покачнулся
      <em>Снег лицо заполнил</em>
      Но боль в груди он всё же превозмог
      И произнёс с улыбкой
      <strong><em>Ты брат гений</em></strong>
      <em>Ты преподал мне ст<strong>о</strong>ящий урок</em>
      <em>Ты дал понять мне что не так всё плохо</em>
      <em>Что живы чувства у тебя в груди</em>
      <em>Благословляю дальнюю дорогу</em>
      <em>Которую с тобою мы прошли</em>
      <em>Которой шли друг другу помогая</em>
      <em>Плечом к плечу</em>
      <em>В годину злой нужды</em>
      <em>Мы шли вперёд</em>
      <em>Ухаб не замечая</em>
      <em>Но вот теперь</em>
      <em>Я вижу</em>
      <em>Добрели</em>
      <em>Лишь об одном прошу</em>
      <strong><em>Попробуй быть спокойным</em></strong>
      <em>Прошу тебя</em>
      <strong><em>За всё вини меня</em></strong>
      <em>И последи за сердцем</em>
      <em>Ради Бога</em>
      <strong><em>Оно шалило прежде у тебя</em></strong>
    `
  },
  {
    id:'274',
    name: 'Был выверен бросок с поправкою на ветер',
    text: `
      <strong>Б</strong>ыл выверен бросок с поправкою на ветер
      И смыслом страшных слов был послан бумеранг
      Затем чтобы убить
      Того кто чист и светел
      Того
      Кто жить в грязи кому-то помешал
      <em>И помешал лишь тем</em>
      Что грязи не приемля
      Себе определил иного свойства путь
      Который осветил ему свечой Вселенской
      Тот
      Кто поздней открыл понятий скрытых Суть
      &nbsp;
      И пламенем свечи
      Сияющим Любовью
      Был прерван тот полёт
      Что нёс собой беду
      Вернулся бумеранг
      К тому кем прежде брошен
      Вернулся чувством
      <strong>Я тебя</strong>
      <strong>Люблю</strong>
    `
  },
  {
    id:'275',
    name: 'Не отводи прошу тебя свой взгляд',
    text: `
      <strong>Н</strong>е отводи<em> прошу тебя </em>свой взгляд
      В нём все Миры в которых ты живёшь
      А я хочу понять где ты сейчас
      Когда со мною разговор ведёшь
      &nbsp;
      Твой взгляд всегда спокоен и глубок
      В нём отраженье Мудрости Веков
      И он открыл мне лучше всяких слов
      Смысл мною созданных бытийностью оков
      <em>&nbsp;</em>
      Вот взгляд иной
      И к счастью он не твой
      В нём нет любви и глубины в нём нет
      В нём сконцентрированная пустота
      Что не позволит<em> <strong>да</strong> </em>сказать в ответ
      <em>&nbsp;</em>
      А вот лукавый
      Через щёлки глаз
      В стремлении увидеть всё везде
      Не упустить
      Не прозевать тот час
      В который можно много и себе
      &nbsp;
      Есть взгляд который видит лишь себя
      Не видя что творится рядом с ним
      Самодостаточностью посчитав
      Ограниченье проявляемое им
      &nbsp;
      <em>Есть много выражений взгляда</em>
      <em>Но есть один взгляд</em>
      <em>Что награда </em>
      <em>Тому</em>
      <em>Кто проявив себя </em>
      <em>Не отведёт от взгляда глаз</em>
      <em>В ком Свет Небесный не угас</em>
      <em>Кто чист и светел пред собой</em>
      <em>И перед мерностью иной</em>
    `
  },
  {
    id:'276',
    name: 'Холодно',
    text: `
      <strong>Х</strong>олодно
      Жарко
      Сквозняк или душно
      Больно
      Щекотно
      Смешно или грустно
      Закат иль рассвет
      Уход иль приход
      <em>В безмерности Смысла Судьбы хоровод</em>
      <em>&nbsp;</em>
      В лучах отраженного Света Миров
      Мы слышим Духовные песни хор<strong>о</strong>в
      В религиях Мира и в ликах Святых
      Мы ищем великие тайны других
      &nbsp;
      <em>Мгновением счастья ты счастлив вовек</em>
      <em>&nbsp;</em>
      <em>Ты мал и ничтожен <strong>Земной человек</strong></em>
    `
  },
  {
    id:'277',
    name: 'Наверно большего чем сам ',
    text: `
      <strong>Н</strong>аверно б<strong>о</strong>льшего чем сам
      Врага тебе на свете нет
      Наверно больше чем ты сам
      Никто не ждёт твоих побед
      Побед
      Укутанных в позор
      Презренья
      <em>Не твоей Душой</em>
      Побед
      Украденных тобой
      У тех
      Кто рядом с тобой шёл
      &nbsp;
      А это означает лишь
      <em>Что ты природен</em>
      <strong><em>Но иной</em></strong>
      Что всё что ты в ночи творишь
      <em>Творишь спокойною душой</em>
      &nbsp;
      <strong>Да будет так</strong>
      <em>Живи в ночи</em>
      <em>Покуда ночь твоей души </em>
      <em>Не в силах разглядеть огонь </em>
      <strong><em>Огонь</em></strong>
      <strong><em>Сверкающей Свечи</em></strong>
      &nbsp;
      Но не далёк <em>последний час</em>
      Когда пришедший за тобой
      Захочет чтоб огонь угас
      <em>Чтобы забрать тебя с собой</em>
      &nbsp;
      Но может быть иначе всё
      <strong>Очнись</strong>
      <em>Покуда время есть</em>
      И замени в своей Душе понятье<em> <strong>месть</strong> </em>
      На чувство<em> <strong>Честь</strong></em>
      И всё изменится вокруг
      Ты вспомнишь <strong>то</strong> <strong>что солнце есть</strong>
      <strong>И новый жизненный ресурс </strong>
      <strong>Тебе вручит Благая Весть</strong>
    `
  },
  {
    id:'278',
    name: 'Доказано не раз что грязь целебна',
    text: `
      <strong>Д</strong>оказано не раз ч<em>то грязь целебна</em>
      Что в грязь уходит хворь из нас
      Я подтверждаю это
      Это верно
      <em>Грязь это <strong>то</strong> что лечит нас</em>
      &nbsp;
      Нам ванны грязевые укрепляют тело
      <em>Но это лишь частичка дела</em>
      <em>&nbsp;</em>
      Ещё есть грязь <em>что укрепляет Дух</em>
      Есть грязь <em>что исцеляет Душу</em>
      Я обещаний данных не нарушу
      Тем
      Что пытаюсь вам сказать
      Что грязь даётся <em>чтоб познать</em>
      Познать
      <strong>Что</strong> есть собою грязь
      Познать
      <em>Чтоб абсолютно знать</em>
      <strong>Что лишь познав её Душой </strong>
      <strong><em>Мы исцелимся грязью той</em></strong>
    `
  },
  {
    id:'279',
    name: 'За слабость принимаем такт',
    text: `
      <strong>З</strong>а слабость принимаем такт
      А мягкость принимаем за бессилье
      Не ценим <strong>то</strong>
      Что выше всех наград
      И что сильнее всякого насилья
      Нам не понятна Истинная Суть
      Души открытой
      Искренних объятий
      И потому
      Не веря ни кому
      Мы ждём в свой адрес лжи и ждём проклятий
      &nbsp;
      Но есть у каждого свой срок
      В который нам дадут понять
      Что <strong>то</strong> что ждём мы от других
      Есть <strong>то</strong> что сами можем дать
      &nbsp;
      Мы искорёжили себя
      Отсутствием добра в себе
      И потому нелишне знать
      Что <strong>то</strong><em> что ждёшь</em>
      <strong><em>ТО </em></strong><em>есть в тебе</em>
    `
  },
  {
    id:'280',
    name: 'Дурная привычка',
    text: `
      <strong>Д</strong>урная привычка
      <em>Навсегда уходить</em>
      <em>Чтоб потом возвращаться</em>
      <em>&nbsp;</em>
      Дурная привычка
      <em>Бесталанно творить</em>
      <em>Чтоб затем восхищаться</em>
      <em>&nbsp;</em>
      Дурная привычка
      <em>Подарив </em>
      <em>Упрекать</em>
      <em>&nbsp;</em>
      Дурная привычка
      <em>Не нашедши</em>
      <em>Терять</em>
      &nbsp;
      Дурная привычка
      <em>Напридумывать</em>
      <em>Что не сбылось</em>
      &nbsp;
      Дурная привычка
      <em>Отрицать</em>
      <em>То что сделать пришлось</em>
      &nbsp;
      Дурная привычка
      <em>Обладателем быть привычек дурных</em>
      <em>Что мешают нам жить</em>
      &nbsp;
      <strong><em>Жить иначе</em></strong>
      <strong><em>Гармонию в Мире творя</em></strong>
      <strong><em>Чисто жить</em></strong>
      <strong><em>Ни кого ни за что не коря</em></strong>
      <strong><em>В океане Любви</em></strong>
      <strong><em>Раскрылив паруса</em></strong>
      <strong><em>Плыть туда где наш дом</em></strong>
      <strong><em>Где всемерна краса</em></strong>
    `
  },
  {
    id:'281',
    name: 'Идущих рядом мы не замечаем',
    text: `
      <strong>И</strong>дущих рядом мы не замечаем
      Не в<strong>е</strong>даем с кем рядом мы живём
      С кем говорим и тех не примечаем
      А коль кого приметим
      Сильно бьём
      За <strong>то</strong>
      <em>Чтоб под ногами не болтался</em>
      За <strong>то</strong>
      <em>Что говорит и что молчит</em>
      <strong>Затем </strong>
      <em>Чтоб от других не отличался</em>
      <strong>Затем </strong>
      <em>Чтоб не остался он не бит</em>
      &nbsp;
      Активная сплошная мешанина
      <em>Кто кулаком </em>
      <em>Кто палкой </em>
      <em>Кто ножом</em>
      А вечером
      В коттедже
      У камина
      Мы в<strong>и</strong>ски пьём
      <em>Синяк прикрывши льдом</em>
      Губой распухшей подперев сигару
      Мы вспоминаем как мы <em>дали ж<strong>а</strong>ру</em>
      И просто счастливы
      Всем тем
      Чем мы живём
      &nbsp;
      <em>И невдомёк нам</em>
      <em>Что наступит час</em>
      <em>В который</em>
      <em>Дети наши камень бросят в нас</em>
    `
  },
  {
    id:'282',
    name: 'Как будто бы вода меж пальцев ',
    text: `
      <strong>К</strong>ак будто бы вода меж пальцев
      Утекают дни
      Текут они туда
      Где встретят их потомки
      И качество вперёд текущих дней
      Проявит качество людей
      Грядущему отдавших
      <em>Для детей</em>
      Красивость Душ своих
      Или Души обломки
      &nbsp;
      <em>Не каждый знает наперёд </em>
      <em>Что внукам правнуков пошлёт</em>
      Пошлёт Любовь и глубину
      Души высокого полёта
      Иль гниль и грязь
      Что до краёв
      Заполнило души болото
    `
  },
  {
    id:'283',
    name: 'Понять мы можем только то',
    text: `
      <strong>П</strong>онять мы можем только <strong>то</strong>
      Что можем
      Оставив б<strong>о</strong>льшее за гранью осознанья
      Осмыслить Мирозданье мы не сможем
      Ни силой воли
      Ни иным стараньем
      Спортивные успехи не помогут
      И ум чужой нам не раскроет Сути
      Так и живём
      <em>Свою стреножив Душу</em>
      Так и уйдём
      <em>Часть в небо</em>
      <em>А часть в сушу</em>
    `
  },
  {
    id:'284',
    name: 'Как только злиться начинаем',
    text: `
      <strong>К</strong>ак только злиться начинаем
      <em>Так в тот же миг</em>
      К себе болезни привлекаем
      <em>Будь мы дитя или старик</em>
      <strong><em>&nbsp;</em></strong>
      <strong>Закон забыли</strong>
      <strong><em>А он для всех</em></strong>
      <strong>Обидой пачкаем других</strong>
      <strong><em>А это грех</em></strong>
      &nbsp;
      Первопричины своих обид в себе ищи
      Не хочешь в этом разобраться <em>так не взыщи</em>
      Но я уверен
      Хватит сил Души чтобы понять причину боли
      <em>Но не спеши</em>
      Не торопясь и без желанья себя жалеть
      Найди причины своей обиды
      <em>Они есть плеть</em>
      Плеть
      <em>Что калечит плоть и Душу</em> <em>до рваных ран</em>
      &nbsp;
      Не обижайте
      Не обижайтесь
      <strong><em>Победы Нам</em></strong>
    `
  },
  {
    id:'285',
    name: 'Встретились однажды',
    text: `
      <strong>В</strong>стретились однажды
      Познакомились
      Обсудили у кого и как идут дела
      Поболтали <em>в общем</em>
      Чуть повздорили
      И остались вместе
      Навсегда
      &nbsp;
      Были правда случаи
      Не скрою
      И сейчас бывает иногда
      <em>Так сожмётся сердце</em>
      <em>Так заноет</em>
      Лучше б не встречал её тогда
      &nbsp;
      Но привыкли мы&nbsp; друг к другу видно
      <em>Что тут скажешь </em>
      <em>Вместе столько лет</em>
      Сейчас расстаться было бы обидно
      Без неё теперь мне жизни нет
      Без неё при свете дня не видно
      Без неё не ярок солнца свет
      Без неё
      Конечно не возможно
      На вопрос серьезный дать ответ
      <em>&nbsp;</em>
      Если честно
      <em>Без неё жить можно</em>
      <em>Многие не рады встречи с ней</em>
      С той
      С которой <em>не легко</em> <strong>но</strong> <em>просто</em>
      &nbsp;
      А я живу с ней
      &nbsp;
      <strong><em>С Совестью своей</em></strong>
    `
  },
  {
    id:'286',
    name: 'Я чувствам верю больше чем словам',
    text: `
      <strong>Я </strong>чувствам верю больше чем словам
      С недавних пор такое приключилось
      Всё от того
      Что мысль твоя
      С твоим же словом не соединилась
      Ты на словах один
      В делах другой
      Клянёшься в вечной дружбе <em>предавая</em>
      Ты так отрепетировал свой взор
      Что я стал должником тебе давая
      Но я очнулся
      Ты уж извини
      Теперь с тобой поладим по иному
      <em>Ты будешь продолжать жить в липкой лжи</em>
      <em>А я </em>
      <em>Всё что смогу</em>
      <em>Отдам другому</em>
      Я верю
      Ты всё делал не со зла
      Ты не хотел меня обманывать
      Я верю
      Ты просто жил
      Не веруя в Христа
      <em>Мне жаль</em>
      <em>Что нет в душе твоей Креста</em>
      &nbsp;
      Но это можно изменить
      <em>Тебе лишь нужно попросить</em>
      <em>Тебе лишь нужно осознать</em>
      Он даст тебе возможность быть
      Он даст тебе возможность знать
      &nbsp;
      <em>И если ты к тому готов</em>
      <em>Тебя я поздравляю</em>
      &nbsp;
      Проси
      <strong>ОН примет под Покров</strong>
      <strong><em>Я это точно знаю</em></strong>
    `
  },
  {
    id:'287',
    name: 'Дырявят небо вековые сосны',
    text: `
      <strong>Д</strong>ырявят небо вековые сосны
      Своими кронами уйдя за облака
      Я чрез прорехи эти&nbsp; вижу звёзды
      Которых вы не видите пока
      &nbsp;
      Ну что вам ст<strong>о</strong>ит
      Только присмотритесь
      И взору вашему откроются тогда
      Небесные дор<strong>о</strong>ги
      С<strong>о</strong>лнца
      Л<strong>у</strong>ны
      Вселенские моря и города
      Где жизнь течёт не по земным законам
      Где вы творите <em>Милостью Его</em>
      Своею мыслью
      Взглядом
      Чувством
      Вздохом
      Всё <strong>то</strong><em> что есть</em>
      <em>Что будет</em>
      <em>Что прошло</em>
    `
  },
  {
    id:'288',
    name: 'Человек',
    text: `
      <strong>Человек</strong>
      <em>Интересное ты существо</em>
      Ходишь <em>словно часы</em>
      Мыслишь <em>будто компьютер</em>
      Пьёшь горючее ты т<em>очно так как авто</em>
      В грудь колотишь себя
      <em>Так колотишь</em>
      <em>Как в бубен</em>
      &nbsp;
      Напридумывал праздников и выходных
      После них ты считаешь потери
      Странно как-то живёшь ты
      <em>С желаньем войны</em>
      <em>В нежелании помнить о Вере</em>
      &nbsp;
      Что случилось с тобой
      <em>Дорогой человек</em>
      <em>Ты забыл <strong>кто ты есть</strong></em>
      <em>Что являешь собою</em>
      <em>Ты не помнишь себя</em>
      <em>В череде многих лет </em>
      <em>Жизней тех что прожиты тобою</em>
      &nbsp;
      Интересное ты существо
      <em>Человек</em>
      Всё смотрю на тебя
      И не в силах понять
      <em>Что же нужно ещё</em>
      <em>Что ещё нужно дать</em>
      <em>А быть может не дать</em>
      <em>А скорее забрать</em>
      Для того чтобы ты смог себя осознать
      &nbsp;
      Осознать тебе нужно всего лишь одно
      Осознать
      Что <strong><em>ты</em></strong><em> Мысль воплот<strong>и</strong></em>
      Коль не в силах ты это простое понять
      То придётся&nbsp; как есть тебе срок доживать
      <em>Я прощаюсь с тобою</em>
      <em>Прости</em>
    `
  },
  {
    id:'289',
    name: 'Бежим вперед',
    text: `
      <strong>Б</strong>ежим вперед
      Наш финиш
      <em>Горизонт</em>
      Нас обгоняют собственные тени
      А мы бежим не зная лени
      От бега <em>дикого</em> у нас дрожат колени
      Бежим порознь
      Бежим все вместе
      <em>Не понимая</em>
      <em>Что бежим на месте</em>

    `
  },
  {
    id:'290',
    name: 'Как часто глупости мы обряжаем в злато',
    text: `
      <strong>К</strong>ак часто глупости мы обряжаем в злато
      Чтоб выглядели глупости богато
      И чтобы взгляд безумный &nbsp;оценив убранство
      Воспел бездумно ханжество и хамство
      &nbsp;
      <em>Суть смыслов выглядит скромней</em>
      <em>Её не всем дано узреть в потоке дней</em>
      &nbsp;
      Но коль имеешь в этом превосходство
      В убранстве золот<strong>о</strong>м найдёшь уродство
      Красивой глупости уродство извращений
      Подменой Истинных
      Суть - Смысловых значений
    `
  },
  {
    id:'291',
    name: 'Зачем тереть по палке палкой ',
    text: `
      <strong>З</strong>ачем тереть по палке палкой
      Пытаясь развести костёр
      Быть может проще зажигалкой
      <em>Раз чиркнул и горит огонь</em>
      <em>&nbsp;</em>
      Мы почему-то усложняем
      <strong>То</strong> что возможно без труда
      И путь что пройден повторяем
      Хоть он завёл нас не туда
      &nbsp;
      Мы не желаем обучаться
      Ошибки прошлого не в счёт
      Мы продолжаем удивляться
      <em>Что как и прежде - не везёт</em>
    `
  },
  {
    id:'292',
    name: 'Можно было б спокойно',
    text: `
      <strong>М</strong>ожно было б спокойно
      <em>А вышло на срыв</em>
      Было бы многоцветье
      <em>А сейчас негатив</em>
      Можно ли всё исправить
      <em>Трудно сказать</em>
      В этом нужно признаться
      <em>Что бы в будущем знать</em>
      <strong>Знать</strong>
      <em>Что <strong>грубость творящий</strong></em>
      <em>Наделяет себя</em>
      <em>Черно-белой картинкой в цвете нового дня</em>
    `
  },
  {
    id:'293',
    name: 'Способность дай простить ',
    text: `
      <strong>С</strong>пособность дай простить
      <em>Кто не прощён</em>
      Способность дай мне
      <em>Выдержать прощенье</em>
      Прощенье <strong>тех</strong>
      Кто мною оскорблён
      И <strong>тех</strong>
      Кто мне прощает заблужденья
      Способность дай мне
      <em>Искренней Душой </em>
      <em>Собою быть </em>
      <em>А не своею тенью</em>
      <em>И пережить того</em>
      <em>Который мной </em>
      <em>Пройдёт свой путь </em>
      <em>Ведущий к возрожденью</em>
      Способность дай мне
      <em>Не свернуть с пути</em>
      Способность дай
      <em>Его мне весь пройти</em>
      Способность дай
      <em>Возможность сохранить</em>
      <em>Простившему других </em>
      <em>Тобой прощённым быть</em>      
    `
  },
  {
    id:'294',
    name: 'Ах как они танцуют дивно',
    text: `
      <strong>А</strong>х как они танцуют дивно
      В великолепии цветном
      Звучащих внеземных мотивов
      В всезвучьи Мировых псалмов
      Они
      <em>Невидимые многим </em>
      Затеяли весёлый <em>сход</em>
      Образовав и вовлекая меня в великий хоровод
      <em>&nbsp;</em>
      <em>Межпланетарным единеньем </em>
      <em>Союз ВсеМировых людей</em>
      <em>Всезвучной пластикой общений</em>
      <em>Строкой Духовных откровений</em>
      <em>Путь открывает в новый день</em>
    `
  },
  {
    id:'295',
    name: 'Мы разучились понимать слова',
    text: `
      <strong>М</strong>ы разучились понимать слова
      Доверия к словам у нас не много
      <em>Ни потому ль у многих среди нас </em>
      <em>На жизненном пути грязна дорога</em>
      &nbsp;
      Мы говорим слова не зная их
      Не понимая истинных значений
      <em>Ни оттого ль у многих среди нас </em>
      <em>На жизненном пути с лихвою злоключений</em>
      <em>&nbsp;</em>
      Чредой красивых но бездушных фраз
      Мы засоряем чистоту пространства
      <em>Ни потому ли многие из нас</em>
      <em>Не знают цвета материнских глаз</em>
      <em>&nbsp;</em>
      <em>Мы говорим</em>
      <em>И снова говорим</em>
      <em>Всё время говорим</em>
      <em>Не умолкая</em>
      <em>Не думая о том </em>
      <em>Что близок час</em>
      <em>В который призовут к ответу нас</em>
      <em>За то что говорим &nbsp;&nbsp;</em>
      <em>Не понимая</em>
    `
  },
  {
    id:'296',
    name: 'Утрачено понятье Смысла Слова',
    text: `
      <strong>У</strong>трачено понятье Смысла Слова
      Во времени размыты Сути Слов
      <em>Так и живём</em>
      Не ведая былого Значенья Слова
      Сути тысяч Слов
      &nbsp;
      Мы выражаем чувства наизнанку
      Косноязычьем извратив <strong>всё то</strong>
      Что было ясно и понятно <strong>нам же</strong>
      В прож<strong>и</strong>тых жизнях
      В тысячах Миров
    `
  },
  {
    id:'297',
    name: 'Отзвенело много вёсен',
    text: `
      <strong>О</strong>тзвенело много вёсен
      Отбелело много зим
      Но по-прежнему мы носим
      Под рубахой
      <em>Что не бросим</em>
      <strong><em>То</em></strong>
      <em>Что нужно нам самим</em>
      <em>&nbsp;</em>
      У одних нательный крестик
      У других под сердцем шрам
      У одних Душ<strong>и</strong> горенье
      У других бесстыдный срам
      У иных билет партийный
      У иных молитвослов
      <em>Но&nbsp; все мы без исключенья</em>
      <em>Носим много нетерпенья</em>
      <strong><em>К тем </em></strong>
      <strong><em>Кто нынче не таков</em></strong>
      <em>&nbsp;</em>
      Так живём
      Враждой дыша
      Нет у нас осознаванья
      Что имеют право все
      На своё существованье
      &nbsp;
      И горящие в огне
      Своего непониманья
      Носим
      Бережно храня
      Что за пазухой имеем
      <em>И при том не сожалеем</em>
      <strong><em>Что рубаха не своя</em></strong>
    `
  },
  {
    id:'298',
    name: 'Гром грянул',
    text: `
      <strong>Г</strong>ром грянул
      <em>В клочья перепонки</em>
      Свет вспыхнул
      <em>Вытекает зренье</em>
      Ах если б только мог
      <em>Но нет уменья</em>
      Любить всё <strong>то</strong> что мне дано с рожденья
      &nbsp;
      А прежде мог
      В раскатах грома слышать
      Мелодии заоблачных волнений
      И в вспышке света углядеть источник
      Свободы мыслей
      Смыслов
      И прозрений
    `
  },
  {
    id:'299',
    name: 'Самый яркий свет в ночи',
    text: `
      <strong>С</strong>амый яркий свет в ночи
      <em>Это темнота</em>
      Самый сильный в мире звон
      <em>Это тишина</em>
      Самый близкий из всего
      <em>Это горизонт</em>
      Самый ласковый приют
      <em>Это фронт</em>
      &nbsp;
      Много в жизни поглядел
      <em>Нет глаз</em>
      Много в жизни претерпел
      <em>Это фарс</em>
      Много пройдено дорог
      <em>Нет ног</em>
      Много преданных друзей
      <em>Одинок</em>
      Солнце светит мне в окно
      <em>Нет окна</em>
      Соловьи сошли с ума
      <em>Не весна</em>
      Позвонили мне вчера
      <em>Связи нет</em>
      Фотография анфас
      <em>Не портрет</em>
      &nbsp;
      Может изменить себя
      <em>Вдруг смогу</em>
      И услышу я тогда тишину
      И смогу тогда пройти сто дорог
      И не буду я тогда одинок
      &nbsp;
      Солнце светит улыбаясь в глаза
      От весны сошла с ума стрекоза
      Ветер песни мне поёт про Любовь
      Закипает как вулкан в жилах кровь
      &nbsp;
      Не смогу уже вернуться где был
      <em>Я совсем туда дорогу забыл</em>
    `
  },
  {
    id:'300',
    name: 'Роль воспитанья и образованья',
    text: `
      <strong><em>Роль воспитанья и образованья</em></strong>
      Легко к подобной теме подходить
      Тому
      Кто искажённостью Сознанья
      Не ведает что может натворить
      &nbsp;
      Запрятавшись от истинных понятий
      Карьерный рост пытаясь сохранить
      В мозги детей он вкладывает знанья
      Шаблонной мерой
      <em>Запретив творить</em>
      Не дав понятий Сути корреляций
      Программных знаний с Сутью Миросфер
      Он обрезает путь к себяпонятью
      <em>И очень многих ждёт такой удел</em>
      <em>&nbsp;</em>
      <strong>Но есть</strong>
      <strong>Да</strong> к счастью есть ещё другие
      Пусть их не много
      Кто умён и смел
      Кто знает Силу Истинную Слов
      Вскрывающих Духовный<em> беспредел</em>
      <strong>Они</strong>
      Кто в понимании Вселенском
      Являются носителем <strong>того</strong>
      Что исцеляет
      Формирует генно
      Всё <strong>то</strong> что на сей день поражено
      &nbsp;
      А те кто скажут
      Что поправить генность нельзя
      Посредством пробужденья Душ
      Окажутся не правы
      Несомненно
      <em>Им эти знанья как холодный душ</em>
      <em>&nbsp;</em>
      <strong>Но тем</strong>
      <strong>Кто смыслом дней своих считает</strong>
      <strong><em>Ответственность не только за себя</em></strong>
      <strong>Вселенная Сознанье расширяет</strong>
      <strong>И сил даёт</strong>
      <strong>И их сопровождает </strong>
      <strong>В пути строенья завтрашнего дня</strong>
    `
  },
  {
    id:'301',
    name: 'Цвет слова в запахе звучаний',
    text: `
      <strong>Ц</strong>вет слова в запахе звучаний
      Вибрацией ансамбля букв
      Рождая состоянье пониманий
      Сгущает воздух
      Укрепляет Дух
      И вкусом стройной фразы насыщаясь
      Оттенки мыслей выстроившись в нить
      Нас окрыляя смыслами слияний возносят к Богу
      <em>И увы</em>
      Бросают вниз
      &nbsp;
      А там&nbsp;
      На самом дне&nbsp; непониманий
      Под грузом Сути Всемогущих фраз
      Беспомощность телесных извиваний
      Не даст возможность воскрешенья в нас
      <em>Всепонимания Величья Слова</em>
      <em>Его Всемерной Сути глубину</em>
      <strong><em>Но даже там есть звуки</em></strong>
      <strong><em>Коих много</em></strong>
      <strong>Я и из них слова Любви пряду</strong>
      <em>Любви не той</em>
      <em>Что сахарною сладью </em>
      <em>Возможность даст не чувствовать <strong>того</strong></em>
      <strong><em>Что</em></strong><em> есть глоток свободы пониманья </em>
      <em>Звучанья Сл<strong>о</strong>ва</em>
      <em>В Рождестве Его</em>
    `
  },
  {
    id:'302',
    name: 'Пока что лишь в своём воображеньи',
    text: `
      <strong>П</strong>ока что лишь в своём воображеньи
      Деревья обряжаются в листву
      Но очень скоро
      <strong>То </strong>придет мгновенье
      В которое
      Высоким ВдохНовеньЕм
      Нарядятся деревья наяву
      &nbsp;
      <em>Так мыслеобраз созданный Душой</em>
      <em>Пройдя этап ментального строенья</em>
      <em>Яв<strong>и</strong>тся нам Создателя Твореньем</em>
      <em>Творением в материи живой</em>
      &nbsp;
      И проявленьем чувственных идей
      В неуловимых переходах состояний
      Мы возрождаемся проекцией людей
      Людей
      Живущих в Многомерье Знаний
    `
  },
  {
    id:'303',
    name: 'Одноразовость тебя',
    text: `
      <strong><em>О</em></strong><em>дноразовость тебя</em>
      <em>В одноразовости дней</em>
      <em>Одноразово волнует </em>
      <em>Одноразовых людей</em>
      &nbsp;
      Одноразовость событий
      Одноразовость души
      Одноразовость открытий
      Одноразовость любви
      Одноразовость желаний
      <em>Одноразовых удач</em>
      Одноразовость страданий
      <em>Что жестоки как палач</em>
      Одноразовость блаженства
      <em>Одноразовых утех</em>
      Одноразовость мечтаний
      Одноразовый успех
      &nbsp;
      <em>Одноразово прощённых</em>
      <em>Одноразово простив</em>
      <em>Одноразово забыли</em>
      <em>Один раз похоронив</em>
      &nbsp;
      <strong><em>И прощая всех ушедших</em></strong>
      <strong><em>В одноразовый закат</em></strong>
      <strong><em>Для иных открыл дорогу возвращенья</em></strong>
      <strong><em>В Жизни Сад</em></strong>
    `
  },
  {
    id:'304',
    name: 'Давай поразмышляем что есть старость',
    text: `
      <strong>Д</strong>авай поразмышляем <strong><em>что </em></strong><em>есть старость</em>
      Ты говоришь
      Душевная усталость
      Когда грядущий день уже не в радость
      Когда считаешь сколько жить осталось
      Ты говоришь
      Когда недуги и сердце рвётся от натуги
      Когда нет сил с постели встать
      Когда не хочется мечтать
      &nbsp;
      <em>Ты говоришь и говоришь</em>
      <em>Болезни все перечисляешь</em>
      <em>А главного не называешь</em>
      <em>Как видно попросту не знаешь</em>
      &nbsp;
      Я помогу тебе узнать
      <strong><em>Что</em></strong><em> нужно старостью считать</em>
      <em>&nbsp;</em>
      <strong>Тогда</strong> перестает греть кровь
      <em>Когда забыл что есть Любовь</em>
      Не <strong>та</strong> <em>которая услада</em>
      А <strong>та</strong>
      <em>Которой</em> <em>небо радо</em>
      <strong>Любовь </strong>
      <em>Которая&nbsp; Суть Света</em>
      <strong>В Любви &nbsp;ты Вечен</strong>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Помни это</em></strong>
    `
  },
  {
    id:'305',
    name: 'Без пуговиц',
    text: `
      <strong>Б</strong>ез пуговиц
      Без молний
      Без застежки
      <em>Душа распахнута</em>
      Она живёт в тебе
      Живёт для тех
      Чьё восприятье дома
      <em>Глаза детей и нежных матерей</em>
      &nbsp;
      В волнительных форматах переходов
      <em>В не полном понимании Сути их</em>
      Живёт понятье <em>неземного дома</em>
      При расставаньи любящих двоих
      &nbsp;
      <em>Эгоистичность тонкой нитью острой</em>
      <em>Рождая жалость самого к себе</em>
      <em>Не даст понять смысл жизнеперехода</em>
      <em>Заставит болью думать о Судьбе</em>
      &nbsp;
      Затем возможности ночных общений
      Во исполнение данных небом прав
      Относятся&nbsp; тобой в разряд видений
      Слезами созданных твоих уставших глаз
      &nbsp;
      А <strong>Истинную Суть</strong> <em>контрольной точки</em>
      Осмыслить сможешь
      В тот момент когда
      С той стороны
      Проявлено и точно
      Увидишь нас и жизни берега
      &nbsp;
      И лишь когда отгородясь чертою
      От тех кто дорог
      Кто остался <em>жить</em>
      <em>С желаньем объяснить</em>
      Порой ночною
      Ты тоже сможешь с ними говорить
    `
  },
  {
    id:'306',
    name: 'А что с обратной стороны Земли',
    text: `
      <strong>А</strong> что с обратной стороны Земли
      <strong><em>Земля</em></strong>
      А что с обратной стороны Луны
      <strong><em>Луна</em></strong>
      А что с обратной стороны Любви
      <strong><em>Боюсь сказать</em></strong>
      А что с обратной стороны Судьбы
      <strong><em>Не время знать</em></strong>
    `
  },
  {
    id:'307',
    name: 'Ты знаешь то',
    text: `
      <strong>Т</strong>ы знаешь <strong>то</strong>
      Что многие не знают
      Ну а точней сказать
      Забыли
      Им память неспроста укоротили
      <em>Причиной послужило <strong>то</strong></em>
      <em>Что им без пользы оказались</em>
      <em>Те знанья</em>
      <em>Что тебе достались</em>
    `
  },
  {
    id:'308',
    name: 'Стучим',
    text: `
      <strong>С</strong>тучим
      А дверь не открывают
      <em>Нас здесь не ждут</em>
      А мы опять стучим
      <em>Нас за той дверью просто не желают</em>
      А мы стучим
      Стучим
      Стучим
      Стучим
      &nbsp;
      Мы где-то слышали
      <strong>Стучите</strong>
      <strong>Вам откроется</strong>
      Вот и стучим
      <em>В ответ лишь тишина</em>
      <strong>Или мудрее что-то в той пословице</strong>
      <em>Вот достучимся и подумаем тогда</em>
      &nbsp;
      &nbsp;
      &nbsp;
      <strong><em>Не знаем мы</em></strong>
      <strong><em>В чём кроются различья</em></strong>
      <strong><em>Манерности и степени приличья</em></strong>
      <strong><em>Не видим разницы</em></strong>
      <strong><em>Между &nbsp;куплетами</em></strong>
      <strong><em>И из глубин Веков дошедшими &nbsp;Заветами</em></strong>
    `
  },
  {
    id:'309',
    name: 'Ты можешь показаться лучше',
    text: `
      <strong>Т</strong>ы можешь показаться лучше
      Того
      Какой ты есть на самом&nbsp; деле
      <em>Тебе поможет в этом <strong>то</strong></em>
      <em>Во что тебя с утра одели</em>
      &nbsp;
      Ну а к обеду лоск спадёт
      Костюм помнётся
      И к ужину
      <em>Всё что ты есть</em> в тебе проснётся
      &nbsp;
      А утро завтрашнего дня тебя про<strong>я</strong>вит
      И лишь таким какой ты есть народу <strong>я</strong>вит
      &nbsp;
      <strong><em>Запомни</em></strong>
      Лучший твой наряд
      Который впору
      Лишь он позволит нам с тобой <em>с горы и в гору</em>
      <em>Тебе удобно будет в нём</em>
      <em>А нам спокойно</em>
      <em>И мы пройдём совместный путь</em>
      <em>Вполне достойно</em>
    `
  },
  {
    id:'310',
    name: 'Те',
    text: `
      <strong>Т</strong>е
      От кого поддержки ждёшь
      Гораздо чаще обижают
      Чем <strong>те</strong>
      Которые вздыхают
      И говорят сколь ты хорош
      &nbsp;
      <em>Ты рад услышать эту ложь</em>
      <em>Ты веришь в эти восхваленья</em>
      <em>И безо всякого&nbsp; смущенья </em>
      <em>Считаешь что и впрямь пригож</em>
      <em>&nbsp;</em>
      <strong>Бог даст</strong>
      <strong>Наступит в жизни час</strong>
      <strong>Когда поймём кто ценит нас</strong>
      <strong>Те</strong>
      <strong>Кто обидеть не боятся</strong>
      <strong>Иль те</strong>
      <strong>Кто будут улыбаться </strong>
      <strong>И говорить что ты прекрасен</strong>
      &nbsp;
      <strong><em>Так кто из них</em></strong>
      <strong><em>Для нас опасен</em></strong>
    `
  },
  {
    id:'311',
    name: 'Ты смел и горд',
    text: `
      <strong>Т</strong>ы смел и горд
      <em>За<strong> то</strong> тебе спасибо</em>
      Ты крепок Духом и красив в бою
      <em>За честь считаю быть с тобою рядом</em>
      <strong>Ты</strong>
      Лютый враг мой
      <strong>Я тебя </strong>
      <strong>Люблю</strong>
    `
  },
  {
    id:'312',
    name: 'Ты природен своим совершенством',
    text: `
      <strong>Т</strong>ы природен своим совершенством
      <em>В совершеньи грехов</em>
      Ты велик и глубок <em>непрозреньем</em>
      <em>Неспособностью видеть Исток</em>
      Ты есть Мудрость познания Мира
      <em>В непознаньи себя</em>
      Ты ласкаешь всех нас как крапива
      <em>Не проходит и дня</em>
      &nbsp;
      Ты велик
      <em>Потому что прир<strong>о</strong>ден</em>
      Ты велик
      <em>Потому что живёшь</em>
      Ты велик
      <em>Потому что угоден</em>
      Ты велик
      <em>Потому что уйдёшь</em>
      Ты велик своим царским подарком
      <em>Выставляя себя напоказ</em>
      Ты великий для тех
      <em>Кто сумеет прочитать твоей жизни рассказ</em>
      <em>О естественном ходе событий</em>
      <em>О значеньи проявленных дней</em>
      <em>О Величьи Духовных открытий</em>
      <em>О понятьи ошибки своей</em>
    `
  },
  {
    id:'313',
    name: 'Напрягся',
    text: `
      <strong>Н</strong>апрягся
      Ситуацию сломал
      &nbsp;
      <strong>Ты победил</strong>
      <strong>Да здравствует герой</strong>
      <strong>&nbsp;</strong>
      Но вот беда
      <em>Кто б мог предполагать</em>
      Пришли проблемы дружною гурьбой
      &nbsp;
      <strong>А избежать проблем ты всё же мог</strong>
      <strong><em>Узрев взаимосвязь</em></strong>
      <strong><em>Поняв Урок</em></strong>
    `
  },
  {
    id:'314',
    name: 'Ты постоянен лишь в своём непостоянстве',
    text: `
      <strong>Т</strong>ы постоянен лишь в своём непостоянстве
      Сегодня тебе&nbsp; <em>этак</em>
      Завтра тебе &nbsp;<em>так</em>
      Что для тебя важней всего сегодня
      <strong><em>То</em></strong><em> завтра продаёшь ты за пятак</em>
      Сегодня <em>веришь в Бога</em>
      Завтра <em>&nbsp;не в Него</em>
      Мечтаешь о вершинах
      <em>А сам идёшь на дно</em>
      Сегодня ветер свежий тебя позвал
      <em>А завтра солнце в небе ты не узнал</em>
      Сегодня ты стремленье
      <em>А завтра лень</em>
      Сегодня свет весенний
      <em>А завтра тень</em>
      &nbsp;
      <strong><em>Причины перепадов живут в Веках</em></strong>
      <strong><em>В не понятых тобою вчерашних днях</em></strong>  
    `
  },
  {
    id:'315',
    name: 'Ох как меня разобрало',
    text: `
      <strong><em>О</em></strong><em>х как меня разобрало</em>
      Даю советы
      Щёки надуваю
      <em>А сам такое вытворяю</em>
      <strong><em>Ух как меня разобрало</em></strong>
      &nbsp;
      В стыде своём горю как на костре
      Признаться в этом - <em>дело не простое</em>
      Но признаюсь
      Чтобы в огне стыда
      Сжечь до конца всё самое дурное
      Обиды сжечь
      И страхи сжечь
      Себя очистив от излишней дряни
      Вобрать в себя
      Дарованные мне
      Сознаньем Веры
      Сути Высших Знаний
    `
  },
  {
    id:'316',
    name: 'Вода общением с тобой',
    text: `
      <strong><em>В</em></strong><em>ода</em> общением с тобой
      Проявит &nbsp;к<em>то ты есть такой</em>
      Воде твоя понятна Суть
      Её не сможешь обмануть
      Как ни лукавь
      Как ни хитри
      Она читает что внутри
      <em>Читает чем Душа живёт</em>
      И превращая себя в лёд
      Конфигурацией кристалла
      Проявит все твои начала
      &nbsp;
      Иное свойство есть воды
      <em>Она спасает от беды</em>
      Любовь небес вобрав в себя
      Творит Преображенье дня
      И для тебя неся спасенье
      Тебе дарует <em>Воскресенье</em>
      Собой воскреснешь
      <em>Если ты познаешь вкус Святой Воды</em>
      &nbsp;
      И если ты воскрес Душой
      То сможешь ч<em>исто жить</em>
      Чтоб знать как можешь ты любить
      <em>И этим дорожить </em>
      Любить зарю
      Любить ручей
      Любить друзей и не друзей
      Любить всё то &nbsp;что в жизни есть
      <em>А этого не перечесть</em>
      &nbsp;
      И небеса приняв Любовь
      Любовь
      Творимую Душой
      Откроют для тебя канал
      И будут говорить с тобой
    `
  },
  {
    id:'317',
    name: 'Уносит время миг в былое',
    text: `
      <strong>У</strong>носит время миг в былое
      И вот всё <strong>то</strong>
      Что было близкое &nbsp;родное
      Уж далеко
      И не достать его рукою
      <em>Как ни старайся</em>
      <em>Не достать</em>
      Поток стремительных событий не пустишь вспять
      &nbsp;
      Всё в этом Мире проходяще
      <em>Так будет впредь</em>
      <strong><em>И то что есть</em></strong>
      <strong><em>И то что будет</em></strong>
      <strong><em>Не запереть</em></strong>
    `
  },
  {
    id:'318',
    name: 'Имен не помню',
    text: `
      <strong>И</strong>мен не помню
      Помню только лица
      Ну а точней сказать
      Глаза
      <em>Средь них такие есть</em>
      <strong><em>Я им готов молиться</em></strong>
      Увидев
      Не забудешь те глаза
      &nbsp;
      <em>Глаза колодцы</em>
      Глубже их не встретишь
      <em>Глаза Любовь</em>
      Их лучезарней нет
      <em>Глаза Вселенная</em>
      Мудрее не отыщешь
      <em>Глаза покой</em>
      В них приглушенный свет
      &nbsp;
      Я помню лишь глаза
      Не помня лица
      Через Века я их несу в своей Душе
      <em>Средь них такие есть</em>
      <strong><em>Я им готов молиться</em></strong>
      &nbsp;
      Вы видели такие
      Или нет
    `
  },
  {
    id:'319',
    name: 'Интересное свойство памяти',
    text: `
      <strong>И</strong>нтересное свойство <strong>памяти</strong>
      Помнить <strong>то</strong> ч<em>то желаешь забыть</em>
      Помнить <strong>то</strong> ч<em>то терзает и мучает</em>
      <em>Что хотелось бы изменить</em>
      Изменить
      Проживая заново ситуации прошлых лет
      Интересное свойство памяти
      Помнить <strong>тех</strong><em> кого нынче нет</em>
      &nbsp;
      Интересное свойство <strong>совести</strong>
      Раздвигающей времена
      Отпускающий в наш адрес колкости
      <em>Мы же терпим</em>
      <em>Она права</em>
      <em>&nbsp;</em>
      Интересное свойство <strong>сознания</strong>
      <em>Нежеланьем расширить себя</em>
      И живём мы <em>в среде обитания</em>
      Суматохой вчерашнего дня
      &nbsp;
      Интересное свойство <strong>мудрости</strong>
      <em>Подытожив</em>
      <em>Объединить</em>
      <em>Свойства вечно живущих глупостей</em>
      <em>С тем </em>
      <em>Чтоб в будущем вновь их творить</em>
    `
  },
  {
    id:'320',
    name: 'Кружится жизнь',
    text: `
      <strong>К</strong>руж<strong>и</strong>тся жизнь
      Как стрелки у часов
      Бывает торопливо
      <em>Как секундная</em>
      Или неспешною размерностью шагов
      Как стрелка <strong>та</strong> которая<em> минутная</em>
      Случается что замедляет ход плывя едва как стрелка<em> часовая</em>
      <strong>А я живу отсутствием границ</strong>
      <strong>Потоки времени </strong>
      <strong>В пространствах </strong>
      <strong>Растворяя</strong>
    `
  },
  {
    id:'321',
    name: 'Через Сознанье',
    text: `
      <strong>Ч</strong>ерез Сознанье
      Как чрез ушк<strong>о</strong> иголки
      Протягиваю нитью тонкой мысль
      Затем
      Чтоб сшить событья шёлком смысла
      Чтобы из них наряд красивый мыслям сшить
      И чтобы он пришёлся мыслям впору
      Велик чтоб не был
      Также как и мал
      Чтобы одежды мысль не изменяли
      Чтоб мысль свою в одеждах узнавал
      И лишь когда слова Суть смысла не изменят
      А лишь проявят смысловую Суть
      <strong>То</strong> будет означать
      Что шить слова <strong>умею</strong>
      И лишь тогда позволят вам на них взглянуть
      &nbsp;
      <em>При том </em>
      <em>Прошу не забывать</em>
      <strong><em>Их следует Душой читать</em></strong>
      Тогда открыто будет вам что Мысль доверила Словам
    `
  },
  {
    id:'322',
    name: 'Из ветра я себе построил дом',
    text: `
      <strong>И</strong>з ветра я себе построил дом
      Есть пол и стены в доме том
      Есть потолок и небольшой балкон
      Обои в доме небосводу в тон
      Есть лестница и выход на чердак
      <em>Вполне приличный дом</em>
      Но вот беда&nbsp;
      <em>Сквозняк</em>
      &nbsp;
      Решил проконопатить дом туманом
      <em>Не вышло ничего&nbsp; </em>
      <em>Туман был рваным</em>
      Пытался щели залатать водой
      <em>Не получилось&nbsp; </em>
      <em>Дождик был грибной</em>
      Старался в трещину приладить солнца луч
      <em>Тот спрятался за стаей плотных туч</em>
      Пока перебирал ремонтный материал
      <em>И ветер стих </em>
      <em>И дом пропал</em>
      &nbsp;
      С тех пор живу в дожде
      <em>Когда есть дождь</em>
      Живу в тумане
      <em>Что окутал поле</em>
      И в солнечном луче живу
      Живу во всём живущем в море
      Живу в заре и в звёздной тишине
      Живу во всём что землю окружает
      <strong><em>И лучшего не нужно дома мне</em></strong>
      <em>Да кстати</em>
      <strong><em>Лучшего и не бывает</em></strong>
    `
  },
  {
    id:'323',
    name: 'Рождённый ползать',
    text: `
      <strong>Р</strong>ождённый ползать
      Полетел
      И набирая стать полёта
      С высот заоблачных узрел
      Кишащее роднёй болото
      <em>Так получилось</em>
      <em>Что не знал он своего предназначенья</em>
      <strong><em>По той причине и взлетел</em></strong>
      <strong><em>Не чувствуя ограниченья</em></strong>
      &nbsp;
      &nbsp;
      И нам нелишним будет знать
      <strong>Взлетим</strong>
      Если хотим летать
      &nbsp;
      Но чтоб взлететь
      Нужно уметь ограничение презреть
      <strong>Ограничение Сознанья</strong>
      Что формирует наши <em>знанья</em>
      Лжезнанием для нас творящих незнанье
      <em>Знаний Настоящих</em>
    `
  },
  {
    id:'324',
    name: 'Не передать',
    text: `
      <strong>Н</strong>е передать
      Как я хотел бы знать
      <em>Что должен я Вселенной дать</em>
      Ну а пока наоборот <em>Вселенная мне всё даёт</em>
      <em>&nbsp;</em>
      Даёт Любовь
      Даёт тревоги
      Даёт не легкие дороги
      Даёт Судьбу
      Даёт мечту
      Даёт закат
      Даёт зарю
      Даёт возможность познавать
      И что познал - Осознавать
      Даёт над тем поразмышлять
      <em>Что должен я Вселенной дать</em>
      <em>&nbsp;</em>
      И размышляя я творю
      <em>Творю Любовь</em>
      <em>Творю тревоги</em>
      <em>Творю не легкие дороги</em>
      <em>Творю Судьбу</em>
      <em>Творю мечту</em>
      <em>Творю закат</em>
      <em>Творю зарю</em>
      Творю всё <strong>то</strong>
      Что мне даёт Вселенской жизни хоровод
    `
  },
  {
    id:'325',
    name: 'Нападал белый снег и чище стали мысли',
    text: `
      <strong>Н</strong>ападал белый снег и чище стали мысли
      В зависимости я от белизны полей
      Мне не страшны теперь душевные обиды
      Припорошил их снег
      <em>Снег памяти моей</em>
      &nbsp;
      Снег памяти моей &nbsp;собрал в свои ладони
      Теплом моей Души растоплен был тот снег
      Которым я умылся пытаясь смыть дурное
      Что накопил за год
      Точней сказать за Век
      За Век который мной был пр<strong>о</strong>жит этим годом
      В котором было всё
      И радость и беда и встречи невзначай
      И расставанья тоже
      И вечная весна и стужи холода
      &nbsp;
      Из новой белизны я дом себе построил
      Чтобы укрыться в нём от неуёмных вьюг
      Пух<strong>о</strong>вую постель я в доме том устроил
      Чтоб было мне на чём послушать сердца стук
      А сердце
      Кто б мог знать
      Пуховость превратило в стеклянную постель
      <em>На ней не улежать</em>
      Ну а затем её и вовсе растопило
      Заставив тем себя ещё сильней стучать
      &nbsp;
      Потом растаял дом
      Растопленный Душою
      Я снова оказался средь полей
      В которых не смогу укрыться белизною
      От стука сердца памяти моей
    `
  },
  {
    id:'326',
    name: 'Кто шутит так со мной',
    text: `
      <strong>К</strong>то шутит так со мной
      <em>Зима а дело к лету</em>
      Открыл глаза
      <em>А время уж к обеду</em>
      Ещё не начиналось
      <em>А прошло</em>
      Сияло солнце
      <em>Ночь глядит в окно</em>
      Кто шутит так со мной
      Не понимаю
      <em>Что раньше знал</em>
      <em>Теперь того не знаю</em>
      &nbsp;
      <strong><em>Когда мазками жизнь воспринимаешь</em></strong>
      <strong><em>То многого вокруг не замечаешь</em></strong>
      &nbsp;
      <strong>Живи мгновеньем каждым</strong>
      <strong>Каждым вздохом</strong>
      <strong>Живи Душой</strong>
      <strong><em>Только тогда</em></strong>
      <strong>Жизнь</strong>
      <strong>В мимолётных свойствах переходов</strong>
      <strong>Откроется Вселенной для тебя</strong>
    `
  },
  {
    id:'327',
    name: 'Корабль космический я свой',
    text: `
      <strong>К</strong>орабль космический я свой
      Зову <em>мечтой</em>
      Он прост и лёгок в управленьи
      Хоть скорость выше световой
      Её совсем не ощущаю
      И всё быстрее разгоняю
      Корабль космический я свой
      &nbsp;
      Умчась за грань понятья <strong><em>расстоянье</em></strong>
      Ресурсы скорости утратили свой смысл
      Вселенский праздник
      <em>Красотой слияний</em>
      <em>Отсутствием значений <strong>верх</strong> и <strong>низ</strong></em>
      В свободу превратил меня
      Неведомым мне прежде состояньем
      Дал ощутить себя частичкой Знаний
      <em>В Вселенских измереньях пониманий</em>
      Великих Вечных Знаний неземных
    `
  },
  {
    id:'328',
    name: 'Иные формы жизни',
    text: `
      <strong>И</strong>ные формы жизни
      Иные Миры
      Иные виды мысли
      Иные дни
      Иная Суть понятий
      Иной закат
      Иное солнце в небе
      Иной расклад
      Иные звёзды светят
      <em>Иной их свет</em>
      Иные ветры стелют
      Иной рассвет
      Иное пробужденье
      Иной восход
      Иное песнопенье
      Иной уход
      <em>А как иного много вокруг тебя</em>
      Иная форма жизни
      <em>В цветке пчела</em>
      Иная форма жизни
      <em>Течёт река</em>
      Иная форма жизни
      <strong><em>Сегодня </em></strong><em>и <strong>вчера</strong></em>
    `
  },
  {
    id:'329',
    name: 'Дельфин',
    text: `
      <strong>Д</strong>ельфин
      Ты помнишь как всё было
      &nbsp;
      Как в пыльной непроглядной мгле
      Небесный&nbsp; путь формировали
      К пустой <em>тогда ещё</em> Земле
      &nbsp;
      Ты не забыл что было прежде
      Какой великий пройден путь
      Так может быть пора
      Скажи мне
      Дать знаний им
      Хоть сколь нибудь
      Что
      Не готовы к восприятью
      Не тем путём идут
      Опять
      Ну сколько же ещё
      Дружище
      Ты будешь Знанья охранять
      &nbsp;
      <strong><em>Есть те кто знают</em></strong>
      <strong><em>Их не слышат</em></strong>
      Не потому ли что молчат
      Да
      Несомненно
      Это правда
      В том предостаточно преград
      &nbsp;
      Ну что ж плыви мой друг сердечный
      <strong>Бог даст увидимся ещё</strong>
      <strong><em>Когда поймут умом презренным</em></strong>
      <strong><em>То</em></strong>
      <strong><em>Что им космосом дано</em></strong>
    `
  },
  {
    id:'330',
    name: 'Я погулял немного по Луне',
    text: `
      <strong>Я</strong> погулял немного по Луне
      По звёздам побродил недолго
      <em>А тот что рядом был</em>
      <em>Он так мешался мне</em>
      Всё говорил
      <em>Вернулся б ты домой</em>
      <em>Тебя там ждут</em>
      <em>Вдобавок ты сырой</em>
      <em>Мол не по возрасту тебе</em>
      <em>Бродить по звёздам и Луне</em>
      &nbsp;
      Лишь утром я отправился домой
      Першило горло
      Но доволен был собой
      &nbsp;
      <strong>Открою вам секрет</strong>
      Как вылечу ангину
      Так в ту же ночь я снова дом покину
      И пусть меня опять терзает стужа
      &nbsp;
      <strong>Вот только бы не высохла та лужа</strong>
    `
  },
  {
    id:'331',
    name: 'Я посетил созвездье Гончих Псов',
    text:   `
      <strong>Я </strong>посетил созвездье <em>Гончих Псов</em>
      Был на Планете
      Где нет войн
      Нет драки
      &nbsp;
      Я рассказал о том
      На Землю возвратясь
      Подробно рассказал своей собаке
      &nbsp;
      Я рассказал о том
      Как был любим
      Как обожаем был семьей добрейших такс
      Как я разлил чернила в той семье
      И счёту н<strong>е </strong>было числу чернильных клякс
      Как испугавшись я залез под стол
      <em>Но н<strong>е </strong>был бит </em>
      Лишь самый старший такс меня предупредил
      Чтоб не шалил
      И тут же бросил колбасы в кормильный таз
      &nbsp;
      <em>Мой добрый пёс</em>
      <em>Он выслушал меня</em>
      <em>Затем залез на руки и уснул</em>
      <em>Но перед тем</em>
      <em>Он всё ж успел спросить</em>
      <em>Зачем мне нужно было так шутить</em>
      <em>И объяснил</em>
      <strong><em>Что на Планете той</em></strong>
      <strong><em>Людей не кормят колбасой</em></strong>
    `
  },
  {
    id:'332',
    name: 'Только один человек меня ждёт',
    text: `
      <strong>Т</strong>олько один человек меня ждёт
      <em>Это моя собака</em>
      Только один человек мне не лжёт
      <em>Это моя собака</em>
      Только один человек чист Душой
      <em>Это моя собака</em>
      Только один знает я какой
      <em>Это моя собака</em>
      Только один принимает меня
      <em>Точно таким как я есть</em>
      Только один не унизит меня
      <em>Мне даря грубую лесть</em>
      Только один в отношеньи меня
      <em>Не знает что значит месть</em>
      Только один счастлив полной Душой&nbsp;
      <em>Тем </em>
      <em>Что я рядом есть</em>
    `
  },
  {
    id:'333',
    name: 'Глаза в глаза сомкнувши брови',
    text: `
      <strong>Г</strong>лаза в глаза сомкнувши брови
      С тобой единым воздухом дышу
      И взгляд родной
      Знакомый мне до боли
      Открыл твоих желаний чистоту
      Ту чистоту открытости смиренной
      В доверии
      В твоей любви ко мне
      Так никогда
      Никто во всей Вселенной
      С такой готовностью не доверялся мне
      &nbsp;
      <em>Я знаю твой характер</em>
      <em>Но не глубже</em>
      <em>Чем знаешь ты характер мой</em>
      <em>Так и живём</em>
      <em>Куда же ещё лучше</em>
      <strong><em>Ты</em></strong>
      <em>Мой любимый пёс</em>
      <strong><em>И я</em></strong>
      <em>Хозяин твой</em>
    `
  },
  {
    id:'334',
    name: 'Людей люблю не всех',
    text: `
      <strong>Л</strong>юдей люблю не всех
      Животных
      Всех
      Они гораздо чище тех
      Кто спрятать суть свою пытается
      Дыша враждою
      Улыбается
      В руке держа цветы
      А за спиною&nbsp; нож
      Рассказывает мне сколь я хорош
      &nbsp;
      Людей люблю не всех
      Животных - обожаю
      <em>Я им гораздо больше доверяю</em>
    `
  },
  {
    id:'335',
    name: 'Собак не следует бояться',
    text: `
      <strong>С</strong>обак не следует бояться
      Людей
      Тех стоит опасаться
      &nbsp;
      Взгляни хоть раз в глаза собаке
      Взгляни хоть раз
      Чтобы понять
      Что не дано им предавать
      &nbsp;
      Несправедливость принимая
      Они всё терпят
      Нас прощая
      &nbsp;
      Собаки нам не могут лгать
      Они нас могут защищать
      И погибают защищая
      <em>Собачья жизнь</em>
      <em>Она такая</em>
      &nbsp;
      Собакам
      Нас бояться стоит
      Иной мерзавец удостоит ударом
      <em>Вот мол я каков</em>
      <em>Я из плеяды смельчаков</em>
      &nbsp;
      <em>Я ввязываюсь в бой</em>
      <em>Когда случится видеть</em>
      <em>Как кто-то из людей решит обидеть</em>
      <em>Собаку</em>
      <em>Что умнее многих нас</em>
      <em>О чём нам говорит взгляд умных добрых глаз</em>
      &nbsp;
      <em>Ещё</em>
      <em>Я б многое отдал</em>
      <strong><em>Чтоб тот кто бьёт</em></strong>
      <strong><em>Собакой стал</em></strong>
    `
  },
  {
    id:'336',
    name: 'Бывает',
    text: `
      <strong><em>Б</em></strong><em>ывает </em>
      <em>Такое случается с нами</em>
      <em>Что помнится нам не стираясь годами</em>
      &nbsp;
      Весёлое солнце ласкает траву
      Все дети смеются
      Я
      Горько реву
      Я помню <strong>всё</strong>
      <em>Словно было вчера</em>
      Ах как веселилась тогда детвора
      Смеялась <strong>тому</strong>
      Как коты убегали
      Когда эти дети в них камни бросали
      Как кошка собой закрывала котят
      Себя подставляя под каменный град
      &nbsp;
      <em>С тех пор утекло очень много воды</em>
      <em>Но я не испытывал б<strong>о</strong>льшей беды</em>
      <strong><em>Любое</em></strong>
      <em>Что в жизни со мной приключалось</em>
      <em>В сравненьи <strong>с той болью</strong></em>
      <em>Лишь самая малость</em>
      С тех пор умирать приходилось ни раз
      Повоевать выполняя приказ
      Я жизнью пож<strong>и</strong>л
      Той
      Что кости ломала
      Для жизни такой и троих было б мало
      <em>Но помню всю жизнь</em>
      <em>Буду помнить всегда</em>
      <strong><em>Той кошки</em></strong>
      <strong><em>Котят укрывавшей</em></strong>
      <strong><em>Глаза</em></strong>
    `
  },
  {
    id:'337',
    name: 'У меня умерла собака',
    text: `
      <strong>У</strong> меня умерла собака
      <em>Те кто знает</em>
      <em>Беда так беда</em>
      Не прощу себе
      Не забуду
      <em>Хворь мою забрала и ушла</em>
      Это горе
      Такое горе
      Хоть извойся
      Хоть исскулись
      А она ушла верной Душою
      <em>Вверх</em> на небо
      А тело <em>вниз</em>
      Боль такая
      <em>Как рвут на части</em>
      Ну зачем выпал ранний срок
      Не прощу себе
      Не забуду
      Что ей лучший не дал кусок
      Пред глазами стоит картина
      Только что была рядом со мной
      А теперь её нет
      Вот горе
      <em>Не убрать отведя рукой</em>
      Разрываю себя на части
      Лучше б сам
      Но ушла она
      Горше самой лихой напасти
      <em>Вот беда</em>
      <em>Так уж это беда</em>
      &nbsp;
      Сел
      Взъерошены ёжиком волосы
      Сердце бьётся как барабан
      Слёзы
      Иглами да иголками
      Колкой дробью бьют по глазам
      Посидел
      Поморгал
      Очухался
      Закурил
      <em>Две иль три подряд</em>
      Осмотрелся кругом
      И не верится
      Ночь
      Все живы и мирно спят
      &nbsp;
      Спит жена в одеяло укутавшись
      Спит собака
      Лишь я не сплю
      После этаких снов
      Понимаешь острей
      Как я вас дорогие Люблю
      &nbsp;
      Сердце
      Молотом
      Сознание
      Скомкано
      Эту боль просто так не унять
      И давай вас двоих
      Спящих в комнате
      <em>Кого гладить</em>
      <em>Кого целовать</em>
    `
  },
  {
    id:'338',
    name: 'Настал желанный миг',
    text: `
      <strong>Н</strong>астал желанный миг
      Счастливая пора
      Свобода
      Долгожданная свобода
      И солнце светит ярче чем вчера
      И более приятная погода
      Что запрещалось прежде
      То теперь
      Возможно всё
      Хватило бы здоровья
      Коль не удобно в дверь
      То можно в щель
      И вот оно
      Безбрежное раздолье
      &nbsp;
      <em>Довольно долго я терпел</em>
      <em>И&nbsp; объяснял что так нельзя</em>
      <em>А он на столько осмелел </em>
      <em>Что для него уж Я не Я</em>
      <em>Ну как ещё мне объяснять </em>
      <em>Как дать ему понять</em>
      <em>Что вседозволенность </em>
      <em>С свободой </em>
      <em>Нельзя отождествлять</em>
      &nbsp;
      <strong><em>Непониманием своим</em></strong>
      <strong><em>Приблизил он тот срок</em></strong>
      <strong><em>Когда я снова прицепил к колечку поводок</em></strong>
    `
  },
  {
    id:'339',
    name: 'Колодой гадальной раскину Судьбу',
    text: `
      <strong>К</strong>олодой гадальной раскину Судьбу
      В колоде листов
      <em>Весь мой срок</em>
      Пасьянс разложу разноцветьем мастей
      <em>Душевных страданий</em>
      <em>Кипучих страстей</em>
      <em>И грустью печальных дорог</em>
      Колоду гадальную
      Сколь ни тасуй
      Разложится так как должна
      И коль не сойдётся
      <em>Уж ты мне поверь</em>
      Что в том не виновна она
      Причиной тому
      Что не выпал расклад
      Является <strong>то</strong>
      Что ты <strong>сам</strong>
      Однажды не смог устоять
      И убрал
      Бубновую карту в карман
    `
  },
  {
    id:'340',
    name: 'Я в городе своём',
    text: `
      <strong>Я </strong>в городе своём
      И подданный и царь
      Преступник и судья
      Палач и лекарь
      Я в городе своём
      И трагик и хохмач
      Я господин и раб
      И всё одновременно
      &nbsp;
      Издав Закон
      Его нарушу сам
      Приговорю себя и оправдаю
      Затем заплачу громко рассмеясь
      Затем люблю и тут же унижаю
      &nbsp;
      Я город свой построил на Века
      Возвёл под небо крепостные стены
      <em>Вот только </em>
      <em>Одиноко иногда</em>
      <em>В закрытом городе</em>
      <em>Под куполом Вселенной</em>
    `
  },
  {
    id:'341',
    name: 'Снег виски испачкал белизной',
    text: `
      <strong>С</strong>нег виск<strong>и </strong>испачкал белизной
      Всё что было
      Было не со мной
      Всё что помню
      Памятью чужой
      Снег виск<strong>и </strong>испачкал белизной
      Жизнь проходит
      Возрастом других
      Тех кого любил
      Не зная их
      Тех кого встречая не увидел
      Тех кого не знал
      Но всё ж обидел
      Снег виск<strong>и</strong> испачкал белизной
      Рядом с вами те
      Кто не со мной
      И круж<strong>и</strong>тся зимней сединой
      Жизнь моя
      Что прожит<strong>а</strong> не мной
    `
  },
  {
    id:'342',
    name: 'Глаза полуоткрыты',
    text: `
      <strong>Г</strong>лаза полуоткрыты
      Шевелиться лень
      А мысли скачут <em>как с цепи сорв<strong>а</strong>лись</em>
      Прохладный вечер вытесняет день
      Закатный луч себя укутал в тень
      А мыслям <em>нипочём</em>
      Они гулять собр<strong>а</strong>лись
      &nbsp;
      Ну и пошли бродить по улочкам Судьбы
      Заглядывая в каждый уголок
      Их не поймать теперь
      Бегут куда хотят
      Они свободны
      <em>Я им в том помог</em>
      &nbsp;
      Им это не вперв<strong>о</strong>й
      <em>Бывало так и прежде</em>
      Наскучит беготня ко мне вернуться вновь
      В подарок принеся забытые надежды
      И вечную как жизнь Вселенскую Любовь
    `
  },
  {
    id:'343',
    name: 'Ну сервис',
    text: `
      <strong>Н</strong><strong>у сервис</strong>
      <strong><em>Душу в стирку не берут</em></strong>
      Костюм
      Совсем другое дело
      Пятно
      Костюмчик в стирку смело
      <strong><em>А Душу в стирку не берут</em></strong>
      <strong>&nbsp;</strong>
      Пятно с Души не вычистишь не смоешь
      Так и живи с запятнанной душой
      <em>Ну что тут сделаешь</em>
      Зато костюмчик модный
      <strong><em>Он так хорош </em></strong>
      <strong><em>Костюмчик &nbsp;модный мой</em></strong>
      &nbsp;
      А <strong>что</strong> Душа
      Её совсем не видно
      Не очень чистая
      <em>Так в чём же здесь беда</em>
      <strong>Беда в другом</strong>
      <em>Костюм уже лоснится</em>
      <em>А поносил его совсем недолго я</em>
      &nbsp;
      <strong>Ещё беда</strong>
      &nbsp;
      Так можно разориться
      <em>Подорожал костюм</em>
      <strong><em>На столько </em></strong>
      <strong><em>Что кошмар</em></strong>
      &nbsp;
      А <strong>что</strong> Душа
      <em>Ведь ей цена копейка</em>
      Упал на Души спрос
      <em>Не ходов<strong>о</strong>й товар</em>
    `
  },
  {
    id:'344',
    name: 'Не покидай меня',
    text: `
      <strong>Н</strong>е покидай меня
      Прошу
      Со мной останься
      Умоляю
      Ведь я других совсем не знаю
      Вернись
      Я так тебя люблю
      &nbsp;
      <em>Так я просил свою мечту</em>
      &nbsp;
      Она ж в ответ мне отвечала
      <em>Я </em>
      <em>Как и ты</em>
      <em>Других не знала до сей поры</em>
      <em>Но пр<strong>о</strong>бил час</em>
      <em>Настал момент</em>
      <strong><em>И я сбылась</em></strong>
    `
  },
  {
    id:'345',
    name: 'О чём мы думаем разглядывая море',
    text: `
      <strong>О</strong> чём мы думаем разглядывая море
      О чём мечтаем глядя в небеса
      О ком грустим потупив в землю взоры
      Куда спешим
      <em>Вселенной нет конца</em>
      <em>&nbsp;</em>
      Вселенной нет конца
      <em>Но миг наш краток</em>
      И в этом миге нужно нам понять
      <em>Что в окруженьи множества загадок </em>
      <em>Нам предстоит хоть что-то осознать</em>
      Нам предстоит понять значенье жизни
      <em>Своей </em>
      <em>Земной</em>
      Нам предстоит понять
      <em>Необходимость осознанья смысла</em>
      И осознать
      И смысла Суть принять
      &nbsp;
      Затем
      В пути <em>Большого Перехода</em>
      Мы встретим <strong>тех</strong> с кем проживали дни
      И будем ждать
      Когда отправят снова
      <strong>Опять туда </strong>
      <strong>Откуда мы ушли</strong>
    `
  },
  {
    id:'346',
    name: 'Никто не видел моих слёз',
    text: `
      <strong>Н</strong>икто не видел моих слёз
      Когда был предан <em>и не раз</em>
      Никто не видел моих слёз
      Когда огонь любви угас
      Никто не видел моих слёз
      Когда друг детства умирал
      Никто не видел моих слёз
      Когда<em> случалось</em> погибал
      &nbsp;
      <strong>Но стаи журавлиной клич</strong>
      Прощаньем преданной Души
      Клинообразно распоров
      Простор небесной тишины
      Пронзил меня
      Вдох оборвал
      Стон нерва обнажил в груди
      Прощеньем покарал меня
      За грязно прожитые дни
      &nbsp;
      <em>И разрываясь на куски</em>
      <em>В себе не в силах удержать</em>
      <em>Безудержную боль тоски</em>
      <em>Я плачу</em>
      <em>Плачу</em>
      <em>И опять</em>
      <em>Взгляд устремив в Святую Синь</em>
      <em>Что вдаль уносит журавлей</em>
      <em>Я плачу не стыдясь теперь</em>
      <em>Слезами всех своих потерь</em>
    `
  },
  {
    id:'347',
    name: 'Путь познаванья самого себя',
    text: `
      <strong><em>П</em></strong><em>уть познаванья самого себя</em>
      <em>В хитросплетеньях множества дорог</em>
      Маршрут не прост
      То в гору то с горы
      <em>Такой Урок определил мне Бог</em>
      &nbsp;
      В заплечном подсознания мешке
      Груз прежних жизней
      Знаний многих груз
      Мозоли натирает на хребте
      А снять его всё как-то не решусь
      <em>А что казалось проще</em>
      Снять с плечей
      Ослабить горловины бечеву
      И рассмотреть в чём тяжесть прежних дней
      <em>А я несу</em>
      <em>Несу</em>
      <em>Несу</em>
      <em>Несу</em>
      Но толи к грузу привыкать я стал
      Иль может растерял его в пути
      Но я однажды утром осознал
      Что стало легче мне его нести
      Причину я не сразу углядел
      Но как-то раз в очередной подъём
      Мешок совсем как будто опустел
      <em>А оказалось</em>
      <strong><em>Мы несли вдвоем</em></strong>
      Когда причину смог я осознать
      И взор любви направил к небесам
      Мне дали знать
      <em>Что грузом в том мешке был раньше я</em>
      Да
      <em>Грузом был я сам</em>
      Что грузом поиска первопричин <strong><em>в других</em></strong>
      Я заполнял заплечный свой мешок
      <em>И облегчил его</em>
      <em>Когда <strong>в себе</strong> первопричину обнаружить смог</em>
      Что путь тернистый
      <em>Тот которым шёл</em>
      Шёл не один
      <em>ОН был всегда со мной</em>
      <strong>Тот </strong>
      Кто так часто пропасть убирал из под ноги моей
      Когда я был слепой
      Когда не видел и не понимал
      Что этот путь познания себя
      Всего лишь мост длиною в эту жизнь
      Мост в Мир иной
      Где так же жизнь моя
    `
  },
  {
    id:'348',
    name: 'По жизни шёл я в общем то спокойно',
    text: `
      <strong>П</strong>о жизни шёл я в общем то спокойно
      Шёл в г<strong>о</strong>ру тихо
      А с горы бегом
      Ухабистость дорог встречал достойно
      Застигнет дождь
      <em>По грязи босиком</em>
      Шёл многого вокруг не замечая
      Настанет ночь
      <em>Я сплю</em>
      А утро
      <em>В путь</em>
      Проголодался&nbsp;
      <em>Съел котлету с чаем</em>
      Вспотел
      <em>Присел в тенёчек отдохнуть</em>
      &nbsp;
      Но вышло так
      Что на одном из спусков
      Споткнулся я о собственную мысль
      <em>О&nbsp; том </em>
      <em>Что путь мной пройденный лежит в пределах круга</em>
      <em>А круг собой являет мою жизнь</em>
      Мне б зарыдать
      А я развеселился
      Развеселился по причине <strong>той</strong>
      Что всё же смог я разглядеть причинность
      <em>Вчера ещё не понятую мной</em>
      Причинность возвращения на точку
      С которой н<strong>а</strong>чат жизненный отсчёт
      С того же места
      В тоже время
      Точно
      Я начал новый путь
      <strong><em>Спиралевый полёт</em></strong>
    `
  },
  {
    id:'349',
    name: 'Поют колокола',
    text: `
      <strong>П</strong>о<strong>ю</strong>т колокола
      <em>В набатный бас вплетая баритоны</em>
      По<strong>ю</strong>т колокола
      <em>Пот<strong>о</strong>ком светлых дум</em>
      По<strong>ю</strong>т колокола
      <em>И песни их бездонны</em>
      По<strong>ю</strong>т колокола духовной гаммой чувств
      &nbsp;
      Прозрачная свирель хрустальных переливов
      Раздвинув хмарь и разбудив рассвет
      Сиятельным осьмушек перезвоном
      Включила нежных красок ранний свет
      &nbsp;
      Пересыпая бисерною трелью
      Плывущий бархат звука колоколен
      Гармония
      Слияньем измерений
      Раскрыла Душу мне
      <em>Я ей теперь свободен</em>
    `
  },
  {
    id:'350',
    name: 'Полыхнул пожар на небе',
    text: `
      <strong>П</strong>олыхнул пожар на небе
      Разожгли его закатными лучами
      А когда утих огонь
      То расстреляли
      Небо
      Звёздными очередями
      &nbsp;
      Через эти звёздные прорехи
      Свет
      Мерцаньем
      Землю умывая
      Жизнь даёт в объёме бесконечности
      Ну а мы её на годы разбиваем
      &nbsp;
      Годы жизни стягивая туго
      <em>На Земле не мало мудрецов</em>
      Размещаем время
      В центр круга
      Циферблата
      Стареньких часов
      &nbsp;
      Разбивая время на минуты
      Мы стремимся вовремя лечь спать
      Что бы утром следующих суток
      Вновь
      По кругу Вечности
      Бежать
    `
  },
  {
    id:'351',
    name: 'Поговорить мы можем ни о чём',
    text: `
      <strong>П</strong>оговорить мы можем ни о чём
      Красноречиво помолчать мы можем
      От счастья можем плакать мы
      От безысходности смеяться можем
      Мы можем многое суметь
      И ничего не сделать можем
      Мы можем громко песню петь
      И шёпотом ругаться можем
      Мы можем подарить покой
      Но и лишить покоя можем
      Мы можем вместе быть с тобой
      В разлуке жить мы тоже можем
      Мы можем пробудить любовь
      И ненависть разжечь мы можем
      Мы можем возрождаться вновь
      Вновь умирать мы тоже можем
      Мы можем знанья обретать
      В невежестве прожить мы можем
      Мы можем высоко взлетать
      И падать с высоты мы можем
      Мы можем дальше продолжать
      И подытожить тоже можем
      Мы можем многое ещё
      <em>Но вот вопрос&nbsp; </em>
      <strong><em>Что мы не можем</em></strong>
      <em>&nbsp;</em>
      За окнами сгустилась ночь
      И я просил её помочь
      Ответить на один вопрос
      <em>Что в жизни можем мы не мочь</em>
      <em>&nbsp;</em>
      <em>С усмешкой мне сказала ночь </em>
      <strong>Не можешь ничего не мочь</strong>
    `
  },
  {
    id:'352',
    name: 'По весеннему тонкому льду',
    text: `
      <strong>П</strong>о весеннему тонкому льду
      <em>Мягкой поступью</em>
      <em>Словно кошка</em>
      Я иду
      <em>Страшновато немножко</em>
      Но меня ждут на том берегу
      &nbsp;
      Путь по тонкому льду не прост
      Оступился
      <em>Пропал</em>
      Тот что рядом со мною шёл
      Не дошёл
      <em>Упал </em>
      <em>Застонал</em>
      &nbsp;
      Но меня ждут на том берегу
      Я и сам этой встречи жду
      <em>Не дойти я туда не могу</em>
      <strong>Я на встречу с собою иду</strong>
    `
  },
  {
    id:'353',
    name: 'Пошла в галоп дорога',
    text: `
      <strong><em>П</em></strong><em>ошла в галоп дорога</em>
      Дробью гулкой
      Бьёт по копытам моего коня
      И звёзды яркие
      Себя окутав небом
      Зовут меня
      Я подстегнул дорогу лунной плёткой
      Та ввысь взметнулась звонкою струной
      А ворон<strong>о</strong>й
      В лицо мне гривы щёткой
      <em>И вот уж я вдал<strong>и</strong></em>
      <em>От суеты земной</em>
    `
  },
  {
    id:'354',
    name: 'Прошу вас',
    text: `
      <strong>П</strong>рошу вас
      <em>Ну хотя бы раз</em>
      Попробуйте
      Прошу
      <em>Сейчас</em>
      Закрыть глаза
      И ни о чём не думать
      Не думать
      <em>Ну хотя бы миг</em>
      Прошу затем
      Чтобы достиг
      Душ ваших мой Душевный крик
      Я прокричу вам что постиг
      И крик моей Души прим<strong>и</strong>те вы Душою
      &nbsp;
      Внимание
      &nbsp;
      Кричу
      &nbsp;
      <strong><em>Вы &nbsp;Все&nbsp; Любимы&nbsp; Мною</em></strong>
    `
  },
  {
    id:'355',
    name: 'Расстегнулась молния на небе',
    text: `
      <strong>Р</strong>асстегнулась молния на небе
      И рассыпались гирлянды звёздным златом
      Раскатились <strong>по</strong> полу Вселенной
      Что явилось для Земли хорошим знаком
      &nbsp;
      Ну а мы
      Бывает наблюдаем
      Как играют звёзды в догонялки
      И пытаемся придумывать желанья
      <em>Только вот желанья как-то ж<strong>а</strong>лки</em>
      <em>&nbsp;</em>
      А они
      Зол<strong>о</strong>ченою нитью
      Расшивают красотой пространство
      Предъявляя нам на обозренье
      Картой неба
      Строгий стиль убранства
      &nbsp;
      Слава Богу
      Что консервативен
      Модельер
      Что одевает небо
      Слава Богу
      Небо не спесиво
      <em>Столько лет один наряд носило</em>
      <em>&nbsp;</em>
      Я о том мечтаю
      Чтоб и впредь
      Стилист небесный не менял фасон
      Он элегантен
      И удобен всем
      Кто под этой красотой рождён
    `
  },
  {
    id:'356',
    name: 'Тебя всегда услышит тот',
    text: `
      <strong>Т</strong>ебя всегда услышит тот
      Чей продолжаешь жизни Род
      Твой Род
      Чрез мерности Миров
      Поддержку оказать готов
      Понятье дав Планетных дней
      И роли их в Судьбе твоей
      &nbsp;
      <em>Взаимодействием с собой</em>
      <em>Но в измерениях других</em>
      <em>Душою растворяя боль</em>
      <em>Осветишь жизнь как ночь огонь</em>
      &nbsp;
      Осознаваньем своей роли
      С собой в согласии живя
      Гармонию Душой творя
      Преодолев смятенья страх
      Себя узнаешь ты в Мирах
      <em>Как друга Ветра и Огня</em>
      <em>Планеты друга и Воды</em>
      Собою Рождество творя
      Поймёшь
      Что это<em> Перст Судьбы</em>
    `
  },
  {
    id:'357',
    name: 'Родиться должен человек',
    text: `
      <strong>Р</strong><strong>одиться должен человек</strong>
      И Души в ожидании решенья
      <em>Которую из них отправят на спасенье</em>
      <em>Того </em>
      <em>Которого сейчас должны родить</em>
      Готовятся &nbsp;вселенье совершить
      &nbsp;
      Идут последние приготовленья
      К перемещенью в Мир иной
      В жизнь что уж прожит<strong>а</strong> Душой
      Прож<strong>и</strong>та
      И ужё не раз
      <em>Но каждый раз как новый раз</em>
      &nbsp;
      <strong>А нам живущим невдомёк</strong>
      <em>Что Души в нас вселил Господь</em>
      <em>Не для того чтоб нежить плоть</em>
      А чтоб &nbsp;могли &nbsp;
      <em>Любить</em>
      <em>Творить </em>
      <em>Мечтать </em>
      <em>Страдать и вспоминать</em>
      <em>Чтобы была возможность знать</em>
      <strong>Знать </strong>
      <strong><em>Что вселённая Душа уж не настолько хороша</em></strong>
      <strong><em>Что совершенствовать её поручено тебе</em></strong>
      <em>А контролировать процесс поручено звезде</em>
      <strong>Звезде </strong>
      <em>Которая в ночи сияет как маяк</em>
      <strong>Звезде </strong>
      <em>Что будет освещать путей грядущих мрак</em>
      <em>С тем чтобы ты не заплутал</em>
      <em>Не сбился что б с пути</em>
      <em>Чтобы ты мог Душой своей Вселенский Свет нести</em>
      <em>Гармонию Душой творя что бы прошёл свой путь</em>
      <em>И чтоб когда наступит срок </em>
      <em>Мог с высоты взглянуть</em>
      На удивительный процесс
      Процесс начала дня
      В который принесла Судьбу
      <strong>Тебе Душа твоя </strong>
    `
  },
  {
    id:'358',
    name: 'Веришь не веришь',
    text: `
      <strong>В</strong>еришь не веришь
      Знаешь не знаешь
      Любишь не любишь
      Прощать не прощаешь
      Прож<strong>и</strong>л как не жил
      В этом мире&nbsp; дилемм
      Оставил <em>ничто</em>
      Как и сам был <em>ничем</em>
      &nbsp;
      Я к вам обращаюсь
      <em>Ещё не пришедшим</em>
      Но тропку свою к нашей жизни нашедшим
      <em>Чтоб не было в жизни подобного с вами</em>
      <em>Задуматься нужно</em>
      <strong><em>Покуда</em></strong><em> <strong>вы в маме</strong></em>
      &nbsp;
      Задуматься важно <strong>ещё до рожденья</strong>
      <em>Кого ты собою являешь творенье</em>
      <em>И что ты собой привнесёшь в этот Мир</em>
      <em>Лязг ржавых цепей или музыку лир</em>
      <em>&nbsp;</em>
      Задуматься &nbsp;нужно
      <em>Чем раньше тем лучше</em>
      О том <em>что дают</em>
      И о том <em>что берёшь</em>
      <em>Чем следует жить чтобы чувствовать глубже</em>
      О том
      <em>С чем к финальной черте подойдёшь</em>
      &nbsp;
      И только тогда будешь верить и знать
      Любить и прощать
      Мечтать и творить
      И только тогда не забудут тебя
      <strong>Те </strong>кто <strong>тобою</strong> прощён и любим
      И только тогда вспыхнет в небе звезда
      <em>Свет памяти вечно живой</em>
      И только тогда в ней узнают тебя
      Все <strong>те</strong>
      Кого грел ты Душой
    `
  },
  {
    id:'359',
    name: 'Вечереет',
    text: `
      <strong><em>В</em></strong><em>ечереет</em>
      <em>Темнеет</em>
      <em>Смеркается</em>
      В проявленьях заявлен процесс
      И сплетается зыбкое кружево
      Изменением цвета небес
      Состояние томными&nbsp; действами
      Заполняет пространство собой
      И беседа
      Неспешная
      Ровная
      Льётся между тобою и мной
      Недосказанности растворяются
      Размываются в мареве вечера
      Благо<em> <strong>время </strong></em>понятье абстрактное
      Исчисляемое мигом Вечности
      <em>&nbsp;</em>
      <em>Вечереет</em>
      <em>Темнеет</em>
      <em>Смеркается</em>
      Всё о чём говорим
      Забывается
    `
  },
  {
    id:'360',
    name: 'Самая короткая дорога',
    text: `
      <strong>С</strong>амая короткая дорога
      Это та к<em>оторой прошагал</em>
      Самое большое удивленье
      Тому ч<em>то отыскал чего искал</em>
      Самый долгий час
      Час ожиданья <em>исполнения того о чём мечтал</em>
      Самый близкий миг
      Миг расставанья с<em> тем</em> <em>кого пока не повстречал</em>
    `
  },
  {
    id:'361',
    name: 'Это то',
    text: `
      <strong>Э</strong><strong>то то </strong>
      <em>Без чего невозможно дышать</em>
      <em>Невозможно ходить</em>
      <em>Невозможно любить</em>
      <strong>Это то </strong>
      <em>Без чего невозможно желать</em>
      <em>Невозможно узреть</em>
      <em>Невозможно успеть</em>
      <strong>Это то </strong>
      <em>Без чего невозможно летать</em>
      <em>Невозможно мечтать</em>
      <em>Невозможно прощать</em>
      &nbsp;
      <strong>Это то </strong>
      <em>Что не даст невозможности быть</em>
      <strong>Это Вечность </strong>
      <strong>В стремлении вечном<em> Творить</em></strong>
    `
  },
  {
    id:'362',
    name: 'Стихи читая',
    text: `
      <strong>С</strong>тихи читая
      Вы найдёте повторенья
      Я повторяю многие слова
      А как назвали б Вы <em>Творца Творенье</em>
      Какие б подобрали Вы слова
      А как иначе Вы назвали б <em>Вечность</em>
      Какою строчкой выразили б <strong>то</strong>
      Что заставляет <em>болью биться сердце</em>
      Как описали б что такое <em>зло</em>
      Как <em>зло</em> в <em>добро</em> <em>вплетается корнями</em>
      Какой секрет в себе хранит <em>добро от зла</em>
      Как описали б Вы что <em>видите Душою</em>
      Как описали б то что <em>спит Душа</em>
      А как иначе Вы назвали б <em>маму</em>
      Как Вы назвали б <strong><em>жизнь</em></strong> <em>что хороша</em>
      <em>Урок Судьбы</em> каким назвали б словом
      Как Вы назвали б <em>проявленье сна</em>
      Что б Вы назвали <em>зрением Духовным</em>
      <em>Любовь </em>назвали б как&nbsp;
      Как <em>облака</em>
      <em>&nbsp;</em>
      <em>Я знаю </em>
      <em>Вы писали б по-иному</em>
      <em>Бог даст </em>
      <em>Начнёте </em>
      <em>С завтрашнего дня</em>
    `
  },
  {
    id:'363',
    name: 'В отражённой глубине я купаюсь',
    text: `
      <strong>В </strong><em>отражённой глубине</em> я купаюсь
      Безграничностью её восхищаюсь
      Пропитав себя её чистотою
      Я себя в ней растворил <em>синевою</em>
      &nbsp;
      Все понятия глубин Мирозданья
      Я в себя вбираю Сутью Созданья
      Я присутствую и в белом и в красном
      В фиолетовом и в звёздно-прекрасном
      Я участник световых&nbsp; переходов &nbsp;
      Многомерных Вселенских проходов
      В Многомерье я познал <em>МногоМирье</em>
      Осознал что есть понятье <em>Всесилье</em>
      &nbsp;
      Бесконечностью начал восторгаюсь
      И на Землю каждый раз возвращаясь
      Приношу с собою Счастье Прозренья
      И Вселенскую Волну Вдохновенья
    `
  },
  {
    id:'364',
    name: 'Прозрачным цветом я рисую свет',
    text: `
      <strong>П</strong>розрачным цветом я рисую свет
      Прозрачной мыслью
      На прозрачном своде
      В прозрачных степенях стремления к свободе
      В прозрачных рамках безграничности границ
      Прозрачность знаний
      На прозрачности страниц
      <em>Рисую я </em>
      Прозрачности понятий
      Того
      Что мы живя в прозрачности объятий
      В прозрачности невидимых глубин
      Прозрачно чувствуя&nbsp;
      Прозрачно спим
      Прозрачно дышим и прозрачно любим
      Творим прозрачность
      Что прозрачно губим
      Прозрачно помним также забывая
      <em>Живём</em>
      Прозрачности того не понимая
      <em>&nbsp;</em>
      <strong>Прозрачность<em> от бескрая до бескрая</em></strong>
      <strong>Прозрачной пустотой воспринимая</strong>
    `
  },
  {
    id:'365',
    name: 'Узором январским покрыто стекло',
    text: `
      <strong>У</strong>зором январским покрыто стекло
      На улице вьюга
      А в доме тепло
      Тепло
      Даже жарко
      Хоть голым ходи
      Вот только осколок морозный в груди
      Сидит как заноза и студит нутро
      А в доме тепло от печи и светло
      &nbsp;
      Проталину я продышал на стекле
      И будто подтаял осколок во мне
      &nbsp;
      <strong>Есть связь</strong>
      <strong>Между тем что нам<em> радует &nbsp;глаз</em></strong>
      <strong>И тем</strong>
      <strong>Что Душой называется в нас</strong>
    `
  },
  {
    id:'366',
    name: 'У каждой речки',
    text: `
      <strong>У</strong> каждой речки
      <em>Даже самой мелкой</em>
      Есть русло
      <em>Это путь её пути</em>
      Есть заводь усмиренья чувства
      <em>В ней жизнь проводят в дрёме караси</em>
      Валун в реке
      <em>Препятствие движенью</em>
      <em>Свободному движению вперёд</em>
      Но гибкостью прозрачных омовений
      Себя речушка дальше понесёт
      Перебирая камешки помельче
      Зализывая острые края
      Она течёт спокойно и красиво
      Любовью солнца в омутках звеня
      &nbsp;
      <strong><em>Вот так бы нам</em></strong>
      <em>Плывя в потоке жизни</em>
      <em>Уметь преграды плавно огибать</em>
      <em>Любовью сглаживая острые моменты</em>
      <em>Дар жизни благодарно принимать</em>
    `
  },
  {
    id:'367',
    name: 'Трудны',
    text: `
      <strong>Т</strong>рудны
      Не предсказуемы дороги
      <em>То поворот слепой</em>
      <em>То вверх</em>
      <em>То резко вниз</em>
      <em>То крен такой что дрожью сводит ноги</em>
      <em>То пл<strong>а</strong>то</em>
      <em>То ущелье</em>
      <em>То карниз</em>
      &nbsp;
      Но я привык к подобным перепадам
      Привык к огню
      Привык к воде
      Я на пути своём преград встречал не мало
      <em>Случалось даже страшно было мне</em>
      &nbsp;
      Да
      Я привык
      Я путешествую с рожденья
      Я путешествую собой в своей Судьбе
      <em>То в руки руль беру для управленья</em>
      <em>То пассажиром еду на себе</em>
    `
  },
  {
    id:'368',
    name: 'Я знаю',
    text: `
      <strong>Я </strong>знаю
      <em>Я умею плавать</em>
      Всю жизнь плыву
      <em>Меняю только стиль</em>
      А вот сейчас так хочется заплакать
      <em>Хотя что плакать если полный штиль</em>
      <em>&nbsp;</em>
      <em>Плыви себе спокойно</em>
      <em>Наслаждайся</em>
      <em>Играй с водой</em>
      <em>Устанешь </em>
      <em>Отдохни</em>
      Казалось бы как просто
      <em>С восхищеньем вбирать в себя просторы красоты</em>
      &nbsp;
      Но вот беда
      При внешнем полном штиле
      Во мне всё больше набирает мощь
      Волна великая свободы от насилья
      Волна той силы
      Что бросает в дрожь
      И в подавлении в себе волны кипучей
      Не уследил
      Как вырвались наверх
      Две капли силы в торжестве могучем
      Собою затуманив белый свет
      &nbsp;
      И с ужасом увидев что содеял
      Мне стало стыдно за свершённый шаг
      И я просил у Высших Сил Вселенских простить меня
      За то что вышло&nbsp; так
      &nbsp;
      Прошу прощения
      У всех кому доставил бессоние терзающих ночей
      Прошу простить меня
      Что слабостью сознанья я заблудился в глупости своей
    `
  },
  {
    id:'369',
    name: 'Простудилось нынче лето',
    text: `
      <strong>П</strong>ростудилось нынче лето
      Льёт как будто из ведра
      От заката до рассвета
      Льёт сегодня как вчера
      &nbsp;
      Промочило настроенье
      Промочило небеса
      Промочило всё на свете
      Даже птичьи голоса
      &nbsp;
      Раскаленностью простудной
      <em>Всем прогнозам вопреки</em>
      Промочивши раскалило даже камни у реки
      &nbsp;
      Накалилась обстановка нездоровостью небес
      Жаром мокрым тлеет воздух
      Мхом промокшим преет лес
      &nbsp;
      <strong>Мы страдаем вне сознанья</strong>
      <strong>Непонятием того</strong>
      <strong>Что в процессе воспитанья мы находимся давно</strong>
      &nbsp;
      Что уж были наказанья и водою и огнём
      Но не помня тех страданий мы в беспамятстве живём
      &nbsp;
      <em>&nbsp;</em>
      <em>&nbsp;</em>
      <em>А для того чтоб наказаний неба</em>
      <em>На этот раз смогли мы избежать</em>
      <em>В себе нам нужно отыскать</em>
      <em>Крупицу человечьего начала</em>
      <em>Чтоб жизнь поняв начать её сначала</em>
      <strong><em>Что бы началом продолжения пути</em></strong>
      <strong><em>К природосообразности идти</em></strong>
    `
  },
  {
    id:'370',
    name: 'Слова небес Суть Смыслов окрыляют',
    text: `
      <strong><em>Слова небес Суть Смыслов окрыляют</em></strong>
      <strong><em>Мощнее многих прочих тысяч слов</em></strong>
      <strong><em>Душе твоей просторы открывают</em></strong>
      <strong><em>Принять слова небес старайся быть готов</em></strong>
      <em>&nbsp;</em>
      <em>Вскинь руки вверх Духовный взор направив Ввысь</em>
      <em>И голубь белый</em>
      <em>С высоты незримой </em>
      <em>Стрелою мысли бросившийся вниз</em>
      <em>С твоих ладоней лёгким ветерком</em>
      <em>Смахнёт причины твоего недуга</em>
      <em>Что породит в твоей Душе вопрос</em>
      <strong>А как я жил до сей поры без друга</strong>
      <em>&nbsp;</em>
      <em>Мой голубь друг</em>
      <em>Мой голубь брат</em>
      <em>Скажи мой голубь белокрылый</em>
      <strong>Пред кем и сколь я виноват</strong>
      <strong>За что я брошен был в пучину</strong>
      <strong>В пучину </strong>
      <strong>Жизненных штормов</strong>
      <strong>В пучину </strong>
      <strong>Бранных перепалок</strong>
      <strong>В пучину </strong>
      <strong>Беспокойных снов</strong>
      <strong>В пучину дня </strong>
      <strong>Что слабо ярок</strong>
      <em>&nbsp;</em>
      <em>И взглядом отвечал мне друг</em>
      <em>Расправив кипильные крылья</em>
      К тебе я прилетел не вдруг
      Ты слаб и болен от бессилья
      Бессилья отыскать в себе причины множества ошибок
      Бессилия найти в себе понятье своего Всесилья
      Я помогу тебе мой брат вернуть сознанье стержня жизни
      Ты станешь мудрым
      И тогда расправишь собственные крылья
      &nbsp;
      <strong>Но обещай </strong>
      Что обретя возможности перемещений
      Ты Славе Света посвятишь открытия своих прозрений
      <em>Не торопись мне отвечать</em>
      <em>Ведь легковесность обещаний</em>
      <em>Не даст возможность осознать</em>
      <strong><em>Смысл обещанья</em></strong><em> от отчаянья</em>
    `
  },
  {
    id:'371',
    name: 'Мир тонкий',
    text: `
      <strong><em>М</em></strong><em>ир тонкий</em>
      Растворяюсь в нём
      Своей Душой Любовью и доверьем
      Я счастлив тем что он всегда со мной
      Что на пути моём он открывая двери
      Даёт возможность глубже понимать
      <em>Зависимости </em>
      <em>В проявленьях Сути</em>
      <em>Взаимосвязей множества задач</em>
      <em>С распознаванием их в центре перепутий</em>
      Тех перепутий множества дорог
      Которые ведут меня к решеньям
      Решеньям
      Формирующим <em>Урок</em>
      <em>Урок познанья</em>
      <strong><em>Часто чрез лишенья</em></strong>
      <strong><em>&nbsp;</em></strong>
      Но вот открыта следующая дверь
      За ней рассвет раскрытья пониманья
      Того
      <strong><em>Что я теперь лишён потерь</em></strong>
      И тем моё расширено сознанье
      &nbsp;
      Ведь те лишения
      <em>Как я считал их прежде</em>
      Мне всё-таки позволили понять
      Понять
      <strong>Как чрез лишенья обретать</strong>
      &nbsp;
      И обретаю я
      Душой творя
      Любовь Вселенского Единого Начала
      Познав
      Что целостность собою означала
      <em>Раскрытие всемерья для меня</em>
      <em>Способность в Тонком Мире жить</em>
      <em>Как и ему&nbsp; во мне</em>
      Познав и то
      <em>Что жизнь моя не только на Земле</em>
      <em>Что жизнь течёт в Мирах иных</em>
      <em>И эта&nbsp; жизнь моя</em>
      <em>Собой являет Вечный Миг</em>
      <em>Существования</em>
    `
  },
  {
    id:'372',
    name: 'Пусть на поверхности бушуют волны рьяно',
    text: `
      <strong>П</strong>усть на поверхности бушуют волны рьяно
      Покой хранят глубины океана
      <em>И если б мы учились у Природы</em>
      <em>На долго бы свои продлили годы</em>
      &nbsp;
      На мелководье лишь волна достигнет дна
      Поднимет муть в себе перемешает
      <em>Такой водой ты не напьёшься в зной</em>
      <em>Как и не дашь Любви не чистою Душой</em>
    `
  },
  {
    id:'373',
    name: 'Твой вдох',
    text: `
      <strong>Т</strong>вой вдох
      <em>Есть выдох всех Планет</em>
      Что Солнце белое подсвечивает снизу
      И что подвластно твоему капризу
      Готово изменить их бег
      &nbsp;
      Ты там где Вечность каждый миг
      Где планетарные порядки
      Для тех
      Кто в них пока не вник
      Определяешь как загадки
      <em>Чтоб тот кто сможет </em>
      <em>Их постиг</em>
      <em>&nbsp;</em>
      А выдох твой
      <em>Есть вдох Планет</em>
      Себя которым насыщают
      И тем живут
      <em>Живя</em>
      <em>Не знают</em>
      Что ты есть т<strong>о</strong>
      Что называют
      <strong>Свет</strong>
    `
  },
  {
    id:'374',
    name: 'О Господи',
    text: `
      <strong>О </strong><strong>Господи</strong>
      За всё тебе спасибо
      За всё тебя благодарю
      <em>Благодарю за то что так красиво</em>
      <em>За ночь и за зарю благодарю</em>
      <em>Благодарю за дождь за снег и ветер</em>
      <em>За слёзы неба выпавшей росой</em>
      <em>За то что небосвод Велик и Светел</em>
      <em>За то что Звёздный Дом над головой</em>
    `
  },
  {
    id:'375',
    name: 'Как стать Глубинной Чистотой',
    text: `
      <strong><em>К</em></strong><em>ак стать Глубинной Чистотой</em>
      <em>Как Светом стать Высокой Мысли</em>
      <em>Как стать сияющей Звездой</em>
      <em>Являясь Всем</em>
      <em>Как быть собой</em>
      &nbsp;
      А для того чтоб этим стать
      Нам нужно просто понимать
      Что <strong>стать</strong>
      Практически возможно
      Однако
      Это очень сложно
      <em>&nbsp;</em>
      Но можно проще всё решить
      <strong>Всем этим</strong>
      <strong>Нужно просто БЫТЬ</strong>
    `
  },
  // «Директория любви» ==================================================================================
  {
    id:'376',
    name: 'Реконструируя язык',
    text: `
      <strong>Р</strong>еконструируя язык
      Надуманные правила вводя
      Тем самым мы меняем жизнь
      Меняем восприятье дня
      Меняем истинную Суть понятных нам доселе слов
      Мы даже исказили смысл понятья вечного <em>ЛЮБОВЬ</em>
    `
  },
  {
    id:'377',
    name: 'Я приглашаю вас в страну моей Любви',
    text: `
      <strong>Я </strong>приглашаю вас в страну моей Любви
      Любви ко всем и ко всему на свете
      Я приглашаю вас в великолепный Мир
      Где грусть нежна
      Где каждый добр и светел
      Где горизонты чувственных границ
      Раздвинуты до уровня Вселенной
      Где птицей белой
      Устремлённой ввысь
      Летит Любовь Души нетленной
      Где образы сливаются в один
      В себя вобравший всемогущье Света
      Я приглашаю вас в страну моей Любви
      Где вечен миг
      В котором <strong>все</strong> поэты
      Я приглашаю вас в страну моей Любви
      Где песнями наполнено дыханье
      Где мысли
      Растворённые в Любви
      Творцом являются процесса созиданья
      Я приглашаю вас в страну моей Любви
      Откройте для себя заоблачные дали
      Любви возьмите
      Сколько сможете вместить
      Чтобы затем
      Другим её дарить
      Я приглашаю вас
      В страну
      Моей
      Любви
    `
  },
  {
    id:'378',
    name: 'Люблю любое время года',
    text: `
      <strong>Л</strong>юблю любое время года
      <em>Весну</em>
      За жизненную страсть
      Когда пьянящая свобода
      Набухшей почкой взорвалась
      &nbsp;
      <em>Период летнего угара</em>
      Люблю за спелость жарких дней
      За цвет волнующий загара
      За тень разлапистых аллей
      &nbsp;
      <em>Осенний праздник многоцветий</em>
      Люблю за сочность и покой
      За треугольник журавлиный
      За небо над речной волной
      &nbsp;
      <em>Прозрачность ледяного царства</em>
      Люблю за чистоту земли
      За печь пылающую жарко
      За размышленья о любви
      &nbsp;
      <em>Но если Вам</em>
      <em>Другое мненье </em>
      <em>Со мной не позволяет соглашаться</em>
      <em>То видно Вам не доводилось </em>
      <em>Зимой суровою влюбляться</em>
      <em>Коль так</em>
      <em>Луна на небосводе </em>
      <em>Для Вас всего лишь светлый круг</em>
      <em>Который блёкло освещает</em>
      <em>Ту тьму что сгрудилась вокруг</em>
      <em>&nbsp;</em>
      <em>А у меня иное мненье</em>
      <em>Луна</em>
      <em>Источник вдохновенья</em>
      <em>Вот и сейчас глядит в моё оконце</em>
      <em>Луна</em>
      <em>Она моё ночное солнце</em>
    `
  },
  {
    id:'379',
    name: 'Прошу Тебя',
    text: `
      <strong>П</strong>рошу Тебя
      Не прерывай строку
      Что проливается на лист моей рукою
      Даруй Мгновенье
      Я его вдохну
      Прошу Тебя
      Продли свою строку
      Прошу
      Дай снять с Души моей кор<strong>о</strong>сту бытия
      Чтоб тоньше чувствовать Душою пробуждённой
      Прошу Тебя
      Пусть льётся мысль Твоя
      Сияньем Света рифмы обнажённой
      Прошу
      Расширь способность осознать
      Всё
      Что в себя включают междустрочия
      Способность дай не исказив писать
      О том
      Что предстоит ещё познать
      <strong>Строкою истинной </strong>писать
      Не многоточия
      &nbsp;
      <em>Прошу Тебя</em>
      <strong><em>Продли Свою Строку</em></strong>
    `
  },
  {
    id:'380',
    name: 'Я всех вас обниму и расцелую всех',
    text: `
      <strong>Я</strong> всех вас обниму и расцелую всех
      Прижму к своей груди и поделюсь Любовью
      Я знаю вас
      И вами я живу
      Страстями вашими живу и вашей болью
      &nbsp;
      Всё что могу я сделаю для вас
      <em>Конечно же&nbsp; коль захотите сами</em>
      И в жизни вашей
      <em>Что как зебра</em>
      <em>Полосами</em>
      Скажите только
      Чёрную сотру
    `
  },
  {
    id:'381',
    name: 'Я раньше чувствовал не так',
    text: `
      <strong>Я</strong> раньше чувствовал не так
      Я прежде чувствовал ин<strong>а</strong>че
      Сейчас же
      Словно в первый раз
      Увидел как сосулька плачет
      Как источив себя слезой
      Исплакав до последней капли
      Она прощается со мной
      Слезой
      Прозрачно-ледяной
      Слезой
      Мне Душу бередящей
      &nbsp;
      Я раньше чувствовал не так
      <em>Я думал что она смеётся</em>
      <em>А смех капелью раздаётся</em>
      <em>&nbsp;</em>
      Я раньше чувствовал не так
    `
  },
  {
    id:'382',
    name: 'В обнимку с памятью',
    text: `
      <strong>В</strong> обнимку с памятью
      Вдыхая дух аллей
      Бродил
      Пытаясь надышаться
      Свободой чувств давно прож<strong>и</strong>тых дней
      И не спешил обратно возвращаться
      &nbsp;
      <em>Совсем недавно было так</em>
      <em>Теперь не так</em>
      &nbsp;
      Я благодарен прошлому
      Не скрою
      Сегодня
      Жить <em>в сегодня</em> очень рад
      Причина в том
      Что я весьма богат
      <em>Богат Великой Светлою Любовью</em>
    `
  },
  {
    id:'383',
    name: 'Как можно не любить её',
    text: `
      <strong>К</strong>ак можно не любить её
      Не знаю
      Я иногда её не понимаю
      <em>Но разве повод это чтобы не любить</em>
      Хотя и разною она бывает
      Но лишь таким как есть меня воспринимает
      Случается порою обижает
      Случается напротив обласкает
      Находит время похвалить и пожурить
      <em>Как можно жизнь такую ни любить</em>
    `
  },
  {
    id:'384',
    name: 'Уключина скрипит',
    text: `
      <strong>У</strong>ключина скрипит
      Весло вихляет
      Сочится через дно вода
      А я гребу того не замечая
      <em>Гребу я в будущее из вчера</em>
      Гребу сегодня
      Что есть только м<strong>о</strong>чи
      Гребу упорно
      Мне хватает сил
      А за бортом в ночи луна хохочет
      Которую я чуть ни задавил
      По звёздам Мирового Океана
      Я бью веслом
      Взрывая гладь воды
      <em>Мне б протрезветь</em>
      А я босой и пьяный
      <strong><em>От счастья абсолютной красоты</em></strong>
    `
  },
  {
    id:'385',
    name: 'Погадай мне по руке гадалка',
    text: `
      <strong>П</strong>огадай мне по руке гадалка
      Расскажи что было и что есть
      Расскажи что будет
      Коль сумеешь
      По руке моей грядущее прочесть
      Я не стану задавать вопросов
      Помолчу
      Послушаю о том
      Как петляя по моей ладони
      Жизнь спешит извилистым путём
      Где когда и с чем пересечётся
      Линия ладонного пути
      Чем мне в жизни это отзовётся
      <em>Расскажи гадалка расскажи</em>
      Расскажи какие перемены
      Мне пророчит <em>линия судьбы</em>
      Расскажи про встречи и измены
      <em>Расскажи гадалка расскажи</em>
      &nbsp;
      <em>Сам же я</em>
      <em>Ни прямо не намеком</em>
      <em>Усомниться в том тебе не дам</em>
      <em>Что я абсолютно доверяю</em>
      <em>Твоим взглядам </em>
      <em>Жестам и словам</em>
    `
  },
  {
    id:'386',
    name: 'Ещё немного и забудоражит',
    text: `
      <strong>Е</strong>щё немного и забудоражит
      Ещё совсем чуть-чуть и зазвенит
      Капелью звонкою
      Опять любить заставит
      Ручьём весенним чувства освежит
      <em>И уж тогда держись</em>
      Поток желанной м<strong>у</strong>ки
      В обнимку с блеском солнечных лучей
      Свободу даст от лютой зимней скуки
      Даруя прелесть предстоящих дней
      <em>И уж тогда держись</em>
    `
  },
  {
    id:'387',
    name: 'Весенний макияж наложен на природу',
    text: `
      <strong>В</strong>есенний макияж наложен на природу
      Разбухших почек взрывы оглушают
      Стремленье жить
      Родившийся росток навстречу солнцу направляет
      <em>Живородящая Природа&nbsp; </em>
      <em>Вечный Миг</em>
      Благоуханьем ветер наполняет
      <em>Что знает всё</em>
      <em>Везде бывает</em>
      Который
      Понесёт по свету
      Стих посвящения Творцу
      Создавшему
      Лишь мыслью прелесть эту
      <em>Земли планеты </em>
      <em>Неземную красоту</em>
    `
  },
  {
    id:'388',
    name: 'Один Мудрец сказал',
    text: `
      <strong>О</strong>дин Мудрец сказал
      <em>Любовь в тебе</em>
      <em>Есть сила</em>
      Другой добавил
      <em>Ты в любви </em>
      <em>Есть слабость</em>
      А третий просто знал
      Как жить в Любви Любовью
      Терзаясь светлой
      Жизнь дающей болью
    `
  },
  {
    id:'389',
    name: 'Бездонна синева небес',
    text: `
      <strong>Б</strong>ездонна синева небес
      Таится в ней невиданная сила
      И мудрость
      И Любовь
      И нежный добрый Свет
      И сострадание
      И вечность мига
      &nbsp;
      Я растворяюсь в ней
      В глубокой синеве
      От ночи до утра
      С утра до поздней ночи
      <em>Я растворяюсь в ней</em>
      <em>С тех пор как увидал</em>
      <em>Твои Любимая</em>
      <em>Безоблачные очи</em>
    `
  },
  {
    id:'390',
    name: 'Уключина',
    text: `
      <strong>У</strong>ключина скрипит
      Весло вихляет
      Сочится через дно вода
      А я гребу того не замечая
      <em>Гребу я в будущее из вчера</em>
      Гребу сегодня
      Что есть только м<strong>о</strong>чи
      Гребу упорно
      Мне хватает сил
      А за бортом в ночи луна хохочет
      Которую я чуть ни задавил
      По звёздам Мирового Океана
      Я бью веслом
      Взрывая гладь воды
      <em>Мне б протрезветь</em>
      А я босой и пьяный
      <strong><em>От счастья абсолютной красоты</em></strong>
    `
  },
  {
    id:'391',
    name: 'В глазах теченье мысли',
    text: `
      <strong>В</strong> глазах теченье мысли
      Отраженьем
      Течения извилистой реки
      Которую подпитывают жизнью
      Из дна речного бьющие ключи
      &nbsp;
      Прозрачностью природных проявлений
      Глубинной Сутью Высшего Ума
      Моменты откровенных просветлений
      Полней и глубже делают тебя
      &nbsp;
      В глазах твоих теченье мысли
      Как отраженье жизненной реки
      В твоих глазах Свет Истинных Прозрений
      <em>Глаза твои</em>
      <em>Вселенной родники</em>
    `
  },
  {
    id:'392',
    name: 'Нырнул',
    text: `
      <strong>Н</strong>ырнул
      И выплыть не могу
      Дышу с трудом
      Не слушаются ноги
      Да
      Я иду ко дну
      Да
      Я тону
      <em>В твоих глазах</em>
      <em>В безудержном восторге</em>
    `
  },
  {
    id:'393',
    name: 'Дождём умытые берёзы',
    text: `
      <strong>Д</strong>ождём умытые берёзы
      Волнистой линией река
      Луг заливной после покоса
      И дух смолистый костерка
      Тропинка
      Что свалившись набок
      Вниз покатилась с бугорка
      Нет воздуха
      Есть только запах
      И есть в руке твоя рука
      Трава
      Которая похожа на домотканный половик
      Взгляд глаз твоих
      Что отражают костра волнующийся блик
      И та тропинка
      По которой мы добежим до бугорка
      Ну и конечно сожаленье
      <em>О том</em>
      <em>Что ночка коротка</em>
      &nbsp;
    `
  },
  {
    id:'394',
    name: 'Разметали веточки березки',
    text: `
      <strong>Р</strong>азметали веточки березки
      Раскудрявились
      Мы любви не поддавались
      Но не справились
      Победила нас любовь
      Полонила
      На постель из васильков уронила
      &nbsp;
      <em>В васильковой благодати купаясь</em>
      <em>Небом звёздным с головой укрываясь</em>
      <em>Мы измяли синеву покрывала</em>
      <em>Ты женой мне небом венчанной стала</em>
    `
  },
  {
    id:'395',
    name: 'Пламенем',
    text: `
      <strong>П</strong>ламенем
      Свеча целует полночь
      Полночь
      Нежно обнимает пламя
      Их союз
      Торжественностью мига
      Высветил неровное дыханье
      &nbsp;
      Ты лампадкою горишь в моих объятьях
      Принимая сердцем шёпот н<strong>о</strong>чи
      <em>Я люблю тебя</em>
      <em>Моя родная</em>
      <em>Я люблю тебя родная</em>
      <em>Очень</em>
      &nbsp;
      Пламенем любви меня целуешь
      Нежностью тебя я обнимаю
      <em>Как могли мы прежде друг без друга</em>
      <em>Я совсем </em>
      <em>Того </em>
      <em>Не понимаю</em>
    `
  },
  {
    id:'396',
    name: 'Продрогшее небо',
    text: `
      <strong>П</strong>родрогшее небо
      Прозрачное солнце
      Рваные тряпки простуженных туч
      Ветер охрипший
      С неистовой силой шапки срывает с сугробовых куч
      Стеклянными взглядами лужи замёрзшие
      Глядят не мигая на буйство зимы
      Но
      Где &ndash; то там в глубине замороженной
      Затеплилась <strong>и</strong>скра грядущей весны
      Ровно спокойно без резких движений
      Она начинает подтачивать лёд
      И в этом весенне-ледовом сражении
      Известно кто выживет кто пропадет
      Снег отступая морщинится злобно
      В гневе своем посерел почернел
      И превратился в бесформенно &ndash; клёклый
      Затем безысходностью вовсе истлел
      Как первый пушок над губой у подростка
      Пробилась пушистая травная гладь
      По ней ветерок вдруг сбежавший с пригорка
      Пытается в губы тебя целовать
      И небо свои распростёрло объятья
      И нежное солнце ласкает тебя
      <em>Но всё же</em>
      <em>Веснушек твоих конопатье</em>
      <em>Никто не умеет любить так как я</em>
    `
  },
  {
    id:'397',
    name: 'А если б я тебя спросил',
    text: `
      <strong>А </strong>если б я тебя спросил
      <strong>Любим ли я тобой</strong>
      Чтоб ты сказала мне в ответ
      &nbsp;
      <em>В ответ сказала б</em>
      <strong><em>Нет</em></strong>
      &nbsp;
      А если б я тебя спросил
      <strong>Я для тебя родной</strong>
      Чтоб ты ответила тогда
      &nbsp;
      <em>Сказала б что</em>
      <strong><em>Чужой</em></strong>
      &nbsp;
      А если б я спросил тебя
      <strong>Ты хочешь быть со мной</strong>
      Каков тогда бы был ответ
      &nbsp;
      <em>Ответ</em>
      <strong><em>Не беспокой</em></strong>
      &nbsp;
      А если б я тебя спросил
      <strong>Твой искренен ответ</strong>
      <strong>&nbsp;</strong>
      <em>Тогда бы я </em>
      <em>В который раз</em>
      <em>Тебе сказала</em>
      <strong><em>Нет</em></strong>
    `
  },
  {
    id:'398',
    name: 'Роза',
    text: `
      <strong>Р</strong>оза
      Ромашка
      Тюльпан
      Незабудка
      Астра
      Подснежник
      Иль кактус в горшке
      &nbsp;
      <em>Это всё <strong>то</strong></em>
      <em>Как себя проявляете</em>
      <strong><em>Вы</em></strong>
      <em>В отношеньи <strong>меня</strong></em>
      <em>И вообще</em>
    `
  },
  {
    id:'399',
    name: 'Причины нет не верить Вам',
    text: `
      <strong>П</strong>ричины нет не верить Вам
      Но я по-прежнему не верю
      Я приучил Вас
      <em>Ваш звонок</em>
      <em>И вот он я</em>
      <em>Стою за дверью</em>
      Остерегитесь
      Близок срок
      <em>Такое очень может быть</em>
      Дверь отворите а за ней лишь ветер будет песню выть
      И этот в<strong>е</strong>тряный мотив
      Возможность даст Вам осознать
      <em>Что расставание со мной <strong>заставит</strong> Вас по мне скучать</em>
    `
  },
  {
    id:'400',
    name: 'Скрипит несмазаная дверь',
    text: `
      <strong>С</strong>крипит несмазаная дверь
      И отношенья наши тоже
      На дверь скрипучую похожи
      Скрипят уже который день
      &nbsp;
      В квартире окна на Восток
      Но солнца в них давно не видел
      С тех пор как я тебя обидел
      Преподает оно <em>урок</em>
      &nbsp;
      Теперь нас освещает тень
      Тень прежде солнечной квартиры
      И ночь собой меняет день
      И тень закутанная в лень
      Висит на окнах будто бы гардины
      &nbsp;
      <em>Но через щель задёрнутых гардин</em>
      <em>Мы всё же углядели лучик света</em>
      <em>И это добрая для нас с тобой примета</em>
      <em>Мы ей с тобою тень раззолот<strong>и</strong>м</em>
    `
  },
  {
    id:'401',
    name: 'Не торопись разменивать меня',
    text: `
      <strong>Н</strong>е торопись разменивать меня
      На мелкие упрёки и укоры
      На беспричинные надуманные ссоры
      Не торопись разменивать меня
      Не торопись
      Ещё слышна свирель былых высоких чувств и состояний
      И флейта слышится безоблачных мечтаний
      Не торопись за мной захлопнуть дверь
      Не торопись разменивать меня
      На то что было и на то что будет
      <em>Лишь будущее прошлое рассудит</em>
      <em>Вчерашним завтрашнее бередя</em>
    `
  },
  {
    id:'402',
    name: 'Прогнозы погоды',
    text: `
      <strong>П</strong>рогнозы погоды
      Обман за обманом
      Весну объявляют
      На деле - зима
      Коль ясный и солнечный день обещают
      То будет конечно же дождь и гроза
      Потом объясняют
      <em>Ошибки в природе</em>
      <em>В неточностях всех виновата <strong>она</strong></em>
      И мы с тобой тоже
      Как дети природы
      В прохладе живём
      <em>А прогноз был &ndash; жара</em>
    `
  },
  {
    id:'403',
    name: 'Ах как она болит',
    text: `
      <strong>А</strong>х как она болит
      Пронзённая Душа
      Как стонет сердце
      Как рыдают чувства
      Я на краю стою блаженного безумства
      <em>Всего лишь шаг </em>
      <em>И камнем в никуда</em>
      &nbsp;
      Я умоляю Вас
      Даруйте мне свободу
      Свободу от себя
      Свободу от мечты
      Я в океан любви вошла не зная броду
      И вот теперь тону в своей любви
      &nbsp;
      Ах как она болит
      Пронзённая Душа
      Как стонет сердце
      Как рыдают чувства
      Я на краю стою блаженного безумства
      <em>Всего лишь шаг</em>
      <em>И камнем в никуда</em>
      &nbsp;
      Я Вас прошу
      Помилуйте
      Спасите
      Верните жизнь
      Она Вам не нужна
      Я заклинаю Вас Любовью
      Пощадите
      <em>Ответом прозвучала тишина</em>
      &nbsp;
      Ах как она болит
      Пронзённая Душа
      Как стонет сердце
      Как рыдают чувства
      <em>Я на краю была блаженного безумства</em>
      <em>И птицей белой воспарила в никуда</em>
    `
  },
  {
    id:'404',
    name: 'Я пью твои слёзы',
    text: `
      <strong>Я </strong>пью твои слёзы
      Целуя глаза
      Ладоней твоих я касаюсь губами
      На них
      Тонкой линией жизнь пролегла
      Мы в линии этой Любовь повстречали
      &nbsp;
      Ты пьёшь свои слёзы с ладони моей
      Когда припадаешь к ладони губами
      И плачешь навзрыд
      <em>Коротка у меня</em>
      <em>Которую</em>
      <em>Линией жизни назвали</em>
      <em>&nbsp;</em>
      Не нужно грустить
      Дорогая моя
      Я знаю как нам поступить
      <strong><em>Прижавшись&nbsp; друг к другу</em></strong>
      <strong><em>И глядя в глаза</em></strong>
      <strong><em>Твоей будем линией жить</em></strong>
    `
  },
  {
    id:'405',
    name: 'Не в силах удержать',
    text: `
      <strong>Н</strong>е в силах удержать
      Ручьем струятся слёзы
      В них всё растворено
      Цвет милых глаз и грёзы
      Ознобы первых встреч
      И горечь расставаний
      Безумство двух сердец
      И трепетность признаний
      &nbsp;
      Не в силах удержать
      Ручьём струятся слёзы
      В них всё растворено
      Сомненья и угрозы
      Упреки
      Страх и боль
      Наветы
      Оскорбленья
      Истерики в ноч<strong>и</strong>
      И просьбы о прощеньи
      &nbsp;
      Не в силах удержать
      Ручьём струятся слёзы
      <em>В них жизнь растворена</em>
      <em>Поэзией и прозой</em>
    `
  },
  {
    id:'406',
    name: 'Много лет с тобой живём',
    text: `
      <strong>М</strong>ного лет с тобой живём
      Одинокие вдвоём
      Оттого друг другом каждый недоволен
      Но коль простудится один
      Тут же кашляет другой
      И в конце концов отдельно каждый болен
      Мы живём одной семьёй
      Ты со мною я с тобой
      И в разлуках редких искренне скучаем
      Много лет с тобой живём одинокие вдвоём
      <em>Отчего случилось так </em>
      <em>Не понимаем</em>
    `
  },
  {
    id:'407',
    name: 'Кто нам не дорог',
    text: `
      <strong>К</strong>то нам не дорог
      Тех легко забыть
      Печали в том для нас не много
      И объясненье есть
      <em>У всех своя дорога</em>
      <em>И то что было уж тому не быть</em>
      <em>&nbsp;</em>
      <em>Но непременно </em>
      <em>Тот наступит час</em>
      <em>В который память пенною волною</em>
      <em>На берег завтрашний</em>
      <em>Обрушит дней каскад</em>
      <em>Забытых нынче мною и тобою</em>
    `
  },
  {
    id:'408',
    name: 'Не ноет а болит',
    text: `
      <strong>Н</strong>е ноет а болит
      <em>И надо же </em>
      <em>Так остро</em>
      Как будто бы зажатую в тиски
      Бесчисленным количеством иголок
      Мне Душу разрывают на куски
      &nbsp;
      Я <strong>не </strong>предполагал что может быть так больно
      Всего то
      Это были лишь слова
      И то
      Слова
      Которые невольно
      Без злого умысла
      Ты <strong>не </strong>произнесла
    `
  },
  {
    id:'409',
    name: 'Откройтесь мне мой друг',
    text: `
      <strong>О</strong>ткройтесь мне мой друг
      <em>Какие ветры</em>
      <em>Ваш охладили взор</em>
      <em>И выстудили речь</em>
      Откройтесь мне мой друг
      Признайтесь мне на милость
      <em>Вы потеряли <strong>то</strong> </em>
      <em>Что мне клялись беречь</em>
      Откройтесь мне мой друг
      Что приключилось с Вами
      <em>Я Вас теперь совсем не узнаю</em>
      А помните
      Как прежде
      <em>С бубенцами</em>
      <em>И как встречали юную зарю</em>
      Откройтесь мне мой друг
      Причина в чём скажите
      <em>Вы разлюбили</em>
      Это не беда
      <em>В те дни которые мы вместе проводили</em>
      <em>Уже тогда</em>
      <em>Я не любила Вас сама</em>
      Откройтесь мне мой друг
      <em>Куда умчалось лето</em>
      <em>Кому <strong>теперь</strong> звенит бубенчик под дугой</em>
      Я пожелаю Вам
      А Вы примите это
      <em>Пусть с ней Вам будет лучше чем со мной</em>
      Откройтесь мне мой друг
      <em>Какие ветры</em>
      <em>Ваш охладили взор</em>
      <em>И выстудили речь</em>
      &nbsp;
      <strong>&nbsp;</strong>
      <strong>&nbsp;</strong>
      <strong>Прошу Вас замолчать</strong>
      <strong>Мне не нужны секреты</strong>
      <strong><em>Я так же как и Вы</em></strong>
      <strong><em>Не всё могу сберечь</em></strong>
    `
  },
  {
    id:'410',
    name: 'Живу в твоей Любви',
    text: `
      <strong>Ж</strong>иву в твоей Любви
      И погибаю в ней
      Я ей дышу
      И ею задыхаюсь
      Я пью её
      Я в ней тону
      Выныривая снова погружаюсь
      Я прозреваю ей
      Ей слепну я
      Безумствую
      И нахожу покой в ней
      Я счастлив ей
      И ей несчастлив я
      <em>Рыдаю и смеюсь твоей Любовью</em>
    `
  },
  {
    id:'411',
    name: 'Застлал туман глаза',
    text: `
      <strong>З</strong>астлал туман глаза
      Не вижу чрез него
      Ваш образ превращен в размытое пятно
      Причиною тому явилась не погода
      Я прежде видел Вас в любое время года
      &nbsp;
      Я видел Вас зимой
      <em>В пушистом полушубке</em>
      Весною видел Вас
      <em>В изящной мини-юбке</em>
      И летом видел Вас
      <em>В цветастом сарафане</em>
      И осенью
      <em>Когда</em>
      <em>В саду цветы увяли</em>
      &nbsp;
      Не вижу Вас теперь
      И впредь хочу не видеть
      &nbsp;
      <em>Что бы своей мечтой я Вас не смог&nbsp; обидеть</em>
    `
  },
  {
    id:'412',
    name: 'Звёздный дождь',
    text: `
      <strong>З</strong><strong>вёздный дождь</strong>
      Что красивее есть
      Величавей
      Бескрайней
      Скольких он обвенчал
      Их пожалуй не счесть
      Скольким дал разрешенья их чаяний
      &nbsp;
      Проявленье Любви
      Этот звёздный фейерверк
      Роспись в книге учёта небесной
      <em>И скрепляя печатью Духовный Венец</em>
      <em>Направляют к Земле Свет Чудесный</em>
      &nbsp;
      Так давно повелось
      <em>Если на небесах</em>
      <em>Ты встречаешь свою половинку</em>
      <em>В тот же миг</em>
      <em>Проявлением счастья Отца</em>
      <em>Закр<strong>у</strong>жат звёздочки с небом в обнимку</em>
      &nbsp;
      Коль увидишь в Всевысьи такой хоровод
      Для тебя он послужит сигналом
      Что Вселенский от счастья ликует народ
      Освещая раздолье пожаром
      Тот огонь тебе в сердце Господь поселил
      Знай
      <em>Любовь это щедрый подарок</em>
      Это то
      <em>Чем ты темную ночь превратил в светлый день</em>
      <em>Что в мороз даже жарок </em>
    `
  },
  {
    id:'413',
    name: 'Заплакала свеча',
    text: `
      <strong>З</strong>аплакала свеча
      Мерцаньем всхлип проявлен
      Слезою восковой торит дорожку грусть
      И тихая струна
      Прозрачностью звучанья
      Вплетается в букет полузабытых чувств
      Взгляд неземных озёр
      Стремленьем мотыльковым
      То подлетит к огню
      То устремиться прочь и снова подлетит
      А я прошу у Бога
      О том
      Чтоб не заканчивалась
      Ночь
    `
  },
  {
    id:'414',
    name: 'Берёзонька в наряде подвенечном',
    text: `
      <strong>Б</strong>ерёзонька в наряде подвенечном
      Стройней не сыщешь сколько ни ищи
      Кружавчиков серебряные нити
      Листочки нежные в нарядности росы
      Твой стан прекрасный в белизне крахмальной
      Несмелой дрожью отвечает мне
      Когда в рассветной дрёме
      В час венчальный
      Я прикасаюсь бережно к тебе
      Невестушка
      Что есть тебя прекрасней
      Что есть нежней
      Что есть любимей мной
      Я восторгаюсь ровностью дыханья
      Вдыхая полной грудью выдох твой
      Ах милая
      Родней тебя не знаю
      <em>Ты Символ Света</em>
      <em>Символ Чистоты</em>
      <em>Ты Вечность Чувств</em>
      <em>Ты есть мои Мечтанья</em>
      <em>Ты есть Судьба моя</em>
      <em>Ты есть Любовь Любви</em>
    `
  },
  {
    id:'415',
    name: 'В твоих объятиях я жизнь живу',
    text: `
      <strong>В</strong> твоих объятиях я жизнь живу
      Ты Рождество сияющего дня
      Тобою с упоеньем я дышу
      Тобой Люблю
      Ведь ты Любовь моя
      Тобой творю безумства каждых дней
      Тобою смыслы расцвели зарей
      Ты вспышка света
      Та что всех сильней
      Ты ясность мысли
      Ты ночной покой
      Ты свежесть утра выпавшей росой
      Туман над речкой это тоже ты
      Ты солнце над великою Землёй
      Ты полевые скромные цветы
      Ты водопад сверкающих огней
      Ты родничок журчащий в тишине
      Ты романтизм смолкающих аллей
      Ты праздник что дарован небом мне
    `
  },
  {
    id:'416',
    name: 'Вечером',
    text: `
      <strong>В</strong>ечером
      Прозрачным тихим зимним вечером
      Снежинки падали на плечи мне
      Лаская душу белизной
      &nbsp;
      Вечером
      Прозрачным тихим зимним вечером
      Ты неспеша брела навстречу мне
      Укрывшись снежною фатой
      &nbsp;
      Вечером
      Прозрачным тихим зимним вечером
      Фонарь что снежный круг высвечивал
      Сиял венчальною свечой
      &nbsp;
      Вечером
      Прозрачным тихим зимним вечером
      Мы шли путём Судьбой намеченным
      Шли взявшись <strong>за</strong> руки с тобой
      &nbsp;
      Вечером
      Прозрачным тихим зимним вечером
      Снежинки падали на плечи нам
      Лаская Души белизной
    `
  },
  {
    id:'417',
    name: 'Вроде всё в порядке',
    text: `
      <strong>В</strong>роде всё в порядке
      Только грустно
      Неуютно самому в себе
      Я сказал бы даже <em>как-то пусто</em>
      Я грущу
      Быть может о тебе
      О тебе грущу <em>тебя не зная</em>
      Странным показаться может Вам
      Я и сам
      Того не понимая
      Заскучал
      По Вашим взор-очам
      С Вами мы ни разу не встречались
      <em>Может Вас и вовсе нет такой</em>
      Но коль нет
      То с кем же я общаюсь
      <em>Ну хотя бы имя мне открой</em>
      Я сбиваюсь в разговоре с Вами
      То на <em>ты</em>
      А то опять на <em>Вы</em>
      Не пойму
      <em>Быть может я влюбляюсь</em>
      <em>В Вас мой Ангел</em>
      <em>Имя чьё</em>
      <strong><em>Мечты</em></strong>
    `
  },
  {
    id:'418',
    name: 'Я Вас спрошу',
    text: `
      <strong>Я </strong>Вас спрошу
      А Вы ответьте
      И Ваш ответ откроет мне
      Чем дорожите Вы на Свете
      Что носите в своей Душе
      &nbsp;
      Вопрос для Вас не будет сложным
      Но отвечать не торопитесь
      В себе сначала разберитесь
      <strong>В себе</strong>
      Вот это уже сложно
      Но при желании возможно
      &nbsp;
      <em>Чуть было ни забыл сказать</em>
      <em>Как надлежит Вам отвечать</em>
      <em>&nbsp;</em>
      Из двух ответов лишь один Вы можете назвать
      А это значит что из них придется выбирать
      &nbsp;
      Пришла пора вопрос задать
      Пора настала отвечать
      &nbsp;
      <em>Что Вы в себе смогли понять</em>
      <em>За жизнь что Вы уже прошли</em>
      <em>К чему склониться вы смогли</em>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Чтоб Вас любили</em></strong>
      <strong><em>Или </em></strong>
      <strong><em>Вы</em></strong>
    `
  },
  {
    id:'419',
    name: 'Наивная юность прекрасна',
    text: `
      <strong>Н</strong>аивная юность прекрасна
      И этим довольно опасна
      Ей зрелость об этом напоминает
      А молодость верить тому не желает
      А старость вздыхает
      <em>Как юность прекрасна</em>
      <em>На то не смотря что довольно опасна</em>
    `
  },
  {
    id:'420',
    name: 'Эй молодой',
    text: `
      <strong>Эй молодой</strong>
      Прими совет
      Только не нужно обижаться
      Не нужно драться и ругаться
      Прими спокойно мой совет
      &nbsp;
      <strong><em>Влюбляться нужно по весне</em></strong>
      <em>Тогда не будешь простужаться</em>
      <em>Когда придётся дожидаться</em>
      <em>Ту</em>
      <em>Что сама пришла ко мне</em>
    `
  },
  {
    id:'421',
    name: 'Вы совершенны в красоте своей',
    text: `
      <strong>В</strong>ы совершенны в красоте своей
      Вы неприступны линией бровей
      Вы лучезарны блеском синих глаз
      Прекрасны Вы и в профиль и анфас
      &nbsp;
      <em>А я </em>
      <em>Как ни старался</em>
      <em>Не увидел</em>
      <em>За Вашей красотою </em>
      <em>Вас</em>
    `
  },
  {
    id:'422',
    name: 'Блондинка с чёрными бровями',
    text: `
      <strong>Б</strong>лондинка с чёрными бровями
      С характером под стать брюнетки
      <em>Таких встречал я и не мало</em>
      <em>Все разные но все&nbsp; кокетки</em>
      &nbsp;
      Блондинки с чёрными бровями
      За макияжем пряча суть
      Давно уже не в состояньи меня запутав обмануть
      <em>&nbsp;</em>
      Блондинки с чёрными бровями
      Все как мулатки смуглокожи
      <em>Меж тем лишь только тем и схожи</em>
      <em>Что на блондинок не похожи</em>
    `
  },
  {
    id:'423',
    name: 'Мой замок закрыт на замок',
    text: `
      <strong>М</strong>ой з<strong>а</strong>мок закрыт на зам<strong>о</strong>к
      Твой ключ от замк<strong>а</strong> утерян
      Войти без ключа в з<strong>а</strong>мок мой
      Не сможешь ты
      Я в том уверен
      &nbsp;
      Мой з<strong>а</strong>мок закрыт на зам<strong>о</strong>к
      Зам<strong>о</strong>к на парадной двер<strong>и</strong>
      Есть в з<strong>а</strong>мке моём потайные ходы
      Но это не знаешь ты
      Есть много дверей
      Позволяющих мне
      Покинуть мой з<strong>а</strong>мок и снова вернуться
      <em>А что до тебя</em>
      То не ст<strong>о</strong>ит тебе
      Ждать что они пред тобой распахнутся
      &nbsp;
      Однажды ушедший уходит совсем
      И ключ в тот же миг теряет
      Как в з<strong>а</strong>мок войти
      Не имея ключа
      Он этого просто не знает
      &nbsp;
      Мой з<strong>а</strong>мок закрыт на зам<strong>о</strong>к
      Твой ключ от замк<strong>а</strong> утерян
      Войти без ключа в з<strong>а</strong>мок мой
      Не сможешь ты
      Я в том уверен
    `
  },
  {
    id:'424',
    name: 'Ночь взорвалась душевным ликованьем',
    text: `
      <strong>Н</strong>очь взорвалась душевным ликованьем
      Ночь взорвалась свершившейся мечтой
      Ночь взорвалась открытостью признанья
      Ночь взорвалась любовною игрой
      &nbsp;
      Подкралось утро
      Гаснут в небе звёзды прохладой ранней чувства остудив
      И расстаёмся мы как будто не встречались
      О том что было ночью позабыв
      &nbsp;
      Другая ночь откроет радость встречи
      Другая ночь взорвётся как вулкан
      Другие двое не загасят свечи
      <em>Другим зажгутся звёзды</em>
      <em>Но не нам</em>
    `
  },
  {
    id:'425',
    name: 'Круговорот событий',
    text: `
      <strong>К</strong>руговорот событий
      Каруселью
      Мчит нашу жизнь
      Меняя полюса
      Пренебрегая времени движеньем
      Застыла на щеке твоей слеза
      Могучим ветром беспощадной сути
      Рвет в клочья платье трепетной любви
      Безжалостно-холодный и колючий
      Взгляд глаз моих
      В глаза твоей Души
      Глубокая в бездоньи пониманий
      Занозы слов безропотно приняв
      Ты Мироточишь в торжестве страданий
      Познав весь Мир
      И в нём себя познав
    `
  },
  {
    id:'426',
    name: 'За смехом звонким пряча боль',
    text: `
      <strong>З</strong>а смехом звонким пряча боль
      До слёз смеётся
      Не смолкая
      <em>Она не смеет проявить желание от боли выть</em>
      Вот и смеётся
      Женщина смешная
    `
  },
  {
    id:'427',
    name: 'Как объяснить',
    text: `
      <strong>К</strong>ак объяснить
      Что я для Вас чужой
      Как Вам сказать
      Но так чтоб не обидеть
      Ах как Вы можете голубушка не видеть
      Что я чужой для Вас
      Чужой
      Нет нет
      Не Вы
      А я для Вас
      Примите это
      Согласитесь
      Ну ради Бога
      Приглядитесь
      Насколько я для Вас чужой
      Слезу утрите
      Вот платок
      Поверьте
      Я того не ст<strong>о</strong>ю
      Я не с повинной головою
      Открыл
      Насколько я жесток
      &nbsp;
      Прошу
      Забудьте Вы меня
      &nbsp;
      <em>Я умоляю Вас</em>
      <em>Любя</em>
    `
  },
  {
    id:'428',
    name: 'Не смотрите мне вслед',
    text: `
      <strong>Н</strong>е смотрите мне вслед
      И не жгите мне взглядами спину
      Не зовите меня
      Не пытайтесь меня удержать
      Ухожу
      И быть может вам время поможет
      Всё увидеть как есть
      И меня тем немного понять
      &nbsp;
      Не смотрите мне вслед
      Не царапайте взглядами спину
      Не швыряйте вдогонку отравленных слов
      Не жалея себя позабудьте пустую обиду
      <em>Я в себя ухожу</em>
      <em>Вам оставив на память Любовь</em>
    `
  },
  {
    id:'429',
    name: 'Полнолунное безумство',
    text: `
      <strong>П</strong>олнолунное безумство
      В горклом привкусе ночи
      Проявляют тень от света
      Полуночные ткачи
      Ткут полотна нитью ночи
      Ткут луну и звёзды ткут
      Ткут в разлёте дивном брови
      Ткут уста твои что лгут
      Ткут с неистовым упорством
      Песню света в темноте
      Ткут всё то
      Что даже ночью ясно и прозрачно мне
    `
  },
  {
    id:'430',
    name: 'Считаете я чёрств',
    text: `
      <strong>С</strong>читаете я чёрств
      А я лишь скуп в признаньях
      Что чувствую о том не говорю
      И Вам не дам понять коварное созданье
      Что я Вас всей Душой Боготворю
      Поймёте ль Вы меня
      <em>Боюсь что</em>&nbsp; <strong><em>да</em></strong>
      Увидев то что я немногословен
      Что молча наблюдать за Вами склонен
      И молча понимать что Вас прекрасней нет
      Поймёте ль Вы меня
      <em>Боюсь что</em>&nbsp;&nbsp; <strong><em>нет</em></strong>
    `
  },
  {
    id:'431',
    name: 'Словно мука через сито',
    text: `
      <strong>С</strong>ловно мук<strong>а</strong> через сито
      Сыплется пудра зимы
      Листья опавшие ею укрытые
      Смотрят зелёные сны
      Снится
      Как солнечный луч преломляясь
      В в<strong>е</strong>тках раскидистых крон
      Листочки весенние преображает
      <em>Ах как прекрасен сей сон</em>
      Снится
      Как ветер от страсти дрожащий
      Их обнимая целует в уста
      Снится
      Как искрами льётся на кудри
      Из облачка пышного чудо-вода
      Снится
      Как клён улыбаясь таинственно
      Шепчет на ушко берёзке слова
      И как у неё от его откровений
      Сок забурлил под одеждой ствола
      &nbsp;
      <em>Словно мук<strong>а</strong> через сито</em>
      <em>Сыплется пудра зимы</em>
      <em>Нельзя наделить зелень лета</em>
      <em>Мудростью жёлтой листвы</em>
      <em>Оставьте старанья</em>
      <em>Оставьте стенанья</em>
      <em>Оставьте весне мечты</em>
      <em>Живите спокойно в воспоминаньях</em>
      <em>Смотрите зелёные сны</em>
    `
  },
  {
    id:'432',
    name: 'Мы странные с тобой',
    text: `
      <strong><em>М</em></strong><em>ы странные с тобой</em>
      <em>Такие странные</em>
      Встречаемся
      Как будто невзначай
      А встречи эти
      И тобой и мной желанные
      Они в себе содержат радость и печаль
      <em>Мы странные с тобой </em>
      <em>Такие странные</em>
      Бредём по жизни всяк своим путём
      Встречаемся
      Молчим и улыбаемся
      В улыбке пряча думы о своём
      <em>Мы странные с тобой </em>
      <em>Такие странные</em>
      Мы встречу ждём
      А для чего не знаем
      И если наша встреча не случается
      Мы друг по другу искренне скучаем
      <em>Мы странные с тобой</em>
      <em>Такие странные</em>
      Нас ближе делают моменты расставания
      Без них не можем
      Ими наслаждаемся
      Они нам дарят чувства ожидания
      <em>Мы странные с тобой</em>
      <em>Такие странные</em>
      Не расставаться мы с тобой не можем
      Нам вместе быть с тобой не получается
      Я как и ты
      Своей Судьбой стреножен
      <em>Мы странные с тобой</em>
      <em>Такие странные</em>
      Считая то что судьбы наши схожи
      &nbsp;
      <em>А на снегу </em>
      <em>Горят рябины красные</em>
      <em>Они на кровь Души моей похожи</em>
    `
  },
  {
    id:'433',
    name: 'Ночь пересыпана звёздными блёстками',
    text: `
      <strong>Н</strong>очь пересыпана звёздными блёстками
      Ночь переполнена тайнами снов
      Ночь открывает просторы безбрежные
      Вечной весны под названьем Любовь
      &nbsp;
      Чувства как струны звучанием трепетным
      Грудь заполняют кипеньем Души
      Нам
      Утонувшим в вулкане желаний
      Счастье страдания дарят они
      &nbsp;
      Нежность рассвета румянцем смущения
      Щёки покрыла пришедшего дня
      И нежный ветер дыханием свежести
      Нам наполняет собой паруса
      Мы уплываем под парусом-облаком
      В дальние дали просторов Любви
      Где ожидают свиданьями страстными
      Вечность дарящие новые дни
      &nbsp;
      Ночь пересыпана звёздными блёстками
      Ночь переполнена тайнами снов
      Ночь открывает просторы безбрежные
      Вечной весны под названьем Любовь
    `
  },
  {
    id:'434',
    name: 'Откуда льётся этот Свет',
    text: `
      <strong>О</strong>ткуда льётся этот Свет
      И восхитительные Звуки
      В них свежесть утренней росы
      И глубина желанной м<strong>у</strong>ки
      В них есть мерцание свечей
      И з<strong>а</strong>пах лета
      Безумства завтрашних ночей
      И лёгкость ветра
      Журчанье горных ручейков
      И радость встречи
      Душой творимая Любовь
      И тихий вечер
      &nbsp;
      Вплетая&nbsp; музыку в слова
      Творю Молитву
      И направляю в небеса
      Я чувств палитру
      Я знаю тех кому послал
      Молитву-песню
      Они подарят новый день
      Ещё чудесней
      &nbsp;
      А он всё льется этот Свет
      И восхитительные звуки
      Они нам принесли рассвет
      В котором нет для нас разлуки
      Где ты в объятиях моих
      Как лучик счастья
      Где я отдал всего себя
      В твои объятья
      Где растворив нас в глубине
      Высокой тайны
      Обряд творит
      Небесный Свет и Хор Венчальный
      &nbsp;
      &nbsp;
      Как изумителен тот Свет
      Как восхитительны те звуки
    `
  },
  {
    id:'435',
    name: 'Плывут',
    text: `
      <strong>П</strong>лывут
      Едва земли касаясь
      К щеке щекою прикасаясь
      Плывут
      В объятиях Любви
      Нет никого
      Они одни
      Они одни во всей Вселенной
      В разлитом океане чувств
      И вальс
      Мелодией нетленной
      Влечёт их в высоту безумств
      Туда где нет понятья <em>время</em>
      Туда
      Где Вечностью живёт
      Любовь
      Величьем откровенья
      Где лишь одна она живёт
      &nbsp;
      Счастливцы
      Кто ж они такие
      Кто эти баловни Судьбы
      &nbsp;
      <em>А я их знаю</em>
      <em>Ими были</em>
      <em>И есть </em>
      <em>И будут</em>
      <em>Я и ТЫ</em>
      &nbsp;
    `
  },
  {
    id:'436',
    name: 'Под гипнозом медленного вальса',
    text: `
      <strong>П</strong>од гипнозом медленного вальса
      Растворившись в музыкальном сне
      Я шепчу о том что ты прекрасна
      Ты же танцем отдаёшься мне
      Глаз твоих великомудрые озёра
      Синеву небес вобравшие в себя
      Проявленьем любящего взора
      Помогают мне забыть себя
      В этот миг
      Круженья в лабиринте
      В лабиринте
      Внеземной дали
      Только ты одна
      Во всей Вселенной
      В платье цвета радужной зари
      Только ты
      Весенним настроеньем
      Наполняешь смелостью мечты
      Насыщаешь Душу вдохновеньем
      Даря понятье
      Вечной красоты
    `
  },
  {
    id:'437',
    name: 'Слова заполнили твой взор',
    text: `
      <strong>С</strong>лова заполнили твой взор
      Ты взглядом говоришь со мною
      При том
      Молчишь
      Причина в том
      Что ты испугана собою
      <em>Боишься ты саму себя</em>
      Тебя пугает солнца свет
      И потому
      На свой вопрос
      Самой себе не дашь ответ
      <em>Сама себе его не дашь</em>
      <em>Предпочитая состоянье</em>
      <em>В котором можешь удушить</em>
      <em>Своё неровное дыханье</em>
    `
  },
  {
    id:'438',
    name: 'Тонкая шея',
    text: `
      <strong>Т</strong>онкая шея
      Покатые плечи
      Кожа как бархат
      Взгляд как свеча
      Матово-бронзовым тоном загара
      Вечер берёзовый <strong>о</strong>бнял тебя
      Нежность улыбки в устах заблудилась
      Тронув собой лепестки твоих губ
      Знаю
      В тебя даже солнце влюбилось
      Небо влюбилось
      И всё что вокруг
      Знаю
      Тебе предлагали на выбор
      Кто изумруды
      Кто жемчуга
      Ты же прижав к груди крестик нательный
      Ввысь посмотрела
      И с нищим ушла
      &nbsp;
      <em>С тех пор уже пр<strong>о</strong>житы многие годы</em>
      <em>В любви и согласии нежность храня</em>
      <em>Нищий </em>
      <em>Безмерно красивой Душою</em>
      <em>С<strong>а</strong>мой богатой сделал тебя</em>
    `
  },
  {
    id:'439',
    name: 'Украдкою',
    text: `
      <strong>У</strong>крадкою
      От самого себя
      Ищу я повод что бы Вас увидеть
      Не дай мне Бог хоть чем-то Вас обидеть
      Украдкою от самого себя
      Я слышать Вас хочу
      Ваш голос словно бархат
      И речь как кружево
      Я Вами восхищён
      Украдкою
      Я Ваш вдыхаю запах
      Украдкой от себя
      Я в Вас мой друг
      Влюблён
      &nbsp;
       *
      &nbsp;
      (романс, музыка В.Фридмана)
    `
  },
  {
    id:'440',
    name: 'Шелест вечера',
    text: `
      <strong>Ш</strong>елест вечера
      Словно шёлк накидки
      Звёзды в озере
      Будто жемчуга
      Небо стелется
      Как туман вечерний
      Танцем огненным
      Кр<strong>у</strong>жит блик костра
      &nbsp;
      Ты прекрасная
      Словно песня дивная
      В пояс волосы
      Гривою коня
      В даль манящая
      И неумолимая
      Богом данная
      Как Судьба моя
    `
  },
  {
    id:'441',
    name: 'Я Вас прошу уйти',
    text: `
      <strong>Я </strong>Вас прошу уйти
      Остаться умоляю
      Как Ваш коварен взгляд
      Сколь искренен Ваш взор
      Без Вас я не живу
      И с Вами погибаю
      Вы самый щедрый дар
      И Вы мой приговор
      Я презираю Вас
      Я Вами восхищаюсь
      Вы лицемерный враг
      Вы мой надёжный друг
      И ненавидя Вас
      Я в Вас опять влюбляюсь
      Водоворот страстей выстраивая&nbsp; круг
      &nbsp;
      Я Вас прошу уйти
      Остаться умоляю
      &nbsp;
      *
      (романс, музыка В. Фридмана)
    `
  },
  {
    id:'442',
    name: 'Я сгораю в огне нашей странной любви',
    text: `
      <strong>Я </strong>сгораю в огне нашей странной любви
      Словно хворост горю
      <em>Разве этого Вы ни хотели</em>
      <strong><em>Пощадите меня мой любимый палач</em></strong>
      <strong><em>И не ставьте меня на колени</em></strong>
      &nbsp;
      Я хочу Вас забыть
      Потерять Вас хочу
      Отпустите
      Уйду
      Не губите
      <strong><em>Вы безмерно жестоки мой милый палач</em></strong>
      <strong><em>И любовью моей вовсе не дорожите</em></strong>
      &nbsp;
      Превращаясь в золу в безответном костре
      Ухожу навсегда
      <em>Знаю</em>
      <em>Этого Вы не хотели</em>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Поднимитесь с колен мой вчерашний палач</em></strong>
      <strong><em>В Вашей странной любви мои чувства сгорели</em></strong>
    `
  },
  {
    id:'443',
    name: 'Сняв оковы с Души закипела весна',
    text: `
      <strong>С</strong>няв оковы с Души закипела весна
      Бьются птицей на привязи майские ночи
      Где же <strong>вы</strong> цвета чистого синего неба глаза
      <em>Где же вы лучезарные очи</em>
      <em>&nbsp;</em>
      Я не в силах увидеть без вас красоту
      Я без вас не умею дышать и смеяться
      Я без вас не прозрею
      Без вас пропаду
      Я без вас не могу тем что есть восхищаться
      &nbsp;
      Отсмеялась пор<strong>а </strong>сладким стоном ночей
      Правит бал листопад под названием осень
      Душу рвёт шелест крыльев и плач журавлей
      Где&nbsp; же вы
      <em>Где же вы лучезарные очи&nbsp; </em>
    `
  },
  {
    id:'444',
    name: 'Острым взглядом',
    text: `
      <strong>О</strong>стрым взглядом
      Словно стрелой
      Что сорвалась с тетивы прищ<strong>у</strong>ра
      Притороченной к дугам бровей
      Будто к л<strong>у</strong>ку колдуна &ndash; вогула
      Не сразила наповал но подранила
      Дикой страстью обожгла словно пламенем
      Уложила где стоял словно пьяного
      И растаяла бесследно как марево
      &nbsp;
      <em>Где</em>
      <em>В каких Мирах</em>
      <em>На каких ветрах</em>
      <em>Ты явилась мне остроокая</em>
      <em>Не забыть тебя</em>
      <em>Как и встречи той</em>
      <em>Встречи <strong>той</strong> что от селе далёкая</em>
    `
  },
  {
    id:'445',
    name: 'Сложили золото в мешки',
    text: `
      <strong>С</strong>ложили золото в мешки
      И разбросали по бульвару
      <em>Не подобрали лучшей т<strong>а</strong>ры</em>
      <em>Сложили золото в мешки</em>
      Упаковали в целлофан
      Смиренье желтизны осенней
      <em>А сердце просит откровений</em>
      <em>Прощаясь с золотом листвы</em>
      Вуаль туманной пелены
      Прикрыла голую аллею
      <em>Я в эти дни собой болею</em>
      <em>Я болен памятью весны</em>
    `
  },
  {
    id:'446',
    name: 'Расплавленным золотом',
    text: `
      <strong>Р</strong>асплавленным золотом
      Поздняя осень
      Раскрасила всё
      <em>Кроме ёлок и сосен</em>
      Зарёй пламенеют рябины и клёны
      В красивости дерзкой они чемпионы
      Окрасился воздух оттенками цвета
      Прекрасного
      Нежного
      <em>Бабьего лета</em>
    `
  },
  {
    id:'447',
    name: 'Запуталось лучом закатным солнце',
    text: `
      <strong>З</strong>апуталось лучом закатным солнце
      В ветвях звенящих свежестью берёз
      Той свежестью
      Которую вечерний
      Уставший бегать ветерок принёс
      Той свежестью
      Что остужает ветви
      В которых заплутал прощальный луч
      Той свежестью
      Что распаляет чувства
      Которые навеивают грусть
    `
  },
  {
    id:'448',
    name: 'Дождь осенний рисует узоры на стёклах',
    text: `
      <strong>Д</strong>ождь осенний рисует узоры на стёклах
      Размывая собой память пр<strong>о</strong>житых дней
      Тонкой струйкой воды уплывают невзгоды
      В тонких струйках воды растворяется прошлого тень
      &nbsp;
      Как слеза по Душе бежит струйка осенней печали
      Как последний аккорд в череде мизансцен
      И уносит вода всё о чём я скучаю
      Промывая окно в зародившийся день
    `
  },
  {
    id:'449',
    name: 'Капают слезы осенним дождём',
    text: `
      <strong>К</strong>апают слезы осенним дождём
      Грусть и тоску навевают
      Мы в этот вечер с тобою вдвоём
      Шторы задвинем и свечи зажжём
      <em>Души они согревают</em>
      <em>&nbsp;</em>
      Пламенем стройным танцует свеча
      Сумрак ночи растворяя
      Время
      Собою сжигает свеча
      Время
      Свечу распаляет
      &nbsp;
      Блики и тени на потолке
      В странном свил<strong>и</strong>сь хороводе
      Нам хорошо в этом царстве свечей
      И позабыв о погоде
      Мы наблюдаем как плачет свеча
      Грусть восковую роняя
      Пламенем стройным целует себя
      Новое время рождая
    `
  },
  {
    id:'450',
    name: 'Неба низкого зябко дрожащая просинь',
    text: `
      <strong>Н</strong>еба низкого зябко дрожащая просинь
      Распростёрла объятья встречая всех тех
      Кто уходит из лета в прозрачную осень
      Оставляя за спинами время утех
      Обернуться
      Зачем
      Ведь нельзя возвратиться
      То что было прошло
      И уже не вернуть
      То что было когда-то
      Возможно когда-то приснится
      Через сон позволяя на&nbsp; прошлое время взглянуть
      &nbsp;
      Я не думал что может такое случится
      Я не думал что осень способна такое со мной сотворить
      Но она сотворила
      <em>В тебя мне позволив влюбиться</em>
      <em>Но при этом не дав то что было забыть</em>
    `
  },
  {
    id:'451',
    name: 'Осенняя память',
    text: `
      <strong>О</strong>сенняя память
      Листом опадающим
      Круж<strong>и</strong>тся под ноги покорно стелясь
      Прозрачность ветвей и восход затухающий
      Озябшим и голым рисуют пейзаж
      &nbsp;
      Но можно иначе почувствовать осень
      Увидев
      Как хрупкость прозрачных ветвей мне <strong>под</strong> ноги стелет
      Листом опадающим
      Осеннюю память
      Как счастлив я ей
      &nbsp;
      Осенняя память
      В ней спелость понятий не зрелой когда-то весенней любви
      Осенняя память зарею не гаснущей меня возвращает
      В то время
      Где
      <strong><em>Мы</em></strong>
    `
  },
  {
    id:'452',
    name: 'Промежуточным финишем',
    text: `
      <strong>П</strong>ромежуточным финишем
      Годовой эстафеты
      Осень
      Ветку сухую зиме передаст
      И стартует зима
      Засыпая собою кюветы
      Превращая грунтовку в утрамбованный наст
      &nbsp;
      <em>Но пока что ещё на дистанции осень</em>
      <em>Отчего то она свой замедлила бег</em>
      <em>Видно хочет продлить</em>
      <em>Буйством красок природы</em>
      <em>Догорающий листьями Век</em>
    `
  },
  {
    id:'453',
    name: 'Осенний взгляд',
    text: `
      <strong>О</strong>сенний взгляд
      Осенние слова
      Осенняя прохлада отношений
      Вдали остались летняя жара
      И фейерверк весенних возбуждений
      &nbsp;
      <em>Но всё не так как вы смогли понять</em>
      &nbsp;
      Осенняя прохлада
      <em>Это трезвость</em>
      Осенние слова
      <em>В них жизнь растворена</em>
      Осенний взгляд
      <em>Осознанная зрелость</em>
      <em>&nbsp;</em>
      И уж совсем не далеко зима
      Которая являя неизбежность
      Вморозит чувства в ледяную нежность
      И лишь затем
      Опять
      Придет весна
      &nbsp;
      <em>Тот кто пройдя сей долгий путь</em>
      <em>Смог ощутить в объёме состояний</em>
      <em>Печаль страстей и торжество страданий</em>
      <strong><em>Лишь тот способен в миг любой весну вернуть</em></strong>
    `
  },
  {
    id:'454',
    name: 'Прекрасная',
    text: `
      <strong><em>П</em></strong><em>рекрасная</em>
      <em>Любимая моя</em>
      То счастьем стонешь
      То печалью плачешь
      Горишь лампадкою
      Затем звездой сияешь
      То тихий вечер ты
      То звонкая заря
      <em>Прекрасная</em>
      <em>Любимая моя</em>
      &nbsp;
      Ты страстных чувств своих не прячешь
      То в бездну падаешь и тут же ввысь взмываешь
      И полнотой неистового чувства
      Мне открываешь степени безумства
      <em>Прекрасная</em>
      <em>Любимая моя</em>
      &nbsp;
      Ты
      Каждое прикосновенье
      Моих любовь дарящих рук
      Воспринимая с упоеньем
      Преобразовываешь в звук
      В звук
      Что собою услаждает
      Мой жизнью утомленный слух
      В звук
      Что ценней любого дара
      В звук
      Что мне даришь ты - гитара
      В тот звук что заполняет жизни круг
      <em>Прекрасная</em>
      <em>Любимая моя</em>
    `
  },
  {
    id:'455',
    name: 'Пожелтевший старый лист газетный',
    text: `
      <strong>П</strong>ожелтевший старый лист газетный
      Мятый
      Будто им в снежки играли
      На полях какие то заметки
      Наспех сделанные теми кто читали
      Мелким бисером исписаны межстрочья
      Содержащие другой взгляд на проблемы
      И совсем иное пониманье
      Излагаемой в листе газетном темы
      &nbsp;
      Этот лист газетный пожелтевший
      Я возьму
      И бережно разглажу
      Отутюжу все его морщинки
      Где подтёрся
      Там слегка подкрашу
      Где порвался
      Там его подклею
      Край что обтрепался
      Подровняю
      <em>Этот лист</em>
      <em>Как наши отношенья</em>
      <em>Я их тоже</em>
      <em>Как умею </em>
      <em>Сохраняю</em>
    `
  },
  {
    id:'456',
    name: 'Не нужно грязи в наших отношеньях',
    text: `
      <strong>Н</strong>е нужно <em>грязи</em> в наших отношеньях
      И без того достаточно её
      Не нужно лжи
      Не нужно оскорблений
      Того не нужно что придумаешь ещё
      &nbsp;
      Скажи открыто что тебя терзает
      Что не даёт тебе найти покой
      Открой мне Душу
      Знаю
      Полегчает
      Ведь я не враг
      Я друг надёжный твой
      &nbsp;
      <em>В себе замкнуться</em>
      Это не поможет
      <em>Уйти в себя</em>
      Чтоб вытащить на свет проблемы Суть
      Которая тревожит
      И осознать её что бы найти ответ
      <em>Другое дело</em>
      <em>Можно попытаться</em>
      Мы делали с тобою так не раз
      Но видно вновь пора настала убеждаться
      <em>В том что мелки они</em>
      <em>Чтоб разлучили нас</em>
    `
  },
  {
    id:'457',
    name: 'Интересно получается',
    text: `
      <strong><em>И</em></strong><em>нтересно получается</em>
      Мы вдвоём а будто врозь
      Разговоры словно ссоры
      Слово каждое как гвоздь
      &nbsp;
      Нам теперь и не припомнить
      Как всё это началось
      Но выходит всё не складно
      Друг на друга копим злость
      &nbsp;
      <em>Интересно получается</em>
      Ведь у нас была любовь
      А сегодня отношенья замораживают кровь
      <em>Интересно получается</em>
      Лишь вчера была весна
      А сегодня закружила стужей лютою зима
      Неожиданностью вьюжной заморожено тепло
      И звенит продрогший воздух как разбитое стекло
      &nbsp;
      <em>Интересно проявляется факторов взаимосвязь</em>
      <strong><em>Если бы ни наша ссора то зима б не началась</em></strong>
    `
  },
  {
    id:'458',
    name: 'Волнуюсь',
    text: `
      <strong>В</strong>олнуюсь
      Словно перед первой встречей
      Как будто вскоре тот наступит час
      В который свет потушен
      Только свечи
      И музыка небес звучит для нас
      &nbsp;
      Переживаю
      Трепещу волненьем
      Не знаю как начать наш разговор
      А ты была спокойна
      Без смятенья
      Ключ положила
      Вышла в коридор
      &nbsp;
      &nbsp;
      <em>Я был один</em>
      <em>Я слушал тишину</em>
      <em>А тишина хрипела и кричала</em>
      <em>Я б многое отдал чтоб всё начать сначала</em>
      <em>Что бы мгновенье первое вернуть</em>
    `
  },
  {
    id:'459',
    name: 'Когда бы не хватило сил',
    text: `
      <strong>Когда бы</strong> не хватило сил
      Упрёку твоему дать о себя разбиться
      <strong>Тогда бы</strong>
      Я не знаю что
      В ответ могло б случиться
      &nbsp;
      <strong>Когда бы</strong> не хватило сил
      Принять спокойно похвалу
      <strong>Тогда бы</strong>
      Я не смог понять
      Что всё же мудрым быть могу
      &nbsp;
      <strong>Когда бы</strong> не хватило сил
      Понять тебя чрез ход событий
      <strong>Тогда бы</strong>
      Я не получил
      Возможность собственных открытий
      &nbsp;
      <strong>Тогда бы</strong>
      Не хватило сил
      Увидеть истинность твою
      <strong>Тогда бы</strong>
      О себя разбиться не дал упрёку твоему
      &nbsp;
      <strong>Тогда бы</strong>
      Не хватило сил
      Упрёку твоему дать о себя разбиться
      <strong>Тогда бы</strong>
      Я не знаю что в ответ могло б случиться
    `
  },
  {
    id:'460',
    name: 'Так неумело лгать',
    text: `
      <strong>Т</strong>ак неумело лгать
      Лишь ты умела
      Стараясь убедить краснела и бледнела
      Взгляд
      Словно обжигаясь о слова
      Метался в поисках спасенья
      Душа
      Желая утешенья
      Ждала
      Когда же ты поймешь
      Что лишь самой себе ты лжешь
      А ты была наивна и смешна
      Рассказывала сбивчиво сумбурно
      Затем
      От этого
      Тебе бывало дурно
      А я тебя
      Как помнится
      Жалел
      Не говоря с тобой <strong>о том</strong> о чём хотел
      Сочувствовал тебе
      Лукавое созданье
      Прощаясь не прощай сказал
      А до свиданья
      &nbsp;
      Промчалось время
      Ты поднаторела
      Теперь уже совсем другое дело
      Ты лжёшь изыскано
      Нисколько не смущаясь
      Во лжи своей как в роднике купаясь
      &nbsp;
      Ты лжёшь всю жизнь
      И это твое право
      Ты лжёшь налево
      Ты лжёшь направо
      &nbsp;
      А плачешь искренне
      Когда одна
      <em>Ты так устала</em>
      <em>От самой себя</em>
    `
  },
  {
    id:'461',
    name: 'Разворовала по частям',
    text: `
      <strong>Р</strong>азворовала по частям
      Всё вперемешку
      Душу
      Тело
      Вела себя бесстыдно смело
      Меня воруя по частям
      Была застигнута врасплох
      Лукавству не было предела
      Во мне ж в ту пору жизнь лишь тлела
      Я был бессмысленностью плох
      Но всё - таки хватило сил
      Вернуть что мне принадлежало
      Ты этого не ожидала
      <em>Проворовалась</em>
      <em>Видит Бог</em>
      &nbsp;
    `
  },
  {
    id:'462',
    name: 'Ах сколько нелюбви ко мне',
    text: `
      <strong>А</strong>х сколько нелюбви ко мне
      Твоя любовь к себе содержит
      Она мою любовь к тебе на мелкие кусочки режет
      Затем укладывает их вминая в мякиш дней
      Готовя сложный бутерброд для нелюбви твоей
      &nbsp;
      <em>Так было не недели</em>
      <em>Годы</em>
      <em>Твои не мякиш дни теперь а сухари</em>
      <em>Колючие</em>
      <em>Со вкусом боли</em>
      <em>Моей</em>
      <em>Тобой шинкованной любви</em>
    `
  },
  {
    id:'463',
    name: 'Экстравагантностью',
    text: `
      <strong>Э</strong>кстравагантностью
      Стремленьем удивлять
      Ты образ свой несёшь
      Словно бокал хрустальный
      Но он увы весьма не идеальный
      А ты не в силах этого понять
      &nbsp;
      Самооценка высока безмерно
      По той причине и здоровье скверно
      И одинока ты
      До той поры
      Пока себя ты ни оценишь верно
      &nbsp;
      В противном случае
      Всё будет так как есть
      В страданьи будешь жить
      Считая правдой лесть
      &nbsp;
      Но ты свободна в выборе себя
      Ты хочешь быть такой
      Ну что же будь
      Бог даст
      Поймёшь и ты когда нибудь
      <em>Что фантики не отражают Сут</em>
    `
  },
  {
    id:'464',
    name: 'Ты струны чувств моих терзаешь перебором',
    text: `
      <strong>Т</strong>ы струны чувств моих терзаешь <em>перебором</em>
      И наслаждаешься жестокою игрой
      Они уж не звучат а дребезжат <em>подбором</em>
      А ты всё щиплешь их холодною рукой
      &nbsp;
      Ответь мне
      <em>Для чего</em>
      <em>Кому всё это нужно</em>
      <em>Зачем пытаться выстроить аккорд</em>
      <strong><em>Когда нет слуха</em></strong><em> <strong>это невозможно</strong></em>
      <em>Пусть даже знаешь ты как пишутся семь нот</em>
      &nbsp;
      &nbsp;
      Поэтому прощай
      Мне ни к чему всё это
      <em>Я струны подтяну</em>
      <em>И выправлю лады</em>
      <em>И пусть они звучат</em>
      <em>Рождая звуки Света</em>
      Но этой чистоты уж не услышишь ты
    `
  },
  {
    id:'465',
    name: 'Сжигая за собой мосты',
    text: `
      <strong><em>С</em></strong><em>жигая за собой мосты</em>
      Не сможешь ты назад вернуться
      Как и не сможешь улыбнуться
      Когда придётся обернуться
      &nbsp;
      <em>Придёт тому черёд </em>
      <em>Поверь</em>
      И на душе печально станет
      Когда увидишь за собой
      Любовь сожжённую тобой
      &nbsp;
      <em>Нет</em>
      <em>Ты не понял</em>
      Там живут
      Живут в надежде в вере в счастье
      Для них прошла пора напастей
      Они в другой Любви живут
      &nbsp;
      Ты <em>сжёг мосты</em> лишь для себя
      Себя казнив невозвращеньем
      Туда откуда ты ушёл
      Не позаботясь о прощенье
      &nbsp;
      <em>Сжигая за собой мосты</em>
      Не сможешь ты назад вернуться
      Как и не сможешь улыбнуться
      Когда придётся обернуться
      &nbsp;
      <em>Придёт тому черёд</em>
      <em>Поверь</em>
    `
  },
  {
    id:'466',
    name: 'Боюсь себе признаться в том',
    text: `
      <strong>Б</strong>оюсь себе признаться <strong>в том</strong>
      <em>Что я боюсь себе признаться</em>
      <strong>В том</strong>
      <em>Что устал с собой сражаться</em>
      <em>И сердце уж покрылось льдом</em>
      &nbsp;
      А прежде
      Мы с тобой вдвоём
      Стараясь строили наш дом
      Мы много лет прожили в нём
      И не жалеем о былом
      &nbsp;
      А нынче зябко в доме стало
      Ты тоже <em>вижу я&nbsp; у</em>стала
      Сражаешься сама с собой
      Так и живём
      <em>Что день - то бой</em>
      &nbsp;
      Нет сил
      Чтоб всё начать сначала
      Нет сил чтоб это продолжать
      Что делать
      <em>Если б только знать</em>
      &nbsp;
      А ларчик просто открывается
      Причина в том
      <em>Что все сражаются</em>
      <em>А победить не получается</em>
      <em>И каждый этим раздражается</em>
      &nbsp;
      А жили бы с собой в ладу
      Проблемы были б на виду
      Коль на виду
      Легко решать
      <em>Не жизнь была б а благодать</em>
      <em>&nbsp;</em>
      <em>А для того</em>
      <em>Нелишне знать</em>
      <em>Что нужно лишь всего</em>
      <strong><em>Себя&nbsp; друг в друге отыскать</em></strong>
      <strong><em>И станет вновь тепло</em></strong>
    `
  },
  {
    id:'467',
    name: 'Ах Ангел мой',
    text: `
      <strong>А</strong>х Ангел мой
      Архангел белокрылый
      Тобой любуюсь
      Ты явился мне
      Возник передо мной твой образ милый
      Он проявился в неба синеве
      &nbsp;
      Я звал тебя
      И ты меня услышал
      Я счастлив
      Этим
      Я благословлён
      Мы как и прежде
      В неразрывной связи
      С Истока незапамятных времён
    `
  },
  {
    id:'468',
    name: 'В оперенье трепещущем крылья',
    text: `
      <strong>В</strong> оперенье трепещущем крылья
      Белым взмахом возносят меня
      В Мир в котором нет места насилью
      В Мир сияющий вечностью дня
      &nbsp;
      <em>Растворяясь в глубинах простора</em>
      <em>Полнотой открываемых чувств</em>
      <em>Чувств в которых нет места раздору</em>
      <em>Я частичкой всех вас становлюсь</em>
      &nbsp;
      В оперенье трепещущем крылья
      Белым взмахом меня вознесли
      В Мир творящего Чудо Всесилья
      В Мир Высокой и Вечной Любви
    `
  },
  {
    id:'469',
    name: 'Зимой',
    text: `
      <strong>З</strong>имой
      Когда сугробы правили свой бал
      А вьюга остужала чувства и желанья
      Я повстречал её
      И в тот же миг узнал
      <em>Она </em>
      <em>Весна Душевного сиянья</em>
      &nbsp;
      С тех пор мы вместе с ней
      И летом и зимой
      Со мной моя весна и осенью прозрачной
      &nbsp;
      А как ин<strong>а</strong>че
      <em>Ведь она &nbsp;моя жена</em>
      <em>В прошедшем</em>
      <em>В будущем</em>
      <em>И в настоящем</em>
    `
  },
  {
    id:'470',
    name: 'Я давно ей не говорил',
    text: `
      <strong>Я</strong> давно ей не говорил
      <em>Что люблю её как и прежде</em>
      Я давно ей не говорил
      <em>Что живу как мальчишка</em>
      <em>В надежде</em>
      Я давно ей не говорил
      <em>Слов о том как она прекрасна</em>
      Я давно ей не говорил
      <em>То что ей</em>
      <em>И без слов моих ясно</em>
    `
  },
  {
    id:'471',
    name: 'Отзывчивая',
    text: `
      <strong>О</strong>тзывчивая
      Кроткая
      Милая
      Весёлая
      Печальная
      Смешная
      Заботливая
      Умная
      Красивая
      <strong>Я так хочу чтоб ты была такая</strong>
      &nbsp;
      <em>Не дарю тебе душистую розу</em>
      <em>Я дарю тебе ромашек охапку</em>
      <em>А ещё дарю цветок незабудки</em>
      <em>Ну а ты мне</em>
      <em>Свет и радость в достатке</em>
    `
  },
  {
    id:'472',
    name: 'Ровное глубокое дыхание',
    text: `
      <strong>Р</strong>овное глубокое дыхание
      Еле слышится в предутренней тиши
      Стёрты сном с лица следы страданий
      Ты так красива <em>хоть портрет пиши</em>
      Только как суметь как умудриться
      Выплеснуть на холст душевность чувств
      <strong>Тех</strong> что мне позволили влюбиться
      В плавность линий <em>лжи не знавших уст</em>
      В завитки светловолосых прядей
      Разметавшихся по ласковой подушке
      <em>Той которой сны ты доверяешь</em>
      <em>Как далёких детских дней подружке</em>
      Как возможно было ни влюбиться
      В оперенье трепетных ресниц
      <strong><em>Тех</em></strong><em> что будто дивные жар-птицы</em>
      <em>Вверх взмывая украшают высь</em>
      &nbsp;
      Спи моя родная
      Не тревожься
      Спи голубушка
      Я сон постерегу
      Спи моя прекрасная как солнце
      Скоро свет прольет себя в оконце
      Спи любимая
      <em>Я рядом посижу</em>
    `
  },
  {
    id:'473',
    name: 'Морщинки вокруг глаз',
    text: `
      <strong>М</strong>орщинки вокруг глаз
      Я их любя цел<strong>у</strong>ю
      Родные
      Милые
      Морщинки вокруг глаз
      Я в жизни не встречал красивую такую
      Какой была ты прежде и сейчас
      Морщинки вокруг глаз
      Улыбку затаили
      И мудрость и надёжность и Любовь
      Морщинки вокруг глаз
      Мне счастье подарили
      Покой и нежность и мечты полёт
      Морщинки вокруг глаз
      Я их любя цел<strong>у</strong>ю
      О том наверно странно говорить
      Но я тебя по-прежнему ревную
      <em>И слава Богу</em>
      <em>И должно так быть</em>
    `
  },
  {
    id:'474',
    name: 'Нам с тобой приснился сон',
    text: `
      <strong>Н</strong>ам с тобой приснился сон
      На двоих один
      Будто вышли из огня
      Каждый невредим
      Из огня моей любви
      Что была костром
      А горели в том костре
      Мы с тобой вдвоём
      &nbsp;
      Дождь не в силах был костра пламя загасить
      Снег не в силах был костра пламя остудить
      Ветер раздувал его добавляя жар
      Это даже не костер
      Это был пожар
      &nbsp;
      Много лет прошло с тех пор
      Наш пожар угас
      Но тепло до сей поры согревает нас
      &nbsp;
      Дети
      Искру подобрав
      Развели костёр
      Он для них сейчас горит
      Рвётся на простор
      &nbsp;
      Рядышком с костром детей
      Не замёрзнуть нам
      <em>Ведь у нас</em>
      <em>Как прежде сны</em>
      <em>Сны </em>
      <em>Напополам</em>
    `
  },
  {
    id:'475',
    name: 'Мириады Галактик',
    text: `
      <strong>М</strong>ириады Галактик
      Бесконечность начал
      В колыбели сегодня я ребёнка качал
      Подрастай мой хороший
      Свою память буди
      Здесь
      На этой Планете нам отпущены дни
      Здесь твоё продолженье
      Продолженье начал
      И Душой обнимая я ребёнка качал
      Подрастай мой любимый
      Подрастай не спеша
      Только так можно вспомнить что такое Душа
      Только так можно вспомнить что такое Любовь
      Только так можно вспомнить что родился ты вновь
    `
  },
  {
    id:'476',
    name: 'Когда в тебе затеплилась иная',
    text: `
      <strong>К</strong>огда в тебе затеплилась иная
      Дарованная Небом жизнь
      Ты не имеешь права жить не понимая
      Того
      Что жизнью правит <strong>мысль</strong>
      Того
      Что ты являешься отныне
      Хранима Высшей Сферой Высших Сил
      Того
      Что ты являешься Богиней
      В понятьи тех кто путь определил
      &nbsp;
      Путь твой и путь того чья жизнь в тебе
      Не легкий путь но истинно природный
      Прими его своей Душой свободной
      <em>И будь уверена</em>
      <strong><em>На это хватит сил</em></strong>
    `
  },
  {
    id:'477',
    name: 'Томно синь пастельная обнимает думою',
    text: `
      <strong>Т</strong>омно синь пастельная обнимает думою
      Нежностью безбрежною я тебя укутаю
      <em>Под головку облачко</em>
      <em>Перьевое ясное</em>
      <em>Ветерочком песенку </em>
      <strong><em>Спи дитя прекрасное</em></strong>
      <em>Покрывальце лёгкое </em>
      <em>Междузвездьем тканное</em>
      <em>Положу на ноженьки </em>
      <strong><em>Спи дитя желанное</em></strong>
      &nbsp;
      Звёздочка небесная спой песню колыбельную
      Дай глоток небесности
      Света запредельного
      Лунностью золоченной ему дорожку вымости
      Запахами нежными ему постельку выстели
      Радостью открытия разбуди Сознанье
      <em>Чтоб при встрече с жизнью было Восклицанье</em>
      И наполни Душу Мудростью Вселенской
      Ощущеньем Мира
      Правды Вознесенской
      И тихонько бережно не смешав понятья
      Опусти на Землю
      <em>Прям в мои объятья</em>
    `
  },
  {
    id:'478',
    name: 'Толкается',
    text: `
      <strong>Т</strong>олкается
      <em>Знать о себе даёт</em>
      Пихается
      <em>Конечно тесновато</em>
      Он так со мною говорит
      Мол <em>выпускай</em>
      Нет милый
      <em>Рановато</em>
      &nbsp;
      Спина болит
      <em>Как будто вбили кол</em>
      Боль изнуряет
      Не дает покоя
      И нервы столь напряжены
      <em>Как будто бы обнажены</em>
      И состоянье в целом <em>никакое</em>
      &nbsp;
      Усталость накопилась
      Вместе с тем
      Крепчает боль обида раздраженье
      И ничего на свете нет сильней желанья
      <em>Съесть селедку и варенье</em>
      &nbsp;
      Меня ты&nbsp; утешаешь кое-как
      Но все слова меня лишь возмущают
      <em>Как смеешь ты мне говорить о том</em>
      <em>что мои формы всех буквально восхищают</em>
      <em>Что ничего прекрасней в мире нет</em>
      <em>чем женщина беременная в счастье</em>
      <em>Что лучшее в природе это <strong>я</strong></em>
      <em>И то что <strong>ты</strong> всецело в моей власти</em>
      <em>Мерзавец лиходей подлец нахал</em>
      <em>И эгоист конечно </em>
      <em>Это ясно</em>
      <em>Как жизнь свою могла с тобой связать</em>
      <em>С тобою жить достаточно опасно</em>
      Затем без перехода
      <em>Милый мой</em>
      <em>Любимый славный самый чистый</em>
      <em>Ты мой единственный ты мой родной</em>
    `
  },
  {
    id:'479',
    name: 'Колыбельных песен много',
    text: `
      <strong><em>Моей внученьке Полинушке &nbsp;посвящается, и в её лице</em></strong>
      <strong><em>всем детям, внукам и правнукам нашего Мира</em></strong>
      <strong><em>и всей Вселенной. </em></strong>
      &nbsp;
      &nbsp;
      <strong>К</strong>олыбельных песен много
      <em>Б<strong>а</strong>юшки &ndash; ба<strong>ю</strong></em>
      Для тебя <strong>мой лучик света</strong> я спою свою
      &nbsp;
      Спи мой птенчик
      Спи родной мой
      Глазки закрывай
      <em>Б<strong>а</strong>ю &ndash; б<strong>а</strong>ю</em>
      <em>Б<strong>а</strong>ю &ndash; б<strong>а</strong>ю</em>
      <em>Б<strong>аю</strong>-бай</em>
      Спи мой носик
      Спи мой ротик
      Ласточка моя
      Ты прекрасней всех на свете
      Я люблю тебя
      Ты мой славный
      Ты мой милый
      Главный человек
      Мы с тобой ещё увидим белый снег
      И увидим зелень лета
      И красу весны
      Мы с&nbsp; тобой ещё увидим жёлтые кусты
      Спи мой птенчик
      Спи родной мой
      Глазки закрывай
      <em>Б<strong>а</strong>ю &ndash; б<strong>а</strong>ю</em>
      <em>Б<strong>а</strong>ю &ndash; б<strong>а</strong>ю</em>
      <em>Б<strong>аю</strong>-бай</em>
      Ты всех лучше и прекрасней
      <em>Ненаглядный &nbsp;мой</em>
      Ни о чём не беспокойся
      Я с тобой
      Ни каким на свете силам нас не разлучить
      Мы с тобой в красивом мире будем жить
      Спи мой птенчик
      Спи родной мой
      Глазки закрывай
      <em>Б<strong>а</strong>ю &ndash; б<strong>а</strong>ю</em>
      <em>Б<strong>а</strong>ю &ndash; б<strong>а</strong>ю</em>
      <em>Б<strong>аю</strong>-бай</em>
      Колыбельных песен много
      <em>Б<strong>а</strong>юшки &ndash; ба<strong>ю</strong></em>
      Для тебя мой лучик света
      Я пропел свою
    `
  },
  {
    id:'480',
    name: 'Слеза',
    text: `
      <strong>С</strong>леза
      Слезинка
      И опять слеза
      Твоими слёзками рыдают небеса
      Но вот коснулась губ твоих улыбка
      И небо стало в тоже время зыбко
      Ресницы вверх взметнула
      <strong>О</strong>жил ветер
      И небосвод опять глубок и светел
      &nbsp;
      <em>Прошу тебя</em>
      <em>Не нужно больше плакать</em>
      <em>Мне надоела за окошком слякоть</em>
    `
  },
  {
    id:'481',
    name: 'Зыбким облаком пушатся',
    text: `
      <strong>З</strong>ыбким облаком пушатся
      Мысли
      Чувства и слова
      И на белый лист ложатся
      Рифмой
      Нового стиха
    `
  },
  {
    id:'482',
    name: 'А если б родился не я',
    text: `
      <strong>А</strong> если б родилс<strong>я</strong> не я
      А если б был рождён другой
      Каким бы он был
      Как и я
      Иль был бы он совсем другой
      &nbsp;
      <em>Не знаю</em>
      <em>Может как и ты</em>
      <em>А может быть совсем другой</em>
      &nbsp;
      Тогда ответь
      А коль другой
      Любим бы был тобой
      &nbsp;
      <em>Конечно я б его любил</em>
      &nbsp;
      А больше чем меня
      <em>&nbsp;</em>
      <em>Вопрос дурацкий</em>
      <em>Милый сын</em>
      <em>Ты рассердил меня</em>
      &nbsp;
      А если б <strong>он </strong>его задал
      Ты б тоже осерчал
      <em>&nbsp;</em>
      <em>Наверное</em>
      Ответил я
      <em>И горько замолчал</em>
      <em>&nbsp;</em>
      <em>Я замолчал не оттого</em>
      <em>Что я не знал ответ</em>
      <em>А от того</em>
      <em>Что у сынка</em>
      <em>Увы</em>
      <em>Братишки нет</em>
    `
  },
  {
    id:'483',
    name: 'Ох как бы я тебя обнял',
    text: `
      <strong>О</strong>х как бы я тебя обн<strong>я</strong>л
      Прижав к груди
      Тебя б ласкал
      Я б з<strong>а</strong>пах твой вдыхал в себя
      Ах как бы я любил тебя
      Я б Душу растворил в тебе
      Я б дал тебе всё чем владею
      И делал бы всё что умею
      Я бы учил тебя смеяться
      Я научил бы не бояться
      Учил бы Светлым быть Душой
      Высоким быть полётом мысли
      Учил бы быть самим собой
      И ты б обрел себя со мной
      <em>Ребёнок</em>
      <em>Не рождённый мой</em>
    `
  },
  {
    id:'484',
    name: 'Бедою ливневой бушует небосвод',
    text: `
      <strong>Б</strong>едою ливневой бушует небосвод
      И по щекам твоим струятся слёзы
      Не плачь родная
      Поутихнут грозы
      И распушатся грёзы вновь
      &nbsp;
      Ты хочешь знать на вкус Любовь
      А <strong>что</strong> она
      Пока не знаешь
      Пока ещё не понимаешь
      Что разновкусная Любовь
      &nbsp;
      Есть слаще мёда
      Есть горчит
      Есть вкуса слёз
      Есть вкуса наслажденья
      Поверь мне
      Ты её ещё найдёшь
      <em>Сегодня </em>
      <em>Только </em>
      <em>Твой десятый </em>
      <em>День </em>
      <em>Рожденья</em>
    `
  },
  {
    id:'485',
    name: 'Как только тебя вспомнил',
    text: `
      <strong>К</strong>ак только тебя вспомнил
      Дождь заплакал
      Гром громыхнул
      А на Душе покой
      Ещё бы
      Жизнь прошла с того мгновенья
      Когда расстались мы с тобой
      &nbsp;
      Как ты жила все эти годы я не знаю
      И ты не знаешь как прож<strong>и</strong>л их я
      Увидевшись конечно не узнаем
      Ни ты меня ни я тебя
      &nbsp;
      Но память
      Эту страшную картину
      Прокручивает будто бы кино
      В котором взгляд твой мне прожигает спину
      <em>Бог мой</em>
      <em>Как это было всё давно</em>
      <em>&nbsp;</em>
      Я знаю
      Ты конечно же простила
      Я сам себя тогда не понимал
      <em>Ты </em>
      <em>Просто мальчика конфеткой угостила</em>
      <em>А я <strong>за то</strong></em>
      <em>Твои куличики топтал</em>
    `
  },
  {
    id:'486',
    name: 'Лепестками кружатся снежинки',
    text: `
      <strong>Л</strong>епестками кр<strong>у</strong>жатся снежинки
      Чистотой рисуя белизну
      Нежность с грустью
      Побрели в обнимку
      В памятью забытую Страну
      &nbsp;
      В ту Страну мы ходим ненад<strong>о</strong>лго
      В той Стране прекрасен каждый час
      В той Стране
      Пушистая
      В иголках
      Обнимает ёлка детства нас
      В той Стране
      Где мама молодая
      Духом пирогов наполнен дом
      Дом
      В котором счастья выше края
      Где живём
      Меж явью и меж сном
      &nbsp;
      В доме том всё было настоящим
      Ёлка
      Чувства
      Время
      И Любовь
      &nbsp;
      <em>Неужели это проходящее</em>
      <em>Неужели не вернется вновь</em>
    `
  },
  {
    id:'487',
    name: 'Мой младшенький',
    text: `
      <strong>М</strong>ой младшенький
      Поскрёбыш ненаглядный
      <em>Твердила мать</em>
      <em>Прижав к себе дитя</em>
      В том нижнем ящичке лежит твоя пелёнка
      Которой пеленала я тебя
      С тех самых пор
      До этого мгновенья
      Прошло совсем ещё немного лет
      А ты мой маленький
      Голубчик лучезарный
      Каким то образом стал совершенно сед
      Я знаю милый
      Трудною дорогой
      Ты прошагал с рожденья <em>до сейчас</em>
      И я жила наполненной тревогой
      Душа тобой болела каждый час
      Я знаю мой родимый
      Ой как знаю
      Как нелегко даётся эта жизнь
      Я знаю мой сыночек
      Знаю
      Знаю
      Присядь поближе и щекой прижмись
      Я заберу своей Душою горечь
      С Души твоей
      Мой нежный мой родной
      Отступит прочь печаль твоя
      Сыночек
      Я растворю её и отведу рукой
      &nbsp;
      <em>И сын уснул щекой к щеке прижавшись</em>
      <em>Вдыхая материнское тепло</em>
      <em>Всю жизнь бы так проспал</em>
      <em>Не просыпаясь</em>
      <em>Но вот уж снова за окном светло</em>
      &nbsp;
      Глаза открыл
      Но сам не шелохнулся
      Не шелохнулась старенькая мать
      Она могла б наверное столетье
      Своей щекою щеку подпирать
      &nbsp;
      И ничего что онемели ноги
      И ничего что затекла спина
      И ничего что сердцу очень больно
      Не первый раз
      Пройдёт
      <em>Так думала она</em>
      <em>&nbsp;</em>
      Вот только бы сынишка не увидел
      Вот только бы не кинулся жалеть
      Что если вдруг с собой не совладаю
      Не удержусь
      Да как начну реветь
      Нет
      Этого позволить невозможно
      Не покажу ему
      Как я слаба
      &nbsp;
      Вставай сынок
      <em>Сказала осторожно</em>
      Вставай любимый
      Чайник ставлю я
    `
  },
  {
    id:'488',
    name: 'Кто-то вдруг повзрослел',
    text: `
      <strong>К</strong>то-то вдруг повзрослел
      <em>Не заметив того</em>
      Кто-то вдруг постарел
      <em>Поседело чело</em>
      Кто-то вдруг засмеялся
      <em>И счастье вокруг</em>
      Кто-то вдруг погрустнел
      <em>Не придёт больше друг</em>
      Кто-то вдруг закричал
      <em>Сколько солнца смотри</em>
      Кто-то вдруг простонал
      <em>Бесконечные дни</em>
      &nbsp;
      <strong>Кто-то памятью жив</strong>
      <strong><em>Созерцаньем былого</em></strong>
      <strong>Кто-то смотрит вперед</strong>
      <strong><em>Не имея иного</em></strong>
    `
  },
  {
    id:'489',
    name: 'Плачет небо осенними брызгами',
    text: `
      <strong>П</strong>лачет небо осенними брызгами
      Барабаня по крыше соломенной
      С головой в одеяло укутавшись
      Я лежу неподвижной колодиной
      И ни то чтобы встать
      Шевелиться
      Нет ни сил у меня не желания
      Вроде как и не сплю только снится
      Встречи день и момент расставания
      &nbsp;
      Плачет небо осенними брызгами
      Сердце вторит дождю
      Так уж водится
      <em>Ничего</em>
      <em>Дождь пройдёт</em>
      <em>Рассияется</em>
      <em>А там глядишь&nbsp; на Душе распогодится</em>
    `
  },
  {
    id:'490',
    name: 'Берёзовая роща под горой',
    text: `
      <strong>Б</strong>ерёзовая роща под горой
      Ручей бежит
      Песчинками играет
      И молодой
      Нахальный ветерок
      Листву берёз собой перебирает
      Пьянящий воздух
      Ароматом трав
      Дурманит
      Насыщает и волнует
      В предутренний
      Благословенный час
      Глухарь
      В туманном молоке токует
      Укрытая росою спит трава
      Своё досматривая сновиденье
      Вдруг смолкло всё
      <em>Ручей</em>
      <em>Берёз листва</em>
      <em>И прекратил глухарь своё моленье</em>
      <em>Остановило время свой полёт</em>
      <em>И сердце будто биться перестало</em>
      Мгновеньем
      Абсолютной тишины
      Рождалось жизни
      Новое
      Начало
    `
  },
  {
    id:'491',
    name: 'Прильнув лицом к весеннему цветку',
    text: `
      <strong>П</strong>рильнув лицом к весеннему цветку
      Впитай прозрачность чистоты
      К росе губами прикоснись
      И в дымке ранней растворись
      Оттенком нежным ветерка
      Травинку каждую целуй
      И мягкокрылость мотылька
      Прочувствуй и Душой ликуй
      &nbsp;
      <em>Воздушность вспененных берез</em>
      <em>Что веточками небо гладят</em>
      <em>Рождая переливы грёз </em>
      <em>Измятость чувств твоих разгладят</em>
      &nbsp;
      Вселяя в Душу новизну
      Воспетую сияньем утра
      Лаская неба синеву
      Волшебностью Святого чувства
      Ты обретаешь себя вновь
      <em>И сердца нежная Любовь </em>
      <em>Откроет путь тебе туда</em>
      <em>Где всё Едино навсегда</em>
      <em>Где спелость солнечного дня </em>
      <em>Возможность даст познать себя</em>
    `
  },
  {
    id:'492',
    name: 'Стремленье любить',
    text: `
      <strong>С</strong>тремленье любить
      Ничего не решает
      Пожалуй
      Любви оно только мешает
      &nbsp;
      Любовь не придет
      Сколь её ни зови
      И будешь влачить без неё свои дни
      &nbsp;
      Но может прийти
      Уж когда и не ждёшь
      Когда стал седой
      И когда не зовёшь
      &nbsp;
      Придёт как пожар
      Придёт как вулкан
      И это не будет самообман
      &nbsp;
      Любовь
      Выбирает сама с кем ей быть
      Кого неземной красотой одарить
      &nbsp;
      Любовь не обманешь
      Ей ведомо всё
      Не знает
      Пожалуй - что только одно
      Не знает Любовь
      <em>Что такое Любовь</em>
      &nbsp;
      Ты ей расскажи
      Если встретишься вновь
    `
  },
  {
    id:'493',
    name: 'Открыл в себе способность',
    text: `
      <strong>О</strong>ткрыл в себе способность
      <em>Чудеса</em>
      <strong>Я невидимкой был сегодня полчаса</strong>
      &nbsp;
      Я так старался ей попасться на глаза
      <em>Как мог старался</em>
      Целых долгих полчас<strong>а</strong>
      &nbsp;
      Она ж смотрела сквозь меня и улыбалась
      <em>Лет восемнадцать ей</em>
      <em>И восемнадцать мне <strong>до ста осталось</strong></em>
    `
  },
  {
    id:'494',
    name: 'Вечереет на душе вечереет',
    text: `
      <strong>В</strong>ечереет на душе вечереет
      И в глазах уже закатный огонь
      А бывали времена веселее
      Но ушло куда-то всё словно сон
      А сейчас всё больше как-то спонтанно
      Средь тумана солнце вдруг проблеснёт
      И уйдёт обратно закатно
      И опять туманом все заволочёт
      &nbsp;
      <strong>Песнь услышу</strong>
      <em>Не такая как прежде</em>
      <strong>Лягу спать</strong>
      <em>Да не улягусь никак</em>
      <strong><em>И живу какой то прежней надеждой</em></strong>
      <strong><em>И дышу как будто как-то не так</em></strong>
      &nbsp;
      <strong>Ну а может взять за ворот рубаху</strong>
      <em>Галстук сняв и аккуратно сложив</em>
      <strong>И рвануть её наотмашь с размаху</strong>
      <em>Но при том совсем её не повредив</em>
      <strong>Взять бутылку раскрутить против стрелки</strong>
      <em>Да и в рюмку по каёмку налить</em>
      <strong>Огурец загрызть в прихлёбку с рассолом</strong>
      <em>И на скатерть ничего не пролить</em>
      <strong>И влюбиться до безсознанья</strong>
      <em>Только так чтоб не узнала жена</em>
      <strong>Но пришло </strong>
      <em>Хоть и не звал</em>
      <strong>Осознанье</strong>
      <strong><em>Что сегодня всё не так как вчера</em></strong>
      &nbsp;
      Ну и что с того что что-то иначе
      Я не стану охранять свой покой
      Потому что я осознанно понял
      <em>Что живой я ёлки-палки живой </em>
      Что по - прежнему я в состояньи
      Развесёлую песнь прокричать
      И возникло вдруг большое желанье
      Внукам всё о себе рассказать
      <strong>&nbsp;</strong>
      <strong>Рассказать</strong>
      <strong><em>Как в весеннем задоре</em></strong>
      <strong><em>Бесшабашный и радостный псих</em></strong>
      <strong><em>Молодой </em></strong>
      <strong><em>Бесконечно весёлый</em></strong>
      <strong><em>Я женился на бабушке их</em></strong>
    `
  },
  {
    id:'495',
    name: 'Волю чувствам дав своим',
    text: `
      <strong>В</strong>олю чувствам дав своим
      После лютой стужи
      Обнаружив на земле вместо снега лужи
      Обувь сняв по ним бежал и кричал при этом
      Что люблю я&nbsp; эту жизнь и зимой и летом
      &nbsp;
      Но заботливый народ проявил тревогу
      Матерясь кричал мне вслед что пораню ногу
      Что пора бы научиться усмирять желания
      Обещал поколотить в виде воспитания
      &nbsp;
      <em>Что поделать </em>
      <em>Приучили нас желанья усмирять</em>
      <em>Потому и через слово в лексиконе чья-то мать</em>
      &nbsp;
      Знаю
      Прав кричащий люд
      Впрямь бы мог поранить ноги
      Как бы бегал я тогда по весенней по дороге
      Как измерил бы тогда глубину глазастых луж
      Что собою подменили зеркала вчерашних стуж
      &nbsp;
      Волю чувствам дав своим
      После лютой стужи
      Обнаружив на земле вместо снега лужи
      В обуви по ним бежал
      И кричал при этом
      Что люблю я&nbsp; эту жизнь и зимой и летом
    `
  },
  {
    id:'496',
    name: 'Снегопадом',
    text: `
      <strong>С</strong>негопадом
      Рифмы белого стиха
      Сыплются на белый лист бумажный
      Тот
      Который от слезинок влажный
      Коими заплакала Душа
      &nbsp;
      Переливом звонких птичьих стай
      В ритмах пробудившейся природы
      Льёт на лист стихи весёлый май
      Чувства проливая через край озёр бездонных
      На глаза похожих
      &nbsp;
      Маревом расплавленных небес
      Раскаленных смыслами понятий
      Выжигает лето на листе
      Мыслеформы прожит<strong>ы</strong>х распятий
      &nbsp;
      Спелым вкусом вызревшей строки
      Аромат осенних всецветий
      Не спеша льёт на Душу стихи
      О зиме
      О весне
      О себе и о лете
    `
  },
  {
    id:'497',
    name: 'На чистый лист',
    text: `
      <strong>Н</strong>а чистый лист
      Как будто бы на скатерть
      Я проливаю чувства как вино
      О чём я думаю
      О чём я знаю
      В разводах чувств моих&nbsp; прочесть не всем дано
      Ведь я пишу что мне Душа диктует
      В попытке смыслы чувств одеть в слова
      И в них вместить просторы Вездесущья
      Освободив от рамок бытия
      <em>&nbsp;</em>
      Чтобы пластичность пр<strong>о</strong>литого слова
      Волненьем страстным наполняла грудь
      Чтоб невесомостью озвученного чувства
      Возможно было с высоты на Мир взглянуть
      &nbsp;
      Словами нарисую лес весенний
      И бархатность проснувшейся травы
      Словами нарисую нежность встречи
      Которую так долго ждали мы
      Слова Души
      Звучащие как песня
      Что нам у колыбели пела мать
      Слова
      Объёмом с голубое небо
      Которое Душой хочу обнять
      &nbsp;
      <em>Благодарю Святое Занебесье</em>
      <em>Благодарю наполненной Душой</em>
      <strong><em>За то</em></strong><em> что мне возможность дали</em>
      <em>Благодарить заоблачные дали</em>
      <strong><em>За то</em></strong><em> что небеса всегда со мной</em>
    `
  },
  {
    id:'498',
    name: 'На краю',
    text: `
      <strong>Н</strong>а краю
      На самом краешке обрыва
      Я стою
      И восхищаюсь красотой
      Я не видел прежде
      Как прекрасна
      Та
      Что распласталась предо мной
      Я не видел глаз её<em> озёрных</em>
      Кос не видел <em>вспененной рекой</em>
      Рук пластичных <em>горною тропою</em>
      Стана лёгкого <em>взмывающей скалой</em>
      Грудь не видел <em>пышными холмами</em>
      Веера ресниц <em>пушистою травой</em>
      И вот смотрю я
      Звёздными глазами
      Растворяясь в прелести земной
      &nbsp;
      <em>А хор небесный </em>
      <em>Во&nbsp; Вселенском Храме</em>
      <em>Свой обряд творящий внеземной</em>
      <em>СвятоМировыми голосами </em>
      <em>Вечностью </em>
      <em>Венчает нас с тобой</em>
    `
  },
  {
    id:'499',
    name: 'Флейтой звучат голоса занебесные',
    text: `
      <strong>Ф</strong>лейтой звучат голоса занебесные
      Скрипкой проснувшейся вздрогнул рассвет
      <em>Жизнь освятил колокольною песнею</em>
      <em>Всё нарастающий Солнечный Свет</em>
      Блики его покрывают узорами
      Реки и горы
      Леса и моря
      <em>Вечность </em>
      <em>Объятую чистыми взорами</em>
      <em>Душам людским подарила заря</em>
    `
  },
  {
    id:'500',
    name: 'Чиркнув по Душе огнивом чувства',
    text: `
      <strong>Ч</strong>иркнув по Душе огн<strong>и</strong>вом чувства
      Запалил страстей своих свечу
      <strong>Ту</strong> что вспышкой пламени безумства
      Озарив греховную мечту
      Унесла меня потоком н<strong>о</strong>чи
      Погрузив в светящуюся тьму
      <strong>Там </strong>где падший ангел крылья сбросил
      И бескрылым падает ко дну
      &nbsp;
      Мимолётность данного вид<strong>е</strong>нья
      Отрезвила чувства и мечты
      Возвращая в прежнее мгновенье
      Тихим шёпотом
      <em>Прошу тебя</em>
      <em>Прости</em>
    `
  },
  {
    id:'501',
    name: 'Распушился нежный вечер одуванчиком',
    text: `
      <strong>Р</strong>аспушился нежный вечер одуванчиком
      Обволакивая тело маслом воздуха
      Раскрывается Душа в груди баянчиком
      Задушевные выводит мелодии
      Васильковые просторы как кружево
      Разузорили луга грустью летнею
      И заполнены пространства межзвёздные
      Родниковой красотой благолепия
    `
  },
  {
    id:'502',
    name: 'Бархатистостью страданий',
    text: `
      <strong>Б</strong>архатистостью страданий
      В лёгком платье смысла чувств
      Многоцветностью мечтаний
      В твоих мыслях растворюсь
      &nbsp;
      Шёлком плазмы утепляя твои утренние сны
      Унесу тебя в бескрайность
      В Мир безудержной весны
      &nbsp;
      Песней звуков первозданных
      Танцем огненных Светил
      Попрошу что б <em>путь во мраке</em>
      <em>Млечный Путь</em> нам осветил
      Чтоб прижавшихся к друг&nbsp; другу
      В безграничности Любви
      Растворило нас в Сознаньи
      Неба Моря и Земли
      Чтоб кружа нас во Вселенной
      Заморозив обожгло
      Чтоб в небо звёздное одело
      Чтоб Солнце&nbsp; в волосы вплело
      &nbsp;
      И чтоб процесс сей непрерывный
      Бесконечностью начал
      Наивысшей Силой Власти
      Нас с тобою обвенчал
    `
  },
  {
    id:'503',
    name: 'Белая сирень щекочет чувства',
    text: `
      <strong>Б</strong>елая сирень щекочет чувства
      Вечер Душу маслом протирает
      Громче грома бьётся твое сердце
      Тот кто не любил
      Того не знает
      &nbsp;
      Ну а те
      Кого крылом коснулся
      Дух
      Вселенской нежности и ласки
      Те живут величьем состоянья
      Пребывая в светлой доброй сказке
      &nbsp;
      Сказка
      Это есть не небылица
      Сказка
      Состоявшееся чудо
      Только те кто любят
      Те познают
      Кто её послал нам и откуда
      &nbsp;
      Только те
      В кого Любовь вселилась
      Жизнь творят в потоках Благодати
      Только те
      Отзывчиво и чутко
      Вашей нелюбви
      Любовью платят
    `
  },
  {
    id:'504',
    name: 'Васильковая небесность',
    text: `
      <strong>В</strong>асильковая небесность
      Мягким шелестом ноч<strong>и&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>
      Притушила <em>солнцелучик</em>
      Включила<em> звездосветлячки</em>&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;
      В очертаньях <em>светолунья</em>
      Проявляя <em>светоночь</em>
      Невесомостью беззвучья
      Эхо взгляда разнеслось
      <em>&nbsp;</em>
      <em>Нежноветровым</em> лобзаньем
      Прикасаясь к облакам
      Всепроявленность мечтаний
      Я стелил к твоим ногам
      &nbsp;
      На папирусе межзвёздном
      <em>Любочувствием </em>чернил
      Твоё имя в книге жизни
      Вечной вязью выводил
      &nbsp;
      И в проявленности скрытой
      Родниковостью Светил
      Всеблаженным ощущеньем
      Над Землею я парил
      &nbsp;
      В запредельностях Вселенной
      Всем Мирам что я встречал
      Как Любовь Земли Великой
      Я ромашки повручал
      &nbsp;
      И вручал им незабудки
      <em>Синеокости Земли</em>
      Понимая
      Что безмерны наших единений дни
    `
  },
  {
    id:'505',
    name: 'Глубинное теченье мысли',
    text: `
      <strong>Г</strong>лубинное теченье мысли
      Непроявлением волны
      Не вносит беспорядок в ритмы жизни
      В прозрачный смысл течения воды
      &nbsp;
      Гладь речки нежно нарушает
      Лишь нежное касанье ветерка
      И кружева волненья пробуждает
      Ели заметной дрожью тростника
      &nbsp;
      Когда же мысль отбросивши дремоту
      Осознавая значимость свою
      Рождая силу
      Обретя свободу
      Расправит плечи возбудив волну
      Тогда
      Волненьем праведным объята
      Вскипит река
      Перевернув вверх дном
      Событья прошлого
      <em>Прож<strong>и</strong>того когда-то</em>
      Закрытого сакральности замк<strong>о</strong>м
      И разорвав собою гладь речную
      Бурлящей мыслью вспененной волны
      Она покажет нам себя иною
      <em>Хранителем Духовной чистоты</em>
      <em>&nbsp;</em>
      И растворив себя в твоём Сознаньи
      Пробившись к свету памяти Души
      В тебе разбудит истинность понятий
      Величья поднебесной глубины
    `
  },
  {
    id:'506',
    name: 'Весною распускаются цветы',
    text: `
      <strong>В</strong>есною распускаются цветы
      Весною распускаются желанья
      Весною распускается любовь
      Весною распускаются мечтанья
      Весною кажется распущенною высь
      Весеннего лазоревого свода
      Весной пьянящей&nbsp; Душу бередит
      С ума себя сводящая природа
      &nbsp;
      Прошла весна смятенным ветром чувств
      Настал период летнего Сознанья
      Которое безжалостной&nbsp; жарой
      Случалось вызывало и страданья
      &nbsp;
      Затем осенний листопадный дождь
      Прожилками янтарного листа
      Рассказывал о прожитых год<strong>а</strong>х
      Что сгрудились в несбывшихся мечтах
      &nbsp;
      А вот и снег что Душу серебрит
      Припорошив собою всё былое
      Покрасив белым небо голубое
      В сугробе спрятал мой гадальный лист
      &nbsp;
      <em>Но снежный ветродуй не в силах</em>
      <em>Духовный пламень сбить свечи</em>
      <em>Которая горит в ночи</em>
      <em>Сиянием своим творя</em>
      <em>Начало завтрашнего дня</em>
      <em>Когда распустится весной</em>
      <em>Что было не дож<strong>и</strong>то мной</em>
    `
  },
  {
    id:'507',
    name: 'Жизнь без Любви',
    text: `
      <strong>Ж</strong>изнь без Любви
      <em>Существованье</em>
      <em>&nbsp;</em>
      Существование
      <em>Мираж</em>
      &nbsp;
      Мираж
      <em>Плод вашего мечтанья</em>
      &nbsp;
      Мечта
      <em>Стремленье к осязанью</em>
      <em>&nbsp;</em>
      А осязанье
      <em>Результат</em>
      &nbsp;
      Путь к результату
      <em>Созиданье</em>
      <em>Соединение начал</em>
      &nbsp;
      Собой <em>начало </em>означало
      <em>Так было с самого начала</em>
      <strong>Любовь</strong>
      <strong><em>Великий Божий Дар</em></strong>
    `
  },
  {
    id:'508',
    name: 'Закипают небеса пенной тучею',
    text: `
      <strong>З</strong>акипают небеса пенной тучею
      Из которой льёт дождём тьма колючая
      Мелкой сеткою взор окутавшая
      Мокрым воздухом думы спутавшая
      Прошивают небеса иглы-молнии
      Раскатились над землёй гром-симфонии
      Но не долго быть сему наваждению
      Победит его собой песнь весенняя
      Буйным ростом трав
      Шелестом дубрав
      Множеством цветов
      Бликами костров
      <em>Рассияется </em>
      <em>Расхохочется</em>
      <em>Раззадориться </em>
      <em>Распогодиться</em>
      И исчезнет тень
      Засияет день
      Чувства разбудив
      Солнце подарив
      Я запью тоску ранней свежестью
      Чтоб раскрылась даль
      Жизнью-Вечностью
    `
  },
  {
    id:'509',
    name: 'Ещё не утро',
    text: `
      <strong>Е</strong>щё не утро
      Но уже не ночь
      Дежурный ветерок тьму отгоняет прочь
      И где-то там
      За пеленой тумана
      Род<strong>и</strong>лось время
      Имя его &ndash; <strong><em>Рано</em></strong>
    `
  },
  {
    id:'510',
    name: 'Дождь',
    text: `
      <strong>Д</strong>ождь
      Как будто ребёнок
      Плескался в реке
      Промочив себя с пят до макушки
      Так замёрз
      Что дрожа пузырьками в воде
      Превращал блики солнца в веснушки
      &nbsp;
      Вот шалун
      Прекрати
      Вылезай из воды
      Я тебя оботру полотенцем из неба
      И на тёплый расстеленный луг уложу
      Угощу молоком и краюхою сдобного хлеба
      &nbsp;
      Отогрейся
      Обсохни и дальше шал<strong>и</strong>
      Чтоб вниманье привлечь - шалость верное средство
      Очень скоро ты встретишь иные дожди
      Но любые из них будут родом из детства
      &nbsp;
      В отношеньи тебя
      Я спокоен вполне
      Знаю
      Будешь и добрым и светлым
      Но я всё - таки в тысячный раз попрошу
      <em>Не дружи с невоспитанным ветром</em>
    `
  },
  {
    id:'511',
    name: 'Здесь',
    text: `
      <strong>З</strong>десь
      В розовом Раю
      Зелёная трава
      Зеркальна гладь воды
      Здесь слышно пенье птичье
      И небосвод лазурный здесь всегда
      Неописуемо велик своим величьем
      &nbsp;
      Здесь
      В розовом Раю
      Коль дождик так грибной
      Коль ветерок так ласковый и нежный
      И воздух здесь прозрачно-кружевной
      И сон глубокий сладкий безмятежный
      &nbsp;
      Здесь
      В розовом Раю
      Нет горя и нужды
      Здесь каждый чист и свят
      Здесь каждый откровенен
      Любой здесь чей-то <em>внук сестра ребенок брат</em>
      И нет средь них таких кто в чём-то виноват
      &nbsp;
      <em>Здесь </em>
      <em>В розовом Раю</em>
      <em>Мы начинаем <strong>быть</strong></em>
      <em>И проживаем срок в преддверьи детства</em>
      <em>&nbsp;</em>
      <em>Здесь</em>
      <em>В розовом Раю</em>
      <em>Хотим подольше жить</em>
      <em>Но жизнь уводит нас в иное место</em>
    `
  },
  {
    id:'512',
    name: 'Из глубины Миров',
    text: `
      <strong>И</strong>з глубины Миров
      <em>Редчайший чёрный жемчуг</em>
      В изысканной оправе от Кардена
      Изящной линией спадающего шёлка
      Звучит как колдовской ноктюрн Шопена
    `
  },
  {
    id:'513',
    name: 'Как жаль что этого нельзя',
    text: `
      <strong>К</strong>ак жаль что этого нельзя
      А как хотелось бы
      Прижаться и ни на миг не расставаться
      Губами прикоснуться к волосам
      Обнять за талию
      Рукою руку гладить
      Но то что есть того нельзя исправить
      Поскольку Вы
      <em>Увы</em>
      Моя мечта
    `
  },
  {
    id:'514',
    name: 'Какого цвета у Любви глаза',
    text: `
      <strong>К</strong>акого цвета у Любви глаза
      Быть может синие
      Быть может голубые
      Быть может серые иль карие глаза
      <em>Нет</em>
      <em>Всё не так</em>
      <em>Они совсем другие</em>
      <strong>Родного</strong> цвета у Любви глаза
      С оттенком нежности заботы и терпенья
      С оттенком&nbsp; материнского прощенья
      <strong>Святого </strong>цвета у Любви глаза
    `
  },
  {
    id:'515',
    name: 'Кисточкой',
    text: `
      <strong>К</strong>источкой
      Из перьевого облака
      Небесный свод
      Насыщенным сиропом
      Я крашу
      И покрасил даже звездочку
      Ту что сверкает дрожью над дорогой
      Покрасил воздух
      Растворив в нём звон малиновый
      Покрасил речку
      Обмывая в речке кисточку
      Твой смех покрасил
      В цвет ультрамариновый
      Свою Любовь к тебе
      Покрасил я
      В цвет Истинный
    `
  },
  {
    id:'516',
    name: 'Нежный смычок',
    text: `
      <strong>Н</strong>ежный смычок
        Сумасшедшими ласками
        Скрипку измучив
        Блаженство творя
        Звуками неба
        На землю сошедшими
        В ночь полнолунную м<strong>а</strong>нит меня
        &nbsp;
        <em>Воздух </em>
        <em>Пропитанный запахом клевера</em>
        <em>Голову кр<strong>у</strong>жит </em>
        <em>Дурманом по<strong>и</strong>т</em>
        <em>Шёлковость трав </em>
        <em>Что под ноги постелена</em>
        <em>Тайны </em>
        <em>Любовных историй хранит</em>
        &nbsp;
        Трель соловьиная всполохом утренним
        Звёздную ночь превратила в рассвет
        И пробуждаясь к молитве заутренней
        Лес
        Вскинул ветви свои до небес
        &nbsp;
        Солнечный луч
        Словно спица вязальная
        Вяжет фату из туманной тесьмы
        А я прошу
        Чтобы платье венчальное
        Вывязал тем
        Кто сейчас влюблены
        &nbsp;
        Скрипка смеётся весёлыми брызгами
        Смелый смычок переливы творя
        Звуками ночи
        Росою умытыми
        В утро счастливое вывел меня
    `
  },
  {
    id:'517',
    name: 'Обжёгся ветерок рябиновым огнём',
    text: `
      <strong>О</strong>бжёгся ветерок рябиновым огнём
      Раскрасился кленовым разноцветьем
      И уронив себя на бугорок
      Примял траву своим великолепьем
      Передохнул слегка и снова в путь
      Хлопот сегодня у него не мало
      Сегодня нужно облака раздуть
      И речку освежить а то течёт устало
      Затем продуть раздольные поля
      Проветрить лес
      Поворошить покосы
      Девчонку ту
      Что стоя у плетня ввысь смотрит
      Потрепать за косы
      Затем подняться выше облаков
      Проверить всё ли там в порядке
      Потом вздремнуть до <em>первых петухов</em>
      И в новый день ворваться без оглядки
      В жизнь новую и в новые дела
      В дела которым и конца не видно
      &nbsp;
      <em>У ветерка нет время для себя</em>
      <em>Мне за него немножечко обидно</em>
    `
  },
  {
    id:'518',
    name: 'Ночной костёр',
    text: `
      <strong>Н</strong>очной костёр
      Поляна в бликах света
      Дрова трещат и искры до небес
      Мелодии гитарных откровений
      Самозабвенно слушал тихий лес
      &nbsp;
      Пучки аккордов <strong>и</strong>скристой палитрой
      Бросали в небо образы себя
      Волнением встревоженного звука
      В Душе звучала сольная струна
      И даже небо опустилось ниже
      Придвинувшись к затейнику костру
      А тот и рад гореть собой на небе
      Пожаром пылким разжигая тьму
      <em>И удалось</em>
      Сияют искры-звёзды
      Творя в бездонной в<strong>ы</strong>си хоровод
      Я в этой сказке находился долго
      <em>Не знаю сколько точно ночь иль год</em>
      Моей восторженности продолженьем
      Явилось утро что принесло&nbsp; собой
      Прозрачность состояний
      Вдохновенье
      Малины куст окрашенный зарей
      И искорки вчерашнего пожара
      <em>Всё это было будто бы во сне</em>
      Они как&nbsp; земляничные гирлянды
      <em>Но всё же искорки</em>
      Лежали на траве
    `
  },
  {
    id:'519',
    name: 'Разгулялось',
    text: `
      <strong>Р</strong>азгулялось
      Нарядившись <em>бабье лето</em>
      В разноцветности вуалевой листвы
      И лучом нежарким воскрешает
      Чувства прежние и прежние мечты
      Что в период сочного цветенья
      Жаждой жизни напитав траву
      Проявлялись страстью нетерпенья
      В форме жизни <em>Я тебя люблю</em>
      Но когда безудержность желаний
      Превратясь в осознанности дней
      Позволяя вдумчиво-спокойно
      Принимать осенний дух аллей
      В эту пору желтизны смиренной
      Вдруг взорвались вспышками огня
      Гармоничным сочетаньем цвета
      Ветер
      Солнце
      Небо
      Жизнь моя
      Многокрасочной наполненостью мига
      Всё смешав мозаичной игрой
      Хор всецветья спелостью мотива
      Проявил собою <em>Рай Земной</em>
      &nbsp;
      <em>Мне волшебность цветохоровода</em>
      <em>Сил даёт уметь любить тебя</em>
      <em>И пускай виск<strong>и</strong> мне красит осень</em>
      <em>Белым снегом завтрашнего дня</em>
    `
  },
  {
    id:'520',
    name: 'Расстелю луга',
    text: `
      <strong>Р</strong>асстелю луга
      Покрывалом бархатным
      Разолью весну
      Синевой небес
      Обниму Душой
      Чтоб наполнить радостью
      Нежно-трепетный
      И прозрачный лес
      &nbsp;
      Звонкий ветерок
      Переливом флейтовым
      Унесёт мотив
      Ввысь за облака
      Эхом вздрогнувшим
      Отзовётся ранняя
      В жизнь вплетённая
      Чистая заря
      &nbsp;
      Расстелю луга
      Покрывалом ситцевым
      Напою росой
      Утренний цветок
      И пойду босой
      К серебристой реченьке
      Попрошу её
      <em>Ты меня умой</em>
      <em>&nbsp;</em>
      <em>Речка</em>
      <em>Жизнь моя</em>
      <em>Я тебе поведаю</em>
      <strong><em>Как прекрасна ты</em></strong>
      <strong><em>В Вечности Любви</em></strong>
      <em>Я тебе открою</em>
      <em>Что об этом ведаю</em>
      <strong><em>Ну а ты всё это</em></strong>
      <strong><em>В завтра унеси</em></strong>
    `
  },
  {
    id:'521',
    name: 'Ты прикоснись к моей Душе',
    text: `
      <strong>Т</strong>ы прикоснись к моей Душе
      Согрейся коли ты замерз
      Ты прикоснись к моей Душе
      Коль горько на твоей до слёз
      Войди в неё как входишь ты
      В родной любимый дом
      <em>Вот видишь</em>
      <em>В единеньи&nbsp; Душ нам хорошо вдвоём</em>
      Но вот смотри
      Ещё одна замёрзшая Душа
      <em>Смелее в нашу заходи</em>
      <em>Согреешься тогда</em>
      А вон ещё
      Ещё
      Ещё
      И счёту нет конца
      <em>Нас примет всех и приютит Вселенская Душа</em>
      <em>&nbsp;</em>
      <strong><em>И испытав себя во Всём</em></strong>
      <strong><em>И Всё в себе одном</em></strong>
      <strong><em>Ты в этом доме дверью стал</em></strong>
      <strong><em>И сам же к ней ключом</em></strong>
    `
  },
  {
    id:'522',
    name: 'Тихо дремлет за окошком ночь',
    text: `
      <strong>Т</strong>ихо дремлет за окошком ночь
      Легким ветерком сомненья отгоняю прочь
      Бархатистым покрывалом сумрака ноч<strong>и</strong>
      Накрываю твои думы
      <em>Ну а ты молчи</em>
      Окружив тебя прозрачной звездной пеленой
      Сон дарю тебе прекрасный
      Сон живой
      Лишь постукивает время на стене
      Мелодичностью мгновений в тишине
      Отмеряя перемены наших дней
      Дар бесценный преподносит памяти твоей
      &nbsp;
      Этот сон тебе не снится
      Это есть
      Снов реальных превращений и не счесть
      Откровению открытий той ноч<strong>и</strong>
      Мы обязаны рассвету
      <em>Но о том молчи</em>
    `
  },
  {
    id:'523',
    name: 'Кленовый лист',
    text: `
      <strong>К</strong>леновый лист
      Обрывком солнца
      Нас согревал своим теплом
      Смягчить пытаясь боль утраты
      Рождённую прож<strong>и</strong>тым днём
      А ветер
      Колким настроеньем
      В стремленьи загасить пожар
      Пожар
      Кленового горенья
      Ещё сильнее разжигал
      А тот сверкал
      Сиял листвою
      Усыпав огненным дождём
      Прозрачный путь
      Что мы с тобою
      Шли в одиночестве своём
    `
  },
  {
    id:'524',
    name: 'Я вам задам сейчас вопрос',
    text: `
      <strong>Я</strong> вам задам сейчас вопрос
      Для вас он будет неожидан
      И может выявить того
      Судьбою кто своей обижен
      &nbsp;
      Не нужно на Судьбу пенять
      Ведь это будет означать
      Что несогласие с Судьбой
      Являет бой самих с собой
      &nbsp;
      Ответив на вопрос
      Кого-то
      <em>Такое очень может быть</em>
      Проснувшись память закруж<strong>и</strong>т
      И унесёт собой даря
      Воспоминанья того дня
      В котором кроется ответ
      Ну а кого-то вовсе нет
      Не унесет
      Оставит здесь
      <em>На то причина тоже есть</em>
      &nbsp;
      Не з<strong>а</strong>дал я ещё вопрос
      А многих он уже унёс
      В недорешённость тех ночей
      Которые порядком дней
      Построив следственную связь
      Волнуют их и посейчас
      &nbsp;
      <em>И кто из вас сейчас был где</em>
      <em>Оставьте это при себе</em>
      &nbsp;
      Но всё же я задам вопрос
      Чем подарю вам свежесть грез
      И память чувств сверкнёт лучом
      <strong><em>&nbsp;</em></strong>
      <strong><em>Кто целовался под дождем</em></strong>
    `
  },
  {
    id:'525',
    name: 'Твоё ложе застелю я шёлковой травой',
    text: `
      <strong>Т</strong>воё ложе застелю я шёлковой травой
      Поутру тебя умою я живой водой
      Разбужу тебя с рассветом дивным пеньем птиц
      <em>Ты увидишь как в листочках искорки зажглись</em>
      Зорьку раннюю цветную в косы заплету
      Обвяжу лучом рассветным талию твою
      Синевой глаза наполню
      Нежность в грудь вдохну
      В рощу правды за собою сердцем поведу
      &nbsp;
      Белоствольный храм построю я на берегу
      <em>Отдаю тебе себя я <strong>так</strong> как я могу</em>
      Солнцем Ветром и Водою я тебя люблю
      Я люблю тебя травою
      Пеньем птиц люблю
      Я люблю тебя росою
      Я Душой люблю
      Я люблю тебя Вселенной
      <em>Вот как я люблю</em>
      &nbsp;
      От тебя мне нужно малость
      Лишь бы знала ты
      <em>Что любима мной </em>
      <em>Желанна</em>
      <em>Как весной цветы</em>
      &nbsp;
      Если ты меня не любишь
      <em>Не гони меня</em>
      Если ты меня не знаешь
      <em>Не вини меня</em>
      Всё равно нам друг без друга не прожить и дня
      <em>&nbsp;</em>
      <em>Я сейчас тебе представлюсь</em>
      <strong>&nbsp;</strong>
      <strong>Я </strong>
      <strong>СУДЬБА ТВОЯ</strong>
    `
  },
  {
    id:'526',
    name: 'Растворяя в Душе',
    text: `
      <strong>Р</strong>астворяя в Душе
      Растворяясь Душой
      Вы
      Любимые мной
      Словно искорки счастья
      Не могу жить без вас
      Ни ходить ни дышать
      Не корите меня за такое участье
      &nbsp;
      Вижу в ваших глазах я
      Созвездия чувств
      Ваши руки к губам прижимаю
      И живя вместе с вами
      За вас я молюсь
      С каждым
      В трудный момент умираю
      &nbsp;
      Воскрешаюсь в Любви
      Воскрешаюсь Любя
      Воскрешаюсь как будто бы сам
      Сколько ж нужно добра
      Сколько истинных чувств
      Чтоб понять кем являюсь я вам
      &nbsp;
      Ваши чувства
      Великий Космический Дар
      Преисполненный радостью Мига
      В этом миге мы с вами большая семья
      В этом Тайна Великая скрыта
      &nbsp;
      Растворяя себя растворением в вас
      Растворяя в себе вас с любовью
      Не корите меня
      Ну хотя бы сейчас
      <em>Миг пройдет</em>
      <em>И вы станете мною</em>
    `
  },
  {
    id:'527',
    name: 'Как часто не хватает простоты',
    text: `
      <strong>К</strong>ак часто не хватает простоты
      Во всём буквально не хватает
      И нет бы мне сказать
      <em>Я нюхаю цветы</em>
      Я ж говорю
      <em>Букет меня ласкает</em>
      Я говорю
      <em>Я растворяюсь в нём</em>
      <em>Вдыхая ароматы излучений</em>
      <em>Я опьянён</em>
      <em>Я просто восхищён</em>
      <em>Звучаньем запаха бушующих цветений</em>
      &nbsp;
      А я всего лишь нюхаю цветы
      И мне приятен их весенний аромат
      А если проще
      <em>Я люблю как пахнет жизнь</em>
      И если просто уж совсем
      <em>Я этой жизни рад</em>
    `
  },
  {
    id:'528',
    name: 'За исполненье мечт',
    text: `
      <strong>За исполненье мечт</strong>
      Прошу наполнить рюмки
      <strong>За исполненье мечт</strong>
      Прошу
      Мои друзья
      <strong>За исполненье мечт</strong>
      Союзом нашим дружным
      Что н<strong>а</strong>лито
      То
      Осушить до дна
      <strong>За исполненье мечт</strong>
      Прошу озвучить это
      Ударом рюмок
      Пусть звучит наш тост
      Хрустальным чувством
      Вознося к рассвету
      Звон наших Душ
      <strong><em>Он Вечен</em></strong>
      <strong><em>Этот звон</em></strong>
    `
  },
  {
    id:'529',
    name: 'Уставшим взглядом излучают нежность',
    text: `
      <strong>У</strong>ставшим взглядом излучают нежность
      Всепонимающие добрые глаза
      В которых растворились бирюзою
      Наполненные жизнью небеса
      &nbsp;
      <strong><em>Коль вам удастся повстречаться взглядом</em></strong>
      <strong><em>С тем взглядом о котором написал</em></strong>
      <em>То вы поймёте</em>
      <em>Лишних слов не надо</em>
      <strong><em>Поймёте всё</em></strong>
      <strong><em>О чём я умолчал</em></strong>
    `
  },
  {
    id:'530',
    name: 'Распахните рубахи и Души',
    text: `
      <strong>Р</strong>аспахните рубахи и Души
      Ветер свежести ощутите
      Этот ветер не ветер стужи
      От него не замерзнут лужи
      Вы его внутрь себя впустите
      &nbsp;
      Свежий ветер понятий новых
      Освежает мое Сознанье
      Пробуждая меня дремавшего
      Открывает Суть настоящего
      &nbsp;
      Всё что прежде считалось пройденным
      Проявило себя&nbsp; не осмысленным
      Всё что прежде считалось ст<strong>о</strong>ящим
      Проявило себя бессмысленным
      &nbsp;
      <em>Распахните рубахи и Души </em>
      <em>Полнотой состояний очнитесь</em>
      <em>И в себе растворяя ветер</em>
      <em>Ветру свежему помолитесь</em>
    `
  },
  {
    id:'531',
    name: 'Пусть на поверхности бушуют волны рьяно',
    text: `
      <strong>П</strong>усть на поверхности бушуют волны рьяно
      Покой хранят глубины океана
      <em>И если б мы учились у Природы</em>
      <em>На д<strong>о</strong>лго бы свои продлили годы</em>
      &nbsp;
      На мелководье лишь волна достигнет дна
      Поднимет муть в себе перемешает
      <em>Такой водой ты не напьёшься в зной</em>
      <em>Как и не дашь Любви не чистою Душой</em>
    `
  },
  {
    id:'532',
    name: 'Былые заслуги',
    text: `
      <strong>Б</strong>ылые заслуги
      <strong>И</strong> прежние знанья
      <strong>Б</strong>ылые победы
      <strong>Л</strong>юбовь
      <strong>И</strong> терзанья
      <strong>Я</strong>вились сегодня
      <strong>Б</strong>ывалостью прежней
      <strong>И</strong> прекратили мой сон безмятежный
      <strong>Б</strong>ольшая Любовь пробудилась от сна
      <strong>Л</strong>икует теперь во Вселенной она
      <strong>И</strong> новые знанья открылись зарей
      <strong>Я</strong> ими живу как живут они мной
    `
  },

  // «Знакомьтесь, это мы» ======================================================================================
  {
    id:'533',
    name: 'Я думаю',
    text: `
      ВСТУПЛЕНИЕ
      &nbsp;
      <strong>Я</strong> думаю
      Что очень скоро
      Вокруг стихов возникнет спор
      Но я прошу Вас
      <em>Ради Бога</em>
      Не выносите приговор
      Моей заслуги к сожаленью не много
      <em>Роль скромна моя</em>
      Я лишь писал в момент прозренья
      Диктуемые мне слова
      Лишь кое-где менял местами
      <em>Так чтобы Суть не исказить</em>
      Так можно ли
      <em>Судите сами</em>
      Меня за это обвинить
      &nbsp;
      В стихах нет точек
      Нет и запятых
      И прочих знаков нет
      <em>Я их не ставил</em>
      Причина в том
      Что я для Вас
      Процесс прочтенья творческим оставил
      &nbsp;
      Осознанность рифмующихся строк
      В развёрнутом объёме пониманья
      Предложит каждому <strong>той</strong> тяжести <em>мешок</em>
      Который сможет он вместить в своё Сознанье
      &nbsp;
      <strong><em>А я молю </em></strong>
      <strong><em>Молю Всевысье</em></strong>
      <strong><em>Что б продолжали быть Слова</em></strong>
      <strong><em>Слова </em></strong>
      <strong><em>Диктуемые Высью</em></strong>
      <strong><em>А я б записывал Слова </em></strong>
    `
  },
  {
    id:'534',
    name: 'Не могу передать',
    text: `
      <strong>Н</strong>е могу передать
      <em>Не могу</em> х<em>оть умри</em>
      Что я вижу и что ощущаю
      Не могу описать
      Не могу проявить
      Слов таких не найду
      <em>От того и страдаю</em>
      Впору грудь разорвать
      Впору вытряхнуть всё
      Разобраться в чём скрыта причина
      Не дающая мне изложить <em>что хочу</em>
      Выжимает мне Душу <em>как тряпку</em> кручина
      Почему все слова столь мелки и бледны
      Не могу отразить ими чувства
      Не могу описать
      <em>Сколь прекрасен порыв Сил небесных</em>
      <em>Чьё имя <strong>Искусство</strong></em>
      &nbsp;
      Написал что сумел
      <em>Обессилил совсем</em>
      <strong>Вдруг рывок</strong> просветлением&nbsp; мысли
      <strong><em>Автоматным письмом</em> </strong>
      <em>Но рукою моей </em>
      Полились на листы
      Откровений открывшихся Смыслы
      &nbsp;
      Из незримых высот потаённых глубин
      Вечность
      Занавес приподняв
      Приоткрыла просторы владений своих
      <em>Показав мне на чьих я руках</em>
      Показала мне <strong>тех </strong>
      <em>Кто в ответе за всех</em>
      <em>Перед кем <strong>мы </strong>несём ответ</em>
      Лишь тогда вспомнил я
      <em>То</em> ч<em>то жизнь</em> - с<em>уета</em>
      В суете не найти мне такие слова
      <em>На земле слов мне нужных нет</em>
      &nbsp;
      Нам даются другие слова на Земле
      Для того что бы мы попытались
      <em>Разобраться в себе</em>
      <em>Разобраться в Судьбе</em>
      <em>Разобраться кем были кем стали</em>
      &nbsp;
      Нам даются слова
      Для того что бы нам
      <em>Были зримы различия</em> <em>Мысли и Слова</em>
      Для того
      <em>Чтоб себя подготовить могли</em>
      <em>К жизни Вечной</em>
      <em>К понятиям новым</em>
      Чтобы мысль была чистой
      <em>Как горный ручей</em>
      Чтоб слова <em>чистоту отражали</em>
      Чтобы знали
      <em>Слова нам диктует Душа</em>
      <em>О которой в земной суете забывали</em>
      &nbsp;
      &nbsp;
      <em>И когда не хватает мне слов </em>
      <em>Что бы чувства свои описать</em>
      <em>То из этого следует только одно</em>
      <strong><em>Нужно глубже себя понимать</em></strong>
    `
  },
  {
    id:'535',
    name: 'Всё то что небеса диктуют',
    text: `
      <strong>В</strong>сё <strong>то</strong> что небеса диктуют
      Записываю я
      Стараясь тем тебе открыть
      Сокрытый смысл понятья - <strong><em>жить</em></strong>
      &nbsp;
      Прочтя стихи Душой
      Познаешь свойства нитей
      Которые связуют нас с тобой
      Найди в себе меня
      Ведь ты живёшь на свете
      Лишь потому что ты род<strong>и</strong>лся мной
      &nbsp;
      <em>Послание своё себе я адресую</em>
      <em>Тому который вновь родится мной</em>
      <em>Взамен меня того</em>
      <em>Что обретёт иную</em>
      <em>Иную жизнь в свободе внеземной</em>
    `
  },
  {
    id:'536',
    name: 'Лью откровенья на листок выплёскивая смыслы',
    text: `
      <strong>Л</strong>ью откровенья на листок выплёскивая смыслы
      <em>Уже затеплился Восток а я всё лью их на листок</em>
      <em>Прохладным светом мысли</em>
      &nbsp;
      Не знаю
      Будут ли читать
      <em>Хотелось бы</em>
      <em>Не скрою</em>
      Вдруг для кого-то что нибудь строкою приоткрою
      Вдруг кто-то сможет осознать не понятое прежде
      Узрев себя таким как есть и <em>правду не в одежде</em>
      &nbsp;
      Лью откровенья на листок выплёскивая смыслы
      Приняв записываю их преобразовывая в стих
      Прохладным светом мысли
    `
  },
  {
    id:'537',
    name: 'Простыми словами пытаюсь сказать',
    text: `
      <strong>П</strong>ростыми словами пытаюсь сказать
      <em>Что жизнь нужно чувствовать и понимать</em>
      Простыми словами рисую картины
      В которых есть люди
      Деревья
      Машины
      Есть радость и горе
      Есть день и есть ночь
      Стремления есть погубить и помочь
      Есть небо и недра
      Есть холод и жар
      Есть нежность объятий и хлёсткий удар
      &nbsp;
      Есть <strong>то</strong> в этой жизни
      Что было до нас
      &nbsp;
      <em>Что мы привнесём в неё</em>
      <em>Спросится с нас</em>
    `
  },
  {
    id:'538',
    name: 'Безмерное количество Миров',
    text: `
      <strong>Б</strong>езмерное количество Миров
      Безмерны формы их существованья
      Взаимосвязь безмерных величин
      Не подлежит Земному толкованью
      &nbsp;
      Иерархия соблюдена везде
      Во Всём царит Закон <em>Причин и Следствий</em>
      Вселенский Разум Властью наделён
      Блюсти Баланс сложением последствий
      &nbsp;
      Я знаю <strong>то</strong>
      Что многие из вас
      <em>В любой из жизни-форм в которой вы сейчас</em>
      Увидев жизнь свою как искорку мгновенья
      Способны ощутить Вселенскую волну
      <em>Волну Свободы</em>
      Вспышкой озаренья
      &nbsp;
      Не важно кто Вы
      И не важно где
      <em>Вы на Земле живете иль не на Земле</em>
      <strong>Но знайте</strong>
      <strong><em>Миропостроенье</em></strong>
      <strong><em>Есть лишь фрагмент Единого творенья</em></strong>
    `
  },
  {
    id:'539',
    name: 'Что изначально знаем',
    text: `
      <strong>Ч</strong>то изначально знаем
      Забываем
      По той причине и не понимаем
      Того
      Что временами вспоминаем
      &nbsp;
      <em>Так отчего ж такое происходит</em>
      &nbsp;
      Ответ простой
      <em>По жизни так выходит</em>
      Живём себя не вспоминая
      Притом на жизнь кивая&nbsp; <strong><em>ух какая</em></strong>
      <strong><em>Она</em></strong><em> нам подменила Сути Знаний</em>
      <em>На выдуманности&nbsp; лжеобоснований</em>
      Так и живём
      <em>Хотя порой обидно</em>
      Что за надуманным Природного не видно
      <em>А как увидеть</em>
      <em>Если отрицаем</em>
      Всё <strong><em>то</em></strong> что попросту не понимаем
      &nbsp;
      <strong><em>Пытайтесь вспомнить</em></strong>
      <em>&nbsp;</em>
      <strong><em>Тем кому удастся</em></strong>
      <strong><em>Великой щедростью небес воздастся</em></strong>
    `
  },
  {
    id:'540',
    name: 'За напускным жизнь скрыла Суть',
    text: `
      <strong>З</strong>а <em>напускным</em> жизнь скрыла Суть
      Не позволяя заглянуть
      В глубины истинных процессов Мирозданья
      И выставляет напоказ наивной глупости <em>каркас</em>
      За к<strong>о</strong>им кроется Вселенское Сознанье
      &nbsp;
      <em>Вот и живём не ведая себя</em>
      <em>Не чувствуя живём не понимая</em>
      <em>Нам невдомёк что жизнь совсем иная</em>
      <em>Совсем не <strong>та</strong></em>
      <em>Что нами прожит<strong>а</strong></em>
    `
  },
  {
    id:'541',
    name: 'Позабыты нами времена',
    text: `
      <strong>П</strong>озабыты нами времена
      Когда небо было золотое
      <em>Сейчас оно совсем уже другое </em>
      <em>Теперь иная неба красота</em>
      <em>&nbsp;</em>
      Но дело не в красивости небес
      Всё дело в знаниях открытых
      <em>Когда-то небом золотым разлитых </em>
      <em>На океан на горы и на лес</em>
      <em>Тогда </em>
      <em>Живущие на голубой планете </em>
      <em>Чисты как Боги были иль как дети</em>
      <em>Тогда естественность природного пути</em>
      <em>Прозренье позволяла обрести</em>
      &nbsp;
      Позабыты нами времена
      Когда небо было золотое
      Сейчас оно совсем уже другое
      Теперь иная неба красота
      И мы совсем не те что были прежде
      А небо продолжает жить в надежде
      На то
      <em>Что всё ж наступит час</em>
      <em>Проснётся память в спящих нас</em>
      <em>И всколыхнётся синь небес</em>
      <em>И золото Высоких Знаний </em>
      <em>Вернёт способность пониманий</em>
      <em>Взаимосвязей множества чудес</em>
    `
  },
  {
    id:'542',
    name: 'Опору ищет взгляд',
    text: `
      <strong>О</strong>пору ищет взгляд
      В безбрежностях Вселенной
      И не находит чем себя остановить
      Так будет до тех пор
      Пока Душа ни сможет
      Свою способность виденья открыть
      &nbsp;
      Опору ищет мысль
      Пытаясь зацепиться за краешек ума
      Не усмиряя прыть
      Она как птица продолжает биться
      В желаньи высоту полёта ощутить
      И коль она
      В стремленье стать свободной
      Себя сумеет в небе растворить
      Только тогда
      Она себя осмыслить сможет
      И только лишь тогда
      Свободной сможет быть
      &nbsp;
      <em>Кому-то </em>
      <em>Всё это знакомо до боли</em>
      <em>Кому-то </em>
      <em>Ещё предстоит осознать</em>
      <strong><em>Что видеть Душой </em></strong>
      <strong><em>Как и мыслить просторно</em></strong>
      <strong><em>Не то что рассказы об этом читать</em></strong>
    `
  },
  {
    id:'543',
    name: 'Как много времени',
    text: `
      <strong>К</strong>ак много времени
      <em>По меркам человечьим</em>
      Прошло с тех пор
      Когда впервые
      Увидел горы голубые
      Материки и океанов гладь
      Увидел
      В первозданном виде
      Всё то
      Что нынче не узнать
      &nbsp;
      И ты
      Народ сегодняшней Планеты
      Ты стал иной
      В отличие от тех
      Которые собою заселяли Планету эту
      <em>Я их помню всех</em>
    `
  },
  {
    id:'544',
    name: 'Облака',
    text: `
      <strong>О</strong>блака
      Как скомканная вата
      Где-то там
      Вокруг Земли
      Внизу
      Той Земли что временем распята
      Той Земли где жил и я когда-то
      И где оставил тех кого люблю
      &nbsp;
      Облака как скомканная вата
      Где-то там
      Вокруг Земли
      Внизу
      Той Земли что Вечностью объята
      Той
      Которую я на руках держу
      &nbsp;
      Облака как скомканная вата
      Вот они
      Вокруг Земли
      Вверху
      Той Земли где жил и я когда-то
      Той Земли где и теперь живу
      &nbsp;
      Облака
      Как скомканная вата
      Вот они
      Вокруг Земли
      Вверху
      Той Земли что чувствами богата
      Той Земли с которой вновь уйду
      &nbsp;
      Облака
      Как скомканная вата
    `
  },
  {
    id:'545',
    name: 'Волнующая Душу красота',
    text: `
      <strong>В</strong>олнующая Душу красота
      В изящной форме неземных творений
      Прекрасность красок всуе расплескал на холст Творец
      Непревзойденный Гений
      &nbsp;
      Развёрнутость пространства бытия
      Различных уровней глубинность Мирозданья
      Во всём живет Вселенская Душа
      Бескрайностью возможностей познанья
      &nbsp;
      <em>В кричащем и клокочущем безмолвии</em>
      <em>Зажатые в просторе Мирозданья</em>
      <em>В свободе кандалов Сознаньем созданных</em>
      <em>Мы по пути идём самопознанья</em>
      <strong>В</strong>олнующая Душу красота
      В изящной форме неземных творений
      Прекрасность красок всуе расплескал на холст Творец
      Непревзойденный Гений
      &nbsp;
      Развёрнутость пространства бытия
      Различных уровней глубинность Мирозданья
      Во всём живет Вселенская Душа
      Бескрайностью возможностей познанья
      &nbsp;
      <em>В кричащем и клокочущем безмолвии</em>
      <em>Зажатые в просторе Мирозданья</em>
      <em>В свободе кандалов Сознаньем созданных</em>
      <em>Мы по пути идём самопознанья</em>
    `
  },
  {
    id:'546',
    name: 'За тонкой линией',
    text: `
      <strong>З</strong>а тонкой линией
      Чьё имя <em>Горизонт</em>
      Не много шансов оказаться
      <strong>Тем</strong> кто привык себя бояться
      <strong>А те</strong>
      <em>Кто пусть хотя бы раз</em>
      Откроют сердце для желанья
      <em>Быть за чертой</em>
      <strong><em>Те будут там</em></strong>
      В просторе самоосознанья
      Взлетят Душой
      И в тот же миг
      Постигнут <strong>то</strong>
      Что я постиг
    `
  },
  {
    id:'547',
    name: 'Долго разбегался и взлетел',
    text: `
      <strong>Д</strong>олго разбегался и взлетел
      <em>Нарушая физики законы</em>
      Те
      С которыми конечно вы знакомы
      <em>Я их <strong>все</strong> нарушил <strong>и взлетел</strong></em>
      <em>&nbsp;</em>
      Да и как мне было ни взлететь
      Если разбегался я всю жизнь
      И когда пришла моя пора
      Я Душой взлетел
      Я ввысь лечу
      <strong>Ура</strong>
    `
  },
  {
    id:'548',
    name: 'Терпенья Вам',
    text: `
      <strong>Т</strong>ерпенья Вам
      Мой друг
      И вновь терпения
      Вы на тропе труда
      А не везения
      &nbsp;
      <em>Везенье</em>
      <em>Лишь тогда Вам отзовётся</em>
      <em>Когда Вам миг удачи улыбнётся</em>
      &nbsp;
      <em>Удача</em>
      <em>Улыбнётся Вам тогда</em>
      <em>Когда трудом достигнута она</em>
      &nbsp;
      Терпенья Вам
      Мой друг
    `
  },
  {
    id:'549',
    name: 'Прислушайся',
    text: `
      <strong>П</strong>рислушайся
      Как вздрагивают звуки
      В туманке утра розовой зари
      Прозрачной зыбью пробуждая чувства
      Прохладой ранней растворяя сны
      Как юный ветерок вплетая в травы
      Звучанье вечной песни соловья
      В реке умывшись
      Улетает в д<strong>а</strong>ли
      С собою унос<strong>я</strong> твои года
      &nbsp;
      <em>Аккордом позабытого мотива</em>
      <em>Вернулась память</em>
      <em>Принеся мне <strong>то</strong></em>
      <em>Что столько лет Душа моя хранила</em>
      <em>Хранила <strong>так</strong></em>
      <em>Что позабыла <strong>Что</strong></em>
    `
  },
  {
    id:'550',
    name: 'Шёпот осени',
    text: `
      <strong>Ш</strong>ёпот осени
      Шуршаньем спелых листьев
      Отрезвляет и наводит на раздумья
      <em>Вон под музыку простуженного ветра</em>
      <em>Липа пляшет златовласая колдунья</em>
      <em>Клён дрожа всем телом бьётся в танце</em>
      <em>В транс безудержный вошедшего шамана</em>
      <em>Разноцветие лоскутных шалей </em>
      <em>Осыпается с берёзового стана</em>
      <em>На поляне пятна желтотравий</em>
      <em>Как луна на глади океана</em>
      <em>Слёзы неба капают на землю</em>
      <em>Как вода из сломанного крана</em>
      &nbsp;
      Я люблю период многоцветий
      <em>Он даёт возможность понимать</em>
      <strong><em>То</em></strong>
      <em>Что мы наивные как дети</em>
      <em>И то что&nbsp; нам живя на этом свете</em>
      <em>Жизнь как данность нужно принимать</em>
      И весну люблю
      <em>Что обостряет чувства</em>
      Лето
      <em>Как возможность познавать</em>
      Осень
      <em>Как великое искусство </em>
      <em>Осознавать всё <strong>то</strong> что смог познать</em>
      Зиму
      В белоснежных одеяньях
      <em>Для того чтоб&nbsp; хладнокровным был</em>
      <em>В час когда придут воспоминанья</em>
      <em>Вспомнить <strong>то</strong></em>
      <em>Что всё что знал</em>
      <strong><em>Забыл</em></strong>
    `
  },
  {
    id:'551',
    name: 'Как- то одно за другое цепляется',
    text: `
      <strong>К</strong>ак- то одно за другое цепляется
      Словно петельки за острый крючок
      Петли обрезать
      Не получается
      И от того вероятно цепляется
      День предстоящий за пр<strong>о</strong>житый срок
      Знаю как будто
      Умом понимаю
      Есть нерушимый Вселенский Закон
      <em>Всё что посеял ты</em>
      <em>То вырастает</em>
      <em>Нынче посеянным завтрашним днём</em>
      &nbsp;
      От понимания вовсе не легче
      Вырастить <em>завтра</em> светлей чем <em>вчера</em>
      И продолжает цепляться за <em>завтра</em>
      Глупость
      <em>Вчера</em> мной прож<strong>и</strong>того дня
    `
  },
  {
    id:'552',
    name: 'Песочные часы',
    text: `
      <strong>П</strong>есочные часы
      Текучестью мгновенья
      Песком иных времён пересыпают время
      Крупицами прошедшего струится
      Период Вечности
      Который длится
      Лишь краткий миг перетеканья время
      Вернувшегося струйкой откровенья
    `
  },
  {
    id:'553',
    name: 'Сорвался с тормозов',
    text: `
      <strong>С</strong>орвался с тормозов
      Теперь не остановишь
      Вхожу в пространства не сбавляя ход
      <em>Я ждал мгновенья этого</em>
      <em>Не скрою</em>
      И вот свершилось
      <strong><em>Я лечу вперёд</em></strong>
      &nbsp;
      <em>Земля</em>
      <em>Галактика</em>
      <em>Вселенское безмерье</em>
      Всё проявилось жизнью для меня
      Всё развернулось
      Всё в моих объятьях
      В свободе Света пребываю я
      &nbsp;
      Здесь время ни к чему
      Здесь вовсе нет его
      Здесь только жизнь
      Здесь бесконечны дали
      &nbsp;
      <em>И если б Вы со мной здесь побывали</em>
      <em>То знали б</em>
      <strong><em>Лучшего нет в жизни ничего</em></strong>
    `
  },
  {
    id:'554',
    name: 'Из ничего слепил снежок',
    text: `
      <strong>И</strong>з <em>ничего</em> слепил <em>снежок</em>
      Упругий получился
      <em>Не помню</em>
      <em>Где когда и как такому научился</em>
      Он не велик но и не мал
      Нормального размера
      Со мной такое в первый раз
      <em>Но вышло так умело</em>
      Он на ладони у меня сверкает и искр<strong>и</strong>тся
      Сначала думал что я сплю
      <em>Что только не приснится</em>
      Но нет не сплю
      Держу в руке шар идеальной формы
      <em>Так было в первый раз со мной</em>
      <em>Сейчас же &nbsp;в рамках нормы</em>
      А в первый раз пока смотрел
      Мой шарик оживился
      И покатился по руке по шее покатился
      &nbsp;
      <em>В тот самый первый в жизни раз</em>
      <em>Мне шарик дал понять</em>
      <strong><em>Что&nbsp; силой мысли мы должны умело управлять</em></strong>
      &nbsp;
      С тех пор
      В любой буквально миг возможно что бы он возник
      Его не нужно долго ждать
      Вот он
      <em>Хотите подержать</em>
    `
  },
  {
    id:'555',
    name: 'Почаще к небу поднимай глаза',
    text: `
      <strong>П</strong>очаще к небу поднимай глаза
      <em>Тогда увидишь как сверкнёт гроза</em>
      <em>Тогда увидишь как сияет солнце</em>
      <em>Как облака рисуют чудеса</em>
      Почаще к небу поднимай глаза
      <em>Тогда увидишь синеву небес</em>
      <em>Тогда увидишь звёздный в небе крест</em>
      <em>И лунную тропу</em>
      <em>И высоту</em>
      &nbsp;
      <strong>А он в ответ</strong>
      &nbsp;
      <em>Хочу</em>
      <em>Но не могу</em>
      <em>Того боюсь</em>
      <em>Что оступлюсь</em>
      <em>В канаву с грязью провалюсь</em>
      &nbsp;
      Сказал
      И в тот же миг
      Упал
      <em>Тогда лишь только небо увидал</em>
      &nbsp;
      Чем дольше он смотрел
      Тем небо было глубже
      <em>А он лежал</em>
      <em>В пропахшей гнилью луже</em>
      &nbsp;
      <strong>Отсюда вывод прост</strong>
      &nbsp;
      <strong><em>Как ни смотри под ноги</em></strong>
      <strong><em>Собьёшься обязательно с дороги</em></strong>
      <strong><em>&nbsp;</em></strong>
      <strong><em>А если путь свой ты сверяешь с небом</em></strong>
      <strong><em>Дойдёшь туда</em></strong>
      <strong><em>Где ты покуда не был</em></strong>
    `
  },
  {
    id:'556',
    name: 'Кто объяснит',
    text: `
      <strong>К</strong>то объяснит
      <em>Что значит <strong>человек</strong></em>
      <em>Как он устроен</em>
      <em>Кем он управляем</em>
      <em>Что значит <strong>жизнь</strong> и <strong>человечий век</strong></em>
      Который мы различно проживаем
      Как так выходит
      <em>Что из ничего</em>
      <em>Как будто вдруг</em>
      <em>Рождаемся на свет</em>
      Вопросов много
      Но пока
      Лишь рассуждения в ответ
      &nbsp;
      <em>Мы рассуждаем обо всём </em>
      <em>И ни о чём в конечном счете</em>
      <em>Мы погрузились в темноту </em>
      <em>Не вспоминая о Природе</em>
      <em>О той природе высших свойств</em>
      <em>Что создаёт возможность <strong>быть</strong></em>
      <em>Но в тоже время </em>
      <em>Позволяет нам очень многое забыть</em>
      <em>О той Природе</em>
      <em>У которой на всё буквально есть ответ</em>
      <strong><em>О той</em></strong>
      <strong><em>Себя венцом которой считает глупый человек</em></strong>
    `
  },
  {
    id:'557',
    name: 'Мы загрубели восприятьем',
    text: `
      <strong>М</strong>ы загрубели восприятьем
      Нам
      Для того чтоб различить
      Необходимо на контрасте
      Сюжет событья получить
      &nbsp;
      В полутонах не разобраться
      В полутонах размыта грань
      Полутонов не восприятье
      Неразвитости нашей дань
      &nbsp;
      <em>По той причине и не видим</em>
      <em>Не чувствуем </em>
      <em>Не понимаем</em>
      <strong><em>Мы загрубели восприятьем</em></strong>
      <strong><em>Полутонов не различаем</em></strong>
    `
  },
  {
    id:'558',
    name: 'Природа цвета - это скорость света',
    text: `
      <strong>Природа цвета - это скорость света</strong>
      <strong>Чем выше скорость тем темнее цвет</strong>
      <strong>Лишь скорость света предопределяет </strong>
      <strong>Возможность видеть света цвет</strong>
      &nbsp;
      &nbsp;
      Различных уровней глубины Мирозданья
      Имеют разнородную сред<strong>у</strong>
      Среда
      Является простором созиданья
      Луча бесцветного в преломленном ряду
      В ряду градационных переходов
      Суть-степеней безмерных величин
      И их взаимосвязи со свободой
      Предельных уровней энергий сверхпричин
      <strong><em>&nbsp;</em></strong>
      <strong><em>&nbsp;</em></strong>
      <strong><em>А в отношеньи нас</em></strong>
      Всё что мы с вами видим
      Является проекцией того
      Что говорим
      Что думаем
      Что пишем
      Что делаем
      И прочие все <em>Что</em>
      <strong>&nbsp;</strong>
      <strong>&nbsp;</strong>
      <strong>Энергии</strong>
      Невидимые многим
      Собой питая уровни Миров
      Обратной связью нам напоминают
      Что Дух наш не свободен от <em>ок<strong>о</strong>в</em>
      Ок<strong>о</strong>в
      Рожденных низостью понятий
      Понятий
      Не раскрывшейся Души
      Души
      Живущей в ласковых объятьях
      Объятьях
      Ставших нормой грубой лжи
      &nbsp;
      &nbsp;
      Так стало быть
      Мы Сотворцы Природы
      И в тоже время мы её продукт
      А это значит
      Чем её наполним
      То тут же
      То же
      Нам и отдадут
      <strong><em>&nbsp;</em></strong>
      <strong><em>Отсюда</em></strong>
      Цветность восприятья Мира
      Отсюда и различья цвета глаз
      <em>А жизнь</em>
      <em>Она была и есть равно-красива</em>
      <em>Вот восприятье жизни разное у нас</em>
    `
  },
  {
    id:'559',
    name: 'Когда нибудь',
    text: `
      <strong>К</strong>огда нибудь
      <em>Я знаю</em>
      <em>Это будет</em>
      Я стану знать что дальше будет
      Я знаю
      Что себя познаю
      <em>Когда нибудь всё это будет</em>
      <em>Знаю</em>
    `
  },
  {
    id:'560',
    name: 'На зеркале жизни следы оставляем',
    text: `
      <strong>Н</strong>а зеркале жизни следы оставляем
      Мечтами
      Словами
      Своими делами
      И пачкаем <strong>так</strong>
      Отраженья не видно
      Поэтому
      Перед собою не стыдно
      &nbsp;
      <em>Но если кому-то увидеть удастся</em>
      <em>Тот сразу поймет</em>
      <em>Чем в грядущем воздастся</em>
    `
  },
  {
    id:'561',
    name: 'Перелистнул страничку жизни',
    text: `
      <strong>П</strong>ерелистнул страничку жизни
      Днём прошедшим
      Я записал на ней всё то чем день прож<strong>и</strong>л
      Вписал всех тех кого сегодня встретил
      В кого влюбился и кого не полюбил
      &nbsp;
      Я перечитываю прошлого страницы
      И эту также предстоит перечитать
      <em>Читая их</em>
      <em>Бывает сожалею</em>
      <em>Что ни одну из них нельзя переписать</em>
    `
  },
  {
    id:'562',
    name: 'Нам жарко в холоде',
    text: `
      <strong>Н</strong>ам жарко в холоде
      Мы мёрзнем на жаре
      Нам душно когда воздуха в достатке
      Устав
      Мы спать ложимся на заре
      А в целом
      Мы живём
      И всё в порядке
      <em>Ну что с того что некого любить</em>
      <em>Ну что с того что нас никто не любит</em>
      <em>И что с того что продолжаем быть</em>
      <em>И что с того что скоро нас не будет</em>
      Уход наш не изменит ничего
      Как и присутствие ничто не изменяет
      &nbsp;
      Но чувствуем
      <em>Что <strong>что-то</strong> здесь не то</em>
      <em>А что <strong>не то</strong></em>
      <em>Опять не понимаем</em>
    `
  },
  {
    id:'563',
    name: 'Не делай глупостей',
    text: `
      <strong>Н</strong>е делай глупостей
      <em>Не попадёшь впросак</em>
      Не делай подлостей
      <em>Не будешь презираем</em>
      Но мы об этом просто забываем
      И принимаем мудрость за пустяк
      &nbsp;
      Нас жизнь уже учила
      <em>И не раз</em>
      Но мы уроки не воспринимаем
      Живя ухабисто
      Ответ <strong>на то</strong> не знаем
      <strong><em>Идём ли мы сквозь жизнь</em></strong>
      <strong><em>Иль жизнь идёт сквозь нас</em></strong>
    `
  },
  {
    id:'564',
    name: 'Ночь не тёмное время суток',
    text: `
      <strong>Н</strong>очь не тёмное время суток
      Ночь полна разноцветных огней
      Тех
      Которые проявляют неосознанность пр<strong>о</strong>житых дней
      &nbsp;
      Пробегаем сквозь дни без оглядки
      А ночами не спим до утра
      Расшифровываем загадки порождённые глупостью дня
      &nbsp;
      Не беда если ночью не спится разбирая событий &nbsp;клубок
      Хуже если уснём не осмыслив
      Эта ночь нам не будет впрок
      &nbsp;
      Ночь не тёмное время суток
      Ночь полна разноцветных огней
      Тех
      Которые позволяют разобраться в ошибке своей
      &nbsp;
      Но вот вопрос
      <em>Что такое ошибка</em>
      <em>И как возможно её отыскать</em>
      Чтобы знать
      <strong><em>Что</em></strong> в дальнейшем нужно по возможности избегать
      &nbsp;
      <em>Чтобы нам отыскать ошибку</em>
      <em>Нам достаточно просто знать</em>
      <em>Что ошибка имеет свойство </em>
      <em>По ночам не давать нам спать</em>
      <strong>&nbsp;</strong>
      <em>Ну а коли Вы спите спокойно</em>
      <em>Совершив накануне её</em>
      <em>Это значит </em>
      <strong><em>Она Вас достойна</em></strong>
      <strong><em>Как и Вы достойны её</em></strong>
    `
  },
  {
    id:'565',
    name: 'Вдалеке',
    text: `
      <strong>В</strong>далеке
      Как туман растворилось
      Всё что было буквально вчера
      Вместо прошлого зыбью явилась
      Новизна предстоящего дня
      &nbsp;
      <em>А часы</em>
      <em>Отбивают ритмично</em>
      <em>Пульс назад уплывающих лет</em>
      <em>Совершавших обряды насилия</em>
      <em>Надо всеми кого <strong>уже</strong> нет</em>
      &nbsp;
      Вдалеке
      Как туман растворится
      Всё что будет сегодня и впредь
      Нынче новое
      Станет привычным
      Со способностью быстро стареть
      &nbsp;
      <em>Лишь часы</em>
      <em>Как и прежде</em>
      <em>Ритмично</em>
      <em>Будут пульс отбивать прошлых лет</em>
      <em>Совершая обряды насилия</em>
      <em>Надо всеми кого <strong>пока</strong> нет</em>
    `
  },
  {
    id:'566',
    name: 'Вечер просыпается',
    text: `
      <strong>В</strong>ечер просыпается
      Смеркается
      Нитью тонкой вытянулась мысль
      О мечтах
      Что часто не сбываются
      О ненужностях
      Которые сбылись
      Заплетая в косу получаемость
      Неизбежностью того что впереди
      Формирую в завтра неслучайность
      Следствием прошедшего пути
    `
  },
  {
    id:'567',
    name: 'Сначала тянется',
    text: `
      <strong>С</strong>начала тянется
      Позднее&nbsp; пролетает
      Бывает&nbsp; ровной мерностью шагов
      А в целом&nbsp; незаметно протекает
      И вот уж осень
      А за ней <em>Покров</em>
      &nbsp;
      <em>Мы так устроены что жизнь не замечаем</em>
      <em>В прошедшем всё сливается в одно</em>
      <em>И лишь в мгновенья краткий миг мы понимаем</em>
      <em>Что <strong>всё</strong> что есть <strong>то</strong> уж прошло давно</em>
    `
  },
  {
    id:'568',
    name: 'Вышло время захлопнув дверь',
    text: `
      <strong>В</strong>ышло время захлопнув дверь
      Она ему не нужна теперь
      И как ненужность закрыло окно
      Ни к чему теперь и оно
      Они были ему нужны
      Много лет и до сей поры
      А когда всё что было прошло
      Вслед за прошлым и время ушло
      Но я вижу в сегодняшних днях
      Как плутает в его следах
      Время <strong>то</strong> что пришло взамен
      Непривычностью перемен
    `
  },
  {
    id:'569',
    name: 'Направленьями Судеб',
    text: `
      <strong>Н</strong>аправленьями С<strong>у</strong>деб
      По дорогам ухабистым жизни
      Мчимся мы
      Нарушая правила
      Ослепляя друг друга взглядами
      Немигающими как правило
      Откровенность <em>сплошной-разделительной</em>
      Отражающей линией светится
      Позволяя потокам встречным
      Кратким мигом мгновения встретиться
      &nbsp;
      Протяжённость дорог различная
      Скорость жизни не одинакова
      От того переход полосы
      В свой черед для любого и всякого
      &nbsp;
      <em>Лишь когда переступим сплошную</em>
      <em>Осознаем потоки движения</em>
      <em>В них увидев</em>
      <em>Потоки ухода и потоки ново-рождения</em>
    `
  },
  {
    id:'570',
    name: 'Прилипает к подошвам путь',
    text: `
      <strong>П</strong>рилипает к подошвам путь
      Формируя следами тропинку
      Может быть
      Ты когда нибудь
      Пробежишь этот путь без заминки
      Пробежишь
      По следам того
      Кто сегодня его проложит
      Что быть может тебе&nbsp; поможет
      В обретеньи себя самого
      &nbsp;
      А быть может совсем не так
      Может сам ты
      По дальним далям
      Разбивая в лохмотья сандали
      Прилепляя к подошвам путь
      Для других сформируешь тропинку
      И быть может по ней без заминки
      Пробежит
      Может быть
      Кто нибудь
    `
  },
  {
    id:'571',
    name: 'Написал желанье на листке',
    text: `
      <strong>Н</strong>аписал желанье на листке
      Из листка соорудил кораблик
      Пусть плывёт под парусом мечты
      По волнам затейницы судьбы
      Пусть плывёт и в будний день и в праздник
      &nbsp;
      <em>Судьбоносный не простой маршрут</em>
      <em>Озаряемый моментами прозрений</em>
      <em>Отмывает добел<strong>а</strong> листок</em>
      <em>Мудростью стремительных мгновений</em>
    `
  },
  {
    id:'572',
    name: 'Течение уносит вдаль осколки',
    text: `
      <strong>Т</strong>ечение уносит вдаль осколки
      От прежде мной любимой лёгкой лодки
      <strong>Той</strong> на которой нам не страшен шторм
      <strong>Той</strong> на которой мы под парусом плывём
      <strong>Той</strong> на которой в бурю&nbsp; напролом
      <strong><em>Той лодки</em></strong><em> <strong>что мы юностью зовём</strong></em>
      <em>&nbsp;</em>
      Она разбилась от ударов о борта
      Борта <em>подлодки</em>
      <em>Что моей защитой стала</em>
      То погружусь на ней то вынырну устало
      <strong><em>Подлодка это зрелости пора</em></strong>
      <strong><em>&nbsp;</em></strong>
      Настанет время - перейду на плот
      Который меня в завтра понесёт
      Чтоб где-то там вдали всё началось сначала
      Что б лодка юности опять меня качала
    `
  },
  {
    id:'573',
    name: 'Я брёл на огонёк',
    text: `
      <strong>Я </strong>брёл на огонёк
      Казавшейся мне близким
      Манящим трепетом мерцающей свечи
      Луна раздетая не помня о приличьях
      Сиянье ногаты роняла на кусты
      И внемля ей
      Сорвав с Души одежды
      Отождествившись с раннею звездой
      Забыв всё то что было в жизни прежде
      Я полетел вдогонку за собой
    `
  },
  {
    id:'574',
    name: 'Я знаю что я есть',
    text: `
      <strong>Я</strong> знаю что <strong>я есть</strong>
      Я знаю что <strong>я здесь</strong>
      Но кто <strong>я есть здесь</strong>
      Я того не знаю
      А знать пора бы
      Кто <strong>есть я что здесь</strong>
      И для чего
      Для срама иль для славы
      &nbsp;
      Я знаю <strong>что я есть</strong>
      И есть <strong>не только здесь</strong>
      Живу везде
      Того <strong>кто я не зная</strong>
      <em>&nbsp;</em>
      <em>Как видно не готов я для того</em>
      <em>Чтоб жить везде себя осознавая</em>
    `
  },
  {
    id:'575',
    name: 'Часто',
    text: `
      <strong>Ч</strong>асто
      Очень часто так случается
      Что впотьмах в себе живём при ярком свете
      Что задумали того не получается
      Расстаёмся с кем хотим быть вместе
      &nbsp;
      Часто
      Очень часто так случается
      Понапрасну мы себя изводим
      Обижаемся
      А чаще обижаем
      Попрощавшись вовсе не уходим
      &nbsp;
      Часто
      Очень часто так случается
      Жизнь живём не видя тех кто рядом
      Солнце светит
      Тут же обжигаемся
      Дождь осенний
      Значит б<strong>и</strong>ты градом
      &nbsp;
      Часто
      Очень часто так случается
      Что себя совсем не понимаем
      День и ночь с&nbsp; Судьбой своей сражаемся
      Победить Судьбу свою желаем
      &nbsp;
      Часто
      Очень часто так случается
      И тогда мол<strong>и </strong>иль не мол<strong>и</strong>
      <em>День</em>
      <em>Далёким прошлым возвращается</em>
      <em>Прошлым</em>
      <em>Тем что ждёт нас впереди</em>
    `
  },
  {
    id:'576',
    name: 'Раздеваю снимая одежды',
    text: `
      <strong>Р</strong>аздеваю снимая одежды
      <em>Аккуратно одну за другой</em>
      И немного боюсь исполненья желанья
      <em>Обнажённой увидеть её пред собой</em>
      Вдруг иная она чем хотелось бы видеть
      Вдруг другая чем та что так долго искал
      Или вдруг осознаю
      Что <em>голую правду</em>
      Перед тем как раздеть
      В глупость сам обряжал
    `
  },
  {
    id:'577',
    name: 'Я сам себе заклятый друг',
    text: `
      <strong>Я </strong>сам себе заклятый друг
      Я сам себе надежный враг
      Я сам выстраиваю в круг
      Всё то
      Что делаю не так
      &nbsp;
      Я сам себя порой боюсь
      Порою сам собой горжусь
      Другой порою
      Сам себе
      Наивной глупостью кажусь
      &nbsp;
      Сегодня
      За себя я рад
      По той причине что сумел
      Увидеть самого себя
      И написать о том &nbsp;посмел
    `
  },
  {
    id:'578',
    name: 'Я уже перестал удивляться',
    text: `
      <strong>Я</strong> уже перестал удивляться
      Перестал удивляться тому
      Что я всё ещё удивляюсь
      Удивляюсь себе самому
      &nbsp;
      Познаванью себя нет предела
      Удивительно в этом <strong>то</strong>
      Что я сам в себе открываю
      <strong>То</strong> что прочим известно давно
    `
  },
  {
    id:'579',
    name: 'Со стороны всё кажется понятней',
    text: `
      <strong>С</strong>о стороны всё кажется понятней
      Яснее видится со стороны
      И потому советуем и судим
      Считая что мотивы нам ясны
      &nbsp;
      Не обольщайтесь
      Мы не так вел<strong>и</strong>ки
      Что бы&nbsp; помочь в решении дилемм
      <em>Ведь взгляд со стороны не открывает Истин</em>
      <em>И не даёт проникнуть в Суть чужих проблем</em>
      &nbsp;
      <em>Во всё что я сказал</em>
      <em>Поверить вы должны</em>
      <strong><em>Ведь мне видней со стороны</em></strong>
    `
  },
  {
    id:'580',
    name: 'Мы говорим что ситуация понятна',
    text: `
      <strong>М</strong>ы говорим что ситуация понятна
      <em>Что мол она ясна как белый день</em>
      При всём при этом недопонимаем
      Что означает <strong><em>свет</em> </strong>и означает <strong><em>тень</em></strong>
      &nbsp;
      Живём в плену наивных заблуждений
      Нам невдомёк что значит <strong><em>свето - тень</em></strong>
      Нам так же невдомёк что значит <strong><em>свет от тени</em></strong>
      <em>О том не думать нам хватает лени</em>
      Мы ограничены понятьем&nbsp; <strong><em>Да</em></strong> и <strong><em>Нет</em></strong>
      &nbsp;
      Мы пленники дремотного сознанья
      Считаем будто тело &ndash; человек
      Что всё то что мы знаем &ndash; это знанья
      <strong><em>Мы далеки от самоосознанья</em></strong>
      <strong><em>По той причине короток наш век</em></strong>
    `
  },
  {
    id:'581',
    name: 'Поспешность в принятьи решенья опасна',
    text: `
      <strong>П</strong>оспешность в принятьи решенья опасна
      <em>Вам ясным лишь кажется то что не ясно</em>
      Но и затягивать тоже не ст<strong>о</strong>ит
      <em>Порой промедленье не малого ст<strong>о</strong>ит</em>
      Чтоб взвешенным было решенье
      Придётся
      Увидеть чем спешность в конце обернётся
      А так же узреть результат промедленья
      Тогда лишь продуманным будет решенье
      &nbsp;
      При этом
      Всегда нужно помнить одно
      <strong><em>Душа Ваша знает сколь верно оно</em></strong>
    `
  },
  {
    id:'582',
    name: 'На отсюда далёком',
    text: `
      <strong><em>Н</em></strong><em>а отсюда далёком</em>
      <em>Другом берегу</em>
      <em>Отыщу</em>
      <em>Что на этом сберечь не смогу</em>
      Так я думал
      Когда Света Истин не знал
      И потерей совсем не потерю считал
      &nbsp;
      <em>А когда дальний берег дальним быть перестал</em>
      <em>Оказавшись на нём</em>
      <em>Я его не узнал</em>
    `
  },
  {
    id:'583',
    name: 'Устал',
    text: `
      <strong>У</strong>стал
      Но всё ж таки держусь
      Не поддаваться глупостям пытаюсь
      Бывает правда обижаюсь
      <em>За это позже на себя сержусь</em>
      То что понятно мне <strong>то </strong>им не объяснить
      Не оттого что слушать не желают
      Как будто слушают
      Но всё ж не понимают
      <em>Они другие</em>
      <em>Я не смею их винить</em>
      Конечно можно развернуться и уйти
      И пусть себе живут так как умеют
      Они о том&nbsp; не пожалеют
      <em>У них совсем иного свойства дни</em>
      Но что-то не даёт покинуть их
      Что не даёт
      Не то что понимаю
      Скорей Душою ощущаю
      <em>Я нужен им как мне нужны они</em>
      Зачем и для чего и почему
      Ответ в Природе
      Он весьма прозрачный
      <em>Бывает день как ночь густая - мрачный</em>
      <em>И ночи светлые - как дни</em>
    `
  },
  {
    id:'584',
    name: 'Терпенье кончилось',
    text: `
      <strong>Т</strong>ерпенье кончилось
      Выносливость осталась
      <em>Ну что ж родимая не подведи</em>
      Кругом капканы
      Петли
      Волчьи ямы
      Тропинки узкие сплетённые в круги
      Но впереди забрезжило свеченье
      <em>Неужто вырвусь</em>
      <em>То-то будет пир</em>
      Но остужаю я&nbsp; своей Души кипенье
      Покуда &nbsp;рано слушать звуки лир
      Пока что я на пол - пути к рассвету
      Пока что я гляжу из темноты
      И песнь моя пока ещё не спета
      Стихом прелестного сонета
      <em>Но знаю я</em>
      <em>Наступят эти дни</em>
    `
  },
  {
    id:'585',
    name: 'Что-то сделало время со мной',
    text: `
      <strong><em>Ч</em></strong><em>то-то сделало время со мной</em>
      Босоногим
      В коротких штанишках
      С тем что бегал с игрушкой подмышкой
      <em>Всё же сделало что-то со мной</em>
      &nbsp;
      <em>Как случилось понять не могу</em>
      Лишь вчера я плескался в пруду
      В отражении неба&nbsp; плескался
      А сегодня я в небе парю
      <em>Что же сделало время со мной</em>
    `
  },
  {
    id:'586',
    name: 'Нам есть о чём с тобой поговорить',
    text: `
      <strong>Н</strong>ам есть о чём с тобой поговорить
      Нам вечер мал и ночи не хватило
      Мы не одну свечу с тобой сожгли
      <em>Ну вот и эту утро растопило</em>
      <em>&nbsp;</em>
      Оно прозрачное пришло что б нас прервать
      И этим постараться дать понять
      Что главное в столь длительной беседе
      Есть <strong>то</strong>
      О чём смогли мы умолчать
      &nbsp;
      Нам есть о чём поговорить
      Нам ночь для разговора коротка
      И мало жизни
      Чтоб пересказать
      Всё <strong>то</strong>
      О чём смогли мы промолчать
    `
  },
  {
    id:'587',
    name: 'Ответьте мне',
    text: `
      <strong>О</strong>тветьте мне
      Вы можете увидеть <strong>цвет слова</strong> или <strong>мысли цвет</strong>
      Какого цвета слово <em>ненавидеть</em>
      Какого цвета слово <em>нет</em>
      Какого цвета слово <em>счастье</em>
      Какого цвета слово <em>высота</em>
      Слова какого цвета <em>вот несчастье</em>
      Какого цвета слово <em>красота</em>
      &nbsp;
      <em>Вы красите словами жизнь</em>
      <em>Не украшаете а цвет ей придаете</em>
      Не важно где Вы
      Дома
      На работе
      <strong><em>Вы красите словами жизнь</em></strong>
      <strong><em>И этим цветом Вы её живёте</em></strong>
    `
  },
  {
    id:'588',
    name: 'Взглядом мимолётным пробежал',
    text: `
      <strong>В</strong>зглядом мимолётным пробежал
      Не вдаваясь в подробности
      А возможность была
      Понять
      Отчего он живёт в безысходности
      &nbsp;
      Он и дальше будет бежать
      Не вникая
      Куда и зачем
      А возможность была
      Осознать
      В чём причина его проблем
      &nbsp;
      Ты кричишь ему
      <em>Остановись</em>
      А он бежит обгоняя тень
      Он по жизни вслепую бежит
      <em>Не зови его </em>
      <em>Он спешит</em>
    `
  },
  {
    id:'589',
    name: 'Созреть не давая',
    text: `
      <strong>С</strong>озреть не давая
      Срываю
      Вам странным покажется
      Знаю
      Но рву беспощадно один за другим
      Уж все оборвал
      Лишь остался один
      Всего лишь один
      Я его не сорву
      Я этим листом календарным живу
      Я жил им вчера
      Я прож<strong>и</strong>л им сегодня
      Бог даст проживу предстоящие дни
      И им я уйду
      В день когда за спиною окажутся <strong>те</strong>
      Что сейчас впереди
      &nbsp;
      <em>Есть в жизни у каждого разные дни</em>
      <em>Есть жизнь что отмерена календарями</em>
      <em>Но есть в жизни <strong>то </strong>что не мерят годами</em>
      <em>Есть <strong>то</strong></em>
      <em>Из чего состоялись они</em>
    `
  },
  {
    id:'590',
    name: 'Себя на куски разрываешь',
    text: `
      <strong>С</strong>ебя на куски разрываешь
      Скажи
      <em>Отчего так страдаешь</em>
      Скажи
      <em>Почему истязаешь себя</em>
      В ответ
      <em>Без того не прожить мне и дня</em>
      <em>Я рву свою плоть для того чтобы жить</em>
      <em>Иначе</em>
      <em>Мне незачем попросту быть</em>
      <em>И Вы бы не знались иначе со мной</em>
      <strong><em>Судьба</em></strong>
      Так сказал календарь отрывной
    `
  },
  {
    id:'591',
    name: 'Не в том проблема что подкралась старость',
    text: `
      <strong>Н</strong>е в том проблема что подкралась старость
      Проблема в том что молодость ушла
      Не в том проблема что пришла усталость
      Проблема в том что молодой осталась
      Моя
      Не постаревшая
      Душа
    `
  },
  {
    id:'592',
    name: 'Не бранись',
    text: `
      <strong>Н</strong>е бранись
      Не кричи
      Не злорадствуй
      Не проси
      Не дерзи
      Не лукавь
      Не хвались
      Не грози
      Не препятствуй
      Не подчиняйся но и не правь
      Не смотри
      Не дыши
      Не чувствуй
      Не обманывай
      Не убивай
      Не спеши
      Не люби
      Не сочувствуй
      Не дари и не отнимай
      &nbsp;
      <em>Для того чтобы было понятно</em>
      <em>Кто способен так жизнь прожить</em>
      <em>Отвечаю достаточно внятно</em>
      <strong><em>Тот</em></strong>
      <strong><em>Кто смог в этой жизни не быть</em></strong>
    `
  },
  {
    id:'593',
    name: 'Не оправдывайся',
    text: `
      <strong>Н</strong>е оправдывайся
      Не нужно
      Ты живёшь как умеешь жить
      Это правильно
      Несомненно
      Лишь собой подобает быть
      Не лукавь сам себе
      Не обманывайся
      Не манерничай предо мной
      Ты можешь нравиться мне иль не нравиться
      Оставайся самим собой
      <strong>То</strong> какой ты есть в самом деле
      Это лучшее для тебя
      Не вводи себя в заблуждение
      Будь собой
      Эта жизнь <strong>твоя</strong>
      <em>Но</em>
      <em>При всём этом нужно помнить</em>
      <em>А ещё лучше просто знать</em>
      <strong><em>Нарушеньем Вселенских Законов</em></strong>
      <strong><em>Жизнь не следует засорять</em></strong>
    `
  },
  {
    id:'594',
    name: 'Твой взгляд холодный как металл',
    text: `
      <strong>Т</strong>вой взгляд холодный как металл
      Опасно острый словно нож
      Твой взгляд похож на взгляд врага
      Твой взгляд на палача похож
      &nbsp;
      Ты хладнокровен и жесток
      Ты не простишь ему
      Он тоже
      Он так же смотрит на тебя
      <em>Из зеркала</em>
      <strong>Вы так похожи</strong>
    `
  },
  {
    id:'595',
    name: 'Есть люди откровенные и чистые',
    text: `
      <strong>Е</strong>сть люди откровенные и чистые
      Они как<em> солнышко лучистые</em>
      Есть люди словно<em> чёрная дыра</em>
      От них не стоит ждать тепла
      &nbsp;
      <em>Все люди совершенно разные</em>
      <strong><em>Но все по-своему прекрасные</em></strong>
    `
  },
  {
    id:'596',
    name: 'На зло другим счастливым стать не сможешь',
    text: `
      <strong>Н</strong>а зло другим счастливым стать не сможешь
      На зло другим свободным стать нельзя
      <strong>На зависть</strong> многим
      Быть счастливым можешь
      И быть свободным
      Равно как и я
      &nbsp;
      <em>Но чтоб свободой обладать</em>
      <em>Свободу нужно понимать</em>
      <em>Ин<strong>а</strong>че можно заплутать</em>
      <em>При этом этого не знать</em>
    `
  },
  {
    id:'597',
    name: 'От точки А до точки Б',
    text: `
      <strong>О</strong>т &nbsp;точки <strong><em>А</em></strong> до точки <strong><em>Б</em></strong>
      Кротчайший путь - <em>прямой</em>
      На самом деле
      Этот путь изогнутый дугой
      Пространство искривив его
      Лишь приоткрыло Суть
      <em>Возможно разберётесь в том и Вы</em>
      <em>Когда-нибудь</em>
      &nbsp;
      Когда Вы на листке бумажном
      <em>Практически без проволочек</em>
      Проложите маршрут линейный
      Соединением двух точек
      Тогда найдёте аргументы
      Чтоб упрекнуть меня в подвохе
      Но это будет легковесно
      <em>Ваш взгляд на тему не глубокий</em>
      &nbsp;
      <strong><em>Бумаг исписано не мало </em></strong>
      <strong><em>О том как подобает жить</em></strong>
      <strong><em>А Вы попробуйте</em></strong>
      <strong><em>Хоть день</em></strong>
      <strong><em>Так как предписано прожить</em></strong>
    `
  },
  {
    id:'598',
    name: 'От добра добра не ищут',
    text: `
      <strong><em>О</em></strong><em>т добра добра </em>не ищут
      За <em>добро</em> бывает взыщут
      Взыщут <strong>так </strong>что свет померкнет
      Взыщут <strong>так </strong>что больно жить
      Взыщут <strong>так</strong> что начинаешь добротою дорожить
      Дорожить
      Поглубже спрятав
      Чтоб не вырвалось <em>добро</em>
      Потому что очень часто неуместное оно
      &nbsp;
      <em>Может так</em>
      <em>А может нет</em>
      <em>Каждый знает свой ответ</em>
    `
  },
  {
    id:'599',
    name: 'К ясной цели не ясным путём',
    text: `
      <strong>К</strong> ясной цели не ясным путём
      Мы друг друга и денно и нощно несём
      Путь не прост и изрядно запутан маршрут
      На котором для нас есть и <em>пряник </em>и <em>кнут</em>
      Мы дор<strong>о</strong>ги не видим в толчее многих дней
      Мы толпою несомые мчимся по ней
      Мы цепляемся цепко за спины других
      А упавших мы топчем
      <em>Не жалко нам их</em>
      &nbsp;
      К ясной цели не ясным путём
      Мы друг друга и денно и нощно несём
      И когда завершаем этот долгий маршрут
      Лишь тогда понимаем
      <em>Что нас там не ждут</em>
    `
  },
  {
    id:'600',
    name: 'Солнцепад листом кленовым стелет дивные ковры',
    text: `
      <strong>С</strong>олнцепад листом кленовым стелет дивные ковры
      Жёлтым
      Огненно - бордовым расцвели дворы
      Город обрядился в осень
      День короче стал
      Дворник чтоб прибрать убранство спозаранку встал
      <em>Трёт метлой по тротуару словно по Душе</em>
      Переводит стрелки Время
      <em>Ведь Октябрь уже</em>
      И для нас листом кленовым улетает год
      Что грядущий заготовил
      <em>Знать бы наперёд</em>
      Дней что впереди&nbsp; не видно
      Но они придут
      <strong>То</strong> что нынче мы <em>посеем</em>
      <strong>То</strong> и <em>принесут</em>
    `
  },
  {
    id:'601',
    name: 'Врагов нам посылает Бог',
    text: `
      <strong>В</strong>рагов нам посылает Бог
      Друзей себе мы выбираем сами
      Так и живём
      Ведя борьбу с врагами
      А между тем
      С друзьями водку пьём
      &nbsp;
      <em>Но очень часто</em>
      <em>Тот что враг</em>
      <em>Поможет перейти овраг</em>
      <em>И очень часто</em>
      <em>Тот что друг</em>
      <em>Нас предает</em>
      <em>Как будто вдруг</em>
    `
  },
  {
    id:'602',
    name: 'Мне нужно доказать что Вы не хороши',
    text: `
      <strong>М</strong>не нужно доказать <em>что Вы не хороши</em>
      Причём
      Ни кто другой мне это не докажет
      <em>Мне всё равно что он о Вас расскажет</em>
      Способны доказать мне только Вы
      &nbsp;
      Прошу
      Поймите правильно меня
      Вам искренность доказывать не ст<strong>о</strong>ит
      Она и без того не мало ст<strong>о</strong>ит
      Коль искренне Вы - <em>значит хороши</em>
      &nbsp;
      Мне можно доказать что Вы не хороши
      Но и тогда я Вас судить не буду
      Скорей всего я просто Вас забуду
      Коль Вы докажите что Вы не хороши
      &nbsp;
      Я так живу
      <em>Но есть вопрос</em>
      <em>И он бесспорно актуален</em>
      <strong><em>Как получилось </em></strong>
      <strong><em>Что к себе </em></strong>
      <strong><em>Я более чем к Вам лоялен</em></strong>
    `
  },
  {
    id:'603',
    name: 'В который раз',
    text: `
      <strong>В</strong> который раз
      На том же самом месте
      Ты поскользнулся и упал
      Но быстро встал
      <em>Все косточки на месте</em>
      <em>Всего то час какой- то похромал</em>
      &nbsp;
      И вновь бегом
      Всё по тому же кругу
      Бегом в жару
      Бегом и в дождь и вьюгу
      Бегом не видя <em>мелочей</em>
      Бегом по кругу глупости своей
      &nbsp;
      <em>Но если б хоть на миг остановился</em>
      <em>То самому себе</em>
      <em>Премного б удивился</em>
    `
  },
  {
    id:'604',
    name: 'Растерзана',
    text: `
      <strong>Р</strong>астерзана
      Разорвана на клочья
      Истоптана в чужой грязи
      <strong><em>Но всё ж жива</em></strong>
      И дай ей Бог здоровья
      Душе
      Познавшей свойства мерзкой лжи
    `
  },
  {
    id:'605',
    name: 'Всех подгоняем под себя',
    text: `
      <strong>В</strong>сех <em>подгоняем под себя</em>
      И с<strong>у</strong>дим
      <strong>Су</strong>дим
      <strong>Су</strong>дим
      <strong>Су</strong>дим
      <em>Когда же мы добрее будем</em>
      Так нет же
      С<strong>у</strong>дим
      <strong>Су</strong>дим
      <strong>Су</strong>дим
      &nbsp;
      Всё <em>подгребаем под себя</em>
      Гребём
      Гребём
      Гребём
      Гребём
      <em>Что так нельзя </em>
      <em>Бог даст поймём</em>
      Ну а пока гребём
      Гребём
      &nbsp;
      И если жить так дальше будем
      Себя самих мы тем погубим
      <em>Как можно этого не знать</em>
      Но нам на это наплевать
      Мы продолжаем истязать
      Мы продолжаем истреблять
      Мы продолжаем убивать
      Всех тех кого рожала мать
    `
  },
  {
    id:'606',
    name: 'Все одинаково наивны',
    text: `
      <strong>В</strong>се одинаково наивны
      Все одинаково чисты
      Все одинаково безв<strong>и</strong>нны
      Все одинаково добры
      Все одинаково любимы
      Все одинаково скромны
      Все одинаково активны
      Все одинаково честны
      Все одинаково несчастны
      <em>Непониманием того</em>
      <em>Что одинаковость опасна </em>
      <em>Неосмыслением её</em>
    `
  },
  {
    id:'607',
    name: 'Жизнью считается',
    text: `
      <strong>Ж</strong>изнью считается
      <em>Всё что успел</em>
      <em>Всё что хотел</em>
      <em>Всё что посмел</em>
      Жизнью считается
      <em>Всё что сбылось</em>
      Жизнью считается
      <em>Что не пришлось</em>
      Жизнью считается
      <em>Жизнь воплоти</em>
      Жизнью считаются
      <em>Мысли твои</em>
      Жизнью считается
      <em>Всё что прошло</em>
      Жизнью считается
      <em>Что не дошло</em>
      Жизнью зовём
      <em>Настоящие дни</em>
      <strong><em>Пусть хоть какие</em></strong>
      <strong><em>Лишь</em></strong>
      <strong><em>Были б они</em></strong>
    `
  },
  {
    id:'608',
    name: 'Измучил время',
    text: `
      <strong>И</strong>змучил время
      Торопя его
      <strong>Ты</strong>
      Ожиданьем время <em>своего</em>
      В котором смог бы проявить себя
      Оно ж ушло
      Далёко
      Без тебя
      &nbsp;
      <em>Причина в том</em>
      <em>Что ты не смог понять</em>
      <em>Что время это <strong>то</strong></em>
      <em>Чего не нужно ждать</em>
    `
  },
  {
    id:'609',
    name: 'Начавшись с ручейка',
    text: `
      <strong>Н</strong>ачавшись с ручейка
      Вобрав в себя притоки
      Плывёт река лаская берега
      И в<strong>о</strong>ды той реки прозрачны и глуб<strong>о</strong>ки
      И небо в той реке купает облака
      &nbsp;
      Чем дальше в даль бежит
      Тем полноводней речка
      И вот уже могучая река
      Но <strong>не </strong>перемешать с потоками притоков
      Начальный стержень струйки ручейка
      &nbsp;
      <em>Начальность есть во всём</em>
      <em>В ней истинность сокрыта</em>
      <strong><em>Её нельзя смешать с тем что привнесено</em></strong>
      <em>И потому мой друг</em>
      <em>Живи Душой открытой</em>
      <em>А то грядущее окажется в былом</em>
    `
  },
  {
    id:'610',
    name: 'Коль ты не можешь быть мне другом',
    text: `
      <strong>К</strong>оль ты не можешь быть мне другом
      Не дай и повода мне&nbsp; стать твоим врагом
      Тогда лишь заживём не мучая друг друга
      <em>И это будет общая заслуга</em>
      Бог даст
      При этом дольше проживём
      &nbsp;
      <em>Довольно часто так бывает</em>
      <em>Когда людей объединяет </em>
      <em>Их неспособность быть друзьями</em>
      <em>И нежеланье быть врагами</em>
      <em>&nbsp;</em>
      <em>Но вариантов в жизни&nbsp; больше</em>
      <em>Есть <strong>те</strong> средь них</em>
      <em>Что много&nbsp; горше</em>
    `
  },
  {
    id:'611',
    name: 'Включился',
    text: `
      <strong>В</strong>ключился
      Выключился
      <em>Всё опять сначала</em>
      То натянул
      То скинул одеяло
      Глядишь
      И сутки пролетели
      <em>Так что ж с того на самом деле</em>
      &nbsp;
      Включился
      Выключился
      <em>Всё опять сначала</em>
      То натянул
      То скинул одеяло
      Глядишь
      И годы пролетели
      <em>Так что ж с того на самом деле</em>
      &nbsp;
      Включился
      Выключился
      Больше не включился
      <em>И что с того что навсегда забылся</em>
    `
  },
  {
    id:'612',
    name: 'Поговорим давай о том',
    text: `
      <strong>П</strong>оговорим давай о том
      <em>Что есть</em>
      <em>Что будет </em>
      <em>Что прошло</em>
      Что это давнее <em>прошло </em>тебе в <em>сегодня </em>принесло
      &nbsp;
      Ты жить <em>в сегодня</em> преуспел
      Ты окружил себя <em>добром</em>
      <em>Добро</em> в понятии твоём
      Я называю <em>барахлом</em>
      И <em>барахлом</em> не потому что не имею сам того
      А оттого что связи есть
      Меж <em>есть</em>
      <em>Меж будет</em>
      Меж <em>прошло</em>
      Ну а ещё
      <em>Уж так и быть</em>
      Тебе открою я секрет
      <em>Потом мы сможем говорить</em>
      <em>А если не захочешь</em>
      <em>Нет</em>
      &nbsp;
      Я знаю <strong>то </strong>что <strong>нынче</strong> есть
      Не зная <strong>будет что ещё</strong>
      И знаю <strong>что</strong> ушло туда
      Откуда это всё пришло
      &nbsp;
      Ещё я знаю только <strong>то </strong>
      Что всем&nbsp; что есть доволен я
      Что данность завтрашнего дня осветит новая заря
      Что солнцем выпитой росой умножат мудрость небеса
      Что я по ней бродил босой
      И в этот Мир влюблённый я
      Что неразрывная есть связь
      Меж мной и множеством планет
      &nbsp;
      <strong>И вот ещё что я скажу</strong>
      &nbsp;
      <em>Ах извини</em>
      <em>Тебя уж нет</em>
    `
  },
  {
    id:'613',
    name: 'Словно кубарем с горы',
    text: `
      <strong>С</strong>ловно кубарем с горы
      День за днём
      Неделя за неделей
      Мчатся
      Кувыркаются в пыли&nbsp;
      Наших мыслей
      Чувств и непрозрений
      Вместе с ними катимся и мы
      <em>Бьёмся больно плотью бытия </em>
      <em>Об уступы не осознанных причин</em>
      Катимся
      Ругаемся
      Кричим
    `
  },
  {
    id:'614',
    name: 'Полупрозрачностью прикрытый',
    text: `
      <strong>П</strong>олупрозрачностью прикрытый
      Дышащий свежестью зимы
      Могучий
      Молниями битый
      Великий дуб
      А рядом с ним
      Дрожа промёрзшими стволами
      Собрался в кучку березняк
      Трепещет хрупкими ветвями
      Его гнетёт зимы сквозняк
      &nbsp;
      <em>Вот так и мы</em>
      <em>Собравшись в кучу</em>
      <em>Дрожа душою от невзгод</em>
      <em>Сбиваемся вокруг могучих</em>
      <em>Могучий &ndash; лидер</em>
      <em>Мы &ndash; народ</em>
      &nbsp;
      А чуть поодаль
      На пригорке
      В своих нарядных шубках ёлки
      Им всё равн<strong>о </strong>зима иль лето
      <em>Они в любой сезон одеты</em>
      Стоят тихонечко в сторонке
      <em>Не подходи </em>
      <em>У них иголки</em>
      &nbsp;
      Вон корабельная сосна
      Стройна
      Красива
      Высока
      Уходит кроной в небеса
      Одна стоит
      Но ей не одиноко
      Ей с высоты видать далёко
      Ей видно <strong>то</strong>
      Что не увидеть <strong>тем</strong>
      Кто в кучу сбившись
      Новых ждёт проблем
    `
  },
  {
    id:'615',
    name: 'Она такая разная',
    text: `
      <strong>О</strong>на такая разная
      Есть чистая
      Есть грязная
      То стелет шёлком
      То крапивой
      Покорною бывает и строптивой
      Бывает яркою
      Бывает тусклою
      Бывает радостной
      Бывает грустною
      <em>Но у кого какая б ни бывала</em>
      <em>Её всегда и всем бывает мало</em>
      &nbsp;
      Я говорю о той
      Что есть у всех
      В которой каждый свой отыщет грех
      Я говорю о той
      Чьё имя
      <em>Жизнь</em>
      Которая к ошибкам нашим чуткая
      Которая у всех такая хрупкая
    `
  },
  {
    id:'616',
    name: 'Пытаемся навязывать',
    text: `
      <strong>П</strong>ытаемся навязывать
      Пытаемся рассказывать
      Пытаемся исправить
      Пытаемся ломать
      Пытаемся обманывать
      Пытаемся заманивать
      Пытаемся наказывать
      И как-то объяснять
      &nbsp;
      Они ж сопротивляются
      Ругаются
      Брыкаются
      То гневом возгораются в попытке устоять
      То молча соглашаются
      То вовсе замыкаются
      Пытаясь своё виденье процесса отстоять
      &nbsp;
      А мы тверды в решениях
      Убрав с пути сомнения
      Ещё сильней и яростней ведём борьбу за тех
      Кто нам родной по крови
      И кто любим до боли
      И тех кто в наших действиях пытается понять
      <em>Что служит нам причиной</em>
      <em>Без видимой причины</em>
      <em>Судьбу своих родимых </em>
      <em>Любимых чад менять</em>
    `
  },
  {
    id:'617',
    name: 'Позор и срам',
    text: `
      <strong>П</strong>озор и срам
      Я сам себя стесняюсь
      <em>Как мог такое сотворить</em>
      Да
      Признаюсь
      <em>Бывает что ругаюсь</em>
      А вот теперь посмел и <em>силу</em> применить
      &nbsp;
      Я слабость проявил прибегнув к <em>силе</em>
      И этим сам собою посрамлён
      Виновен я
      Что не хватило силы не применить <strong>то</strong> чем я наделён
      &nbsp;
      Теперь страдая мучаюсь в позоре
      В позоре пред собой
      <em>А Вам и невдомёк </em>
      Что я теперь с самим собой в раздоре
      Поскольку преступил дозволенный порог
      &nbsp;
      <strong>А Вы по-прежнему не видите причины</strong>
      <strong>Которой я пред Вами виноват</strong>
      Не понимаете
      <em>Что я своею силой </em>
      <em>На ход событий изменил Ваш взгляд</em>
    `
  },
  {
    id:'618',
    name: 'У одного',
    text: `
      <strong>У </strong>одного
      <em>Цель жизни</em>
      У другого
      <em>Само собой произошло</em>
      И это одного задело <em>за живое</em>
      А для другого плавно протекло
      &nbsp;
      У одного высокое давление
      Другого можно в космос отправлять
      У одного старанье и терпение
      А у другого просто вдохновение
      <em>И одному другого не понять</em>
      &nbsp;
      Один зубрит как школьник тему
      Другой свою выводит теорему
      Один выписывает <em>умные с</em>лова
      Другому Суть тех слов ясна
      Один не может отыскать покой
      Другой живёт в согласии с собой
      Один себя стремится показать
      Другой себя стремится осознать
      &nbsp;
      <em>Какой же вывод из сего</em>
      <strong><em>&nbsp;</em></strong>
      <em>А он достаточно простой</em>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Остановись</em></strong>
      <strong><em>В себя вглядись</em></strong>
      <strong><em>Узнай</em></strong>
      <strong><em>Ты первый иль второй</em></strong>
    `
  },
  {
    id:'619',
    name: 'Ну наконец-то я сообразил',
    text: `
      <strong>Н</strong>у наконец-то я сообразил
      Ну наконец-то мне понятно стало
      <em>И утомлённый этой маятой </em>
      <em>Роняю на диван себя устало</em>
      <em>&nbsp;</em>
      Довольно долго силился понять
      Но <strong>без</strong> толку
      Не находил причину
      Сейчас же умудрился распознать
      И в тот же миг тем растворил кручину
      &nbsp;
      Всё от чего не клеились дела
      Всё от чего проблемы одолели
      Перед глазами было каждый день
      <em>Всё очевидно было в самом деле</em>
      <em>&nbsp;</em>
      В часах настенных кончился завод
      И время в них остановилось
      <em>Жизнь протекает &ndash; время не идёт</em>
      Завёл часы и жизнь установилась
      С календаря листочки оборвал
      Которые хранили прожит<strong>о</strong>е
      Сегодняшнюю дату отыскал
      <em>Прошедшее &ndash; оно уже былое</em>
      &nbsp;
      И тем вернулся в настоящий день
      Дела решаются в котором
      Дело спорится
      &nbsp;
      <strong>Теперь я знаю</strong>
      <strong><em>Что с календарём</em></strong>
      <strong><em>Как и с часами </em></strong>
      <strong><em>Нам не стоит сориться</em></strong>
    `
  },
  {
    id:'620',
    name: 'Опадает листва с календарного блока',
    text: `
      <strong>О</strong>падает листва с календарного блока
      Подошёл год к концу
      Мне слегка одиноко
      Пролетел как экспресс
      Только ветром обдал
      Я вдогонку за ним
      <em>Но увы</em>
      <em>Не догнал</em>
      &nbsp;
      А хороший был год
      Словно друг закадычный
      Для меня этот год был весьма необычный
      Я весь срок пр<strong>о</strong>жил в нём словно в трепетном мае
      <em>А теперь его нет</em>
      <em>По нему я скучаю</em>
      &nbsp;
      Что собой принесёт тот который за дверью
      Может память мою он остудит метелью
      &nbsp;
      Я его попрошу
      Он услышит
      Я верю
      Попрошу чтобы был он как можно добрее
      Чтобы светлым он был
      Чтобы сильным и честным
      Чтобы умным он был и во многом полезным
      Чтобы ласковым
      Нежным
      И мудрым он был
      Чтобы всех сохранил
      Кто люб<strong>и</strong>м
      Не люб<strong>и</strong>м
      Чтобы после зимы наступила весна
      А затем
      Чтобы лето и осень
      <em>Чтобы вспомнил его я с любовью</em>
      <em>Тогда</em>
      <em>Когда мой календарь листья все с себя сбросит</em>
    `
  },
  {
    id:'621',
    name: 'Сегодня очень странный день',
    text: `
      <strong>С</strong>егодня очень странный день
      <em>Такой не часто но бывает</em>
      Себя укутываю в лень
      Не то что мысль
      А даже тень её меня не посещает
      &nbsp;
      <em>Сегодня високосный день</em>
    `
  },
  {
    id:'622',
    name: 'Нежными тонами ранней осени',
    text: `
      <strong>Н</strong>ежными тонами ранней осени
      Разукрасив солнце и листву
      Бредёт сентябрь
      Тропинкою протоптанной
      В милом сердцу смешанном лесу
      Очень скоро <strong>под </strong>ноги осыплется
      Золотом сверкающий шатёр
      Обнажит себя ольха бесстыдница
      Из одежды делая ковёр
      &nbsp;
      В эту пору чистых откровений&nbsp;
      Хочется подобием листвы
      Сбросить наземь груз своих сомнений
      И нагим бежать сквозь зиму до весны
      &nbsp;
      Ну а там
      Забурлит жизнь закр<strong>у</strong>жится
      Засмеётся новою листвой
      И разгонит тучи в майских лужицах
      Ветер чистый
      Дерзкий
      Холостой
      &nbsp;
      <em>И опять</em>
      <em>В новый раз</em>
      <em>По спирали</em>
      <em>Отражая собой ход времён</em>
      <em>Я соберу и разбросаю камни</em>
      <em>Которыми маршрут мой наделён</em>
    `
  },
  {
    id:'623',
    name: 'Проникновенные Миры',
    text: `
      <strong>П</strong>роникновенные Миры
      Тепло и ласку излучая
      Бездонномерной глубиной
      Взирают
      Душу освящая
      &nbsp;
      Твои глаза
      Их нет родней
      Умнее нет и нет нежней
      Твои глаза&nbsp;
      Глаза Миров
      Красноречивей многих слов
      Являют истинность Любви
      Которую мне даришь ты
    `
  },
  {
    id:'624',
    name: 'Зажёг свечу',
    text: `
      <strong>З</strong>ажёг свечу
      За дверью ночь логичным продолженьем дня
      Окутав торжеством луны туманом скрытые поля
      Творит волшебностью росы свой еженощный ритуал
      Чтобы к утру цветок Души над Миром Новью заблистал
      Горит свеча
      Даруя мне тепло Духовного огня что разливается во мне
      И этим светом счастлив я
      Гори
      Не угасай свеча
      В тебе свет мысли заключён
      В тебе любовь и жизнь моя и звёзд хрустальный перезвон
      Не угасай
      Гори свеча
      Твори святое волшебство
      Преобразовывай меня будя Природы естество
      &nbsp;
      <em>Когда же был оплавлен воск </em>
      <em>И свет свечи к утру погас </em>
      <em>Свеча</em>
      <em>Горящая во мне</em>
      <em>Сияла ярче</em>
      <em>В сотни раз</em>
    `
  },
  {
    id:'625',
    name: 'Грустная радость',
    text: `
      <strong><em>Г</em></strong><em>рустная радость </em>
      Понятие зрелое
      Вкус её словно вино
      Терпкое
      Выдержанное и светлое
      Таким насладиться не всем суждено
      Ведь для того чтоб вино <em>удал<strong>о</strong>сь</em>
      Много любви и терпения нужно
      Вино не приемлет к себе равнодушья
      Поэтому разным бывает оно
      &nbsp;
      <em>Грустная радость</em>
      Понятие зрелое
      И чтобы её испытать
      Нужно прожить все этапы брожения
      Чтобы доподлинно знать
      Что <em>грустная радость</em>
      Свойственна зрелости
      Вкус её словно вино
      Терпкое
      Выдержанное и светлое
      И ей насладиться не всем суждено
    `
  },
  {
    id:'626',
    name: 'Жёстким стуком колес',
    text: `
      <strong>Ж</strong>ёстким стуком колес
      Мелодичность дороги
      Вдаль уносит меня от привычных вещей
      Не грусти <em>старина</em>
      На оставленном мною пороге
      Покури
      Да и чарку налей
      &nbsp;
      Жёстким стуком колес
      Уплывают вчерашние будни
      Мелодичность пути
      Открывает мне завтрашний день
      Не грусти <em>старина</em>
      Не грустите былые подруги
      Я не буду скучать
      Всё что прожито - <em>тень</em>
      &nbsp;
      Новизной простираются светлые дали
      Новизной ощущений вздыхает рассвет
      Но выходит всё так
      Что забуду былое едва ли
      В новизне я печалюсь что вас рядом нет
      &nbsp;
      Жёстким стуком колес
      Мелодичность дороги
      Приближает меня
      К мной забытым вещам
      И опять я стою
      И курю на пороге
      Вспоминая о прожитых днях
      &nbsp;
      Жёстким стуком колес
      Мелодичность дороги
      Бередит мой покой
      Будоражит меня
      И стою я один
      На краю той дороги
      Что теперь не меня в даль уносит
      <strong>Тебя</strong>
    `
  },
  {
    id:'627',
    name: 'Ночь догорает утренним рассветом',
    text: `
      <strong>Н</strong>очь догорает утренним рассветом
      Бездонной синью плещется простор
      Я так и не уснул
      Но полон сил при этом
      И продолжаю <strong>странный</strong> разговор
      <em>Куда спешишь дорога бесконечная</em>
      <em>Тобою я живу</em>
      <em>Тобою я дышу</em>
      <em>А ты бежишь</em>
      <em>Мол время быстротечно</em>
      <em>И не спеша живя нельзя догнать Судьбу</em>
      &nbsp;
      Но так случилось что измучилась дорога
      Собой прижалась к моему плечу
      Её я <strong>о</strong>бнял
      И обращеньем к Богу
      Я поминальную по ней зажёг свечу
      &nbsp;
      Ночь догорала
      Восковой печатью путь закрывая
      Тот что я прошёл
      И я продолжил с пройденной дорогой
      Вести наш прежний странный разговор
      <em>Зачем спешила ты</em>
      <em>Дорога бесконечная</em>
      <em>Тобой я жил</em>
      <em>Тобою я дышал</em>
      <em>Пусть ты права была что время быстротечно</em>
      <em>Но всё же я свою Судьбу догнал</em>
      <em>Теперь мне нужно быть во много крат мудрее</em>
      <em>Ведь я не всё что ты давала приобрёл</em>
      <em>И путь совместный шёл -&nbsp; <strong>так </strong>&nbsp;как умею</em>
      <em>И так случилось что его прошёл</em>
      &nbsp;
      Ночь догорает утренним рассветом
      Бездонной синью плещется простор
      Я так и не уснул
      Но полон сил при этом
      И начинаю <strong>новый</strong> разговор
      <em>Куда теперь пойдем Судьба</em>
      <em>В какие дали</em>
      <em>Я по твоим следам дос<strong>е</strong>ле шёл</em>
      <strong>Она в ответ</strong>
      <em>Нас ждут такие д<strong>а</strong>ли </em>
      <em>Которых мы покуда не видали</em>
      <em>И дай-то Бог чтоб ты к ним путь нашёл</em>
    `
  },
  {
    id:'628',
    name: 'Всё будет хорошо',
    text: `
      <strong>В</strong>сё будет хорошо
      <em>Поверьте мне</em>
      <em>Я знаю</em>
      Пройдёт всё то что беспокоит Вас
      И новизной рассвета засияет открытый Смысл
      Неведомый сейчас
      &nbsp;
      Но для того
      <em>Поверьте мне</em>
      <em>Я знаю</em>
      Ещё при жизни нужно уяснить
      Что жили в этом Мире <strong>те</strong> кто просто были
      И <strong>те </strong>немногие кто смог при жизни БЫТЬ
    `
  },
  {
    id:'629',
    name: 'Мелкий моросящий непрерывный',
    text: `
      <strong>М</strong>елкий моросящий непрерывный
      И дождём его не назовёшь
      Так какой-то сыплется противный
      На тоску похож а не на дождь
      &nbsp;
      А другой
      Дождина оголтелый
      Бросится корёжить и ломать
      Хоть потоп
      До этого нет дела
      О последствиях не будет горевать
      &nbsp;
      Есть иной
      Которому все рады
      Лей родимый только не буянь
      И не мелочись как моросящий
      Дождь так дождь
      Жаре палящей дань
      &nbsp;
      <strong><em>У людей как у дождей характер</em></strong>
      <em>Тот </em>
      <em>Нытьём замучает</em>
      <em>Хоть плачь</em>
      <em>&nbsp;</em>
      <em>Другой </em>
      <em>Взрывной</em>
      <em>Безжалостный </em>
      <em>Холодный </em>
      <em>Беспощадный - вылитый палач</em>
      <em>&nbsp;</em>
      <em>Третий</em>
      <em>Добрый </em>
      <em>Ровный </em>
      <em>Чистый</em>
      <em>Мягкий</em>
      <em>От него не нужно ждать беды</em>
      <em>Он красивый </em>
      <em>Светлый и желанный</em>
      <strong><em>Мы такими же стараться быть должны</em></strong>
    `
  },
  {
    id:'630',
    name: 'Небо утром пробуждается',
    text: `
      <strong>Н</strong>ебо утром пробуждается
      Умывается прохладою
      И прозрачностью хрустальною насыщает новый день
      Родниковостью звучания
      Новой встречи ожиданием
      Красотой нерукотворною
      Растворяет ночи тень
      Небо
      Небо
      Высь глубинная
      Правда Вечности былинная
      Светом Высшего терпения продолжает нашу жизнь
      Жизнь которой мы удивляемся
      Жизнь которой наслаждаемся
      Жизнь земную
      Жизнь занятную
      <em>Жизнь во многом нам не понятную</em>
    `
  },
  {
    id:'631',
    name: 'Мы',
    text: `
      <strong><em>М</em></strong><em>ы</em>
      <em>Эмитируя любовь</em>
      <em>От поражений ждём удачи</em>
      <em>Платя копейку просим сдачи</em>
      <em>Проснувшись засыпаем вновь</em>
      <em>&nbsp;</em>
      И в этом видя смыслы дней
      Блуждаем по Судьбе вслепую
      И не хотим себе другую
      Тащ<strong>и</strong>мся волоком по ней
      &nbsp;
      Но если вдруг
      Взглянув на небо
      Увидим в небе облака
      В нас просыпается тревога
      И мысль о том <em>что жизнь плоха</em>
      &nbsp;
      Нам не нужны переживанья
      Мы жить в спокойствии хотим
      И чтобы погасить страданья
      Живя
      На небо не глядим
      &nbsp;
      <strong><em>А небо</em></strong>
      <strong><em>Торжеством сиянья</em></strong>
      <strong><em>Дарует нам грядущий день</em></strong>
      <strong><em>В котором мы</em></strong>
      <strong><em>Живя во мраке</em></strong>
      <strong><em>Свою рассматриваем тень</em></strong>
    `
  },
  {
    id:'632',
    name: 'Нащупала струну',
    text: `
      <strong>Н</strong>ащупала струну
      И вот её терзает
      Пытается извлечь Души нестройный звук
      Я ей не помогу
      Она ж того не знает
      Что звук тугой струны я сердцем берегу
      &nbsp;
      Я сердцем как колком меняю тембр звука
      То подтяну струну
      То отпущу слегка
      Тем превращая день в котором злится вьюга
      В весеннюю мечту
      И в пенье соловья
      &nbsp;
      И право слово зря
      Старанья прилагает хмарь за моим окном
      В попытке разозлить
      Того ей не дано
      Она и впрямь не знает
      Что <em>чем живёт Душа того не изменить</em>
    `
  },
  {
    id:'633',
    name: 'Глаза закрою и смотрю кино',
    text: `
      <strong>Г</strong>лаза закрою и смотрю кино
      Смотрю кино&nbsp; своим воображеньем
      Рисует мне оно <strong>то</strong> что прошло давно
      Сюжет рисует красочным вид<strong>е</strong>ньем
      <em>Колодец старенький скрипучий журавель</em>
      <em>Клюёт ведёрком в час по чайной ложке</em>
      Картинку эту видел на обложке
      В далёком детстве в городском дворе
      &nbsp;
      Как память сохранила этот кадр
      Как уберечь смогла
      Не понимаю
      <em>Как звать себя порою забываю</em>
      А тут вот <strong>на</strong> тебе
      Такое вспоминаю
      Что и не знаю <em>верить ли себе</em>
      &nbsp;
      Видать и впрямь серьёзней ничего
      Со мной в прошедшей жизни не случалось
      Тогда откуда эта ранняя усталость
      И что есть <strong>то</strong>
      Что жизнью протекло
      Что п<strong>о</strong>том протекло и кровью
      Печалью
      Болью и любовью
      Со скрежетом зубов и напряженьем
      Всё это перечёркнуто виденьем
      <em>Колодца старенького</em>
      <em>Что как журавель</em>
      <em>Клюёт ведёрком в час по чайной ложке</em>
      Картинку эту видел на обложке
      В далёком детстве
      В городском дворе
    `
  },
  {
    id:'634',
    name: 'Нет',
    text: `
      <strong>Н</strong>ет
      Я не плачу
      Это просто слёзы
      &nbsp;
      <em>Как ни стараюсь </em>
      <em>Не могу остановить</em>
      &nbsp;
      Всё от того
      Что вспомнились берёзы
      Которые мы бросились рубить
      &nbsp;
      <em>И что тут скажешь</em>
      <em>Мы всегда так жили</em>
      <em>Пришла пора</em>
      <em>За окнами мороз</em>
      &nbsp;
      Но вот беда
      Мне сердце остужает
      Огонь в печи
      Охапкой белых грёз
      &nbsp;
      &nbsp;
      <strong><em>И новая строка</em></strong>
      <em>Нет сил не записать</em>
      <em>&nbsp;</em>
      <strong>Пока горят дрова</strong>
      <strong>Так хочется молчать</strong>
    `
  },
  {
    id:'635',
    name: 'Скатился кубарем из мая в осень',
    text: `
      <strong>С</strong>катился кубарем из мая в осень
      Теперь ласкает Душу желтизна
      И спать теперь ложишься ровно&nbsp; в восемь
      И спишь без снов до самого утра
      &nbsp;
      <em>К чему на ум пришли такие строки</em>
      <em>О ком я их пишу</em>
      <em>Не угадать</em>
      <em>&nbsp;</em>
      Вот пропищал будильник
      Утро
      Восемь
      А я ещё и не ложился спать
      &nbsp;
    `
  },
  {
    id:'636',
    name: 'Снится будто бы не спится',
    text: `
      <strong>С</strong>нится будто бы не спится
      Снится будто &nbsp;в облаках&nbsp; я с руки кормлю жар-птицу
      А она вещает так
      <em>Тебе снится что не спится</em>
      <em>Тебе снится будто бы</em>
      <em>Кормишь ты с руки жар-птицу небывалой красоты</em>
      <em>&nbsp;</em>
      <em>Я рекомендую верить в то что ночью тебе снится</em>
      <em>Но особенно в ту ночь когда снится что не спится</em>
      <em>&nbsp;</em>
      <em>Как проснёшься так увидишь на своих ладонях ты</em>
      <em>След от коготков жар-птицы исполняющей мечты</em>
      <em>&nbsp;</em>
      Миг настал
      Я убедился
      <strong>Нужно верить вещим снам</strong>
      Потому что в них подсказка
      Предназначенная нам
    `
  },
  {
    id:'637',
    name: 'Весёлым будь',
    text: `
      <strong>В</strong>есёлым будь
      Не став смешным
      Серьёзным будь
      Не будучи угрюмым
      Будь добрым
      Не за счёт других
      И любящим&nbsp; любых иных
      Открытым будь
      Великодушным
      Будь справедливым
      Щедрым будь
      Будь умным и глубоким в пониманьи
      Будь сильным
      И счастливым будь
      <em>Тем ты откроешь дивный путь</em>
      <em>Тот что ведёт</em>
      <em>К взаимопониманью</em>
    `
  },
  {
    id:'638',
    name: 'Пришла пора',
    text: `
      <strong>П</strong>ришла пора
      Весенние ручьи питают землю
      Солнце пригревает
      Травинка нежная
      Собой
      Асфальт дорожный&nbsp; пробивает
      Ей суждено прожить свой век
      В пыли асфальтового ада
      <em>Она не ропщет на Судьбу</em>
      <em>Своей Судьбе травинка рада</em>
      Борясь за жизнь
      Она живёт превозмогая все невзгоды
      Ей сил хватает устоять перед разгулом непогоды
      &nbsp;
      Пришла пора
      Весенние ручьи питают землю
      Солнце пригревает
      Травинку нежную
      На заливном лугу
      Роса собою умывает
      Растёт травинка в шёлковом раю
      Вдыхая полной грудью аромат
      <em>И ропщет на Судьбу свою</em>
      <em>Что не достаточно мол аромат богат</em>
      &nbsp;
      <em>Но час настал</em>
      <em>И вышли косар<strong>и</strong></em>
      <em>Ей не дожить до завтрашней зари</em>
    `
  },
  {
    id:'639',
    name: 'Годы проходят',
    text: `
      <strong>Г</strong>оды проходят
      Стирая ребристости
      В прошлом тобой не решённых проблем
      И память лукавая прячет <em>в давнишнем</em>
      Фрагменты порой драматических сцен
      Но иногда всё же
      Всполохом боли
      Так полоснёт по сердечным рубцам
      А ты как и прежде винишь невиновных
      Зная Душой что виновен ты сам
      &nbsp;
      <em>Годы проходят</em>
      <em>Стирая ребристости</em>
      <em>Коих бы не было будь ты честней</em>
      <em>Но ты превращаешь кошмары в традиции</em>
      <em>Наспех тобой проживаемых дней</em>
      &nbsp;
      <strong><em>Спасибо</em></strong>
      <em>За то что Вы стих прочитали</em>
      <em>&nbsp;</em>
      <em>Я думаю</em>
      <em>В нём мы друг друга узнали</em>
    `
  },
  {
    id:'640',
    name: 'Вы хотите',
    text: `
      <strong>В</strong>ы хотите
      Чтобы стал он злым
      Ничего у вас не получится
      Ведь он счастлив тем
      Что придётся Вам
      В доброте его
      Мучиться
      Мучиться
      &nbsp;
      <em>Заменю <strong>одно слово</strong> всего</em>
      <em>Тем внесу в эту мысль изменения</em>
      <strong><em>Словом</em></strong>
      <em>Смысл кверху дном разверну</em>
      <em>Тем открою возможность прозрения</em>
      &nbsp;
      Вы хотите
      Чтобы стал он злым
      Ничего у Вас не получится
      Ведь он счастлив тем
      Что придётся Вам
      В доброте его
      <strong>Совестью </strong>
      Мучиться
    `
  },
  {
    id:'641',
    name: 'Что остаётся если ни прощать',
    text: `
      <strong>Ч</strong>то остаётся если <strong><em>ни</em></strong> прощать
      Приходится со многим соглашаться
      Оправдывать
      Когда смогли понять
      Что остаётся если <strong><em>ни</em></strong> прощать
      &nbsp;
      Но в чём секрет обратной стороны
      На это <strong>Вы</strong> ответить мне должны
      &nbsp;
      Что остаётся если <strong>не</strong> прощать
      Встать в полный рост
      И не боясь сражаться
      Иль не прощая на колени встать
      И так же не прощая пресмыкаться
      Что остаётся если <strong>не </strong>прощать
      &nbsp;
      <strong><em>Одна лишь буква</em></strong><em> изменила суть</em>
      <em>Раскрыв понятье проявленьем смысла</em>
      <strong><em>Того</em></strong><em> что жизнь подобье коромысла</em>
      <em>И что не всем дано подняв его шагнуть</em>
      <em>А коль дано шагнуть то чтоб не расплескать</em>
      <em>Ту драгоценность что налита в вёдра</em>
      <em>Иначе грань понятий будет стёрта</em>
      <em>С пустыми вёдрами бессмысленнен ваш путь</em>
      <em>А коль случиться расплескать одно</em>
      <em>То будет перевешивать второе</em>
      <em>И <strong>то</strong> которое окажется пустое</em>
      <em>Заставит вас с прямой тропы свернуть</em>
    `
  },
  {
    id:'642',
    name: 'Бывает так что сгрудятся дела',
    text: `
      <strong>Б</strong>ывает так что сгрудятся дела
      Перемешаются друг с другом
      И ты не можешь разобрать
      С чего решать их начинать
      <em>Они же давят</em>
      <em>Давят своим грузом</em>
      &nbsp;
      Совсем не просто разобраться в них
      Переплелись
      В один клубок смотались
      <em>Вот тут-то начинаешь вспоминать</em>
      <em>Сколь прежде мелкими они тебе казались</em>
      &nbsp;
      Решенье их не терпит суеты
      Но и затягивать решения не стоит
      Решенье каждой из проблем в свой срок
      <em>Поверь мне</em>
      Дорогого стоит
      &nbsp;
      И если уж такое допустил
      Начни с конца решать свои задачи
      Но нить не нужно обрывать
      Чтобы быстрее размотать клубок событий
      <em>В этом нет удачи</em>
      &nbsp;
      Коль оборвёшь
      Потом уже не свяжешь
      И этим вновь себя накажешь
      Поскольку нитью отмеряют дни
      Какими б ни были они
      А если свяжешь
      То узлы
      Укоротят длину в жгут заплетённой нити
      Страдать заставят
      Ведь они
      Как гвоздь в подошве вдаль идущей жизни
    `
  },
  {
    id:'643',
    name: 'Настал момент',
    text: `
      <strong><em>Н</em></strong><em>астал момент </em>
      <em>Потомство оперилось</em>
      <em>Пришла пора детишек научить </em>
      <em>Тому как в будущем им подобает жить</em>
      &nbsp;
      Вот утка жиром задыхаясь закосолапила к реке
      За ней гуськом и часто спотыкаясь
      Спешит семействие познать себя в воде
      Уроки мамы утки
      Что за диво
      <em>Снаружи хвостик</em>
      <em>Голова внизу</em>
      Легко ныряет мама и красиво
      <em>Но для чего не ясно никому</em>
      &nbsp;
      По завершении водной процедуры
      Гурьба весёлая торопится домой
      Где ждёт еда
      Где у плетня крапива
      И где у будки пёс сторожевой
      И где наевшись&nbsp; можно отлежаться
      В тенёчке иль на солнышке поспать
      Потом проснувшись можно покупаться
      Затем опять поесть и&nbsp; отдыхать
      &nbsp;
      <em>Уроки этой жизни скоротечны</em>
      <em>Они прерваться могут в миг любой</em>
      <em>Тем что закроют крышку от кастрюли</em>
      <em>Над уткиной поникшей головой</em>
      &nbsp;
      И в тоже время
      Тем же дивным утром
      <em>Подальше от людей и от кастрюль</em>
      Другой урок
      <em>Другого Мира чувства</em>
      Давал орлёнку его отец и друг
      &nbsp;
      Орла-отца советы принимая
      Страх пересилив
      Распрямив крыло
      Порывом дерзким вниз себя бросая
      Орленок ощутил что значит <em>высоко</em>
      &nbsp;
      Отец - Орел
      Бесстрастно наблюдая
      Как сын-орлёнок набирает стать
      Стремительность полёта набирает
      Давал совет
      <em>Не ст<strong>о</strong>ит воздух рвать&nbsp; </em>
      <em>Когда дыхание своё соединишь </em>
      <em>С дыханьем восходящего потока</em>
      <em>Тогда </em>
      <em>Ты в полной мере ощутишь </em>
      <em>Величие высокого полёта</em>
      &nbsp;
      Так время шло
      <em>Полёты и паденья</em>
      <em>Стремительность решительных рывков</em>
      <em>Разбор ошибок</em>
      Было и смятенье
      Но срок настал и он теперь таков
      <em>Опору под крылами ощущая</em>
      <em>Ликует </em>
      <em>До небес крылом достав</em>
      <em>То точку малую собою представляя</em>
      <em>То молнию </em>
      <em>То парус на ветр<strong>а</strong>х</em>
      &nbsp;
      <strong><em>Величественность гордого сословья</em></strong>
      <strong><em>В союзе с небом </em></strong>
      <strong><em>Ветром и землёй</em></strong>
      <strong><em>Он был и есть </em></strong>
      <strong><em>И вечно будет &ndash; символ</em></strong>
      <strong><em>Парящий днём и ночью надо мной</em></strong>
    `
  },
  {
    id:'644',
    name: 'Старательно стараешься лезть в гору',
    text: `
      <strong>С</strong>тарательно стараешься лезть в гору
      Сбиваешь ноги
      Руки в кровь истер
      Всё для того
      Чтобы с её вершины
      Увидеть смог пылающий костёр
      Костёр
      Что много выше чем тот холмик
      Тот
      На который ты сумел залезть
      А он тебе казался ростом с г<strong>о</strong>ру
      <em>По сути </em>
      <em>Для тебя он так и есть</em>
      &nbsp;
      <em>Для каждого имеются вершины</em>
      <em>Которые взывают и ман<strong>я</strong>т</em>
      <em>Но вот беда</em>
      <em>Не каждый в состояньи </em>
      <em>Преодолеть урочища преград</em>
      &nbsp;
      И если ты
      Нашёл в себе способность
      Вскарабкаться на кочку
      <em>Пусть пока одну</em>
      То я уверен <strong>в том</strong>
      Что очень скоро
      Ты
      Словно кочку
      Покоришь скалу
    `
  },
  {
    id:'645',
    name: 'Когда-то буйный',
    text: `
      <strong>К</strong>огда-то <em>буйный</em>
      Просто высох
      Букет из полевых цветов
      Преобразившись сутью новой
      Хранит в себе последний вдох
      &nbsp;
      Он в б<strong>о</strong>льшей мере уязвимый
      Чем прежде
      В пору сочных дней
      Когда вокруг него
      Глумливый
      Кружился бочкой юный шмель
      &nbsp;
      <em>В букете вижу отраженье</em>
      <em>Я</em>
      <em>Нашего преображенья</em>
      <em>И вижу я</em>
      <em>Как расцветают дали</em>
      <em>Ростками</em>
      <em>К<strong>о</strong>им жизнь мы дали</em>
    `
  },
  {
    id:'646',
    name: 'Причудливость форм солнцем тронутых льдинок',
    text: `
      <strong>П</strong>ричудливость форм солнцем тронутых льдинок
      Прозрачным намёком даёт мне понять
      Что время пришло
      Одеяние зимнее на более лёгкое что-то менять
      &nbsp;
      <em>Одежду сменить не большая проблема</em>
      <em>Гораздо сложней перестроить лады</em>
      <em>Чтоб в звуки Души в исполненьи фагота</em>
      <em>Вплетались прозрачностью чувства альты</em>
    `
  },
  {
    id:'647',
    name: 'Чуть тронуло рассветом небеса',
    text: `
      <strong>Ч</strong>уть тронуло рассветом небеса
      Едва качнулся воздух пробуждаясь
      Я
      Этим чудом наслаждаясь
      Лежал в стогу
      А юная роса
      Собой украсив спящую траву
      Преображение творила
      И луг мгновенно превратила
      <strong>В то</strong>
      Что &nbsp;рождало красоту
      И эта красочная зыбь
      Бросала в небо искры света
      Рисуя этим <strong>те</strong> сюжеты
      Что редко встретишь наяву
      &nbsp;
      И я решив что помогу
      <em>Ведь тоже рисовать могу</em>
      Стал помогать насколько мог
      <em>Испортив этим &nbsp;всё что смог</em>
      &nbsp;
      Бывает часто так
      <em>О чём&nbsp; и &nbsp;говорить</em>
      Помочь пытаясь можно навредить
      &nbsp;
      Не думая
      Стремимся помогать
      Там
      Где нам нужно просто созерцать
      &nbsp;
      Нерукотворное хватаем мы руками
      <strong><em>Да</em></strong>
      <strong><em>Нелегко Вселенной с нами </em></strong>
    `
  },
  {
    id:'648',
    name: 'Семь струн цветных',
    text: `
      <strong>С</strong>емь струн цветных
      Дугой на небе
      Аккордом пьяного дождя
      Увековечили себя
      &nbsp;
      Лаская струны лёгким ветром
      Луч солнца зажимал лады
      А мелодичный ранний вечер
      Собою проявлял мечты
      &nbsp;
      Вплетаясь в музыку цветную
      Цветной вдыхая перезвон
      С ромашкой
      Цветик - семицветик
      Букетом был мной обручён
      &nbsp;
      Семь лепестков семи желаний
      В семи загадочных Мирах
      Закрытыми семью замками
      От тех кто ощущает страх
      Страх перед собственной мечтою
      Страх перед слабостью своей
      Страх перед тем
      Что есть восьмое
      Когда загаданы все семь
      Страх невозможности понятья
      Приоритетности того
      <em>Что для него в момент мечтанья </em>
      <em>Окажется важней всего</em>
      Страх перед завтрашними днями
      И перед днями что прошли
      Страх за детей
      И страх за чувства
      <em>Что также будут учтены</em>
      <em>&nbsp;</em>
      <em>А в небесах дождём промытых</em>
      <em>По- прежнему</em>
      <em>Звучит аккорд</em>
      <em>Аккорд </em>
      <em>Вселенского мотива</em>
      <em>Аккорд</em>
      <em>Семи Вселенских нот</em>
    `
  },
  {
    id:'649',
    name: 'Лишь Вечности подвластно Время',
    text: `
      <strong>Л</strong>ишь Вечности подвластно Время
      Лишь Вечность формирует ход Времён
      Лишь Вечность наделяет Время бременем
      <strong><em>Блюсти баланс </em></strong>
      Что предопределён
    `
  },
  {
    id:'650',
    name: 'Звонок',
    text: `
      <strong>З</strong>вонок
      <em>Спокойно</em>
      <em>Скоро начинаем</em>
      &nbsp;
      Второй звонок
      <em>Не бойся</em>
      <em>Ты готов</em>
      &nbsp;
      И вот уж третий
      <em>Выход твой</em>
      <em>Встречаем</em>
      &nbsp;
      И встретили
      <em>Ах вот же ты каков</em>
      <em>&nbsp;</em>
      <em>Театр мимики и жестов</em>
      <em>А на подмостках</em>
      <em>В главной роли <strong>Ты</strong></em>
      <em>Улыбки</em>
      <em>Радость</em>
      <em>Слезы и веселье</em>
      <em>Грудь материнская</em>
      <em>И первые цветы</em>
      &nbsp;
      Так начал ты свою карьеру
      Актера
      В пьесе многих дней
      На сцене жизни жил
      Не зная режиссёра
      Себя считая автором ролей
      &nbsp;
      В иллюзии незнающей антрактов
      Оттачивая мастерство игры
      Ты так естественно играл спектакль
      Что смог забыть
      <em>Кто ж в самом деле Ты</em>
      Ты смог не вспомнить
      Своей главной роли
      Её значенья
      Для чего рождён
      <em>И занавес уж близок</em>
      <em>Эхом боли</em>
      <em>Так и не понятых тобой времён</em>
    `
  },
  {
    id:'651',
    name: 'Древо жизни',
    text: `
      <strong><em>Д</em></strong><em>рево жизни</em>
      Кто может его пояснить
      Показав в отношении <em>Рода</em>
      Кто приходится кем и кому
      Дни прихода на свет
      Или скорбные даты ухода
      &nbsp;
      Пустота
      Мы не знаем как движется кровь
      Потеряли понятия <em>кровной родни</em>
      Потому и живём в непонятьи себя
      Одинокие путники
      <em>Мытари мы</em>
      <em>&nbsp;</em>
      Мы безродны
      <em>Хоть это по сути не так</em>
      Мы безродны
      Незнанием предков своих
      Мы безродны
      Свой РОД растерявши в веках
      Мы безродны
      Влача одиночества дни
      &nbsp;
      <em>Есть чудаки</em>
      <em>Которые за мзду</em>
      <em>Род покупают</em>
      <em>Титулы и званья</em>
      <em>Они</em>
      <em>Бездушные</em>
      <em>Достойны состраданья</em>
      <strong><em>Так как безродные в прикупленном роду</em></strong>
          `
  },
  {
    id:'652',
    name: 'Не совпадают наши планы',
    text: `
      <strong>Н</strong>е совпадают наши планы
      С тем
      Что нам уготовил Бог
      И мы зализывая раны
      <em>Что следствием явились драмы</em>
      Из-под бровей глядим на храмы
      И вопрошаем
      <strong><em>Как ТЫ мог</em></strong>
      &nbsp;
      Непонимание процесса
      Неосмысленье сути дня
      В нас зарождают возмущенье
      И боль обиды за себя
      &nbsp;
      Лишь крайне редко удаётся
      Увидеть истинность причин
      Ввиду которых мы имеем
      Совсем не то что мы хотим
      &nbsp;
      Лишь крайне редко удаётся
      Понять желаний пустоту
      Сравнив их с тем что нам даётся
      <em>Точней сказать</em>
      <strong>Что воздаётся</strong>
      За нашу <em>слепоглухоту</em>
    `
  },
  {
    id:'653',
    name: 'Ты ждёшь свершения',
    text: `
      <strong>Т</strong>ы ждёшь свершения
      Ты терпишь
      Ты страдаешь
      Ты исполнение желанья подгоняешь
      И коль случилось так что не свершилось
      Взываешь к Богу
      <em>Ну скажи на милость</em>
      <em>Чем я перед тобою провинилась</em>
      <em>&nbsp;</em>
      <strong>А он в ответ</strong>
      <em>Ты искренне молилась</em>
      <em>И&nbsp; Я сменив свой гнев на милость</em>
      <em>С пути грядущего убрал твои страданья</em>
      <em>Ты ж это приняла как наказанье</em>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Уметь терпеть</em></strong>
      <em>Великая наука</em>
      <em>Но есть наука выше</em>
      <strong><em>Смочь Понять</em></strong>
      <em>И если сможешь</em>
      <em>Тем себе поможешь</em>
      <em>Как должное </em>
      <em>Событье воспринять</em>
    `
  },
  {
    id:'654',
    name: 'Подрастают дети',
    text: `
      <strong>Подрастают дети </strong>
      <strong>Вырастают</strong>
      <strong>Жизнь своя</strong>
      <strong>Проблемы чередой</strong>
      <strong><em>Ты ж стоишь как памятник прощенья</em></strong>
      <strong><em>С непокрытой белой головой</em></strong>
      <strong>&nbsp;</strong>
      Почему всё <strong>так</strong>
      Не по - другому
      Почему не так как у других
      Посмотрите
      <em>Вот земля</em>
      <em>Вот горы</em>
      <em>Вот река</em>
      <em>Вот небо</em>
      <strong><em>Сколько их</em></strong>
      И живут
      Друг другу не помеха
      Смыслы всех в один объединив
      Прославляют Землю Солнце Небо
      <em>Силы нет чтоб победила их</em>
      &nbsp;
      Словно в сказке горы вырастают
      <strong>Твои горы</strong> Матушка - Земля
      И плечом к плечу они врастают
      Корневищ подножьями в тебя
      Молодые
      Средние
      В расцвете сил и лет
      Простор тебе даря
      Раздвигают каменные плечи
      Над тобой Родитель &ndash; Мать - Земля
      &nbsp;
      А когда детей разделит пропасть
      И когда ты потеряв покой
      Плачешь беспрестанно днём и ночью
      <em>Горной родниковою слезой</em>
      Тогда дети
      Дети-перевалы
      Проявляя нерушимость уз
      Принимают на свои ладони
      Примирений братских светлый груз
      &nbsp;
      <em>Текут Века</em>
      <em>Седеют гор вершины </em>
      <em>Светлостью блистающих снегов</em>
      <em>А в объятьях их цветут долины</em>
      <em>Гладью шёлка</em>
      <em>Запахом цветов</em>
      <em>Ты же</em>
      <em>Покрывалом тихой ночи</em>
      <em>Укрываешь головы детей</em>
      <em>И заботой звёздною мерцаешь</em>
      <em>В проявленьи нежности своей</em>
      <em>&nbsp;</em>
      <em>&nbsp;</em>
      <em>А они </em>
      <em>Коленопреклоненно</em>
      <em>Гордо</em>
      <em>Но смиренно пред тобой</em>
      <em>Вечностью пронизывают время </em>
      <em>Над твоей седою головой</em>
      &nbsp;
      Ветер
      Их шлифует силуэты
      Солнце
      Раскаляя твердь дает
      А река
      Собою
      На рассвете
      Песню родниковую поёт
      &nbsp;
      <strong>Порастают дети</strong>
      <strong>Вырастают</strong>
      <strong>Жизнь своя </strong>
      <strong>Проблемы чередой</strong>
      <strong><em>Так живи детей своих прозреньем</em></strong>
      <strong><em>Их к тебе любовью неземной</em></strong>
    `
  },
  {
    id:'655',
    name: 'Конечно есть проблемы',
    text: `
      <strong>К</strong>онечно есть проблемы
      <em>Как без них</em>
      Хотя и дети выросли
      И дети есть у них
      Уклад семейный уж давно сложился
      И вновь проблема
      <em>Старший внук влюбился</em>
      Проблемы как грибы
      Точней сказать крапива
      <em>Коль не было б проблем зажили бы красиво</em>
      Так нет же
      <em>Магазин</em>
      <em>Потом готовка </em>
      <em>Стирка</em>
      <em>У внука младшего на ползуночках дырка</em>
      &nbsp;
      От этой жизни хочется завыть
      <strong>Тому </strong>
      Кто в состоянии забыть
      <strong>То </strong>главное что он <strong>не смеет</strong> забывать
      А это <strong>то</strong>
      Что у него<strong> есть мать</strong>
      &nbsp;
      <em>Всё что сегодня ты недостираешь</em>
      <em>Недоготовишь</em>
      <em>Не дошьёшь</em>
      <em>От этого не много потеряешь</em>
      <strong><em>Вот маму потеряешь</em></strong>
      <strong><em>Не найдешь</em></strong>
    `
  },
  {
    id:'656',
    name: 'В делах увязли',
    text: `
      <strong>В</strong> делах увязли
      В мельтишне
      В интригах
      Забыли кто мы есть и для чего
      Забыли что есть Бог
      Нам ближе поп - расстрига
      Живём в бреду не помня ничего
      &nbsp;
      Хотим казаться
      <em>Не умея быть</em>
      Стремимся подражать
      Тем кто сумел творить
      Того не понимая
      <em>Что творенье</em>
      <em>Иметь не может повторенья</em>
      &nbsp;
      Мы судим всех подряд
      Присвоив с<strong>у</strong>дей роль
      <em>При том не чувствуем за осужденье боль </em>
      Для нас важнее антураж
      Мы далеки от истинных понятий
      Живём в горниле собственных проклятий
      Горя в огне всех не простивших нас
      <strong>&nbsp;</strong>
      <strong><em>За то что вы себя корите</em></strong>
      <strong><em>Подсказку </em></strong>
      <strong><em>Как мой дар </em></strong>
      <strong><em>Примите</em></strong>
      <strong><em>&nbsp;</em></strong>
      <strong><em>Остановитесь</em></strong>
      <em>Умоляю вас</em>
      <em>И жизнь наполнится Безбрежным Чистым Светом</em>
      <strong><em>В иные </em></strong><em>образуя ваши дни</em>
      <em>И вы <strong>мои любимые</strong> при этом</em>
      <em>Познаете <strong>Свет Истиной Любви</strong></em>
    `
  },
  {
    id:'657',
    name: 'Ни кола ни двора',
    text: `
      <strong>Н</strong>и кола ни двора
      Денег только на хлеб и на водку
      И одежда такая что впору сжигать
      И гурьба сизарей в ожидании крошек
      <em>Они знают что он им не может не дать</em>
      &nbsp;
      А вокруг зелень лета утопает в рекламных распятьях
      На огромных щитах предложений - не счесть
      А он не видит их блеска в неоново-ярких объятьях
      Кормит он голубей
      <em>Благо голуби есть</em>
      &nbsp;
      Ни кола ни двора
      Денег только на хлеб и на водку
      И одежда такая что впору сжигать
      И гурьба сизарей в ожидании крошек
      <em>Они знают что он им не может не дать</em>
      &nbsp;
      А вокруг белым саваном зимняя стужа
      Засыпает щиты с предложеньями коих не счесть
      Кормит он голубей
      С ощущеньем <em>хоть птицам да нужен</em>
      Кормит крошками их
      <em>Благо голуби есть</em>
    `
  },
  {
    id:'658',
    name: 'Напьётся бывало',
    text: `
      <strong>Н</strong>апьётся бывало
      Мечтает
      <em>Что завтра же прекращает</em>
      А завтра не получается
      <em>Коль трезвый &ndash; душою мается</em>
      &nbsp;
      Напьётся бывало
      Хохочет
      Душа посмеяться хочет
      <em>На трезвую не получается</em>
      <em>Коль трезвый &ndash; душа запирается</em>
      &nbsp;
      Напьётся бывало
      И плачет
      О том что не знает удачи
      <em>А трезвый плакать не может</em>
      <em>Гордость изнанку гложет</em>
      &nbsp;
      Напьётся бывало
      И добрый
      Он пьяный &ndash; душою ровный
      <em>А трезвый бывает жестоким</em>
      <em>Так и живёт одиноким</em>
      &nbsp;
      Напьётся бывало
      И молится
      <em>На трезвую как-то не можется</em>
      <em>На трезвую не получается</em>
      <em>Открытой душою каяться</em>
      &nbsp;
      Напьётся бывало
      И скажет
      <em>Придёт время Бог накажет</em>
      Потом помолчав добавляет
      <em>Бывает что и прощает</em>
      &nbsp;
      Чуть позже
      Уже засыпая
      Стакан из руки выпуская
      Чуть слышно под нос бормочет
      Что просыпаться не хочет
    `
  },
  {
    id:'659',
    name: 'Отчего ты седой',
    text: `
      <strong>О</strong>тчего ты седой
      Молодой человек
      Почему взгляд угрюм а не весел
      Почему не поёшь когда <em>горькую</em> пьёшь
      Прежде знал много разных ты песен
      Что пришлось испытать
      Что пришлось пережить
      Не молчи
      Расскажи
      Полегчает
      А в ответ
      Лишь слеза из-под пышных ресниц
      Горькой правдой войны набегает
    `
  },
  {
    id:'660',
    name: 'Мне к сожаленью повезло',
    text: `
      <strong>М</strong>не к сожаленью повезло
      К несчастью повезло
      Не скрою
      Произошло всё как в кино
      Случилось это уж давно
      С тех пор живу
      И тихо вою
      &nbsp;
      А у Сереги всё не так
      К его же счастью всё иначе
      Он сразу был убит
      А я
      Живу без ног
      Пью за удачу
      &nbsp;
      <em>Ещё стаканчик пропустив</em>
      <em>Он улыбнулся</em>
      <em>И сказал</em>
      Вот если б был Серёга жив
      Он завтра
      Мне б на водку дал
    `
  },
  {
    id:'661',
    name: 'Ладони тыльной стороной',
    text: `
      <strong>Л</strong>адони тыльной стороной
      Тебя касаюсь осторожно
      И состоянье столь тревожно
      Не обрету никак покой
      &nbsp;
      Я долго шёл к тебе
      К родной
      И вот теперь с тобою рядом
      И катятся слезинки градом
      И трогаю тебя рукой
      &nbsp;
      Мы оба помним этот бой
      В котором ты меня прикрыла
      В тебе была Святая Сила
      В красивой юной но седой
      &nbsp;
      Ты вся дрожишь моя родная
      Узнала
      Через столько лет
      Мы пр<strong>о</strong>жили с тех пор не мало
      И ты чуть-чуть другою стала
      Но для меня роднее нет
      &nbsp;
      Я знаю
      Много нас спасённых
      Тобой спасённых
      Как и я
      Прими поклон от нас рожденных
      Берёзка милая моя
    `
  },
  {
    id:'662',
    name: 'А сколько килограммов весит боль',
    text: `
      <strong>А</strong> сколько килограммов весит боль
      Что на плечах лежит обузой
      К земле сгибая тяжким грузом
      Так кто мне скажет
      <em>Сколько весит боль</em>
      &nbsp;
      А кто мне скажет сколько весит страх
      Ведь эта тяжесть подломила многих
      Всех тех кому недоставало сил
      Страх беспощадно раздавил
      &nbsp;
      Ну что ж
      Скажите мне тогда
      <em>Какая сила</em>
      <em>В чём заключен<strong>а</strong></em>
      Что поднимает в полный рост
      Летать даёт до дальних звёзд
      И всем лишеньям вопреки
      И даже тем кто старики
      Даёт способность посмеяться
      Даёт способность помечтать
      Даёт способность груз держать
      Даёт возможность долго жить
      И тем что свято дорожить
      Не разменявшимся&nbsp; за грош
      Даёт понять сколь Мир хорош
      Ушедшим
      В Мир вернуться вновь
      &nbsp;
      <em>Я понял</em>
      <em>Звать её - <strong>Любовь</strong></em>
    `
  },
  {
    id:'663',
    name: 'Искривляю пространство буквами',
    text: `
      <strong>И</strong>скривляю пространство буквами
      Из которых сплетая слова
      Проявляю душевными чувствами
      Мир прозрачного бытия
      &nbsp;
      Слово к слову
      <em>Рождение строчки</em>
      Строчка к строчке
      <em>Рожденье стиха</em>
      <strong>Что Сознание отрезвляет</strong>
      <strong>Словно капля нашатыря</strong>
    `
  },
  {
    id:'664',
    name: 'Живите так как хочет жить Душа',
    text: `
      <strong>Ж</strong>ивите так как хочет жить&nbsp; Душа
      Тогда и жизнь к вам благосклонней будет
      А коль иначе жить тогда Душа осудит
      <em>Она во все века для всех судья</em>
      &nbsp;
      Живите так как хочет жить Душа
      Она для Вас подсказчик неизменный
      Не важно Вы богатый или бедный
      Живите так как хочет жить Душа
      &nbsp;
      Душа Вас проведёт чрез лабиринт
      Страстей желаний горечей обид
      В любых событиях Душа Вас сохранит
      <em>Когда живёте Вы <strong>так</strong> как Душа велит</em>
      <em>&nbsp;</em>
      <strong>Живите так как хочет жить Душа</strong>
      <strong>Не бойтесь этого а к этому стремитесь</strong>
      <strong>И Вы себе не мало удивитесь</strong>
      <strong>Когда поймёте &nbsp;сколь мудра Душа</strong>
    `
  }
]