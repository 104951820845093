export const  gallerys =  [
  { 
    id: 'ecology-of-consciousness',
    name: 'Экология Сознания',
    titel: 'Авторский тематический цикл',
    forfard1:`Синтез живописи и поэзии. Авторский тематический цикл доктора философии, поэта и живописца Игоря Булгакова (члена творческого Союза художников России, члена Союза писателей России).`,

    forfard2:`Стихи и картины Булгакова – некий особый вид творчества, которому пока нет названия. Но оно никого не оставляет равнодушным. Одни становятся его почитателями, другие реагируют более сдержанно. Но все остаются заинтересованными, пытаясь каждый на свой лад объяснить этот феномен.`,

    forfard3:`То, о чем Игорь Булгаков пишет в стихах: любовь, подлость, вечная борьба двух начал, - он визуализирует на своих полотнах, которые всегда создаются только за один сеанс. Главные герои всех картин художника, объединенных автором в цикл «Экология Сознания», − Человек и Вселенная. В картинах Булгакова в центре сюжета – микрокосмос внутреннего мира человека, который проходит путь духовного, нравственного очищения – катарсиса. Автор задается вопросом: «…мы считаем, что понятие экология включает в себя исключительно физическое засорение природы. И услышав об экологии Мысли, экологии Души, экологии Сознания, мы всерьез начинаем думать над тем, что это - шутка или глупость?». Ответы найдет зритель, внимательно вглядывающийся в живописный мир Булгакова. Ему откроется безграничность сознания и бескрайность творчества, бесконечность оттенков мироощущения и «многоликость моментов». На некоторых работах автора присутствуют поэтические строчки, которые, несомненно, дополнят восприятие метафизической живописи.`,
    
    quote:`
      Когда бы нам увидеть дали ТО
      Что кроется за гранью пониманья 
      Возможно некоторым это помогло б 
      Расширить зону самоосознанья`,
    paintings: [

      // Новые со стихами
      '040-90х70-2013.jpg',
      '034-90х70-2015.jpg',
      '162-100х80-2022.jpg',

      '110-100х70-2018.jpg',
      '004-90х80-2015.jpg',
      '073-100х80-2017.jpg',

      '001-80х100-2005.jpg',
      '002-90х60-2005.jpg',
      '153-100х80-2020.jpg',

      '101-80х50-2016.jpg',
      '157-100х80-2021.jpg',
      '070-100х80-2017.jpg',

      '132-100х50-2019.jpg',
      '066-110х90-2017.jpg',
      '031-80х60-2014.jpg',

      '076-100х80-2017.jpg',
      '116-100х70-2018.jpg',
      '109-120х100-2018.jpg',

      '154-100х80-2020.jpg',
      '122-80х70-2019.jpg',
      '010-80х60-2016.jpg',

      '027-80х60-2015.jpg',
      '078-70х60-2015.jpg',
      '011-70х60-2015.jpg',

      '108-100х60-2018.jpg',
      '052-70х60-2014.jpg',
      '111-100х80-2018.jpg',

      '143-100х80-2019.jpg',
      '097-80х60-2015.jpg',
      '149-100х80-2020.jpg',

      '064-100х80-2016.jpg',
      '152-100х80-2020.jpg',
      '030-80х60-2015.jpg',

      '067-100х60-2016.jpg',
      '156-100х80-2021.jpg',
      '131-100х70-2019.jpg',

      '133-100х80-2019.jpg',
      '119-100х60-2019.jpg',
      '017-60х80-2016.jpg',

      '071-110х60-2016.jpg',
      '142-100х100-2020.jpg',
      '160-70х90-2006.jpg',

      '087-100х70-2018.jpg',
      '053-70х60-2015.jpg',
      '037-80х60-2015.jpg',

      '140-100х80-2019.jpg',
      '019-70х70-2016.jpg',
      '117-100х80-2019.jpg',

      '102-100х80-2018.jpg',


      // Старые со стихами
      'pic_02.jpg',
      'pic_03.jpg',

      'pic_04.jpg',
      'pic_05.jpg',
      'pic_06.jpg',

      'pic_08.jpg',
      'pic_09.jpg',
      'pic_10.jpg',

      'pic_11.jpg',
      'pic_12.jpg',
      'pic_14.jpg',

      'pic_16.jpg',
      'pic_17.jpg',
      'pic_18.jpg',

      'pic_20.jpg',
      'pic_22.jpg',
      'pic_24.jpg',

      'pic_25.jpg',



      // Без стихов
      '060-110х90-2016.jpg',
      '061-100х70-2016.jpg',

      '062-100х70-2016.jpg',
      '063-100х60-2017.jpg',
      '072-100х70-2017.jpg',

      '074-100х80-2017.jpg',
      '075-110х90-2017.jpg',
      '077-100х70-2017.jpg',

      '008-60х70-2014.jpg',
      '058-100х70-2016.jpg',
      '086-100х80-2018.jpg',

      '088-100х80-2018.jpg',
      '090-100х80-2018.jpg',
      '092-100х70-2017.jpg',

      '107-100х80-2018.jpg',
      '112-100х80-2018.jpg',
      '118-80х60-2013.jpg',

      '005-90х70-2015.jpg',
      '079-100х70-2017.jpg',
      '009-90х60-2014.jpg',

      '016-70х90-2008.jpg',
      '024-80х60-2015.jpg',
      '020-70х60-2014.jpg',

      '021-80х60-2015.jpg',
      '022-100х50-2016.jpg',
      '023-90х50-2015.jpg',

      '018-70х70-2016.jpg',
      '026-90х60-2016.jpg',
      '054-80х60-2014.jpg',

      '055-100х80-2016.jpg',
      '056-100х80-2016.jpg',
      '057-100х80-2016.jpg',

      '082-70х80-2016.jpg',




    ] 
  }
]