export const  paintings =  [
  // «Экология созная»

    // Новые со стихами

    //1
  {
    img: '040-90х70-2013.jpg',
    preview:'040-90х70-2013_400.jpg',
    height:'542',
    number: '1',
    material: 'Холст',
    paints: 'масло',
    size: '90х70',
    year: '2013',
    poem:`
      Ты хочешь знать что будет завтра
      Скажу
      Но прежде ты ответь
      <em>Что мой ответ в тебе изменит</em>
      <em>Что в дне сегодняшнем отменит</em>
      <em>Иль может привнесёт в него</em>
      Я жду ответа твоего
      &nbsp;
      Молчишь
      <em>Ты вижу испугался</em>
      Так я тебя не тороплю
      Когда с собою разберёшься
      Зови
      Я вновь к тебе приду
      Тогда быть может потолкуем
      Поразмышляем что к чему
      &nbsp;
      <strong><em>Подумай</em></strong>
      &nbsp;
      <strong><em>Хорошо подумай</em></strong>
      &nbsp;
      <strong>Как знать что я тебе скажу</strong> 
    `
  },

    //2
  {
    img: '034-90х70-2015.jpg',
    preview:'034-90х70-2015_400.jpg',
    height:'542',
    number: '2',
    material: 'Холст',
    paints: 'масло',
    size: '90х70',
    year: '2015',
    poem:`
      Здесь
      В <em>розовом</em> Раю
      Пушистая трава
      Зеркальна гладь воды
      Здесь слышно пенье птичье
      И небосвод всецветный здесь всегда
      Неописуемо велик своим величьем
      &nbsp;
      Здесь
      В <em>розовом</em> Раю
      Коль дождик так грибной
      Коль ветерок так ласковый и нежный
      И воздух здесь прозрачно-кружевной
      И сон глубокий сладкий безмятежный
      &nbsp;
      Здесь
      В <em>розовом</em> Раю
      Нет горя и нужды
      Здесь каждый чист и свят
      Здесь каждый откровенен
      Любой здесь чей-то внук
      <em>сестра</em>
      <em>ребенок</em>
      <em>брат</em>
      И нет средь них таких кто в чём-то виноват
      &nbsp;
      Здесь
      В <em>розовом</em> Раю
      Мы начинаем <strong>быть</strong>
      И проживаем срок в преддверии детства
      Здесь
      В <em>розовом</em> Раю
      Хотим подольше жить
      Но жизнь уводит нас в иное место
    `
  },

    //3
  {
    img: '162-100х80-2022.jpg',
    preview:'162-100х80-2022_400.jpg',
    height:'528',
    number: '3',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2022',
    poem:`
      Родиться должен человек
      И Души в ожидании решенья
      <em>Которую из них отправят на спасенье</em>
      <em>Того </em>
      <em>Которого сейчас должны родить</em>
      Готовятся вселенье совершить
      Идут последние приготовленья
      К перемещенью в Мир иной
      В жизнь
      Что уж прожит<strong>а</strong> Душой
      Прож<strong>и</strong>та
      И ужё ни раз
      <em>Но каждый раз</em>
      <em>Как новый раз</em>
      &nbsp;
      <strong>А нам живущим невдомёк</strong>
      <em>Что Души в нас вселил Господь</em>
      <em>Не для того чтоб нежить плоть</em>
      А чтоб могли
      <em>Любить</em>
      <em>Творить </em>
      <em>Мечтать </em>
      <em>Страдать и вспоминать</em>
      <em>Чтобы была возможность знать</em>
      <strong>Знать </strong>
      <strong><em>Что вселённая Душа уж не настолько хороша</em></strong>
      <strong><em>Что совершенствовать её поручено тебе</em></strong>
      <em>А контролировать процесс поручено звезде</em>
      <strong>Звезде </strong>
      <em>Которая в ночи сияет как маяк</em>
      <strong>Звезде </strong>
      Что будет освещать путей грядущих мрак
      <em>С тем чтобы ты не заплутал</em>
      <em>Не сбился что б с пути</em>
      <em>Чтобы ты мог Душой своей Вселенский Свет&nbsp;</em><em>нести</em>
      <em>Гармонию Душой творя </em>
      <em>Что бы прошёл свой путь</em>
      <em>И чтоб когда наступит срок </em>
      <em>Мог с высоты взглянуть</em>
      <em>На удивительный процесс</em>
      Процесс начала дня
      В который принесла <strong>Судьбу</strong>
      <strong>Тебе Душа твоя</strong> 
    `
  },

    //4
  {
    img: '110-100х70-2018.jpg',
    preview:'110-100х70-2018_400.jpg',
    height:'603',
    number: '4',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2018',
    poem:`
      Кто объяснит
      <em>Что значит <strong>человек</strong></em>
      <em>Как он устроен</em>
      <em>Кем он управляем</em>
      <em>Что значит <strong>жизнь</strong> и <strong>человечий век</strong></em>
      Который мы различно проживаем
      Как так выходит
      <em>Что из ничего</em>
      <em>Как будто вдруг</em>
      <em>Рождаемся на свет</em>
      Вопросов много
      Но пока
      Лишь рассуждения в ответ
      &nbsp;
      <em>Мы рассуждаем обо всём </em>
      <em>И ни о чём в конечном счете</em>
      <em>Мы погрузились в темноту </em>
      <em>Не вспоминая о Природе</em>
      <em>О той природе высших свойств</em>
      <em>Что создаёт возможность <strong>быть</strong></em>
      <em>Но в тоже время </em>
      <em>Позволяет нам очень многое забыть</em>
      <em>О той Природе</em>
      <em>У которой на всё буквально есть ответ</em>
      <strong><em>О той</em></strong>
      <strong><em>Себя венцом которой</em></strong>
      <strong><em>считает глупый человек</em></strong>
    `
  },

    //5
  {
    img: '004-90х80-2015.jpg',
    preview:'004-90х80-2015_400.jpg',
    height:'375',
    number: '5',
    material: 'Холст',
    paints: 'масло',
    size: '90x80',
    year: '2015',
    poem:`
      <em>Вы в розовых очках</em> живёте
      <em>Считая что так легче жить</em>
      Но это не даёт возможность
      О многом с вами говорить
      Вы исказили восприятье
      Простых проявленных вещей
      <em>А с этим не узреть причины </em>
      <em>Пришедшей к вам из прошлых дней</em>
      Вы мотыльком на свет летите
      В стремлении обрести тепло
      Но <strong>то</strong> что видится вам светлым
      В реальной жизни не светло
      И счастье ваше иллюзорно
      <em>Как не поймете вы сего</em>
      И <strong>то</strong> что вы так долго ждёте
      Давно сквозь пальцы утекло
      Но вы привыкли к ожиданью
      Вы им живёте каждый день
      <em>Живёте в торжестве страданья </em>
      <em>Лаская розовую тень</em>
      &nbsp;
      Вы ошибаетесь
      Поверьте
      Ваш Мир хорош тем что в нём есть
      Очки снимите
      Присмотритесь
      Не бойтесь
      Вам пора прозреть
      &nbsp;
      Настал момент
      <em>Решиться нужно </em>
      Увидеть истинность <strong>того</strong>
      <strong>Что</strong> вас сегодня заставляет
      Смотреть на жизнь через стекло  
    `
  },

    //6
  {
    img: '073-100х80-2017.jpg',
    preview:'073-100х80-2017_400.jpg',
    height:'527',
    number: '6',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2017',
    poem:`
      Когда бы вам увидеть дали <strong>то</strong>
      Что кроется за гранью понимания
      <em>Возможно</em>
      Некоторым это помогло б
      Расширить зону самоосознания
    `
  },

    //7
  {
    img: '001-80х100-2005.jpg',
    preview:'001-80х100-2005_400.jpg',
    height:'336',
    number: '7',
    material: 'Холст',
    paints: 'масло',
    size: '80х100',
    year: '2005',
    poem:`
      Лучом тончайшим ухожу в простор
      В просторе я свободен от проблем
      В просторе я живу
      Простор мой дом
      В просторе я Люблю
      Люблю вас всех
      В просторе жизнь не ведает границ
      Простор безмерен
      <em>Как безмерна Вечность</em>
      Простор
      В понятии времени
      <em>Беспечность</em>
      И я беспечен
      <em>Я являюсь им</em>  
    `
  },

    //8
  {
    img: '002-90х60-2005.jpg',
    preview:'002-90х60-2005_400.jpg',
    height:'635',
    number: '8',
    material: 'Холст',
    paints: 'масло',
    size: '90х60',
    year: '2005',
    poem:`
      В меня не веря
      Знаете меня
      В меня не веря
      Вы меня боитесь
      В меня не веря
      Просите меня
      Не веря просите
      При этом не стыдитесь
      &nbsp;
      Для вас для каждого
      Наступит свой черед
      Переосмыслить многое придётся
      &nbsp;
      <em>Теперь пиши</em>
      &nbsp;
      Пусть надо мной смеётся тот
      Кто без последствий для себя смеётся
      &nbsp;
      Но я таких покуда не встречал
      &nbsp;
      <em>Продиктовав ОН замолчал</em> 
    `
  },

    //9
  {
    img: '153-100х80-2020.jpg',
    preview:'153-100х80-2020_400.jpg',
    height:'529',
    number: '9',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2020',
    poem:`
      Вертикальное
      Не линейное
      <em>Постарайтесь его ощущать</em>
      Время Вечности
      Суть мгновения
      <em>Что ещё предстоит осознать</em>
    `
  },

    //10
  {
    img: '101-80х50-2016.jpg',
    preview:'101-80х50-2016_400.jpg',
    height:'678',
    number: '10',
    material: 'Холст',
    paints: 'масло',
    size: '80х50',
    year: '2016',
    poem:`
      Лишь Вечности подвластно Время
      Лишь Вечность формирует ход Времён
      Лишь Вечность наделяет Время бременем
      Блюсти баланс
      <em>Что предопределён</em>
    `
  },

    //11
  {
    img: '157-100х80-2021.jpg',
    preview:'157-100х80-2021_400.jpg',
    height:'528',
    number: '11',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Картинки
      <strong>Те</strong> что открывает зренье
      Являют Суть
      <strong><em>Коль есть прозренье</em></strong>
    `
  },

    //12
  {
    img: '070-100х80-2017.jpg',
    preview:'070-100х80-2017_400.jpg',
    height:'529',
    number: '12',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2017',
    poem:`
      Смешны мы восприятием Природы
      Мы к ней относим
      Дождь
      Траву и лес
      Капризы переменчивой погоды
      <em>А нужно было бы к ней отнести и годы </em>
      <em>Живём в которых формой <strong>Человек</strong></em>
      Где <strong>Человек</strong>
      Всего лишь вид Природы
      Короткий вида срок
      А дальше - <em>Переход</em>
      Причина в том
      Что жизнь границ не знает
      Причина в том
      <em>Что Замысел Высок</em>
      <em>&nbsp;</em>
      Мы продолжаем видоизменяться
      Не прерывая жизненных дорог
      Мы продолжаем перевоплощаться
      Причина в том
      <em>Что Замысел Высок</em>
    `
  },

    //13
  {
    img: '132-100х50-2019.jpg',
    preview:'132-100х50-2019_400.jpg',
    height:'847',
    number: '13',
    material: 'Холст',
    paints: 'масло',
    size: '100x50',
    year: '2019',
    poem:`
      Мы загрубели восприятьем
      Нам
      Для того чтоб различить
      Необходимо на контрасте
      Сюжет событья получить
      &nbsp;
      В полутонах не разобраться
      В полутонах размыта грань
      Полутонов не восприятье
      Неразвитости нашей дань
      &nbsp;
      <em>По той причине и не видим</em>
      <em>Не чувствуем </em>
      <em>Не понимаем</em>
      <strong><em>Мы загрубели восприятьем</em></strong>
      <strong><em>Полутонов не различаем</em></strong>
    `
  },

    //14
  {
    img: '066-110х90-2017.jpg',
    preview:'066-110х90-2017_400.jpg',
    height:'516',
    number: '14',
    material: 'Холст',
    paints: 'масло',
    size: '110х90',
    year: '2017',
    poem:`
      Всё взаимосвязано <strong>настолько</strong>
      Что понять не в&nbsp;силах мы <em>на сколько</em>
    `
  },

    //15
  {
    img: '031-80х60-2014.jpg',
    preview:'031-80х60-2014_400.jpg',
    height:'567',
    number: '15',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Чтоб себя оградить от досадных проблем
      Ты уйти должен <strong>от</strong> составления схем
      &nbsp;
      Схемы
      Это уроки прошедшего дня
      И смывает их ранней росою заря
      Свойства нового дня
      Торжеством перемен
      Не оставят следа
      От составленных схем
      &nbsp;
      <em>Это многим покажется странным подходом</em>
      <strong><em>Ты же знай</em></strong>
      Перед дальним серьёзным походом
      Вверить нужно себя
      Тем
      Кому доверяешь
      И быть готовым познать
      Что покуда не знаешь
      &nbsp;
      <strong><em>Будет резким рывок в Мир наполненный </em></strong>
      <strong><em>новью</em></strong>
      <strong><em>Не робей</em></strong>
      <strong><em>Ты готов</em></strong>
      <strong><em>Ты пройдешь путь с Любовью</em></strong>
    `
  },

    //16
  {
    img: '076-100х80-2017.jpg',
    preview:'076-100х80-2017_400.jpg',
    height:'528',
    number: '16',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2017',
    poem:`
      Случайным не бывает завтра
      Оно
      Рождённое вчера
      Живёт <em>сегодня</em>
      Прошивая собой пространства времена
    `
  },

    //17
  {
    img: '116-100х70-2018.jpg',
    preview:'116-100х70-2018_400.jpg',
    height:'603',
    number: '17',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2018',
    poem:`
      Понять сокрытый смысл дня
      Довольно просто
      <em>Для этого всего лишь нужно снять</em>
      <em>С Души кор<strong>о</strong>сту</em>
    `
  },

    //18
  {
    img: '109-120х100-2018.jpg',
    preview:'109-120х100-2018_400.jpg',
    height:'506',
    number: '18',
    material: 'Холст',
    paints: 'масло',
    size: '120х100',
    year: '2018',
    poem:`
      <strong><em>Одно во Всём и Всё в Одном</em></strong>
      Мы этой формулой живём
      Живём
      Того не понимая
      <em>Что Всё в себе</em>
      <em>Себя во Всём</em>
      Живём
      Буквально растворяя
    `
  },

    //19
  {
    img: '154-100х80-2020.jpg',
    preview:'154-100х80-2020_400.jpg',
    height:'300',
    number: '19',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2020',
    poem:`
      Изнанкой тьмы себя рождает Свет
      Рождает силой мысли в слове
      Формированьем чистой н<strong>о</strong>ви
      Творя среди ноч<strong>и</strong> рассвет
      Напряжено пространство
      Рвётся цвет
      Клокочут Мирозданные Просторы
      Энергий мощью выводя узоры
      В переплетеньях множества небес
      Творящий Свет собой раздвинул тьму
      Своею сутью изменив сред<strong>у</strong>
      Образовав
      Затем стерев граничья
      Полутонам вверяя степени различья
      И в цветопереходах состояний
      Скрыв тайну смысла сотворённых Знаний
      Жизнь пропитав звучанием и цветом
      Объединив слова и мысль при этом
      Сплетает в косу Коды Откровений
      Вселенской Правды
      Вечностью Творений
    `
  },

    //20
  {
    img: '122-80х70-2019.jpg',
    preview:'122-80х70-2019_400.jpg',
    height:'482',
    number: '20',
    material: 'Холст',
    paints: 'масло',
    size: '80х70',
    year: '2019',
    poem:`
      <em>Мир тонкий</em>
      Растворяюсь в нём
      Своей Душой Любовью и доверьем
      Я счастлив тем что он всегда со мной
      Что на пути моём он открывая двери
      Даёт возможность глубже понимать
      <em>Зависимости </em>
      <em>В проявленьях Сути</em>
      <em>Взаимосвязей множества задач</em>
      <em>С распознаванием их в центре перепутий</em>
      Тех перепутий жизненных дорог
      Которые ведут меня к решеньям
      Решеньям
      Формирующим Урок
      <em>Урок познанья</em>
      <strong><em>Часто чрез лишенья</em></strong>
      &nbsp;
      Но вот открыта следующая дверь
      За ней рассвет раскрытья пониманья
      Того
      <strong><em>Что я теперь лишён потерь</em></strong>
      И тем моё расширено сознанье
      &nbsp;
      Ведь те лишения
      <em>Как я считал их прежде</em>
      Мне всё-таки позволили понять
      Понять
      <strong>Как чрез лишенья обретать</strong>
      &nbsp;
      И обретаю я
      Душой творя
      Любовь Вселенского Единого Начала
      Познав
      Что целостность собою означала
      <em>Раскрытие всемерья для меня</em>
      <em>Способность в Тонком Мире жить</em>
      <em>Как и ему во мне</em>
      Познав и то
      <em>Что жизнь моя не только на Земле</em>
      <em>Что жизнь течёт в Мирах иных</em>
      <em>И эта жизнь моя</em>
      <em>Собой являет Вечный Миг</em>
      <em>Существования</em>
    `
  },

    //21
  {
    img: '010-80х60-2016.jpg',
    preview:'010-80х60-2016_400.jpg',
    height:'562',
    number: '21',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2016',
    poem:`
      Вселикостью эфирных проявлений
      <em>Чьи образы сливаются в один</em>
      Звучащей зыбью
      Рождеством явлений
      Простор Вселенский Вечен и Един
      Наполненный событиями жизни
      Дарящий нежность Светлой Чистоты
      Откроется тебе как Дом Единый
      Во всей красе
      <em>После того как ты</em>
      Пройдя этап Земного воплощенья
      <em>Тем сроком что тебе определён</em>
      <strong>Естественностью перевоплощенья </strong>
      Продолжишь путь
      <strong>Которым наделён</strong>  
    `
  },

    //22
  {
    img: '027-80х60-2015.jpg',
    preview:'027-80х60-2015_400.jpg',
    height:'563',
    number: '22',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2015',
    poem:`
      Уносит время миг в былое
      И вот всё <strong>то</strong>
      Что было для тебя родное
      Уж далек<strong>о</strong>
      И не достать его рукою
      <em>Как ни старайся</em>
      <em>Не достать</em>
      Поток стремительных событий не пустишь вспять
      &nbsp;
      Всё в этом Мире проходяще
      <em>Так будет впредь</em>
      <strong><em>И то что есть</em></strong>
      <strong><em>И то что будет</em></strong>
      <strong><em>Не запереть</em></strong>
    `
  },

    //23
  {
    img: '078-70х60-2015.jpg',
    preview:'078-70х60-2015_400.jpg',
    height:'491',
    number: '23',
    material: 'Холст',
    paints: 'масло',
    size: '70х60',
    year: '2015',
    poem:`
      Мысль не видна
      Но виден результат
      <em>Которому ты рад</em>
      <em>Или не рад</em>
    `
  },

    //24
  {
    img: '011-70х60-2015.jpg',
    preview:'011-70х60-2015_400.jpg',
    height:'491',
    number: '24',
    material: 'Холст',
    paints: 'масло',
    size: '70х60',
    year: '2015',
    poem:`
      Свою Судьбу познаешь лишь тогда
      Когда тобою прожита она
      Меж тем
      Возможно видеть завтрашние дни
      <em>Но <strong>тем</strong> кому известно где они</em>
    `
  },

    //25
  {
    img: '108-100х60-2018.jpg',
    preview:'108-100х60-2018_400.jpg',
    height:'705',
    number: '25',
    material: 'Холст',
    paints: 'масло',
    size: '100x60',
    year: '2018',
    poem:`
      Чтобы гармонию творить своей Душой
      Ты должен вспомнить кто ты есть такой
      <strong><em>Покуда не распознан сам собой</em></strong>
      <strong><em>Не сможешь жить в гармонии с Душой</em></strong>
    `
  },

    //26
  {
    img: '052-70х60-2014.jpg',
    preview:'052-70х60-2014_400.jpg',
    height:'495',
    number: '26',
    material: 'Холст',
    paints: 'масло',
    size: '70х60',
    year: '2014',
    poem: `
      Грани форм растворяя
      Убирая границы привычного
      Раздвигая Сознанье за пределы себя
      Ты поймешь<em> что есть <strong>Ты</strong></em>
      Это <strong>то</strong> непривычное
      Что являет собой <em>пустота</em>
      <em>Пустота</em>
      В восприятии формы отсутствия
      <em>Пустота</em>
      В невозможности видеть себя
      <em>Пустота</em>
      Это <strong>я</strong>
      В виде явного сущего
      <em>Пустота</em>
      Это <strong>ты</strong>
      В воплощении меня
      &nbsp;
      Пустоту заполняя собой
      Растворяясь
      Заполняя себя пустотой
      Ты являешь собой
      Только то что осталось
      От меня
      <em>Вновь воскресшим тобой</em>
    `
  },

    //27
  {
    img: '111-100х80-2018.jpg',
    preview:'111-100х80-2018_400.jpg',
    height:'527',
    number: '27',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
    poem: `
      Фиолетовое облако
      Фиолетовым лучом
      Очень бережно коснулось моей думы <em>ни о чём</em>
      &nbsp;
      <em>В фиолетной благодати растворив себя</em>
      <em>Я увидел зарожденье завтрашнего дня</em>
    `
  },

    //28
  {
    img: '143-100х80-2019.jpg',
    preview:'143-100х80-2019_400.jpg',
    height:'527',
    number: '28',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2019',
    poem: `
      Флейтой звучат голоса занебесные
      Скрипкой проснувшейся вздрогнул рассвет
      <em>Жизнь освятил колокольною песнею</em>
      <em>Всё нарастающий Солнечный Свет</em>
      Блики его покрывают узорами
      Реки и горы
      Леса и моря
      <em>Вечность </em>
      <em>Объятую чистыми взорами</em>
      <em>Душам людским подарила заря</em>
    `
  },

    //29
  {
    img: '097-80х60-2015.jpg',
    preview:'097-80х60-2015_400.jpg',
    height:'565',
    number: '29',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2015',
    poem: `
      Небо утром пробуждается
      Умывается прохладою
      И прозрачностью хрустальною насыщает новый день
      Родниковостью звучания
      Новой встречи ожиданием
      Красотой нерукотворною
      Растворяет ночи тень
      Небо
      Небо
      Высь глубинная
      Правда Вечности былинная
      Светом Высшего терпения продолжает нашу жизнь
      Жизнь которой мы удивляемся
      Жизнь которой наслаждаемся
      Жизнь земную
      Жизнь занятную
      <em>Жизнь во многом нам не понятную</em>
    `
  },

    //30
  {
    img: '149-100х80-2020.jpg',
    preview:'149-100х80-2020_400.jpg',
    height:'528',
    number: '30',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2020',
    poem: `
      Я в городе своём
      И подданный и царь
      Преступник и судья
      Палач и лекарь
      Я в городе своём
      И трагик и хохмач
      Я господин и раб
      <em>И всё одновременно</em>
      &nbsp;
      Издав Закон
      Его нарушу сам
      Приговорю себя и оправдаю
      Затем заплачу громко рассмеясь
      Затем люблю и тут же унижаю
      &nbsp;
      Я город свой построил на Века
      Возвёл под небо крепостные стены
      <em>Вот только </em>
      <em>Одиноко иногда</em>
      <em>В закрытом городе</em>
      <em>Под куполом Вселенной</em>
    `
  },

    //31
  {
    img: '064-100х80-2016.jpg',
    preview:'064-100х80-2016_400.jpg',
    height:'529',
    number: '31',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2016',
    poem: `
      Здесь жизнь иная
      Не земная
      И время тут течёт в обратном направлении
      Процесс сей &ndash; тайна для людей
      Сейчас живущих в вашем воплощении
    `
  },

    //32
  {
    img: '152-100х80-2020.jpg',
    preview:'152-100х80-2020_400.jpg',
    height:'527',
    number: '32',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2020',
    poem: `
      Напряжено пространство светом тени
      Взрываются полотна буйством красок
      Ликуют иномерные всецветья
      Вселенской Вечности проявленный порядок
      &nbsp;
      В себя вобрав мазками мощь Всевысья
      Энергией рожденной запредельем
      Холсты
      Ворвавшись в наше измеренье
      Гармонию творят Души прозреньем
      &nbsp;
      Вся сила Света
      Цветовыраженьем
      В бурлящей необузданной свободе
      Себя отдав земному притяженью
      Собой являет Вечный Гимн Природе
    `
  },

    //33
  {
    img: '030-80х60-2015.jpg',
    preview:'030-80х60-2015_400.jpg',
    height:'565',
    number: '33',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2015',
    poem: `
      Отраженья отражений
      Многодверием зеркал
      Повторяя повторенья
      Глубиной проникновенья
      Знать дают <strong>то</strong> что не знал
      &nbsp;
      Коридорами тоннелей
      Увлекают в Мир прозрений
      В Мир свободы новых свойств
      В жизнь иных мироустройств
      В Мир себя осознаванья
      В точке <em>встречерасстованья</em>
      &nbsp;
      <em>В параллельностях теченья </em>
      <em>Протекает обученье</em>
      <em>Открывая тайну знанья</em>
      <em>Через самосознанье</em>
      &nbsp;
      В каждой точке переходов
      В измерения другие
      Мы самих себя встречаем
      Где мы те же
      <em>Но иные</em>
    `
  },

    //34
  {
    img: '067-100х60-2016.jpg',
    preview:'067-100х60-2016_400.jpg',
    height:'705',
    number: '34',
    material: 'Холст',
    paints: 'масло',
    size: '100х60',
    year: '2016',
    poem: `
      Распахните рубахи и Души
      Ветер свежести ощутите
      Этот ветер не ветер стужи
      От него не замёрзнут лужи
      Вы его внутрь себя впустите
      &nbsp;
      Свежий ветер понятий новых
      Освежает моё Сознанье
      Пробуждая меня дремавшего
      Открывает Суть настоящего
      &nbsp;
      Всё что прежде считалось пройденным
      Проявило себя неосмысленным
      Всё что прежде считалось стоящим
      Проявило себя бессмысленным
      &nbsp;
      <em>Распахните рубахи и Души </em>
      <em>Полнотой состояний очнитесь</em>
      <em>И в себе растворяя ветер</em>
      <em>Ветру свежему помолитесь</em>
    `
  },

    //35
  {
    img: '156-100х80-2021.jpg',
    preview:'156-100х80-2021_400.jpg',
    height:'528',
    number: '35',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2021',
    poem: `
      Прозрачным цветом проявляю Свет
      Прозрачной мыслью
      На прозрачном своде
      В прозрачных степенях стремления к свободе
      В прозрачных рамках безграничности границ
      Прозрачность знаний
      На прозрачности страниц
      <em>Отображаю я </em>
      Прозрачности понятий
      Того
      Что мы живя в прозрачности объятий
      В прозрачности невидимых глубин
      Прозрачно чувствуя
      Прозрачно спим
      Прозрачно дышим и прозрачно любим
      Творим прозрачность
      Что прозрачно губим
      Прозрачно помним также забывая
      <em>Живём</em>
      Прозрачности того не понимая
      &nbsp;
      <em>Прозрачность от бескрая до бескрая</em>
      <em>Прозрачной пустотой воспринимая</em>
    `
  },

    //36
  {
    img: '131-100х70-2019.jpg',
    preview:'131-100х70-2019_400.jpg',
    height:'602',
    number: '36',
    material: 'Холст',
    paints: 'масло',
    size: '100х70',
    year: '2019',
    poem: `
      Себя показывают нам
      И то не всем
      А лишь кто видит
      Изгибом преломлений линий
      Ветвей
      Кустов
      Деревьев
      Трав
      &nbsp;
      Для остальных же
      Лес как лес
      Сплошная из ветвей завеса
      &nbsp;
      <em>Не всем себя узреть дают</em>
      <em>Живые вечно</em>
      <em>Духи леса</em>
    `
  },

    //37
  {
    img: '133-100х80-2019.jpg',
    preview:'133-100х80-2019_400.jpg',
    height:'527',
    number: '37',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2019',
    poem: `
      Разлили молоко рассвета
      На лес
      На море
      На холмы
      И бледностью дрожащей света
      Рождая в небе миражи
      Волшебность цветопереходов
      Всё больше проявляя близь
      Даёт понять
      Что в том что рядом мы так и не разобрались
      &nbsp;
      А нас опять влечёт куда-то
      За горизонт
      За край земли
      Здесь оставляя прожитое
      Не зная ТО что впереди
    `
  },

    //38
  {
    img: '119-100х60-2019.jpg',
    preview:'119-100х60-2019_400.jpg',
    height:'705',
    number: '38',
    material: 'Холст',
    paints: 'масло',
    size: '100х60',
    year: '2019',
    poem: `
      Вам д<strong>а</strong>ли будут близкими казаться
      <em>Как только прекратите обижаться</em>
    `
  },

    //39
  {
    img: '017-60х80-2016.jpg',
    preview:'017-60х80-2016_400.jpg',
    height:'315',
    number: '39',
    material: 'Холст',
    paints: 'масло',
    size: '60х80',
    year: '2016',
    poem: `
      Вдалеке
      Как туман растворилось
      Всё что было буквально вчера
      Вместо прошлого зыбью явилась
      Новизна предстоящего дня
      &nbsp;
      А часы
      Отбивают ритмично
      Пульс назад уплывающих лет
      Совершавших обряды насилия
      Надо всеми кого <strong><em>уже</em></strong> нет
      &nbsp;
      Вдалеке
      Как туман растворится
      Всё что будет сегодня и впредь
      Нынче новое
      Станет привычным
      Со способностью быстро стареть
      &nbsp;
      Лишь часы
      По привычке
      Ритмично
      Будут пульс отбивать прошлых лет
      Совершая обряды насилия
      Надо всеми кого <strong><em>пока</em></strong> нет
    `
  },

    //40
  {
    img: '071-110х60-2016.jpg',
    preview:'071-110х60-2016_400.jpg',
    height:'775',
    number: '40',
    material: 'Холст',
    paints: 'масло',
    size: '110x60',
    year: '2016',
    poem: `
      Неба низкого зябко дрожащая просинь
      Распростёрла объятья встречая всех тех
      Кто уходит из лета в прозрачную осень
      Оставляя за спинами время утех
      Обернуться
      Зачем
      Ведь нельзя возвратиться
      То что было прошло и уже не вернуть
      То что было когда-то возможно когда-то&nbsp;приснится
      Через сон позволяя на прошлое время&nbsp;взглянуть
      &nbsp;
      Я не думал что может такое случиться
      Я не думал что осень способна такое со мной&nbsp;сотворить
      Но она сотворила
      <em>В тебя мне позволив влюбиться</em>
      <em>Но при этом не дав то что было забыть</em>
    `
  },

    //41
  {
    img: '142-100х100-2020.jpg',
    preview:'142-100х100-2020_400.jpg',
    height:'421',
    number: '41',
    material: 'Холст',
    paints: 'масло',
    size: '100х100',
    year: '2020',
    poem: `
      Когда ты просишь помощи у <em>сильных</em>
      Как минимум ты должен знать одно
      <em>Что собственное обуздать бессилье</em>
      <em>Тебе и дальше будет не дано</em>
      &nbsp;
      Когда ты просишь помощи у Мудрых
      На этот счёт спокоен можешь быть
      <em>Они дадут тебе возможностью прозренья</em>
      <em>Себя понять и слабость победить</em>
      &nbsp;
      Когда настанет миг и помощь ты отвергнешь
      Себя считая мудрым силачом
      Ты также должен знать
      <em>Что ты утратил силу</em>
      <em>Пред Высшей Силой</em>
      <em>Сыном и Отцом</em>
      &nbsp;
      <strong><em>Ты был сильнее в их глазах</em></strong>
      <strong><em>Когда о помощи просил</em></strong>
      <strong><em>Чем обессиленный сейчас</em></strong>
      <strong><em>Когда гордыню возродил</em></strong>
    `
  },

    //42
  {
    img: '160-70х90-2006.jpg',
    preview:'160-70х90-2006_400.jpg',
    height:'329',
    number: '42',
    material: 'Холст',
    paints: 'масло',
    size: '70х90',
    year: '2006',
    poem: `
      Облака
      Как скомканная вата
      Где-то там
      Вокруг Земли
      <strong>Внизу</strong>
      <em>Той Земли что временем распята</em>
      <em>Той Земли где жил и я когда-то</em>
      <em>И где оставил тех кого люблю</em>
      <em>&nbsp;</em>
      Облака как скомканная вата
      Где-то там
      Вокруг Земли
      <strong>Внизу</strong>
      <em>Той Земли что Вечностью объята</em>
      <em>Той</em>
      <em>Которую я на руках держу</em>
      <em>&nbsp;</em>
      Облака как скомканная вата
      Вот они
      Вокруг Земли
      <strong>Вверху</strong>
      <em>Той Земли где жил и я когда-то</em>
      <em>Той Земли где и теперь живу</em>
      <em>&nbsp;</em>
      Облака
      Как скомканная вата
      Вот они
      Вокруг Земли
      <strong>Вверху</strong>
      <em>Той Земли что чувствами богата</em>
      <em>Той Земли с которой вновь уйду</em>
      &nbsp;
      Облака
      Как скомканная вата
    `
  },

    //43
  {
    img: '087-100х70-2018.jpg',
    preview:'087-100х70-2018_400.jpg',
    height:'466',
    number: '43',
    material: 'Холст',
    paints: 'масло',
    size: '100х70',
    year: '2018',
    poem: `
      Мы держим пост
      И не едим мясного
      <em>Но сколько в мыслях вертится дурного</em>
    `
  },

    //44  
  {
    img: '053-70х60-2015.jpg',
    preview:'053-70х60-2015_400.jpg',
    height:'467',
    number: '44',
    material: 'Холст',
    paints: 'масло',
    size: '70x60',
    year: '2015',
    poem: `
      Откуда ты взялась
      <em>Дитя порока</em>
      <em>Исчадье Ада дивной красоты</em>
      Не удивлюсь
      Если с самой Природой союз бессрочный заключила ты
      &nbsp;
      Глаза &ndash; две пропасти вселенского объёма
      И взор в себя вместивший бесконечность
      Ты столь прекрасна сколь и одинока
      Поскольку в жизни есть лишь только <strong>ты</strong> и <strong>Вечность</strong>
    `
  },

    //45
  {
    img: '037-80х60-2015.jpg',
    preview:'037-80х60-2015_400.jpg',
    height:'565',
    number: '45',
    material: 'Холст',
    paints: 'масло',
    size: '80x60',
    year: '2015',
    poem: `
      Как жаль что этого нельзя
      А как хотелось бы
      Прижаться и ни на миг не расставаться
      Губами прикоснуться к волосам
      Обнять за талию
      Рукою руку гладить
      Но то что есть того нельзя исправить
      Поскольку Вы
      <em>Увы</em>
      Моя мечта
    `
  },

    //46
  {
    img: '140-100х80-2019.jpg',
    preview:'140-100х80-2019_400.jpg',
    height:'529',
    number: '46',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2019',
    poem: `
      Ты прикоснись к моей Душе
      Согрейся коли ты замерз
      Ты прикоснись к моей Душе
      Коль горько на твоей до слёз
      Войди в неё как входишь ты
      В родной любимый дом
      <em>Вот видишь</em>
      <em>В единении Душ нам хорошо вдвоём</em>
      Но вот смотри
      Ещё одна замёрзшая Душа
      <em>Смелее в нашу заходи</em>
      <em>Согреешься тогда</em>
      А вон ещё
      Ещё
      Ещё
      И счёту нет конца
      <em>Нас примет всех и приютит Вселенская Душа</em>
      &nbsp;
      <strong><em>И испытав себя во Всём</em></strong>
      <strong><em>И Всё в себе одном</em></strong>
      <strong><em>Ты в этом доме дверью стал</em></strong>
      <strong><em>И сам же к ней ключом</em></strong>
    `
  },

    //47
  {
    img: '019-70х70-2016.jpg',
    preview:'019-70х70-2016_400.jpg',
    height:'510',
    number: '47',
    material: 'Холст',
    paints: 'масло',
    size: '70х70',
    year: '2016',
    poem: `
      Прислушайся
      Как вздрагивают звуки
      В туманке утра розовой зари
      Прозрачной зыбью пробуждая чувства
      Прохладой ранней растворяя сны
      Как юный ветерок вплетая в травы
      Свободу вечной песни соловья
      В реке умывшись
      Улетает вд<strong>а</strong>ли
      С собою унося твои года
      &nbsp;
      <em>Аккордом позабытого мотива</em>
      <em>Вернулась память</em>
      <em>Принеся мне <strong>то</strong></em>
      <em>Что столько лет Душа моя хранила</em>
      <em>Хранила <strong>так</strong></em>
      <em>Что позабыла <strong>Что</strong></em>
    `
  },

    //48
  {
    img: '117-100х80-2019.jpg',
    preview:'117-100х80-2019_400.jpg',
    height:'500',
    number: '48',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2019',
    poem: `
      Зажёг свечу
      За дверью ночь логичным продолженьем дня
      Окутав торжеством луны туманом скрытые поля
      Творит волшебностью росы свой еженощный ритуал
      Чтобы к утру цветок Души над Миром Новью заблистал
      Горит свеча
      Даруя мне тепло Духовного огня
      что разливается во мне
      И этим светом счастлив я
      Гори
      Не угасай свеча
      В тебе свет мысли заключён
      В тебе любовь и жизнь моя
      и звёзд хрустальный перезвон
      Не угасай
      Гори свеча
      Твори святое волшебство
      Преобразовывай меня будя природы естество
      &nbsp;
      <em>Когда же был оплавлен воск </em>
      <em>И свет свечи к утру погас </em>
      <em>Свеча</em>
      <em>Горящая во мне</em>
      <em>Сияла ярче</em>
      <em>В сотни раз</em>
    `
  },

    //49
  {
    img: '102-100х80-2018.jpg',
    preview:'102-100х80-2018_400.jpg',
    height:'527',
    number: '49',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
    poem: `
      Трудны
      Не предсказуемы дороги
      <em>То поворот слепой</em>
      <em>То вверх</em>
      <em>То резко вниз</em>
      <em>То крен такой что дрожью сводит ноги</em>
      <em>То плато</em>
      <em>То ущелье</em>
      <em>То карниз</em>
      &nbsp;
      Но я привык к подобным перепадам
      Привык к огню
      Привык к воде
      Я на пути своём преград встречал не мало
      <em>Случалось даже страшно было мне</em>
      &nbsp;
      Да
      Я привык
      Я путешествую с рожденья
      Я путешествую собой в своей Судьбе
      <em>То в руки руль беру для управленья</em>
      <em>То пассажиром еду на себе</em>
    `
  },




    // Старые со стихами

    //50
  {
    img: 'pic_02.jpg',
    preview:'pic_02_400.jpg',
    height:'321',
    number: '50',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Я на краю стою
      Вокруг меня <em>бездонье</em>
      Не различить объёма глубины
      Масштабы те что были мне известны
      Не применить
      Границы не видны
      &nbsp;
      <em>Отсутствие понятий измерений</em>
      <em>Даёт почувствовать безмерность величин</em>
      <em>Эфирной зыби в красоте явлений</em>
      <em>Вселенской плоти</em>
      <em>С коей я един</em>
      &nbsp;
      Я на краю стою своих непониманий
      Вокруг меня
      Безмерной глубиной
      Единое Вселенское Сознанье
      <em>Я им дышу</em>
      <em>Как дышит оно мной</em>
    `
  },

    //51
  {
    img: 'pic_03.jpg',
    preview:'pic_03_400.jpg',
    height:'515',
    number: '51',
    material: 'Холст',
    paints: 'масло',
    size: '405x522',
    year: '2021',
    poem:`
      Иду размерено не торопясь
      А мне толпа бежит навстречу
      Стараются друг друга обогнать
      Подножки ставят
      Тем себя калеча
      &nbsp;
      Кричат мне набегу что я сошел с ума
      Что я иду в обратном направленье
      А я иду
      И мне легко шагать
      Я знаю <strong>то</strong>
      Что не дано им знать
      Поскольку
      Возвращаюсь я оттуда
      Куда им не удастся добежать
      &nbsp;
      Я возвратился чтобы предложить
      Всё <strong>то</strong>
      Чем я теперь владею
      Но видя то что вижу
      Я не смею
      <em>С живущим на бегу не</em> <em>ст<strong>о</strong>ит говорить</em>
      &nbsp;
      Я буду говорить для вас
      <em>Кто не бежит</em>
      <em>Кто сердцем слышать может</em>
      Пусть каждому из вас Душа поможет
      Услышать <strong>то</strong>
      Что буду говорить   
    `
  },

    //52
  {
    img: 'pic_04.jpg',
    preview:'pic_04_400.jpg',
    height:'332',
    number: '52',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Чтобы понять природу <em>Высшего Начала</em>
      Себя познать должны мы для начала
      Увидеть что творится вокруг нас
      А мы живём не открывая глаз
      &nbsp;
      Стремленье <em>Жить</em>
      Мы путаем с желаньем
      Господний дар <em>Творить</em>
      Смешали с подражаньем
      Событья происшедшие с другими
      Мы для себя считаем роковыми
      <strong>&nbsp;</strong>
      <em>Мы путаемся </em>
      <em>Путая других</em>
      <em>Причин не ведая </em>
      <em>Не понимая их</em>
    `
  },

    //53
  {
    img: 'pic_05.jpg',
    preview:'pic_05_400.jpg',
    height:'535',
    number: '53',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Позабыты нами времена
      Когда небо было золотое
      <em>Сейчас оно совсем уже другое</em>
      <em>Теперь иная неба красота</em>
      Но дело не в красивости небес
      Всё дело в знаниях открытых
      Когда-то небом золотым разлитых
      На океан
      На горы
      И на лес
      Тогда
      Живущие на <em>голубой планете </em>чисты как Боги были иль как дети
      Тогда
      Естественность природного пути прозренье позволяла обрести
      &nbsp;
      Позабыты нами времена
      Когда небо было золотое
      Сейчас оно совсем уже другое
      Теперь иная неба красота
      И мы совсем не те что были прежде
      А небо продолжает жить в надежде
      На то
      <em>Что всё ж наступит час</em>
      <em>Проснётся память в спящих нас</em>
      <em>И всколыхнётся синь небес</em>
      <em>И золото Высоких Знаний вернёт способность пониманий</em>
      <em>Взаимосвязей множества чудес</em>  
    `
  },

    //54
  {
    img: 'pic_06.jpg',
    preview:'pic_06_400.jpg',
    height:'515',
    number: '54',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Не грусти
      Пройдёт и это
      Отбушует
      Отгремит
      Капля <strong><em>нежного</em></strong> рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Растворит собой тревогу
      Душу Светом исцелит
      &nbsp;
      Не грусти
      Пройдёт и это
      Отликует
      Отблестит
      Капля <strong><em>нового </em></strong>рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Дверь в <em>иное</em> отворит
      &nbsp;
      Не грусти
      Пройдёт и это
      Новым свойством
      Новых дней
      Каплей <strong><em>Вечного </em></strong>рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Сможешь видеть Мир людей
      &nbsp;
      Только знай
      Пройдёт и это
      И тогда увидишь вновь
      Каплю <strong><em>нежного</em></strong> рассвета
      <em>Уверяю</em>
      <em>Будет это</em>
      Что в тебе разбудит кровь
    `
  },

    //55
  {
    img: 'pic_08.jpg',
    preview:'pic_08_400.jpg',
    height:'458',
    number: '55',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Чтоб тоньше чувствовать и глубже понимать
      Придётся многое вам в жизни испытать
      <em>Точней пожалуй даже будет так</em>
      <strong><em>Вам не пройти на Свет </em></strong>
      <strong><em>Пока не пройден мрак</em></strong>
    `
  },

    //56
  {
    img: 'pic_09.jpg',
    preview:'pic_09_400.jpg',
    height:'319',
    number: '56',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Когда мы спим
      <em>Не дремлют небеса</em>
      Когда мы молимся
      <em>Они тому внимают</em>
      Когда мы думаем
      <em>Они нам помогают</em>
      О чём мечтаем мы
      <strong><em>То </em></strong>
      <em>Небеса творят</em>
      &nbsp;
      Кто может слышать
      <em>Тот услышит их</em>
      Кто может видеть
      <em>Тем они покажут</em>
      Желающему знать
      <em>Они расскажут</em>
      Кто горделив без меры
      <em>Тех накажут</em>
      Себя понять стремящимся
      <em>Подскажут </em>
      И знать дадут
      <strong>Что<em> помыслы рожденные Душой</em></strong>
      <strong><em>Суть проявляют</em></strong>
      <strong><em>Быть дают собой</em></strong>
    `
  },

    //57
  {
    img: 'pic_10.jpg',
    preview:'pic_10_400.jpg',
    height:'525',
    number: '57',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>В</strong>ышло время захлопнув дверь
      Она ему не нужна теперь
      И как ненужность закрыло окно
      Ни к чему теперь и оно
      Они были ему нужны
      Много лет и до сей поры
      А когда всё что было прошло
      Вслед за прошлым и время ушло
      Но я вижу в сегодняшних днях
      Как плутает в его следах
      Время <strong>то</strong> что пришло взамен
      Непривычностью перемен
    `
  },

    //58
  {
    img: 'pic_11.jpg',
    preview:'pic_11_400.jpg',
    height:'457',
    number: '58',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>К</strong>артинки
      <strong>Те </strong>что открывает зренье
      Являют Суть
      <strong><em>Коль есть прозренье</em></strong>
    `
  },

    //59
  {
    img: 'pic_12.jpg',
    preview:'pic_12_400.jpg',
    height:'532',
    number: '59',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Летний снег на зимнюю траву
      Сыплется
      Струясь грибным дождём
      Заполняя этот мир обманом
      Тем
      В котором мы всю жизнь живём
    `
  },

    //60
  {
    img: 'pic_14.jpg',
    preview:'pic_14_400.jpg',
    height:'565',
    number: '60',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>Д</strong>ля каждого найдётся искушенье
      Вопрос лишь в том
      <em>Готов ли к искупленью </em>
    `
  },

    //61
  {
    img: 'pic_16.jpg',
    preview:'pic_16_400.jpg',
    height:'601',
    number: '61',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>П</strong>есочные часы
      Текучестью мгновенья
      Песком иных времён пересыпают время
      Крупицами прошедшего струится
      Период Вечности
      Который длится
      Лишь краткий миг перетеканья время
      Вернувшегося струйкой откровенья
    `
  },

    //62
  {
    img: 'pic_17.jpg',
    preview:'pic_17_400.jpg',
    height:'359',
    number: '62',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>В</strong>ы <em>в розовых очках</em> живёте
      <em>Считая что так легче жить</em>
      Но это не дает возможность
      О многом с вами говорить
      Вы исказили восприятье
      Простых проявленных вещей
      А с этим не узреть причины
      Пришедшей к вам из прошлых дней
      Вы мотыльком на свет летите
      В стремленье обрести тепло
      Но то что видится вам светлым
      В реальной жизни несветл<strong>о</strong>
      И счастье ваше иллюзорно
      <em>Как не поймете вы сего</em>
      И <strong>то</strong> что вы так долго ждёте
      Давно сквозь пальцы утекло
      Но вы привыкли к ожиданью
      Вы им живёте каждый день
      Живёте в торжестве страданья
      Лаская розовую тень
      &nbsp;
      Вы ошибаетесь
      Поверьте
      Наш Мир хорош тем что в нём есть
      Очки снимите
      Присмотритесь
      Не бойтесь
      Вам пора прозреть
      &nbsp;
      Настал момент
      Решиться нужно
      Увидеть <em>истинность <strong>того</strong></em>
      Что вас сегодня заставляет
      Смотреть на жизнь через стекло
    `
  },

    //63
  {
    img: 'pic_18.jpg',
    preview:'pic_18_400.jpg',
    height:'602',
    number: '63',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>К</strong>ак много времени
      <em>По меркам человечьим</em>
      Прошло с тех пор
      Когда впервые
      Увидел горы золотые
      Материки и океанов гладь
      Увидел
      В первозданном виде
      Всё <strong>то</strong>
      Что нынче не узнать
      &nbsp;
      И ты
      Народ сегодняшней Планеты
      Ты стал иной
      В отличие от тех
      Которые собою заселяли Планету эту
      <em>Я их помню всех</em>
    `
  },

    //64
  {
    img: 'pic_20.jpg',
    preview:'pic_20_400.jpg',
    height:'722',
    number: '64',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      Прощённым ты настолько можешь быть
      Насколько в состоянии простить
    `
  },

    //65
  {
    img: 'pic_22.jpg',
    preview:'pic_22_400.jpg',
    height:'314',
    number: '65',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>Р</strong>елигий много
      А <em>Исток</em> один
      Един Исток <em>с Момента и Вовеки</em>
      А мы живём не видя <em>Высоты</em>
      Живём <strong>в бреду</strong> не поднимая веки
      &nbsp;
      <strong>Возможно ли кого-то обвинить</strong>
      В том что понятие Единого Начала
      <em>Как Суть глубин познания себя</em>
      Отдали в руки <strong>тех</strong>
      Кто изначально
      В стремлении открыть природность дней
      Даруя людям Знания Единства
      Не в состояньи были углядеть незримость нашу
      Сути<em> Триединства</em>
      Что растащив понятья по углам
      Соорудив из них себе оружье
      Построил каждый для себя свой Храм
      <strong>Крупицей</strong> знанья Смысла<em> Вездесущья</em>
      <em>&nbsp;</em>
      Но я в свой час определил конец
      Непониманья мною многих сутей
      И он явил начальность для меня
      Пути начальность
      Между перепутий
      Мой путь открыл мне многие слова
      Что до того скрывали Коды Знаний
      И Коды Знаний развернули для меня
      Суть слитных Смыслов в Мире пониманий
      <strong>И вот</strong> слова творимые Душой
      Всех вас в Души просторы приглашая
      Являя <em>Суть Целительства </em>собой
      <em>Лекарства Суть</em>
      Болезни отгоняя
      <em>Бальзамом </em>служат для глубоких ран
      <em>Нектаром </em>ото всех недугов жизни
      Надеждой
      Верой
      Знаньем исцелят
      <strong>Ваш</strong> открывая путь к потоку мыслей
      &nbsp;
      <strong><em>И знайте</em></strong>
      <strong><em>БОЛЬ</em></strong> ДЛЯ ВСЕХ ЛЮДЕЙ ЗЕМЛИ
      ЕСТЬ ПОКАЗАТЕЛЬ <strong><em>СКОЛЬ БЕЗДУМНЫ МЫ</em></strong>
    `
  },

    //66
  {
    img: 'pic_24.jpg',
    preview:'pic_24_400.jpg',
    height:'451',
    number: '66',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>Б</strong>езмерное количество Миров
      Безмерны формы их существованья
      Взаимосвязь безмерных величин
      Не подлежит Земному толкованью
      &nbsp;
      Иерархия соблюдена везде
      Во Всём царит Закон <em>Причин и Следствий</em>
      Вселенский Разум Властью наделён
      Блюсти Баланс сложением последствий
      &nbsp;
      Я знаю <strong>то</strong>
      Что многие из вас
      <em>В любой из жизни-форм в которой вы сейчас</em>
      Увидев жизнь свою как искорку мгновенья
      Способны ощутить Вселенскую волну
      <em>Волну Свободы</em>
      Вспышкой озаренья
      &nbsp;
      Не важно кто Вы
      И не важно где
      <em>Вы на Земле живете иль не на Земле</em>
      <strong>Но знайте</strong>
      <strong><em>Миропостроенье</em></strong>
      <strong><em>Есть лишь фрагмент Единого творенья</em></strong>
    `
  },

    //67
  {
    img: 'pic_25.jpg',
    preview:'pic_25_400.jpg',
    height:'506',
    number: '67',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2021',
    poem:`
      <strong>П</strong>оверить в это очень просто
      <em>Но в жизни нет сложнее ничего</em>
      Чтобы поверить <strong>в то</strong>
      <strong>Что всё возможно</strong>
      <em>Стремленьем <strong>Вашим</strong></em>
      <em>Волею <strong>ЕГО</strong></em>
    `
  },






    //Без стихов

    //68
  {
    img: '060-110х90-2016.jpg',
    preview:'060-110х90-2016_400.jpg',
    height:'491',
    number: '68',
    material: 'Холст',
    paints: 'масло',
    size: '110x90',
    year: '2016',
    poem: `
      
    `
  },

    //69
  {
    img: '061-100х70-2016.jpg',
    preview:'061-100х70-2016_400.jpg',
    height:'573',
    number: '69',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2016',
  },

    //70
  {
    img: '062-100х70-2016.jpg',
    preview:'062-100х70-2016_400.jpg',
    height:'578',
    number: '70',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2016',
  },

    //71
  {
    img: '063-100х60-2017.jpg',
    preview:'063-100х60-2017_400.jpg',
    height:'671',
    number: '71',
    material: 'Холст',
    paints: 'масло',
    size: '100x60',
    year: '2017',
  },

    //72
  {
    img: '072-100х70-2017.jpg',
    preview:'072-100х70-2017_400.jpg',
    height:'578',
    number: '72',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2017',
  },

    //73
  {
    img: '074-100х80-2017.jpg',
    preview:'074-100х80-2017_400.jpg',
    height:'501',
    number: '73',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2017',
  },

    //74
  {
    img: '075-110х90-2017.jpg',
    preview:'075-110х90-2017_400.jpg',
    height:'495',
    number: '74',
    material: 'Холст',
    paints: 'масло',
    size: '110x90',
    year: '2017',
  },

    //75
  {
    img: '077-100х70-2017.jpg',
    preview:'077-100х70-2017_400.jpg',
    height:'579',
    number: '75',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2017',
  },

    //76
  {
    img: '008-60х70-2014.jpg',
    preview:'008-60х70-2014_400.jpg',
    height:'356',
    number: '76',
    material: 'Холст',
    paints: 'масло',
    size: '60х70',
    year: '2014',
  },

    //77
  {
    img: '058-100х70-2016.jpg',
    preview:'058-100х70-2016_400.jpg',
    height:'609',
    number: '77',
    material: 'Холст',
    paints: 'масло',
    size: '100х70',
    year: '2016',
  },

    //78
  {
    img: '086-100х80-2018.jpg',
    preview:'086-100х80-2018_400.jpg',
    height:'504',
    number: '78',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
  },

    //79
  {
    img: '088-100х80-2018.jpg',
    preview:'088-100х80-2018_400.jpg',
    height:'503',
    number: '79',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
  },

    //80
  {
    img: '090-100х80-2018.jpg',
    preview:'090-100х80-2018_400.jpg',
    height:'500',
    number: '80',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
  },

    //81
  {
    img: '092-100х70-2017.jpg',
    preview:'092-100х70-2017_400.jpg',
    height:'573',
    number: '81',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2017',
  },

    //88
  {
    img: '107-100х80-2018.jpg',
    preview:'107-100х80-2018_400.jpg',
    height:'501',
    number: '88',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
  },

    //89
  {
    img: '112-100х80-2018.jpg',
    preview:'112-100х80-2018_400.jpg',
    height:'505',
    number: '89',
    material: 'Холст',
    paints: 'масло',
    size: '100x80',
    year: '2018',
  },

    //90
  {
    img: '118-80х60-2013.jpg',
    preview:'118-80х60-2013_400.jpg',
    height:'533',
    number: '90',
    material: 'Холст',
    paints: 'масло',
    size: '80x60',
    year: '2013',
  },

    //91
  {
    img: '005-90х70-2015.jpg',
    preview:'005-90х70-2015_400.jpg',
    height:'551',
    number: '91',
    material: 'Холст',
    paints: 'масло',
    size: '90х70',
    year: '2015',
  },

    //92
  {
    img: '079-100х70-2017.jpg',
    preview:'079-100х70-2017_400.jpg',
    height:'573',
    number: '92',
    material: 'Холст',
    paints: 'масло',
    size: '100x70',
    year: '2017',
  },


  //93
  {
    img: '009-90х60-2014.jpg',
    preview:'009-90х60-2014_400.jpg',
    height:'635',
    number: '93',
    material: 'Холст',
    paints: 'масло',
    size: '90х60',
    year: '2014',
  },

    //94
  {
    img: '009-90х60-2014.jpg',
    preview:'009-90х60-2014_400.jpg',
    height:'635',
    number: '94',
    material: 'Холст',
    paints: 'масло',
    size: '90х60',
    year: '2014',
  },

    //95
  {
    img: '016-70х90-2008.jpg',
    preview:'016-70х90-2008_400.jpg',
    height:'327',
    number: '95',
    material: 'Холст',
    paints: 'масло',
    size: '70х90',
    year: '2008',
  },

    //96
  {
    img: '024-80х60-2015.jpg',
    preview:'024-80х60-2015_400.jpg',
    height:'568',
    number: '96',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2015',
  },

    //97
  {
    img: '020-70х60-2014.jpg',
    preview:'020-70х60-2014_400.jpg',
    height:'488',
    number: '97',
    material: 'Холст',
    paints: 'масло',
    size: '70х60',
    year: '2014',
  },

    //98
  {
    img: '021-80х60-2015.jpg',
    preview:'021-80х60-2015_400.jpg',
    height:'488',
    number: '98',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2015',
  },

    //99
  {
    img: '022-100х50-2016.jpg',
    preview:'022-100х50-2016_400.jpg',
    height:'856',
    number: '99',
    material: 'Холст',
    paints: 'масло',
    size: '100х50',
    year: '2016',
  },

    //100
  {
    img: '023-90х50-2015.jpg',
    preview:'023-90х50-2015_400.jpg',
    height:'761',
    number: '100',
    material: 'Холст',
    paints: 'масло',
    size: '90х50',
    year: '2015',
  },

    //101
  {
    img: '018-70х70-2016.jpg',
    preview:'018-70х70-2016_400.jpg',
    height:'420',
    number: '101',
    material: 'Холст',
    paints: 'масло',
    size: '70х70',
    year: '2016',
  },

    //102
  {
    img: '026-90х60-2016.jpg',
    preview:'026-90х60-2016_400.jpg',
    height:'638',
    number: '102',
    material: 'Холст',
    paints: 'масло',
    size: '90х60',
    year: '2016',
  },

    //103
  {
    img: '054-80х60-2014.jpg',
    preview:'054-80х60-2014_400.jpg',
    height:'561',
    number: '103',
    material: 'Холст',
    paints: 'масло',
    size: '80х60',
    year: '2014',
  },

    //104
  {
    img: '055-100х80-2016.jpg',
    preview:'055-100х80-2016_400.jpg',
    height:'526',
    number: '104',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2016',
  },

    //105
  {
    img: '056-100х80-2016.jpg',
    preview:'056-100х80-2016_400.jpg',
    height:'538',
    number: '105',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2016',
  },

    //106
  {
    img: '057-100х80-2016.jpg',
    preview:'057-100х80-2016_400.jpg',
    height:'527',
    number: '106',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2016',
  },

    //107
  {
    img: '082-70х80-2016.jpg',
    preview:'082-70х80-2016_400.jpg',
    height:'345',
    number: '107',
    material: 'Холст',
    paints: 'масло',
    size: '70x80',
    year: '2016',
  },

   //108
  {
    img: '057-100х80-2016.jpg',
    preview:'057-100х80-2016_400.jpg',
    height:'527',
    number: '108',
    material: 'Холст',
    paints: 'масло',
    size: '100х80',
    year: '2016',
  },
]


